import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AnimatedPage from './AnimatedPage';
import HomeCountdownTimer from '../components/timers/HomeCountdownTimer';
import TeamsHeroSection from './TeamsHeroSection';
import CatchesHeroSection from './CatchesHeroSection';
import SponsorsHeroSection from './SponsorsHeroSection'; 
import EventFeaturesSection from './EventFeaturesSection';
import { Link } from 'react-router-dom';
import Footer from '../components/footer/Footer';
import './HomePage.css';
import './RegisterPage.css';

function HomePage() {
  const { username, eventId } = useParams();
  const [eventSettings, setEventSettings] = useState(null);
  const [numCatches, setNumCatches] = useState(0);   
  const [loadingEventSettings, setLoadingEventSettings] = useState(true);

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
      ? process.env.REACT_APP_SERVER_URL_PRODUCTION
      : process.env.REACT_APP_SERVER_URL_STAGING;

    const fetchEventSettings = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/load_event_profile`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username, eventId }),
        });
        const data = await response.json();
        if (data.success) {
          setEventSettings(data.settings);
          console.log('Here is the event profile that we loaded: ', data.settings)
        } else {
          console.error('Error fetching event settings:', data.message);
        }
      } catch (error) {
        console.error('Error fetching event settings:', error);
      } finally {
        setLoadingEventSettings(false);
      }
    };

    const fetchTotalCatches = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/get_total_num_catches`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username, eventId }),
        });
        const data = await response.json();
        if (data.success) {
          setNumCatches(data.total);
        } else {
          console.error('Error fetching total catches:', data.message);
        }
      } catch (error) {
        console.error('Error fetching total catches:', error);
      }
    };

    fetchEventSettings();
    fetchTotalCatches();
  }, [username, eventId]);

  const formatDate = (epochTime) => {
    if (!epochTime) return '';
    
    const options = { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
      timeZoneName: 'short'
    };
    const date = new Date(Number(epochTime));
    return date.toLocaleDateString('en-US', options);
  };

  const formatPrice = (price) => {
    if (!price) return 'Free';
    
    // If price is a string with digits, convert to number
    const numericPrice = typeof price === 'string' ? parseFloat(price) : price;
    
    // Handle non-numeric or NaN values
    if (isNaN(numericPrice)) return 'Free';
    
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(numericPrice);
  };

  // Check if registration is enabled AND visible
  const isRegistrationEnabled = () => {
    return (eventSettings?.features?.onlineTeamRegistration === "customerPays" || 
           eventSettings?.features?.onlineTeamRegistration === "iPay") &&
           (eventSettings?.pageVisibility?.registration !== false);
  };

  // Check if sponsors feature is enabled AND visible
  const isSponsorFeatureEnabled = () => {
    return (eventSettings?.features?.onlineSponsorRegistration === "customerPays" || 
           eventSettings?.features?.onlineSponsorRegistration === "iPay") &&
           (eventSettings?.pageVisibility?.sponsors !== false);
  };

  // Check if leaderboard is visible
  const isLeaderboardVisible = () => {
    return eventSettings?.pageVisibility?.leaderboard !== false;
  };

  // Check if pots are enabled AND visible
  const isPotsFeatureEnabled = () => {
    return (eventSettings?.features?.fishPots === "customerPays" || 
           eventSettings?.features?.fishPots === "iPay") &&
           (eventSettings?.pageVisibility?.pots !== false);
  };

  // Fix website link for proper navigation
  const getFormattedWebsiteLink = (link) => {
    if (!link) return '';
    
    // Check if link already has a protocol
    if (link.startsWith('http://') || link.startsWith('https://')) {
      return link;
    }
    
    // Add https:// to the link
    return `https://${link}`;
  };
  
  // Format division name by removing " (default)" text
  const formatDivisionName = (name) => {
    if (!name) return '';
    return name.replace(/\s*\(default\)\s*/g, '');
  };

  // Render registration divisions and pricing
  const renderRegistrationDetails = () => {
    const { registration } = eventSettings;
    const hasDivisions = registration?.includeDivisions && registration?.divisions && registration?.divisions.length > 0;
    
    if (!hasDivisions) {
      // Simple case: no divisions
      return (
        <div className="button-section">
          {registration?.includeEarlyBird && (
            <div>
              <h2 className="text-2xl md:text-3xl font-semibold mb-2">Early Bird Registration</h2>
              <p className="text-xl md:text-2xl">{formatPrice(registration?.earlyBirdPrice)} <span className="opacity-80">until {formatDate(registration?.earlyBirdDate)}</span></p>
            </div>
          )}
          <div className="mt-4">
            <h2 className="text-2xl md:text-3xl font-semibold mb-2">Regular Registration</h2>
            <p className="text-xl md:text-2xl">{formatPrice(registration?.registrationPrice)} <span className="opacity-80">until {formatDate(registration?.registrationDate)}</span></p>
          </div>
          <div className="mt-8">
            <Link
              to={`/${username}/${eventId}/register`}
              className="home-signup-button"
              style={{
                backgroundColor: '#0281C5',
                fontSize: '1.25rem',
                padding: '0.75rem 1.5rem'
              }}
            >
              Register a Team
            </Link>
          </div>
        </div>
      );
    }
    
    // With divisions: show all divisions with their respective prices
    return (
      <div className="button-section">
        <h2 className="text-2xl md:text-3xl font-semibold mb-4">Registration Options</h2>
        
        {registration.divisions.map((division, index) => (
          <div key={index} className="mb-6 pb-4 border-b border-white/20 last:border-0">
            <h3 className="text-xl md:text-2xl font-semibold mb-2">{formatDivisionName(division.name)}</h3>
            
            {registration.includeEarlyBird && division.earlyBirdPrice && (
              <div className="mt-2">
                <p className="text-lg md:text-xl">
                  <span className="font-medium">Early Bird:</span> {formatPrice(division.earlyBirdPrice)} <span className="opacity-80 ml-2">
                    until {formatDate(registration.earlyBirdDate)}
                  </span>
                </p>
              </div>
            )}
            
            <div className="mt-2">
              <p className="text-lg md:text-xl">
                <span className="font-medium">Regular:</span> {formatPrice(division.registrationPrice)} <span className="opacity-80 ml-2">
                  until {formatDate(registration.registrationDate)}
                </span>
              </p>
            </div>
          </div>
        ))}
        
        <div className="mt-8">
          <Link
            to={`/${username}/${eventId}/register`}
            className="home-signup-button"
            style={{
              backgroundColor: '#0281C5',
              fontSize: '1.25rem',
              padding: '0.75rem 1.5rem'
            }}
          >
            Register a Team
          </Link>
        </div>
      </div>
    );
  };

  if (loadingEventSettings) return <div>Loading...</div>;

  return (
    <AnimatedPage>
      <main className="min-h-screen">
        {/* Hero Section with Texture */}
        <section 
          className="relative h-screen flex items-center justify-center mobile-hero-section"
          style={{ 
            backgroundColor: eventSettings?.basicInfo?.backgroundColor || '#87CEEB',
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='rgba(255,255,255,0.05)' fill-rule='evenodd'/%3E%3C/svg%3E")`,
            backgroundRepeat: 'repeat'
          }}
        >
          <div 
            className="relative z-10 text-center px-4 mobile-hero-content"
            style={{ color: eventSettings?.basicInfo?.headlineTextColor || '#ffffff' }}
          >
            <br/>
            <h1 className="text-4xl md:text-7xl font-bold mb-4 px-2 md:px-0 mt-8 md:mt-12 mobile-title">
              {eventSettings?.basicInfo?.title}
            </h1>
            <br/>
            <br/>
            <p className="text-lg md:text-2xl mb-6 px-2 md:px-0 mobile-subtitle">
              {eventSettings?.basicInfo?.subheadline}
            </p>
            <br/>
            <div className="bg-white/10 backdrop-blur-md rounded-lg p-4 md:p-8 mobile-details-container">
              <h2 className="text-xl md:text-2xl font-semibold mb-3">
                {eventSettings?.basicInfo?.startDate === eventSettings?.basicInfo?.endDate
                  ? formatDate(eventSettings?.basicInfo?.startDate)
                  : `${formatDate(eventSettings?.basicInfo?.startDate)} - ${formatDate(eventSettings?.basicInfo?.endDate)}`}
              </h2>
              {eventSettings?.basicInfo?.showCountdown && (
                <HomeCountdownTimer 
                  className="text-2xl md:text-3xl font-bold mb-6"
                  targetDate={parseInt(eventSettings?.basicInfo?.startDate, 10)} 
                />
              )}
              
              {/* Registration and Sponsor Details */}
              <br/>
              <div className="event-buttons">
                {isRegistrationEnabled() && renderRegistrationDetails()}
                
                {/* Sponsor Button - Only show if sponsors feature is enabled */}
                {isSponsorFeatureEnabled() && (
                  <div className="button-section">
                    <h2 className="text-2xl md:text-3xl font-semibold mb-4">Support This Event</h2>
                    <Link
                      to={`/${username}/${eventId}/sponsors`}
                      className="home-signup-button"
                      style={{
                        backgroundColor: '#0281C5',
                        fontSize: '1.25rem',
                        padding: '0.75rem 1.5rem'
                      }}
                    >
                      Sponsor Event
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>

        {/* Teams Hero Section */}
        <TeamsHeroSection username={username} eventId={eventId} />

        {/* Catches Hero Section */}
        <CatchesHeroSection username={username} eventId={eventId} />

        {/* Sponsors Hero Section - Only show if sponsors feature is enabled */}
        {isSponsorFeatureEnabled() && (
          <SponsorsHeroSection username={username} eventId={eventId} />
        )}

        {/* Event Features */}
        {/* Render Event Features Section only if eventSettings are loaded */}
        {eventSettings && (
          <EventFeaturesSection 
            username={username} 
            eventId={eventId} 
            eventSettings={eventSettings}
            isRegistrationEnabled={isRegistrationEnabled}
            isSponsorFeatureEnabled={isSponsorFeatureEnabled}
            isLeaderboardVisible={isLeaderboardVisible}
            isPotsFeatureEnabled={isPotsFeatureEnabled}
            getFormattedWebsiteLink={getFormattedWebsiteLink}
          />
        )}

        {/* Footer */}
        <Footer />
      </main>
    </AnimatedPage>
  );
}

export default HomePage;

