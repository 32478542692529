import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import AnimatedPage from './AnimatedPage';
import Footer from '../components/footer/Footer';
import './RegisterPage.css';

function SponsorErrorPage() {
  const { username, eventId } = useParams();
  const [searchParams] = useSearchParams();
  const [eventSettings, setEventSettings] = useState(null);
  const [loadingEventSettings, setLoadingEventSettings] = useState(true);

  // Get sponsor name from URL params
  const sponsorName = searchParams.get('sponsor');

  useEffect(() => {
    const fetchEventSettings = async () => {
      const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
        ? process.env.REACT_APP_SERVER_URL_PRODUCTION
        : process.env.REACT_APP_SERVER_URL_STAGING;

      try {
        const response = await fetch(`${apiUrl}/api/load_event_profile`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username, eventId }),
        });
        const data = await response.json();
        if (data.success) {
          setEventSettings(data.settings);
        } else {
          console.error('Error fetching event settings:', data.message);
          toast.error('Failed to load event settings');
        }
      } catch (error) {
        console.error('Error fetching event settings:', error);
        toast.error('Failed to load event settings');
      } finally {
        setLoadingEventSettings(false);
      }
    };

    fetchEventSettings();
  }, [username, eventId]);

  if (loadingEventSettings) {
    return <div>Loading...</div>;
  }

  return (
    <AnimatedPage>
      <main>
        {/* Banner */}
        <section 
          className="section-banner"
          style={{
            backgroundColor: eventSettings.basicInfo.backgroundColor,
            color: eventSettings.basicInfo.headlineTextColor,
          }}
        >
          <h1>Sponsorship Error</h1>
        </section>

        {/* Sponsorship Section */}
        <section className="section-register">
          <br/>
          <br/>

          <h2>
            An error occurred during the sponsorship registration process.
          </h2>
          <br/>
          <br/>
          <h4>Your account has not been charged.</h4>
          <br/>
          <h4>Please go back to the sponsors page and try again or contact support@customtournamentsolutions.com for assistance.</h4>

          <br/>
          <Link 
            to={`/${username}/${eventId}/sponsors`}
            className="home-signup-button"
            style={{
              backgroundColor: '#0281C5',
              color: '#ffffff',
            }}
          >
            Return to Sponsors Page
          </Link>
          <br/>
          <br/>
        </section>

        <Footer />
      </main>
    </AnimatedPage>
  );
}

export default SponsorErrorPage;

