import React, { useEffect, useState } from 'react';
import './SponsorsHeroSection.css';

const SponsorsHeroSection = ({ username, eventId }) => {
  const [sponsors, setSponsors] = useState([]);
  const [loadingSponsors, setLoadingSponsors] = useState(true);
  const [totalSponsors, setTotalSponsors] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    console.log('SponsorsHeroSection mounted with:', { username, eventId });
    
    const fetchSponsors = async () => {
      const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
        ? process.env.REACT_APP_SERVER_URL_PRODUCTION
        : process.env.REACT_APP_SERVER_URL_STAGING;

      console.log(`Fetching sponsors from: ${apiUrl}/api/get_event_sponsors`);

      try {
        const response = await fetch(`${apiUrl}/api/get_event_sponsors`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username, eventId }),
        });

        console.log('Response status:', response.status);
        
        const data = await response.json();
        console.log('Sponsor data received:', data);
        
        if (data.success) {
          // Check if sponsors is actually an array or needs to be converted
          let sponsorsArray = data.sponsors;
          if (sponsorsArray && !Array.isArray(sponsorsArray)) {
            console.log('Converting sponsors object to array');
            sponsorsArray = Object.values(sponsorsArray);
          }
          
          console.log('Processed sponsors array:', sponsorsArray);
          setSponsors(sponsorsArray || []);
          setTotalSponsors(sponsorsArray ? sponsorsArray.length : 0);
        } else {
          console.error('Error fetching sponsors:', data.message);
          setErrorMessage(data.message || 'Failed to fetch sponsors');
        }
      } catch (error) {
        console.error('Error fetching sponsors:', error);
        setErrorMessage(error.message || 'Unknown error fetching sponsors');
      } finally {
        setLoadingSponsors(false);
      }
    };

    // Detect screen size for responsive design
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    
    fetchSponsors();

    return () => window.removeEventListener('resize', handleResize);
  }, [username, eventId]);

  // Function to determine if tier should be displayed
  const shouldDisplayTier = (tier) => {
    // Don't display 'Custom' tier
    if (tier === 'Custom') return false;
    
    // Display all other tiers
    return true;
  };

  // Group sponsors by tier for display priority
  const groupedSponsors = React.useMemo(() => {
    console.log('Grouping sponsors by tier, total:', sponsors?.length);
    if (!sponsors || !sponsors.length) return {};
    
    return sponsors.reduce((acc, sponsor) => {
      // Make sure each sponsor has the required fields
      if (!sponsor) return acc;
      
      const tier = sponsor.tier || 'Other';
      if (!acc[tier]) {
        acc[tier] = [];
      }
      acc[tier].push(sponsor);
      return acc;
    }, {});
  }, [sponsors]);

  // Get a sorted list of tiers by importance
  const sortedTiers = React.useMemo(() => {
    console.log('Determining tier order from grouped sponsors');
    return Object.keys(groupedSponsors).sort((a, b) => {
      // Define tier priority based on common naming conventions
      const tierOrder = {
        'Platinum': 1,
        'Gold': 2,
        'Silver': 3,
        'Bronze': 4,
        'Custom': 5,
        'Other': 6
      };
      
      return (tierOrder[a] || 999) - (tierOrder[b] || 999);
    });
  }, [groupedSponsors]);

  // Create a flat list of all sponsors sorted by tier
  const sortedSponsors = React.useMemo(() => {
    console.log('Creating sorted flat list of sponsors from tiers');
    const result = [];
    
    sortedTiers.forEach(tier => {
      const tieredSponsors = groupedSponsors[tier] || [];
      // For each tier, sort by amount if available
      const sorted = [...tieredSponsors].sort((a, b) => {
        // Sort by amount (descending) if available
        if (a.amount !== b.amount) {
          return (b.amount || 0) - (a.amount || 0);
        }
        // Otherwise sort alphabetically
        return (a.name || '').localeCompare(b.name || '');
      });
      
      result.push(...sorted);
    });
    
    console.log('Final sorted sponsors:', result);
    return result;
  }, [sortedTiers, groupedSponsors]);

  if (loadingSponsors) {
    return <div className="sponsors-hero-loading">Loading sponsors...</div>;
  }

  if (errorMessage) {
    console.error('Sponsor section error:', errorMessage);
    return null; // Don't show section on error
  }

  if (!sortedSponsors.length) {
    console.log('No sponsors found, not rendering section');
    return null; // Don't render section if no sponsors
  }

  console.log(`Rendering sponsor section with ${sortedSponsors.length} sponsors`);

  if (isMobile) {
    // Mobile Layout
    return (
      <section className="sponsors-hero-section">
        <div className="container">
          {/* Top: Title and Count */}
          <div className="sponsors-text">
            <h1 className="title">Our Sponsors</h1>
            <p className="subheadline">
              Supported by {totalSponsors} sponsor{totalSponsors !== 1 ? 's' : ''}
            </p>
          </div>

          {/* Bottom: Sponsor Marquee */}
          <div className="sponsors-marquee">
            <div className="marquee-wrapper">
              {sortedSponsors.map((sponsor, index) => {
                // Determine tier weight for styling
                const tierMap = {
                  'Platinum': 1,
                  'Gold': 2,
                  'Silver': 3,
                  'Bronze': 4,
                  'Custom': 5,
                  'Other': 6
                };
                const tierWeight = tierMap[sponsor.tier] || 6;
                
                return (
                  <div key={sponsor.sponsorId || `sponsor-${index}`} className={`sponsor-item tier-weight-${tierWeight}`}>
                    {sponsor.logoUrl ? (
                      <div className="sponsor-logo-container">
                        <img 
                          src={sponsor.logoUrl} 
                          alt={`${sponsor.name} logo`} 
                          className="sponsor-logo"
                          onError={(e) => {
                            console.error('Logo failed to load:', sponsor.logoUrl);
                            e.target.onerror = null;
                            e.target.src = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Crect width='100' height='100' fill='%23f0f0f0'/%3E%3Ctext x='50' y='50' font-family='Arial' font-size='14' text-anchor='middle' dominant-baseline='middle' fill='%23999'%3ELogo%3C/text%3E%3C/svg%3E";
                          }}
                        />
                      </div>
                    ) : (
                      <div className="sponsor-name-container">
                        <span className="sponsor-name">{sponsor.name}</span>
                        {sponsor.tier && shouldDisplayTier(sponsor.tier) && 
                          <span className="sponsor-tier">{sponsor.tier}</span>
                        }
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    );
  }

  // Desktop Layout - SWITCHED columns (text on left, marquee on right)
  return (
    <section className="sponsors-hero-section">
      <div className="container">
        {/* Left: Title and Count (SWITCHED) */}
        <div className="sponsors-text">
          <h1 className="title">Our Sponsors</h1>
          <p className="subheadline">
            Supported by {totalSponsors} sponsor{totalSponsors !== 1 ? 's' : ''}
          </p>
        </div>

        {/* Right: Sponsor Marquee (SWITCHED) */}
        <div className="sponsors-marquee">
          <div className="marquee-wrapper">
            {sortedSponsors.map((sponsor, index) => {
              // Determine tier weight for styling
              const tierMap = {
                'Platinum': 1,
                'Gold': 2,
                'Silver': 3,
                'Bronze': 4,
                'Custom': 5,
                'Other': 6
              };
              const tierWeight = tierMap[sponsor.tier] || 6;
              
              return (
                <div key={sponsor.sponsorId || `sponsor-${index}`} className={`sponsor-item tier-weight-${tierWeight}`}>
                  {sponsor.logoUrl ? (
                    <div className="sponsor-logo-container">
                      <img 
                        src={sponsor.logoUrl} 
                        alt={`${sponsor.name} logo`} 
                        className="sponsor-logo"
                        onError={(e) => {
                          console.error('Logo failed to load:', sponsor.logoUrl);
                          e.target.onerror = null;
                          e.target.src = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Crect width='100' height='100' fill='%23f0f0f0'/%3E%3Ctext x='50' y='50' font-family='Arial' font-size='14' text-anchor='middle' dominant-baseline='middle' fill='%23999'%3ELogo%3C/text%3E%3C/svg%3E";
                        }}
                      />
                    </div>
                  ) : (
                    <div className="sponsor-name-container">
                      <span className="sponsor-name">{sponsor.name}</span>
                        {sponsor.tier && shouldDisplayTier(sponsor.tier) && 
                          <span className="sponsor-tier">{sponsor.tier}</span>
                        }
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SponsorsHeroSection;

