import React, { useEffect, useState } from 'react';
import './CatchesHeroSection.css';

const CatchesHeroSection = ({ username, eventId }) => {
  const [catchesStats, setCatchesStats] = useState([]);
  const [totalCatches, setTotalCatches] = useState(0);
  const [totalSpecies, setTotalSpecies] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const fetchCatchesData = async () => {
      const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
        ? process.env.REACT_APP_SERVER_URL_PRODUCTION
        : process.env.REACT_APP_SERVER_URL_STAGING;

      try {
        const response = await fetch(`${apiUrl}/api/admin_get_event_stats`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username, eventId }),
        });

        const data = await response.json();
        if (data) {
          setCatchesStats(Object.entries(data.catchesBySpecies));
          setTotalCatches(data.totalCatches);
          setTotalSpecies(Object.keys(data.catchesBySpecies).length);
        } else {
          console.error('Error fetching catches data');
        }
      } catch (error) {
        console.error('Error fetching catches data:', error);
      }
    };

    fetchCatchesData();
  }, [username, eventId]);

  // Detect screen size to determine mobile or desktop layout
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Mobile if screen width is <= 768px
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (isMobile) {
    // Mobile Layout
    return (
      <section className="catches-hero-section">
        <div className="container">
          {/* Top: Title and Subheadline */}
          <div className="catches-text">
            <h1 className="title">Catch Stats</h1>
            <p className="subheadline">
              Total fish caught: {totalCatches}
              <br />
              Total species caught: {totalSpecies}
            </p>
          </div>

          {/* Bottom: Vertical Scrolling Chiron */}
          <div className="catches-chiron">
            <div className="chiron-wrapper">
              {catchesStats.length > 0 ? (
                catchesStats.map(([species, count], index) => (
                  <div key={index} className="chiron-item">
                    {species}: {count}
                  </div>
                ))
              ) : (
                <p>No catches recorded yet.</p>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }

  // Desktop/Table Layout
  return (
    <section className="catches-hero-section">
      <div className="container">
        {/* Left: Vertical Scrolling Chiron */}
        <div className="catches-chiron">
          <div className="chiron-wrapper">
            {catchesStats.length > 0 ? (
              catchesStats.map(([species, count], index) => (
                <div key={index} className="chiron-item">
                  {species}: {count}
                </div>
              ))
            ) : (
              <p>No catches recorded yet.</p>
            )}
          </div>
        </div>

        {/* Right: Title and Subheadline */}
        <div className="catches-text">
          <h1 className="title">Catch Stats</h1>
          <p className="subheadline">
            Total fish caught: {totalCatches}
            <br />
            Total species caught: {totalSpecies}
          </p>
        </div>
      </div>
    </section>
  );
};

export default CatchesHeroSection;

