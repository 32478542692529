import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import AnimatedPage from './AnimatedPage';
import Box from '@mui/material/Box';
import { Select, MenuItem, CircularProgress, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import dayjs from 'dayjs';

import ToggleSliderButton from '../components/buttons/ToggleSliderButton';
import Carousel from '../components/tables/Carousel';
import ResultTable from '../components/tables/ResultTable';
import Footer from '../components/footer/Footer';

function LeaderboardPage() {
  const { username, eventId } = useParams();
  const [eventSettings, setEventSettings] = useState(null);
  const [isEventSettingsLoading, setIsEventSettingsLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [timestamp, setTimestamp] = useState('');
  const [tournamentHasStarted, setTournamentHasStarted] = useState(false);
  const [resultArray, setResultArray] = useState([]);
  const [isPreliminaryResults, setIsPreliminaryResults] = useState(true);
  
  const viewList = ["List", "Select", "Slideshow"];
  const [viewAlignment, setViewAlignment] = useState('List');
  const [selectedResult, setSelectedResult] = useState([]);
  const [hasSelectedResult, setHasSelectedResult] = useState(false);

  // Generate timestamp with consistent format
  const generateTimestamp = useCallback(() => {
    const now = dayjs();
    return (
      <div style={{ fontSize: '1rem', lineHeight: '1' }}>
        <div><strong>Leaderboard as of:</strong> {now.format('hh:mm A')} on {now.format('DD MMMM YYYY')}.</div>
        <br/>
        <div>For entertainment purposes only.</div>
        <div>Standings may not reflect official results.</div>
        <div>Contact the tournament committee for official standings.</div>
      </div>
    );
  }, []);

  // Check if tournament has started by looking for catches
  const confirmTournamentStarted = useCallback(async () => {
    const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
      ? process.env.REACT_APP_SERVER_URL_PRODUCTION
      : process.env.REACT_APP_SERVER_URL_STAGING;

    try {
      const response = await fetch(`${apiUrl}/api/get_total_num_catches`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, eventId }),
      });
      const data = await response.json();
      setTournamentHasStarted(data.success && data.total > 0);
    } catch (error) {
      console.error('Error fetching total catches:', error);
    }
  }, [username, eventId]);

  // Get weight unit from event settings
  const getWeightUnit = useCallback(() => {
    if (!eventSettings || !eventSettings.units) return 'lb';
    
    const [weightUnit] = eventSettings.units.split(',').map(s => s.trim());
    return weightUnit === 'pounds' ? 'lb' : 'kg';
  }, [eventSettings]);
  
  // Get length unit from event settings
  const getLengthUnit = useCallback(() => {
    if (!eventSettings || !eventSettings.units) return 'in';
    
    const parts = eventSettings.units.split(',').map(s => s.trim());
    const lengthUnit = parts.length > 1 ? parts[1] : 'inches';
    
    if (lengthUnit.includes('inches')) return 'in';
    if (lengthUnit === 'centimeters') return 'cm';
    return 'mm';
  }, [eventSettings]);

  // Complete generateColumnConfig function with debugging
  const generateColumnConfig = useCallback((category, resultData) => {
    console.log('\n----- COLUMN CONFIG GENERATION -----');
    console.log('Category:', category.title);
    console.log('Scoring method:', category.scoringMethod);
    
    // Check if we have result data to analyze
    const firstRow = resultData && resultData.length > 0 ? resultData[0] : null;
    
    if (firstRow) {
      console.log('First row data for column generation:');
      console.log(JSON.stringify(firstRow, null, 2));
    } else {
      console.log('No data rows available for column generation');
    }
    
    // Base columns that appear in all leaderboards
    const baseColumns = [
      { 
        field: 'place', 
        headerName: 'Place', 
        width: 100, 
        flex: 1,
        headerClassName: "super-app-theme--header",
        headerAlign: "center",
        align: "center" 
      },
      { 
        field: 'team', 
        headerName: 'Team', 
        width: 250, 
        flex: 3,
        headerClassName: "super-app-theme--header",
        headerAlign: "center",
        align: "center" 
      }
    ];
    
    // Determine additional columns based on scoring method and actual data
    let additionalColumns = [];
    
    switch (category.scoringMethod) {
      case 'Most Points - Single':
        console.log('Processing columns for Points-based category');
        
        // Only add points column if data actually has points
        if (firstRow && firstRow.points !== undefined) {
          console.log(`Adding points column: value=${firstRow.points}, type=${typeof firstRow.points}`);
          
          additionalColumns.push({
            field: 'points', 
            headerName: 'Points', 
            width: 150, 
            flex: 2,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
            valueFormatter: (params) => {
              return params !== undefined ? Number(params).toFixed(1) : '0';
            }
          });
        }
        
        // Check for bestCatchTime first (for Single Points), then fallback to other time fields
        if (firstRow && firstRow.bestCatchTime) {
          console.log(`Adding bestCatchTime column for Most Points - Single`);
          
          additionalColumns.push({
            field: 'bestCatchTime', 
            headerName: 'Catch Time', // Changed from "Last Catch" for clarity
            width: 220, 
            flex: 3,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
            isDateTime: true,
            valueFormatter: (params) => {
              console.log(`Formatting best catch time value: ${params}`);
              return params ? dayjs(params).format('hh:mm A, MMM D, YYYY') : '';
            }
          });
        }
        break;
  
      case 'Most Points - Cumulative':
        console.log('Processing columns for Points-based category');
        
        // Only add points column if data actually has points
        if (firstRow && firstRow.points !== undefined) {
          console.log(`Adding points column: value=${firstRow.points}, type=${typeof firstRow.points}`);
          
          additionalColumns.push({
            field: 'points', 
            headerName: 'Points', 
            width: 150, 
            flex: 2,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
            valueFormatter: (params) => {
              return params !== undefined ? Number(params).toFixed(1) : '0';
            }
          });
        }
        
        // Only add catches column if data has catches
        if (firstRow && firstRow.catches !== undefined) {
          console.log(`Adding catches column: value=${firstRow.catches}, type=${typeof firstRow.catches}`);
          
          additionalColumns.push({
            field: 'catches', 
            headerName: 'Catches', 
            width: 150, 
            flex: 2,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center" 
          });
        }
        
        // Use lastEligibleCatchTime for cumulative scoring
        if (firstRow && firstRow.lastEligibleCatchTime) {
          console.log(`Adding lastEligibleCatchTime column for Most Points - Cumulative`);
          
          additionalColumns.push({
            field: 'lastEligibleCatchTime', 
            headerName: 'Last Scored Catch', // Renamed for clarity
            width: 220, 
            flex: 3,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
            isDateTime: true,
            valueFormatter: (params) => {
              console.log(`Formatting last eligible catch time value: ${params}`);
              return params ? dayjs(params).format('hh:mm A, MMM D, YYYY') : '';
            }
          });
        }
        break;

      case 'Most Catches':
        console.log('Processing columns for Most Catches category');
        
        // Only add catchCount if it exists in data
        if (firstRow && firstRow.catchCount !== undefined) {
          console.log(`Adding catchCount column: value=${firstRow.catchCount}, type=${typeof firstRow.catchCount}`);
          
          additionalColumns.push({
            field: 'catchCount', 
            headerName: 'Catches', 
            width: 150, 
            flex: 2,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center" 
          });
        } else {
          console.log('CatchCount field not found in data, skipping catchCount column');
        }
        break;
        
      case 'Fastest Catch':
        console.log('Processing columns for Fastest Catch category');
        
        // Only add completionTime if it exists in data
        if (firstRow && firstRow.completionTime !== undefined) {
          console.log(`Adding completionTime column: value=${firstRow.completionTime}, type=${typeof firstRow.completionTime}`);
          
          additionalColumns.push({
            field: 'completionTime', 
            headerName: 'Completion Time', 
            width: 200, 
            flex: 3,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
            isDateTime: true,
            valueFormatter: (params) => {
              console.log(`Formatting completionTime value: ${params}`);
              return params ? dayjs(params).format('hh:mm A, MMM D, YYYY') : 'Incomplete';
            }
          });
        } else {
          console.log('CompletionTime field not found in data, skipping completionTime column');
        }
        break;
        
      case 'Weight Stringer':
        console.log('Processing columns for Weight Stringer category');
        
        // Only add totalWeight if it exists in data
        if (firstRow && firstRow.totalWeight !== undefined) {
          console.log(`Adding totalWeight column: value=${firstRow.totalWeight}, type=${typeof firstRow.totalWeight}`);
          
          additionalColumns.push({
            field: 'totalWeight', 
            headerName: `Total Weight (${getWeightUnit()})`, 
            width: 200, 
            flex: 2,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
            valueFormatter: (params) => {
              console.log(`Formatting totalWeight value: ${params}, type: ${typeof params}`);
              return params !== undefined ? Number(params).toFixed(2) : '0';
            }
          });
        } else {
          console.log('TotalWeight field not found in data, skipping totalWeight column');
        }
        break;
        
      case 'Length Stringer':
        console.log('Processing columns for Length Stringer category');
        
        // Only add totalLength if it exists in data
        if (firstRow && firstRow.totalLength !== undefined) {
          console.log(`Adding totalLength column: value=${firstRow.totalLength}, type=${typeof firstRow.totalLength}`);
          
          additionalColumns.push({
            field: 'totalLength', 
            headerName: `Total Length (${getLengthUnit()})`, 
            width: 200, 
            flex: 2,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
            valueFormatter: (params) => {
              console.log(`Formatting totalLength value: ${params}, type: ${typeof params}`);
              return params !== undefined ? Number(params).toFixed(2) : '0';
            }
          });
        } else {
          console.log('TotalLength field not found in data, skipping totalLength column');
        }
        break;
        
      default:
        console.log(`Unknown scoring method: ${category.scoringMethod}, using default columns`);
        break;
    }

    if ((category.tiebreakers && category.tiebreakers.includes('time')) && 
    !additionalColumns.some(col => ['bestCatchTime', 'lastEligibleCatchTime', 'completionTime'].includes(col.field))) {
  
      // Check which time field to use
      if (firstRow && firstRow.lastCatchTime) {
        console.log(`Adding lastCatchTime column as fallback`);
        
        additionalColumns.push({
          field: 'lastCatchTime', 
          headerName: 'Last Catch', 
          width: 220, 
          flex: 3,
          headerClassName: "super-app-theme--header",
          headerAlign: "center",
          align: "center",
          isDateTime: true,
          valueFormatter: (params) => {
            console.log(`Formatting time value: ${params}`);
            return params ? dayjs(params).format('hh:mm A, MMM D, YYYY') : '';
          }
        });
      }
    }
    
    // Add timestamp column if it exists in data
    // or if the category uses time as tiebreaker
    if ((category.tiebreakers && category.tiebreakers.includes('time')) && 
      !additionalColumns.some(col => ['bestCatchTime', 'lastEligibleCatchTime', 'completionTime'].includes(col.field))) {
    
    // Check which time field to use
    if (firstRow && firstRow.lastCatchTime) {
      console.log(`Adding lastCatchTime column as fallback`);
      
      additionalColumns.push({
        field: 'lastCatchTime', 
        headerName: 'Last Catch', 
        width: 220, 
        flex: 3,
        headerClassName: "super-app-theme--header",
        headerAlign: "center",
        align: "center",
        isDateTime: true,
        valueFormatter: (params) => {
          console.log(`Formatting time value: ${params}`);
          return params ? dayjs(params).format('hh:mm A, MMM D, YYYY') : '';
        }
      });
    }
  }
    
    // Add additional columns based on actual data presence
    // Weight
    if (firstRow && firstRow.weight !== undefined) {
      console.log(`Adding weight column: value=${firstRow.weight}, type=${typeof firstRow.weight}`);
      
      additionalColumns.push({
        field: 'weight',
        headerName: `Weight (${getWeightUnit()})`,
        width: 150,
        flex: 2,
        headerClassName: "super-app-theme--header",
        headerAlign: "center",
        align: "center",
        valueFormatter: (params) => {
          console.log(`Formatting weight value: ${params}, type: ${typeof params}`);
          return params !== undefined ? Number(params).toFixed(2) : '';
        }
      });
    }
    
    // Length
    if (firstRow && firstRow.length !== undefined) {
      console.log(`Adding length column: value=${firstRow.length}, type=${typeof firstRow.length}`);
      
      additionalColumns.push({
        field: 'length',
        headerName: `Length (${getLengthUnit()})`,
        width: 150,
        flex: 2,
        headerClassName: "super-app-theme--header",
        headerAlign: "center",
        align: "center",
        valueFormatter: (params) => {
          console.log(`Formatting length value: ${params}, type: ${typeof params}`);
          return params !== undefined ? Number(params).toFixed(2) : '';
        }
      });
    }
    
    // Girth
    if (firstRow && firstRow.girth !== undefined) {
      console.log(`Adding girth column: value=${firstRow.girth}, type=${typeof firstRow.girth}`);
      
      additionalColumns.push({
        field: 'girth',
        headerName: `Girth (${getLengthUnit()})`,
        width: 150,
        flex: 2,
        headerClassName: "super-app-theme--header",
        headerAlign: "center",
        align: "center",
        valueFormatter: (params) => {
          console.log(`Formatting girth value: ${params}, type: ${typeof params}`);
          return params !== undefined ? Number(params).toFixed(2) : '';
        }
      });
    }
    
    // Log final column configuration
    console.log(`Generated ${baseColumns.length} base columns and ${additionalColumns.length} additional columns`);
    additionalColumns.forEach((col, idx) => {
      console.log(`Additional column ${idx+1}: ${col.field} (${col.headerName})`);
    });
    
    // Create final column configurations
    const desktopColumns = [...baseColumns, ...additionalColumns];
    const mobileColumns = baseColumns.concat(additionalColumns.slice(0, 1));
    
    console.log(`Final desktop column count: ${desktopColumns.length}`);
    console.log(`Final mobile column count: ${mobileColumns.length}`);
    
    return {
      desktopColumns,
      mobileColumns
    };
  }, [eventSettings, getWeightUnit, getLengthUnit]);

  // Fetch data for all leaderboards
  const fetchLeaderboardData = useCallback(async () => {
    console.log('\n========== STARTING FRONTEND LEADERBOARD DATA FETCH ==========');
    setIsLoading(true);
    try {
      // Update timestamp
      let now = dayjs().valueOf();
      setIsPreliminaryResults(parseInt(eventSettings.eventEndDateTimeGMT) > now);
      setTimestamp(generateTimestamp());
  
      // Get API URL
      const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
        ? process.env.REACT_APP_SERVER_URL_PRODUCTION
        : process.env.REACT_APP_SERVER_URL_STAGING;
  
      // Check if tournament has started
      await confirmTournamentStarted();
  
      console.log(`Fetching leaderboard data for ${eventSettings.categoryList.length} categories`);
      
      // Fetch data for each category using the unified endpoint
      const results = await Promise.all(eventSettings.categoryList.map(async (category) => {
        try {
          console.log(`\n----- FETCHING DATA FOR CATEGORY: ${category.title} -----`);
          console.log('Category configuration:', {
            title: category.title,
            scoringMethod: category.scoringMethod,
            selectedSpecies: category.selectedSpecies,
            filtersCount: category?.filters?.length || 0,
            tiebreakerCount: category?.tiebreakers?.length || 0
          });
          
          const requestBody = {
            username,
            eventId,
            category,
            isReport: false,
            numPlaces: eventSettings.numPlaces || 3
          };
          
          console.log('Request payload:', JSON.stringify(requestBody, null, 2));
          
          const response = await fetch(`${apiUrl}/api/get_event_leaderboard`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestBody)
          });
          
          if (!response.ok) {
            console.error(`Error fetching ${category.title}: ${response.status} ${response.statusText}`);
            return {
              title: category.title,
              subtitle: `Error: ${response.status}`,
              numPlaces: eventSettings.numPlaces || 3,
              rows: [],
              category
            };
          }
          
          const leaderboardData = await response.json();
          console.log(`Received ${leaderboardData.length} results for ${category.title}`);
          console.log('Response data sample:', leaderboardData.length > 0 ? JSON.stringify(leaderboardData[0], null, 2) : 'No data');
          
          if (leaderboardData.length > 0) {
            console.log('Points values in response:');
            leaderboardData.forEach((item, idx) => {
              console.log(`Row ${idx+1} (${item.team}): points=${item.points}, totalWeight=${item.totalWeight}, totalLength=${item.totalLength}, catches=${item.catches}, catchCount=${item.catchCount}`);
            });
          }
          
          // Add unique IDs to rows if not present
          const rows = leaderboardData.map((item, index) => {
            const row = {
              ...item,
              id: item.id || item.teamId || `row-${index}`
            };
            
            // Debug row data
            console.log(`Processed row ${index+1}:`, JSON.stringify(row, null, 2));
            
            return row;
          });
          
          // Generate column config based on category type and actual data
          console.log('\n----- GENERATING COLUMN CONFIG -----');
          const { desktopColumns, mobileColumns } = generateColumnConfig(category, leaderboardData);
          
          console.log('Generated desktop columns:');
          desktopColumns.forEach(col => {
            console.log(`Column: ${col.field} (${col.headerName})`);
          });
          
          // Generate a descriptive subtitle
          const categorySubtitle = generateCategorySubtitle(category);
          console.log('Generated subtitle:', categorySubtitle);
          
          return {
            title: category.title,
            subtitle: categorySubtitle,
            numPlaces: eventSettings.numPlaces || 3,
            rows,
            desktopColumns,
            mobileColumns,
            category
          };
        } catch (error) {
          console.error(`Error processing ${category.title}:`, error);
          return {
            title: category.title,
            subtitle: `Error: ${error.message}`,
            numPlaces: eventSettings.numPlaces || 3,
            rows: [],
            category
          };
        }
      }));
  
      // Filter out categories with no data
      const finalResults = results.filter(result => result.rows.length > 0);
      console.log(`Final results: ${finalResults.length} categories with data`);
      
      setResultArray(finalResults);
  
      // Reset to initial view state
      setViewAlignment("List");
      setSelectedResult([]);
      setHasSelectedResult(false);
      
      console.log('========== COMPLETED FRONTEND LEADERBOARD DATA FETCH ==========\n');
    } catch (error) {
      console.error('Error loading leaderboard data:', error);
    } finally {
      setIsLoading(false);
    }
  }, [eventSettings, confirmTournamentStarted, generateTimestamp, generateColumnConfig]);  

  // Fetch event settings on component mount
  useEffect(() => {
    const fetchEventSettings = async () => {
      const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
        ? process.env.REACT_APP_SERVER_URL_PRODUCTION
        : process.env.REACT_APP_SERVER_URL_STAGING;

      try {
        console.log(`Fetching event settings for ${username}/${eventId}`);
        const response = await fetch(`${apiUrl}/api/admin_get_event_settings`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username, eventId }),
        });
        
        if (!response.ok) {
          throw new Error(`Server error: ${response.status} ${response.statusText}`);
        }
        
        const data = await response.json();
        if (data.success) {
          console.log('Successfully fetched event settings');
          setEventSettings(data.settings);
        } else {
          console.error('Failed to fetch event settings:', data.error || 'Unknown error');
        }
      } catch (error) {
        console.error('Error fetching event settings:', error);
      } finally {
        setIsEventSettingsLoading(false);
      }
    };
    
    fetchEventSettings();
  }, [username, eventId]);

  // Fetch leaderboard data when event settings are loaded
  useEffect(() => {
    if (eventSettings) {
      fetchLeaderboardData();
    }
  }, [eventSettings, fetchLeaderboardData]);

  // Generate a descriptive subtitle for a category
  const generateCategorySubtitle = useCallback((category) => {
    if (!category) return '';
    
    const parts = [];
    
    // Add filters
    if (category.filters && category.filters.length > 0) {
      const filterLabels = category.filters.map(filter => {
        switch (filter.type) {
          case 'Division':
            return `Division: ${filter.value.join(' / ')}`;
          case 'Gender':
            return `Gender: ${filter.value.join(' / ')}`;
          case 'AgeBracket':
            return `Age Group: ${filter.value.join(' / ')}`;
          case 'Date':
            return `Date: ${filter.value.join(' / ')}`;
          default:
            return `${filter.type}: ${filter.value.join(' / ')}`;
        }
      });
      
      if (filterLabels.length > 0) {
        parts.push(filterLabels.join(' - '));
      }
    }
    
    // Add scoring method - make sure it's a string
    const scoringMethod = category.scoringMethod || 'Most Points';
    parts.push(scoringMethod);
  
    // Add stringer breakdown for Weight or Length Stringer categories
    if ((scoringMethod === 'Weight Stringer' || scoringMethod === 'Length Stringer') && 
      category.stringerQuantities && Object.keys(category.stringerQuantities).length > 0) {
      const stringerParts = [];
  
      Object.entries(category.stringerQuantities)
      .filter(([_, qty]) => qty > 0)
      .forEach(([species, qty]) => {
        stringerParts.push(`${species} x${qty}`);
      });
  
      if (stringerParts.length > 0) {
      parts.push(`Stringer: ${stringerParts.join(', ')}`);
      }
    }
    
    // Add tiebreakers with more descriptive labels
    if (category.tiebreakers && category.tiebreakers.length > 0) {
      const tiebreakerLabels = category.tiebreakers.map(tiebreaker => {
        switch (tiebreaker) {
          case 'weight':
            return 'Heaviest Fish';
          case 'length':
            return 'Longest Fish';
          case 'girth':
            return 'Largest Girth';
          case 'time':
            // Use more descriptive label based on scoring method
            if (category.scoringMethod === 'Most Points - Single') {
              return 'Earliest Best Catch';
            } else if (category.scoringMethod === 'Most Points - Cumulative') {
              return 'Earliest Last Scored Catch';
            } else if (category.scoringMethod === 'Fastest Catch') {
              return 'Earliest Completion';
            } else {
              return 'Earliest Catch';
            }
          case 'totalWeight':
            return 'Total Weight';
          case 'totalLength':
            return 'Total Length';
          case 'catchCount':
            return 'Most Catches';
          default:
            return tiebreaker;
        }
      });
      
      parts.push(`Tiebreaker${tiebreakerLabels.length > 1 ? 's' : ''}: ${tiebreakerLabels.join(', ')}`);
    }
    
    return parts.join(' - ');
  }, []);
  
  // Handle result selection in Select view
  const handleSelectResult = (e) => {
    const result = resultArray.filter(item => item.title === e.target.value);
    setSelectedResult(result);
    setHasSelectedResult(true);
  };

  // Render the appropriate content based on viewAlignment
  const renderLeaderboardContent = () => {
    // Check if tournament has started
    if (!tournamentHasStarted) {
      return (
        <Box sx={{ my: 4, textAlign: 'center' }}>
          <Typography variant="h5" color="textPrimary" gutterBottom>
            No catches yet... the tournament will begin soon!
          </Typography>
        </Box>
      );
    }

    // Show loading indicator when fetching data
    if (isLoading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
          <Typography variant="h6" sx={{ ml: 2 }}>
            Loading leaderboard data...
          </Typography>
        </Box>
      );
    }

    // Check if we have results to display
    const hasResults = Array.isArray(resultArray) && resultArray.length > 0;
    if (!hasResults) {
      return (
        <Box sx={{ my: 4, textAlign: 'center' }}>
          <Typography variant="h5" color="textPrimary" gutterBottom>
            No leaderboard results available yet.
          </Typography>
        </Box>
      );
    }

    // Render content based on selected view
    switch (viewAlignment) {
      case "List":
        return (
          <Box sx={{ mt: 3 }}>
            {resultArray.map(result => (
              <ResultTable
                key={result.title}
                style={{ width: '100%' }}
                title={result.title}
                subtitle={result.subtitle}
                numPlaces={result.numPlaces}
                rows={result.rows}
                columns={matches ? result.desktopColumns : result.mobileColumns}
                scroll={matches ? null : "scroll"}
                density="compact"
                eventSettings={eventSettings}
              />
            ))}
          </Box>
        );

      case "Slideshow":
        return (
          <Box sx={{ mt: 3 }}>
            <Carousel 
              eventSettings={eventSettings} 
              results={resultArray} 
              title="Leaderboard Results"
              emptyMessage="No leaderboard data available" 
            />
          </Box>
        );

      case "Select":
        return (
          <Box sx={{ mt: 3 }}>
            <Box sx={{ mb: 3 }}>
              <Select
                fullWidth={!matches}
                value={selectedResult[0]?.title || ''}
                onChange={handleSelectResult}
                displayEmpty
              >
                <MenuItem value="" disabled>Select a Category</MenuItem>
                {resultArray.map(result => (
                  <MenuItem key={result.title} value={result.title}>
                    {result.title}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            {hasSelectedResult ? (
              <Box>
                {selectedResult.map(result => (
                  <ResultTable
                    key={result.title}
                    style={{ width: '100%' }}
                    title={result.title}
                    subtitle={result.subtitle}
                    numPlaces={result.numPlaces}
                    rows={result.rows}
                    columns={matches ? result.desktopColumns : result.mobileColumns}
                    scroll={matches ? null : "scroll"}
                    density="compact"
                    eventSettings={eventSettings}
                  />
                ))}
              </Box>
            ) : (
              <Typography variant="h5" align="center" sx={{ mt: 4 }}>
                Please select a category to view results
              </Typography>
            )}
          </Box>
        );

      default:
        return null;
    }
  };

  if (isEventSettingsLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <CircularProgress />
        <Typography variant="h6" sx={{ ml: 2 }}>
          Loading event settings...
        </Typography>
      </Box>
    );
  }

  return (
    <AnimatedPage>
      <main>
        {eventSettings && (
          <>
            <section style={{ backgroundColor: eventSettings.backgroundColor }} className="section-banner">
              <h1 style={{ color: eventSettings.headlineTextColor }}>Leaderboard</h1>
            </section>

            <section className="section-leaderboard">
              <Box sx={{ mt: 2 }}>
                {matches ? (
                  <ToggleSliderButton 
                    choice={viewAlignment} 
                    choiceList={viewList} 
                    alignment={viewAlignment} 
                    setAlignment={setViewAlignment}
                  />
                ) : (
                  <Select
                    value={viewAlignment}
                    onChange={(e) => setViewAlignment(e.target.value)}
                    fullWidth
                  >
                    {viewList.map(option => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </Box>
            </section>

            <section className="section-view">
              <Box sx={{ width: '90%', typography: 'body1', mx: 'auto' }}>
                <Box sx={{ mt: 2, mb: 3 }}>
                  <Typography 
                    variant="subtitle1" 
                    color="textSecondary" 
                    sx={{ fontStyle: 'italic' }}
                  >
                    {timestamp}
                  </Typography>
                </Box>

                {renderLeaderboardContent()}
              </Box>
            </section>

            <Footer />
          </>
        )}
      </main>
    </AnimatedPage>
  );
}

export default LeaderboardPage;

