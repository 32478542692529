import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AnimatedPage from './AnimatedPage';
import Footer from '../components/footer/Footer';
import Box from '@mui/material/Box';
import { MenuItem, FormControl, InputLabel, Select, CircularProgress } from '@mui/material';

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import CountTable from '../components/tables/CountTable';

import './BasePage.css';

dayjs.extend(advancedFormat);

function NewsfeedPage() {
  const { username, eventId } = useParams(); 
  const [eventSettings, setEventSettings] = useState(null);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md")); 
  const [isEventSettingsLoading, setIsEventSettingsLoading] = useState(true);
  const [isCountsLoading, setIsCountsLoading] = useState(true);
  const [isNewsfeedLoading, setIsNewsfeedLoading] = useState(true);
  const [countType, setCountType] = useState("By Species");
  const [eventType, setEventType] = useState("Show All Events");

  // States for various data tables
  const [typeCountData, setTypeCountData] = useState([]);
  const [speciesCountData, setSpeciesCountData] = useState([]);
  const [teamCountData, setTeamCountData] = useState([]);
  const [dateCountData, setDateCountData] = useState([]);
  const [newsfeedData, setNewsfeedData] = useState([]);
  const [filteredNewsfeedData, setFilteredNewsfeedData] = useState([]);

  const desktopColumns = [
    { field: 'category', headerName: 'Category', flex: 2, headerClassName: "super-app-theme--header", headerAlign: "center" },
    { field: 'count', headerName: 'Count', flex: 1, headerClassName: "super-app-theme--header", headerAlign: "center" }
  ];

  const mobileColumns = [
    { field: 'category', headerName: 'Category', flex: 2, headerClassName: "super-app-theme--header", headerAlign: "center" },
    { field: 'count', headerName: 'Count', flex: 1, headerClassName: "super-app-theme--header", headerAlign: "center" }
  ];

  // Event Column Definitions
  const desktopEventColumns = [
    { field: 'type', headerName: 'Type', flex: 2, headerClassName: "super-app-theme--header", headerAlign: "center" },
    { field: 'title', headerName: 'Team', flex: 2, headerClassName: "super-app-theme--header", headerAlign: "center" },
    {
      field: 'subtitle',
      headerName: 'Event',
      flex: 4,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
    },
    {
      field: 'points',
      headerName: 'Points',
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      valueFormatter: (params) => {
        return params === "-" ? "-" : params;
      },
      sortComparator: (v1, v2) => {
        const value1 = v1 === "-" ? -Infinity : parseFloat(v1);
        const value2 = v2 === "-" ? -Infinity : parseFloat(v2);
        return value1 - value2;
      }
    },
    {
      field: 'timestamp',
      headerName: 'Timestamp',
      flex: 3,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      valueFormatter: (params) => {
        return dayjs(params).format('hh:mm A, MMM Do YYYY');
      }
    }
  ];

  const mobileEventColumns = [
    { field: 'type', headerName: 'Type', width: 150, headerClassName: "super-app-theme--header", headerAlign: "center" },
    { field: 'title', headerName: 'Team', width: 200, headerClassName: "super-app-theme--header", headerAlign: "center" },
    {
      field: 'subtitle',
      headerName: 'Event',
      width: 300,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
    },
    {
      field: 'points',
      headerName: 'Points',
      width: 150,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      valueFormatter: (params) => {
        return params === "-" ? "-" : params;
      },
      sortComparator: (v1, v2) => {
        const value1 = v1 === "-" ? -Infinity : parseFloat(v1);
        const value2 = v2 === "-" ? -Infinity : parseFloat(v2);
        return value1 - value2;
      }
    },
    {
      field: 'timestamp',
      headerName: 'Timestamp',
      width: 300,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      valueFormatter: (params) => {
        return dayjs(params).format('hh:mm A, MMM Do YYYY');
      }
    }
  ];

  // Load event settings
  useEffect(() => {
    const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
      ? process.env.REACT_APP_SERVER_URL_PRODUCTION
      : process.env.REACT_APP_SERVER_URL_STAGING;

    const fetchEventSettings = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/admin_get_event_settings`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username, eventId }),
        });
        const data = await response.json();
        if (response.ok && data.success) {
          setEventSettings(data.settings); 
        } else {
          console.error('Error fetching event settings:', data.message);
        }
      } catch (error) {
        console.error('Error fetching event settings:', error);
      } finally {
        console.log("Finally events!")
        setIsEventSettingsLoading(false);
      }
    };
    fetchEventSettings();
  }, [username, eventId]);

  // Load all count and newsfeed data
  useEffect(() => {
    const fetchConfigAndData = async () => {
      setIsCountsLoading(true);
      setIsNewsfeedLoading(true); // Start loading state for both counts and newsfeed
      try {
        const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
          ? process.env.REACT_APP_SERVER_URL_PRODUCTION
          : process.env.REACT_APP_SERVER_URL_STAGING;
  
        // Fetch all data concurrently
        const [typeResponse, speciesResponse, teamResponse, dateResponse, newsfeedResponse] = await Promise.all([
          fetch(`${apiUrl}/api/get_type_count_data_for_newsfeed_table`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ username, eventId }),
          }),
          fetch(`${apiUrl}/api/get_species_count_data_for_newsfeed_table`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ username, eventId }),
          }),
          fetch(`${apiUrl}/api/get_team_count_data_for_newsfeed_table`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ username, eventId }),
          }),
          fetch(`${apiUrl}/api/get_date_count_data_for_newsfeed_table`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ username, eventId }),
          }),
          fetch(`${apiUrl}/api/get_event_data_for_newsfeed_table`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ username, eventId }),
          })
        ]);
  
        // Check all responses are OK
        if (!typeResponse.ok || !speciesResponse.ok || !teamResponse.ok || !dateResponse.ok || !newsfeedResponse.ok) {
          console.error('One or more responses failed', {
            typeResponse: typeResponse.status,
            speciesResponse: speciesResponse.status,
            teamResponse: teamResponse.status,
            dateResponse: dateResponse.status,
            newsfeedResponse: newsfeedResponse.status,
          });
          throw new Error('Failed to fetch one or more resources');
        }
  
        // Process the data and add IDs
        const typeDataWithIds = (await typeResponse.json()).map((row, index) => ({ id: index, ...row }));
        const speciesDataWithIds = (await speciesResponse.json()).map((row, index) => ({ id: index, ...row }));
        const teamDataWithIds = (await teamResponse.json()).map((row, index) => ({ id: index, ...row }));
        const dateDataWithIds = (await dateResponse.json()).map((row, index) => ({ id: index, ...row }));
        const newsfeedDataWithIds = (await newsfeedResponse.json()).map((row, index) => ({ id: index, ...row }));
  
        console.log('Fetched and processed data:', {
          typeDataWithIds,
          speciesDataWithIds,
          teamDataWithIds,
          dateDataWithIds,
          newsfeedDataWithIds
        });
  
        // Set the data to state
        setTypeCountData(typeDataWithIds);
        setSpeciesCountData(speciesDataWithIds);
        setTeamCountData(teamDataWithIds);
        setDateCountData(dateDataWithIds);
        setNewsfeedData(newsfeedDataWithIds);
        setFilteredNewsfeedData(newsfeedDataWithIds); // Initially set filtered data as all events
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        console.log('Finally block reached');
        setIsCountsLoading(false);
        setIsNewsfeedLoading(false); // Ensure loading state is disabled in the finally block
      }
    };
  
    fetchConfigAndData();
  }, [username, eventId]);
  
  useEffect(() => {
    filterNewsfeedData();
  }, [eventType, newsfeedData]);

  const filterNewsfeedData = () => {
    if (eventType === "Show All Events") {
      setFilteredNewsfeedData(newsfeedData);
    } else if (eventType === "Show Catches Only") {
      setFilteredNewsfeedData(newsfeedData.filter(event => event.type === "Catch"));
    } else if (eventType === "Show Announcements Only") {
      setFilteredNewsfeedData(newsfeedData.filter(event => event.type === "Announcement"));
    }
  };

  const onChangeCountType = (e) => {
    setCountType(e.target.value);
  };

  const onChangeEventType = (e) => {
    setEventType(e.target.value);
  };

  // Ensure all data is loaded before rendering
  if (isEventSettingsLoading || isCountsLoading || isNewsfeedLoading) {
    console.log(eventSettings)
    console.log(typeCountData)
    console.log(speciesCountData)
    console.log(teamCountData)
    console.log(dateCountData)
    console.log(newsfeedData)
    return <CircularProgress />;
  }

  return (
    <AnimatedPage>
      <main>
        <section style={{ backgroundColor: eventSettings.backgroundColor }} className="section-banner">
          <h1 style={{ color: eventSettings.headlineTextColor }}>Newsfeed</h1>
        </section>

        <section style={{ backgroundColor: "white", color: eventSettings.headlineTextColor }} className="section-contact">
          <Box sx={{
            width: '90%',
            typography: 'body1',
            display: 'flex',
            flexDirection: matches ? 'row' : 'column',
            gap: 4
          }}>

            {/* Count Tables */}
            <Box sx={{ width: matches ? '33%' : '100%', paddingRight: matches ? 2 : 0 }}>
              <>
                <h2 style={{ color: eventSettings.headlineTextColor }}>Counts</h2>
                <br />
                <FormControl fullWidth>
                  <InputLabel>Select View</InputLabel>
                  <Select
                    label="None Selected"
                    value={countType}
                    onChange={onChangeCountType}
                  >
                    <MenuItem value="By Type">By Type</MenuItem>
                    <MenuItem value="By Species">By Species</MenuItem>
                    <MenuItem value="By Team">By Team</MenuItem>
                    <MenuItem value="By Date">By Date</MenuItem>
                  </Select>
                </FormControl>
                <br />
                <br />
                {countType === "By Type" && <CountTable eventSettings={eventSettings} rows={typeCountData} columns={matches ? desktopColumns : mobileColumns} pagination={true} pageSize={100} pageSizeOptions={[100]}/>}
                {countType === "By Species" && <CountTable eventSettings={eventSettings} rows={speciesCountData} columns={matches ? desktopColumns : mobileColumns} pagination={true} pageSize={100} pageSizeOptions={[100]}/>}
                {countType === "By Team" && <CountTable eventSettings={eventSettings} rows={teamCountData} columns={matches ? desktopColumns : mobileColumns} pagination={true} pageSize={100} pageSizeOptions={[100]}/>}
                {countType === "By Date" && <CountTable eventSettings={eventSettings} rows={dateCountData} columns={matches ? desktopColumns : mobileColumns} pagination={true} pageSize={100} pageSizeOptions={[100]}/>}
              </>
            </Box>

            {/* Newsfeed Table */}
            <Box sx={{ width: matches ? '66%' : '100%', paddingLeft: matches ? 2 : 0 }}>
              <>
                <h2 style={{ color: eventSettings.headlineTextColor }}>Event Log</h2>
                <br />
                <FormControl fullWidth>
                  <InputLabel>Select View</InputLabel>
                  <Select
                    label="None Selected"
                    value={eventType}
                    onChange={onChangeEventType}
                  >
                    <MenuItem value="Show All Events">Show All Events</MenuItem>
                    <MenuItem value="Show Catches Only">Show Catches Only</MenuItem>
                    <MenuItem value="Show Registration Only">Show Registration Only</MenuItem>
                    <MenuItem value="Show Announcements Only">Show Announcements Only</MenuItem>
                  </Select>
                </FormControl>
                <br />
                <br />
                <CountTable
                  eventSettings={eventSettings}
                  rows={filteredNewsfeedData}
                  columns={matches ? desktopEventColumns : mobileEventColumns}
                  pagination={true}
                  pageSize={100}
                  pageSizeOptions={[100]}
                />
              </>
            </Box>
          </Box>
        </section>
        <br/>
        <br/>

        <Footer />
      </main>
    </AnimatedPage>
  );
}

export default NewsfeedPage;

