import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Stack,
  Button,
  Fade,
  Alert,
  IconButton,
  Paper,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
  Grid
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import PaymentIcon from '@mui/icons-material/Payment';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import BlockIcon from '@mui/icons-material/Block';

const RegisterSponsorsStep = ({ data, onUpdate, isValid, featureOption, setActiveStep, setFeatureOption }) => {

  const FEATURE_OPTIONS = {
    NOT_NEEDED: 'notNeeded',
    CUSTOMER_PAYS: 'customerPays',
    I_PAY: 'iPay'
  };

  const [draftTier, setDraftTier] = useState(null);
  const [editingIndex, setEditingIndex] = useState(null);
  const [allowLogoUpload, setAllowLogoUpload] = useState(data?.allowLogoUpload || true);
  const [allowCustomAmount, setAllowCustomAmount] = useState(data?.allowCustomAmount || false);

  useEffect(() => {
    // Always initialize with a consistent data structure, regardless of feature status
    onUpdate({
      tiers: data?.tiers || [],
      allowLogoUpload: data?.allowLogoUpload !== undefined ? data.allowLogoUpload : true,
      allowCustomAmount: data?.allowCustomAmount || false
    });
  }, [featureOption]);

  const handleUpgradeClick = () => {
    setActiveStep(0);
  };

  const handleAddDraftTier = () => {
    setDraftTier({
      name: '',
      amount: '',
      description: ''
    });
    setEditingIndex(null);
  };

  const handleEditTier = (tier, index) => {
    setDraftTier({ ...tier });
    setEditingIndex(index);
  };

  const handleSaveDraftTier = () => {
    if (!draftTier.name || !draftTier.amount) return;
    
    // Clone the current tiers
    const newTiers = [...(data.tiers || [])];
    
    // If editing, replace the tier at the specified index
    if (editingIndex !== null) {
      newTiers[editingIndex] = draftTier;
    } else {
      // Otherwise add as a new tier
      newTiers.push(draftTier);
    }
    
    onUpdate({ 
      tiers: newTiers
    });
    
    // Reset draft state
    setDraftTier(null);
    setEditingIndex(null);
  };

  const handleDeleteTier = (index) => {
    const newTiers = data.tiers.filter((_, i) => i !== index);
    
    onUpdate({ 
      tiers: newTiers
    });
  };

  const handleUpdateDraftField = (field, value) => {
    setDraftTier({
      ...draftTier,
      [field]: value
    });
  };

  const handleToggleLogoUpload = (event) => {
    const newValue = event.target.checked;
    setAllowLogoUpload(newValue);
    onUpdate({ allowLogoUpload: newValue });
  };

  const handleToggleCustomAmount = (event) => {
    const newValue = event.target.checked;
    setAllowCustomAmount(newValue);
    onUpdate({ allowCustomAmount: newValue });
  };

  const getErrorMessage = () => {
    // Don't allow proceeding with unsaved draft tiers
    if (draftTier) {
      return "Please save or cancel the current draft tier before continuing";
    }
    
    // Allow proceeding if custom amount is checked, even with no tiers
    if (data.allowCustomAmount) {
      // Still validate any existing tiers if they exist
      if (data.tiers && data.tiers.length > 0) {
        // Check for valid amounts
        const invalidAmountTier = data.tiers.find(tier => parseFloat(tier.amount) <= 0);
        if (invalidAmountTier) {
          return `Tier "${invalidAmountTier.name}" has an invalid amount. All amounts must be greater than $0`;
        }
        
        // Check for duplicate tier names with the same amount
        const tierMap = new Map();
        for (const tier of data.tiers) {
          if (tierMap.has(tier.name) && tierMap.get(tier.name) === tier.amount) {
            return `Duplicate tier "${tier.name}" with the same amount. Tiers with the same amount must have different names`;
          }
          tierMap.set(tier.name, tier.amount);
        }
      }
      // If all tiers are valid and custom amount is enabled, validation passes
      return null;
    }
    
    // If custom amount is NOT checked, require at least one tier
    if (!data.tiers || data.tiers.length === 0) {
      return "Please add at least one sponsor tier or enable the custom amount option";
    }
    
    // Check for valid amounts
    const invalidAmountTier = data.tiers.find(tier => parseFloat(tier.amount) <= 0);
    if (invalidAmountTier) {
      return `Tier "${invalidAmountTier.name}" has an invalid amount. All amounts must be greater than $0`;
    }
    
    // Check for duplicate tier names with the same amount
    const tierMap = new Map();
    for (const tier of data.tiers) {
      if (tierMap.has(tier.name) && tierMap.get(tier.name) === tier.amount) {
        return `Duplicate tier "${tier.name}" with the same amount. Tiers with the same amount must have different names`;
      }
      tierMap.set(tier.name, tier.amount);
    }

    return null;
  };

  const isValidTier = () => {
    if (!draftTier) return false;
    
    // Basic validation
    if (!draftTier.name || !draftTier.amount) return false;
    if (parseFloat(draftTier.amount) <= 0) return false;
    
    // Check if there's another tier with same name and amount (excluding the one being edited)
    if (data.tiers && data.tiers.length > 0) {
      return !data.tiers.some((tier, index) => 
        index !== editingIndex && 
        tier.name === draftTier.name && 
        parseFloat(tier.amount) === parseFloat(draftTier.amount)
      );
    }
    
    return true;
  };

  const SponsorshipFeeCalculation = ({ amount, whoPays }) => {
    if (!amount) return null;
    
    const basePrice = parseFloat(amount);
    
    // Calculate platform fee (our fee)
    let platformFee;
    let feeDescription;
  
    if (basePrice < 50) {
      platformFee = 3;
      feeDescription = 'Flat $3 fee';
    } else if (basePrice <= 100) {
      platformFee = 5;
      feeDescription = 'Flat $5 fee';
    } else if (basePrice <= 200) {
      platformFee = basePrice * 0.05; // 5%
      feeDescription = '5% fee';
    } else {
      platformFee = basePrice * 0.04; // 4%
      feeDescription = '4% fee';
    }
    
    // Define Stripe fee parameters
    // Standard Stripe fee: 2.9% + $0.30
    const STRIPE_PERCENTAGE = 2.9;
    const STRIPE_FIXED_FEE = 0.30;
    
    // Additional fee for destination charges: +0.5% (no extra fixed fee)
    const DESTINATION_CHARGE_PERCENTAGE = 0.5;
    const DESTINATION_CHARGE_FIXED_FEE = 0;
    
    // Calculate total Stripe fees
    const stripeFeePercentage = STRIPE_PERCENTAGE + DESTINATION_CHARGE_PERCENTAGE;
    const stripeFeeFixed = STRIPE_FIXED_FEE + DESTINATION_CHARGE_FIXED_FEE;
    
    // Determine who is paying which fees
    const whoPaysFees = whoPays === FEATURE_OPTIONS.CUSTOMER_PAYS ? 
      "Sponsor pays fees" : 
      "You pay fees";
    
    if (whoPays === FEATURE_OPTIONS.CUSTOMER_PAYS) {
      // Sponsor pays the platform fee and all Stripe fees
      const sponsorTotal = basePrice + platformFee;
      const stripeFee = (sponsorTotal * stripeFeePercentage / 100) + stripeFeeFixed;
      const totalSponsorPays = sponsorTotal + stripeFee;
      const organizerReceives = basePrice;
      
      return (
        <Accordion defaultExpanded sx={{ mt: 1 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle2" fontWeight="medium">
              Transaction Example <Chip 
                label={whoPaysFees} 
                size="small" 
                color="primary" 
                variant="outlined" 
                sx={{ ml: 1, fontSize: '0.7rem' }}
              />
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ pt: 0 }}>
            <Typography variant="subtitle2" color="primary.dark" sx={{ mb: 1, fontWeight: 'bold' }}>
              What the sponsor pays:
            </Typography>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body2">Base Sponsorship Amount</Typography>
              <Typography variant="body2">${basePrice.toFixed(2)}</Typography>
            </Box>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body2">Our Platform Fee ({feeDescription})</Typography>
              <Typography variant="body2">${platformFee.toFixed(2)}</Typography>
            </Box>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body2">Stripe's Processing Fee ({(stripeFeePercentage).toFixed(1)}% + ${stripeFeeFixed.toFixed(2)})</Typography>
              <Typography variant="body2">${stripeFee.toFixed(2)}</Typography>
            </Box>
            
            <Divider sx={{ my: 1 }} />
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              <Typography variant="body2" fontWeight="bold">Total Sponsor Payment</Typography>
              <Typography variant="body2" fontWeight="bold">${totalSponsorPays.toFixed(2)}</Typography>
            </Box>
            
            <Typography variant="subtitle2" color="success.dark" sx={{ mb: 1, fontWeight: 'bold' }}>
              What you receive:
            </Typography>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body2">Base Sponsorship Amount</Typography>
              <Typography variant="body2">${basePrice.toFixed(2)}</Typography>
            </Box>
            
            <Divider sx={{ my: 1 }} />
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body2" fontWeight="bold">Your Net Revenue</Typography>
              <Typography variant="body2" fontWeight="bold" color="success.main">${organizerReceives.toFixed(2)}</Typography>
            </Box>
          </AccordionDetails>
        </Accordion>
      );
    } else {
      // Organizer pays all fees (platform fee and Stripe fees)
      const sponsorTotal = basePrice;
      const stripeFee = (sponsorTotal * stripeFeePercentage / 100) + stripeFeeFixed;
      const organizerPays = platformFee + stripeFee;
      const organizerReceives = basePrice - organizerPays;
      
      return (
        <Accordion defaultExpanded sx={{ mt: 1 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle2" fontWeight="medium">
              Transaction Example <Chip 
                label={whoPaysFees} 
                size="small" 
                color="primary" 
                variant="outlined" 
                sx={{ ml: 1, fontSize: '0.7rem' }}
              />
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ pt: 0 }}>
            <Typography variant="subtitle2" color="primary.dark" sx={{ mb: 1, fontWeight: 'bold' }}>
              What the sponsor pays:
            </Typography>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body2">Base Sponsorship Amount (no added fees)</Typography>
              <Typography variant="body2">${basePrice.toFixed(2)}</Typography>
            </Box>
            
            <Divider sx={{ my: 1 }} />
            
            <Typography variant="subtitle2" color="warning.dark" sx={{ mb: 1, fontWeight: 'bold' }}>
              Your costs:
            </Typography>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body2">Stripe's Processing Fee ({(stripeFeePercentage).toFixed(1)}% + ${stripeFeeFixed.toFixed(2)})</Typography>
              <Typography variant="body2" color="error.main">-${stripeFee.toFixed(2)}</Typography>
            </Box>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body2">Our Platform Fee ({feeDescription})</Typography>
              <Typography variant="body2" color="error.main">-${platformFee.toFixed(2)}</Typography>
            </Box>
            
            <Divider sx={{ my: 1 }} />
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body2" fontWeight="bold">Your Net Revenue</Typography>
              <Typography variant="body2" fontWeight="bold" color="success.main">${organizerReceives.toFixed(2)}</Typography>
            </Box>
          </AccordionDetails>
        </Accordion>
      );
    }
  };

  const ExampleFeeSection = () => {
    // Get sample tier price from the first tier or use default
    const sampleTierAmount = data.tiers && data.tiers.length > 0 
      ? data.tiers[0].amount 
      : 500;
    
    return (
      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle2" gutterBottom>
          Sponsorship Fee Breakdown
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          This example shows how fees work for a ${sampleTierAmount} sponsorship:
        </Typography>
        
        <SponsorshipFeeCalculation 
          amount={sampleTierAmount}
          whoPays={featureOption}
        />
      </Box>
    );
  };

  const handleFeatureOptionChange = (event, newOption) => {
    if (newOption !== null) {
      setFeatureOption('onlineSponsorRegistration', newOption);
    }
  };  

  const DisabledFeatureMessage = ({ setActiveStep, setFeatureOption }) => (
    <Box sx={{ textAlign: 'center', py: 4 }}>
      <Typography variant="h6" gutterBottom>
        Online Sponsor Registration is currently disabled
      </Typography>
      <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
        To set up online sponsor registration with payment processing, you need to enable this feature.
      </Typography>
      <Stack direction="row" spacing={2} justifyContent="center">
        <Button
          variant="outlined"
          onClick={() => setActiveStep(0)}
        >
          Go to Features Step
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setFeatureOption('onlineSponsorRegistration', 'customerPays');
          }}
        >
          Enable Sponsor Registration
        </Button>
      </Stack>
    </Box>
  );

  const validateStep = () => {
    // Don't validate disabled features
    if (featureOption === FEATURE_OPTIONS.NOT_NEEDED) {
      // When disabled, the step is always valid as we just keep consistent structure
      return true;
    }
    
    // Only validate specifics if feature is enabled
    // Don't allow proceeding with unsaved draft tiers
    if (draftTier) {
      return false;
    }
    
    // Allow proceeding if custom amount is enabled, even without tiers
    if (data.allowCustomAmount) {
      return true;
    }
    
    // If custom amount is NOT checked, require at least one tier
    if (!data.tiers || data.tiers.length === 0) {
      return false;
    }
    
    // Check for valid amounts
    const invalidAmountTier = data.tiers.find(tier => parseFloat(tier.amount) <= 0);
    if (invalidAmountTier) {
      return false;
    }
    
    // Check for duplicate tier names with the same amount
    const tierMap = new Map();
    for (const tier of data.tiers) {
      if (tierMap.has(tier.name) && tierMap.get(tier.name) === tier.amount) {
        return false;
      }
      tierMap.set(tier.name, tier.amount);
    }
  
    return true;
  };

  return (
    <Fade in timeout={400}>
      <Box>
        
        {!validateStep() && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {getErrorMessage()}
          </Alert>
        )}

        <Paper sx={{ p: 3 }}>
          <Typography variant="h6" gutterBottom>
            Set up your sponsor tiers
          </Typography>
          
        {/* Fee Selection Toggle */}
        <Box sx={{ border: '1px solid #e0e0e0', borderRadius: 1, p: 2, mb: 3, bgcolor: '#f9f9f9' }}>
          <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'medium', color: 'text.primary' }}>
            How should fees be handled?
          </Typography>
          
          <ToggleButtonGroup
            value={featureOption}
            exclusive
            onChange={handleFeatureOptionChange}
            aria-label="fee option"
            sx={{ mb: 1 }}
            fullWidth
          >
            <ToggleButton 
              value={FEATURE_OPTIONS.CUSTOMER_PAYS} 
              aria-label="sponsor pays" 
              sx={{ py: 1 }}
            >
              <Tooltip title="Sponsors pay our platform fees" placement="top">
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <PaymentIcon sx={{ mb: 0.5 }} />
                  <Typography variant="caption">Sponsors Pay Fees</Typography>
                </Box>
              </Tooltip>
            </ToggleButton>
            <ToggleButton 
              value={FEATURE_OPTIONS.I_PAY} 
              aria-label="organizer pays" 
              sx={{ py: 1 }}
            >
              <Tooltip title="You pay our platform fees" placement="top">
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <AccountBalanceWalletIcon sx={{ mb: 0.5 }} />
                  <Typography variant="caption">You Pay Fees</Typography>
                </Box>
              </Tooltip>
            </ToggleButton>
            <ToggleButton 
              value={FEATURE_OPTIONS.NOT_NEEDED} 
              aria-label="no registration" 
              sx={{ py: 1 }}
            >
              <Tooltip title="Disable online sponsor registration" placement="top">
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <BlockIcon sx={{ mb: 0.5 }} />
                  <Typography variant="caption">No Registration</Typography>
                </Box>
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
          
          <Typography variant="caption" color="text.secondary">
            {featureOption === FEATURE_OPTIONS.CUSTOMER_PAYS ? 
              "Sponsors will pay the platform fee in addition to your sponsorship fee." :
              featureOption === FEATURE_OPTIONS.I_PAY ?
              "You will pay the platform fee from your portion of the sponsorship fee." :
              "Online sponsor registration is disabled."}
          </Typography>
        </Box>
          
          {/* Show message when feature is disabled but still allow configuration */}
          {featureOption === FEATURE_OPTIONS.NOT_NEEDED ? (
            <Alert severity="info" sx={{ mb: 3 }}>
              <Typography variant="body2">
                Online sponsor registration is disabled. You can still configure sponsor tiers 
                for reference, but sponsors won't be able to register online. Sponsor 
                pages and navigation links will not be visible on your event site.
              </Typography>
            </Alert>
          ) : (
            <ExampleFeeSection/>
          )}

          {/* List of existing tiers */}
          {data.tiers && data.tiers.length > 0 && (
            <Stack spacing={2} sx={{ mb: 4, mt: 4 }}>
              <Typography variant="subtitle1" gutterBottom>
                Current Sponsor Tiers
              </Typography>
              
              {data.tiers.map((tier, index) => (
                <Paper
                  key={index}
                  variant="outlined"
                  sx={{ p: 2 }}
                >
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Typography sx={{ flex: 1, fontWeight: 'bold' }}>
                      {tier.name}
                    </Typography>
                    <Chip 
                      label={`$${parseFloat(tier.amount).toFixed(2)}`} 
                      color="primary" 
                      variant="outlined"
                    />
                    
                    <IconButton 
                      size="small" 
                      onClick={() => handleEditTier(tier, index)}
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton>
                    
                    <IconButton 
                      size="small" 
                      onClick={() => handleDeleteTier(index)}
                      color="error"
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </Stack>
                  
                  {tier.description && (
                    <Typography 
                      variant="body2" 
                      color="text.secondary" 
                      sx={{ mt: 1 }}
                    >
                      {tier.description}
                    </Typography>
                  )}
                </Paper>
              ))}
            </Stack>
          )}

          {/* Draft Tier Form */}
          {draftTier && (
            <Paper
              variant="outlined"
              sx={{ p: 3, mb: 4 }}
            >
              <Typography variant="subtitle1" gutterBottom>
                {editingIndex !== null ? 'Edit Sponsor Tier' : 'Add New Sponsor Tier'}
              </Typography>
              
              <Stack spacing={3}>
                <TextField
                  label="Tier Name"
                  value={draftTier.name}
                  onChange={(e) => handleUpdateDraftField('name', e.target.value)}
                  placeholder="e.g., Gold, Silver, Bronze"
                  fullWidth
                  required
                />
                
                <TextField
                  label="Amount"
                  type="number"
                  value={draftTier.amount}
                  onChange={(e) => handleUpdateDraftField('amount', e.target.value)}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                  placeholder="e.g., 1000"
                  fullWidth
                  required
                  error={draftTier.amount && parseFloat(draftTier.amount) <= 0}
                  helperText={draftTier.amount && parseFloat(draftTier.amount) <= 0 ? "Amount must be greater than $0" : ""}
                />
                
                <TextField
                  label="Description (Optional)"
                  value={draftTier.description}
                  onChange={(e) => handleUpdateDraftField('description', e.target.value)}
                  placeholder="What does this sponsorship tier include?"
                  multiline
                  rows={3}
                  fullWidth
                />
                
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="contained"
                    onClick={handleSaveDraftTier}
                    disabled={!isValidTier()}
                  >
                    {editingIndex !== null ? 'Update Tier' : 'Add Tier'}
                  </Button>
                  
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setDraftTier(null);
                      setEditingIndex(null);
                    }}
                  >
                    Cancel
                  </Button>
                </Stack>
              </Stack>
            </Paper>
          )}

          {/* Add Tier Button */}
          <br/>
          <br/>
          {!draftTier && (
            <Button
              startIcon={<AddIcon />}
              onClick={handleAddDraftTier}
              variant="outlined"
              sx={{ mb: 4 }}
            >
              Add Sponsor Tier
            </Button>
          )}

          <Divider sx={{ my: 3 }} />

          {/* Logo Upload Option */}
          <Box sx={{ mt: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={allowLogoUpload}
                  onChange={handleToggleLogoUpload}
                />
              }
              label="Allow sponsors to upload their logo during registration"
            />
            
            <Typography 
              variant="body2" 
              color="text.secondary"
              sx={{ mt: 1, pl: 4, mb: 2 }}
            >
              This will add a logo upload field to your sponsor registration page, making it easier to collect sponsor logos for your event.
            </Typography>
          </Box>

          {/* Custom Amount Option */}
          <Box sx={{ mt: 3 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={allowCustomAmount}
                  onChange={handleToggleCustomAmount}
                />
              }
              label="Allow sponsors to enter a custom sponsorship amount"
            />
            
            <Typography 
              variant="body2" 
              color="text.secondary"
              sx={{ mt: 1, pl: 4 }}
            >
              This will add a "Custom Amount" option to your sponsor registration page where sponsors can enter their own contribution amount.
            </Typography>
          </Box>
        </Paper>
      </Box>
    </Fade>
  );
};

export default RegisterSponsorsStep;

