import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import AnimatedPage from './AnimatedPage';
import Box from '@mui/material/Box';
import { 
  Select, 
  MenuItem, 
  Button, 
  Alert, 
  CircularProgress, 
  Typography 
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import ToggleSliderButton from '../components/buttons/ToggleSliderButton';
import Carousel from '../components/tables/Carousel';
import ResultTable from '../components/tables/ResultTable';
import Footer from '../components/footer/Footer';
import AddPotModal from '../components/modals/AddPotModal';

dayjs.extend(advancedFormat);

function PotsPage() {
  const { username, eventId } = useParams();
  const [eventSettings, setEventSettings] = useState(null);
  const [isEventSettingsLoading, setIsEventSettingsLoading] = useState(true);
  const [potEntries, setPotEntries] = useState([]);
  const [teams, setTeams] = useState({});
  const [timestamp, setTimestamp] = useState('');
  const [tournamentHasStarted, setTournamentHasStarted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddPotModalOpen, setIsAddPotModalOpen] = useState(false);
  const [registrationEnabled, setRegistrationEnabled] = useState(true);

  // View selection states
  const [displaySelection, setDisplaySelection] = useState("Entries");
  const displayOptions = ["Entries", "Payouts"];

  // Entries
  const entriesViewOptions = ["Board", "By Pot", "By Team"];
  const [entriesViewSelection, setEntriesViewSelection] = useState("Board");
  const [selectedPot, setSelectedPot] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);

  // Payouts
  const [resultArray, setResultArray] = useState([]);
  const viewList = ["List", "Select", "Slideshow"];
  const [viewAlignment, setViewAlignment] = useState('List');
  const [selectedResult, setSelectedResult] = useState([]);
  const [hasSelectedResult, setHasSelectedResult] = useState(false);

  // Media query
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const openAddPotModal = () => setIsAddPotModalOpen(true);
  const closeAddPotModal = () => setIsAddPotModalOpen(false);

  const generateTimestamp = useCallback(() => {
    const now = dayjs();
    return (
      <div style={{ fontSize: '1rem', lineHeight: '1' }}>
        <div><strong>Pot leaderboard as of:</strong> {now.format('hh:mm A')} on {now.format('DD MMMM YYYY')}.</div>
        <br/>
        <div>For entertainment purposes only.</div>
        <div>Standings may not reflect official results.</div>
        <div>Contact the tournament committee for official standings.</div>
      </div>
    );
  }, []);

  const confirmTournamentStarted = useCallback(async () => {
    const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
      ? process.env.REACT_APP_SERVER_URL_PRODUCTION
      : process.env.REACT_APP_SERVER_URL_STAGING;

    try {
      const response = await fetch(`${apiUrl}/api/get_total_num_catches`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, eventId }),
      });
      const data = await response.json();
      setTournamentHasStarted(data.success && data.total > 0);
    } catch (error) {
      console.error('Error fetching total catches:', error);
    }
  }, [username, eventId]);

  // Enhanced fetchPayoutsData function with better error handling and data processing
  const fetchPayoutsData = useCallback(async () => {
    console.log('Starting pot leaderboard data fetch...');
    setIsLoading(true);
    
    try {
      const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
        ? process.env.REACT_APP_SERVER_URL_PRODUCTION
        : process.env.REACT_APP_SERVER_URL_STAGING;

      // Set timestamp for displayed results
      setTimestamp(generateTimestamp());

      // Validate required parameters
      if (!apiUrl || !username || !eventId) {
        console.error('Missing required configuration:', { apiUrl, username, eventId });
        setIsLoading(false);
        return;
      }
      
      // Create request body
      const requestBody = { username, eventId };
      console.log(`Fetching pot leaderboard data with params: ${username}/${eventId}`);
      
      // Make the request to get_event_pot_leaderboard endpoint
      const response = await fetch(`${apiUrl}/api/get_event_pot_leaderboard`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
      });

      // Handle potential errors
      if (!response.ok) {
        const errorText = await response.text();
        console.error(`Server error: ${response.status}`, errorText);
        throw new Error(`Server responded with status: ${response.status}`);
      }

      // Parse the response
      const data = await response.json();
      
      if (data.results && Array.isArray(data.results)) {
        console.log(`Received ${data.results.length} pot leaderboard results`);
        
        // Process results to ensure proper format
        const processedResults = data.results.map(result => {
          // Ensure rows have IDs for DataGrid
          const processedRows = (result.rows || []).map((row, index) => ({
            ...row,
            id: row.id !== undefined ? row.id : index
          }));
          
          // Format subtitle properly without duplication and add split message
          const formattedSubtitle = result.totalPayout 
            ? `${result.subtitle} - Event will determine winner split`
            : `${result.subtitle || ''} - Event will determine winner split`;

          return {
            ...result,
            rows: processedRows,
            subtitle: formattedSubtitle
          };
        });
        
        // Store the results
        setResultArray(processedResults);
        
        // Reset view state
        setViewAlignment("List");
        setSelectedResult([]);
        setHasSelectedResult(false);
      } else {
        console.warn('No valid results found in pot leaderboard data');
        setResultArray([]);
      }

      // Check if tournament has started (for registration controls)
      await confirmTournamentStarted();
    } catch (error) {
      console.error('Error fetching pot payouts:', error);
      setResultArray([]);
    } finally {
      setIsLoading(false);
    }
  }, [username, eventId, confirmTournamentStarted, generateTimestamp]);

  const fetchConfigAndData = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_NODE_ENV === "production"
        ? process.env.REACT_APP_SERVER_URL_PRODUCTION
        : process.env.REACT_APP_SERVER_URL_STAGING;

      setTimestamp(generateTimestamp());

      // Fetch teams
      const teamsResponse = await fetch(`${apiUrl}/api/admin_get_event_teams`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, eventId })
      });
      const teamsData = await teamsResponse.json();
      setTeams(teamsData);

      // Fetch pot entries
      const potEntriesResponse = await fetch(`${apiUrl}/api/get_event_pot_entries`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, eventId })
      });
      const potEntriesData = await potEntriesResponse.json();
      setPotEntries(potEntriesData.entries || []);
      console.log('get_event_pot_entries data:', potEntriesData)
      await confirmTournamentStarted();

    } catch (error) {
      console.error('Error fetching initial data:', error);
    }
  }, [username, eventId, confirmTournamentStarted, generateTimestamp]);

  useEffect(() => {
    const fetchEventSettings = async () => {
      const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
        ? process.env.REACT_APP_SERVER_URL_PRODUCTION
        : process.env.REACT_APP_SERVER_URL_STAGING;

      try {
        const response = await fetch(`${apiUrl}/api/admin_get_event_settings`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username, eventId }),
        });
        const data = await response.json();
        if (response.ok && data.success) {
          // Store the entire settings object
          setEventSettings(data.settings);
        }
        console.log('eventSettings:', data.settings)
      } catch (error) {
        console.error('Error fetching event settings:', error);
      } finally {
        setIsEventSettingsLoading(false);
      }
    };
    fetchEventSettings();
  }, [username, eventId]);

  useEffect(() => {
    if (eventSettings) {
      if (displaySelection === "Entries") {
        fetchConfigAndData();
      } else {
        fetchPayoutsData();
      }
    }
  }, [eventSettings, displaySelection, fetchConfigAndData, fetchPayoutsData]);

  // Update registration availability based on tournament status
  useEffect(() => {
    // If tournament has started, disable registration
    setRegistrationEnabled(!tournamentHasStarted);
  }, [tournamentHasStarted]);

  const handleSelectResult = (e) => {
    // Clear previous selections first
    setSelectedResult([]);
    
    // Then set the new selection
    const result = resultArray.filter(item => item.title === e.target.value);
    setSelectedResult(result);
    setHasSelectedResult(true);
  };

  const renderEntriesBoard = () => {
    // Make sure we have pots data
    if (!eventSettings?.pots?.potList || !Array.isArray(eventSettings.pots.potList)) {
      return <div>No pots configuration found.</div>;
    }

    // Get all unique pots from pots.potList with improved formatting
    const potColumns = eventSettings.pots.potList.flatMap(potConfig => {
      // Use the subPots field to determine how many entries to create
      const subPotCount = parseInt(potConfig.subPots) || potConfig.values.length;
      
      // Create an entry for each subpot value
      return Array.from({ length: subPotCount }, (_, index) => {
        // Ensure we don't access outside the values array
        if (index < potConfig.values.length) {
          return {
            category: potConfig.category || potConfig.categoryTitle,
            categoryTitle: potConfig.categoryTitle || potConfig.category,
            value: potConfig.values[index],
            formattedValue: potConfig.values[index].toLocaleString(),
            key: `${potConfig.category || potConfig.categoryTitle}_${index + 1}`
          };
        }
        return null;
      }).filter(Boolean); // Remove any null entries
    });

    // Get all team names
    const teamNames = Object.values(teams).map(team => team['Team name']).sort();

    // Track team entries and total entries per pot
    const teamEntries = {};
    const totalEntriesPerPot = new Array(potColumns.length).fill(0);

    // Log for debugging
    console.log('Processing pot entries:', potEntries);
    console.log('Pot columns:', potColumns);

    potEntries.forEach(entry => {
      console.log('Processing entry:', entry);
      
      // Check if potName exists and has the right structure
      if (!entry.potName) {
        console.log('Entry has no potName:', entry);
        return;
      }
      
      // Get the category name from the entry (can be in either field)
      const entryCategory = entry.potName.categoryTitle || entry.potName.category;
      
      // Check if we have a valid category
      if (!entryCategory) {
        console.log('Entry has no category:', entry);
        return;
      }
      
      // Handle both arrays and single values
      const entryValues = Array.isArray(entry.potName.values) 
        ? entry.potName.values 
        : [entry.potName.value];
      
      console.log(`Entry ${entry.teamName} has category: ${entryCategory} and values:`, entryValues);
      
      // For each value in the entry
      entryValues.forEach(entryValue => {
        // Find the matching column index
        const potIndex = potColumns.findIndex(pot => 
          (pot.category === entryCategory || pot.categoryTitle === entryCategory) && 
          pot.value === entryValue
        );
        
        if (potIndex !== -1) {
          const teamName = entry.teamName;
          if (!teamEntries[teamName]) {
            teamEntries[teamName] = new Array(potColumns.length).fill(null);
          }
          teamEntries[teamName][potIndex] = 'X';
          totalEntriesPerPot[potIndex]++;
          
          console.log(`Marked ${teamName} for pot column ${potIndex} (${entryCategory} $${entryValue})`);
        } else {
          console.log(`No matching pot column found for ${entryCategory} $${entryValue}`);
        }
      });
    });

    return (
      <div>
        <br/>
        <h3 style={{fontStyle: "italic", fontWeight: "bold"}}>
          Scroll bar at bottom if needed
        </h3>
        <br/>
        <div className="scroll-wrapper">
          <div className="scroll-content">
            <table className="pot-table">
              <thead>
                <tr>
                  <th className="sticky-col">Teams</th>
                  {potColumns.map((pot, index) => (
                    <th key={index}>
                      {pot.category || pot.categoryTitle} <br/>${pot.formattedValue}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {teamNames.map(teamName => (
                  <tr key={teamName}>
                    <td className="sticky-col">{teamName}</td>
                    {potColumns.map((pot, index) => (
                      <td key={index}>
                        {teamEntries[teamName]?.[index] || ''}
                      </td>
                    ))}
                  </tr>
                ))}
                <tr>
                  <td className="sticky-col">Total Entries</td>
                  {totalEntriesPerPot.map((total, index) => (
                    <td key={index}>
                      <strong>{total}</strong>
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  const renderEntriesByPot = () => {
    // Debug: Log the current state
    console.log('Current state:', {
      eventSettings: eventSettings?.pots?.potList || 'No pot list found',
      potEntries,
      selectedPot
    });
  
    // Make sure we have pots configuration
    if (!eventSettings?.pots?.potList || !Array.isArray(eventSettings.pots.potList)) {
      console.error('No pot configuration found in event settings');
      return <div>No pots configuration found.</div>;
    }
  
    // Format currency consistently
    const formatCurrency = (value) => `$${value.toLocaleString()}`;
  
    // Create pot options with proper currency formatting
    const potOptions = eventSettings.pots.potList.flatMap(potConfig => {
      console.log(`Creating options for pot category: ${potConfig.category || potConfig.categoryTitle}`, potConfig);
      
      // Use the subPots field to determine how many entries to create
      const subPotCount = parseInt(potConfig.subPots) || potConfig.values.length;
      
      return Array.from({ length: subPotCount }, (_, index) => {
        // Ensure we don't access outside the values array
        if (index < potConfig.values.length) {
          const categoryName = potConfig.category || potConfig.categoryTitle;
          const option = {
            label: `${categoryName} (${formatCurrency(potConfig.values[index])})`,
            category: categoryName,
            categoryTitle: potConfig.categoryTitle || potConfig.category,
            value: potConfig.values[index],
            subPotNumber: index + 1
          };
          console.log(`Created pot option:`, option);
          return option;
        }
        return null;
      }).filter(Boolean); // Remove any null entries
    });
  
    console.log('All pot options:', potOptions);
  
    // Extract category and value from selectedPot string
    let selectedCategory, selectedValue;
    if (selectedPot) {
      // Match both formats: "Category ($Value)" or "Category ($1,234)"
      const match = selectedPot.match(/(.+) \(\$([0-9,]+)\)$/);
      console.log('Selected pot match result:', match);
  
      if (match) {
        selectedCategory = match[1];
        selectedValue = parseInt(match[2].replace(/,/g, ''));
        console.log('Parsed selected pot:', { selectedCategory, selectedValue });
      } else {
        console.warn('Failed to parse selected pot:', selectedPot);
      }
    }
  
    // Find teams that have registered for the selected pot
    const teamsInSelectedPot = selectedPot
      ? potEntries
          .filter(entry => {
            // Debug the filter operation
            if (!entry.potName) return false;
            
            const entryCategory = entry.potName.categoryTitle || entry.potName.category;
            const categoryMatch = entryCategory === selectedCategory;
            
            // Handle both array and single value structures
            const entryValues = Array.isArray(entry.potName.values) 
              ? entry.potName.values 
              : [entry.potName.value];
            
            const valueMatch = entryValues.includes(selectedValue);
            
            console.log(`Checking entry: ${entry.teamName}, category: ${entryCategory}`, {
              categoryMatch,
              valueMatch,
              entryValues,
              lookingFor: selectedValue
            });
            
            return categoryMatch && valueMatch;
          })
          .map(entry => entry.teamName)
      : [];
  
    console.log('Teams in selected pot:', teamsInSelectedPot);
  
    // Calculate total investment for selected pot
    const totalInvestment = selectedPot ? selectedValue * teamsInSelectedPot.length : 0;
    console.log(`Total investment for ${selectedPot}:`, totalInvestment);
  
    return (
      <div>
        <br/>
        <Select
          value={selectedPot || ''}
          onChange={(e) => {
            console.log('Selected new pot:', e.target.value);
            setSelectedPot(e.target.value);
          }}
          displayEmpty
          fullWidth
          style={{ marginBottom: '20px' }}
        >
          <MenuItem value="" disabled>Select a Pot</MenuItem>
          {potOptions.map(potOption => (
            <MenuItem 
              key={`${potOption.category}_${potOption.value}`} 
              value={potOption.label}
            >
              {potOption.label}
            </MenuItem>
          ))}
        </Select>
  
        {selectedPot && (
          <div>
            <h3>Teams in {selectedPot}:</h3>
            {teamsInSelectedPot.length > 0 ? (
              <>
                <div style={{ listStyleType: 'none', paddingLeft: 0 }}>
                  {teamsInSelectedPot.map(team => (
                    <div key={team}>{team}</div>
                  ))}
                </div>
  
                {/* Display total investment */}
                <div style={{ 
                  fontWeight: 'bold', 
                  fontSize: '1.1em',
                  padding: '10px',
                  backgroundColor: '#f5f5f5',
                  borderRadius: '5px',
                  marginTop: '10px'
                }}>
                  Total Investment: {formatCurrency(totalInvestment)}
                </div>
              </>
            ) : (
              <p>No teams entered this pot.</p>
            )}
          </div>
        )}
      </div>
    );
  };  

  const renderEntriesByTeam = () => {
    // Debug: Log the current state
    console.log('renderEntriesByTeam - Current state:', {
      teams: Object.values(teams).length,
      potEntries: potEntries.length,
      selectedTeam
    });
  
    // Format currency consistently
    const formatCurrency = (value) => `$${value.toLocaleString()}`;
  
    // Get sorted team options
    const teamOptions = Object.values(teams)
      .map(team => team['Team name'])
      .sort();
    
    console.log('Available teams:', teamOptions);
  
    // Get all pot entries for the selected team with detailed logging
    const entriesForTeam = selectedTeam
      ? potEntries.filter(entry => {
          const matches = entry.teamName === selectedTeam;
          if (matches) {
            console.log(`Found pot entry for ${selectedTeam}:`, entry.potName);
          }
          return matches;
        })
      : [];
    
    console.log(`Found ${entriesForTeam.length} pot entries for team: ${selectedTeam}`);
  
    // Process each entry to create formatted pot descriptions
    const potsForSelectedTeam = entriesForTeam.flatMap(entry => {
      if (!entry.potName) return [];
      
      const entryCategory = entry.potName.categoryTitle || entry.potName.category;
      
      // Handle both arrays and single values
      const entryValues = Array.isArray(entry.potName.values) 
        ? entry.potName.values 
        : [entry.potName.value];
      
      const formattedPots = entryValues.map(value => {
        const formattedPot = `${entryCategory} (${formatCurrency(value)})`;
        console.log(`Created formatted pot: ${formattedPot}`);
        return formattedPot;
      });
      
      return formattedPots;
    });
  
    console.log(`Total formatted pots for ${selectedTeam}:`, potsForSelectedTeam);
  
    return (
      <div>
        <br/>
        <Select
          value={selectedTeam || ''}
          onChange={(e) => {
            console.log('Selected new team:', e.target.value);
            setSelectedTeam(e.target.value);
          }}
          displayEmpty
          fullWidth
          style={{ marginBottom: '20px' }}
        >
          <MenuItem value="" disabled>Select a Team</MenuItem>
          {teamOptions.map(teamName => (
            <MenuItem key={teamName} value={teamName}>
              {teamName}
            </MenuItem>
          ))}
        </Select>
  
        {selectedTeam && (
          <div>
            <h3>Pots for {selectedTeam}:</h3>
            {potsForSelectedTeam.length > 0 ? (
              <>
                <div style={{ listStyleType: 'none', paddingLeft: 0, marginBottom: '20px' }}>
                  {potsForSelectedTeam.map((pot, index) => (
                    <div key={`${pot}-${index}`}>{pot}</div>
                  ))}
                </div>

                {/* Calculate and display the total investment */}
                {(() => {
                  // Find all unique pot entries for this team
                  const teamEntries = potEntries.filter(entry => entry.teamName === selectedTeam);
                  
                  // Ensure there's at least one entry before accessing totalPotFee
                  const totalInvestment = teamEntries.length > 0 ? teamEntries[0].totalPotFee : 0;

                  console.log(`Total investment for ${selectedTeam}:`, totalInvestment);

                  return (
                    <div style={{ 
                      fontWeight: 'bold', 
                      fontSize: '1.1em',
                      padding: '10px',
                      backgroundColor: '#f5f5f5',
                      borderRadius: '5px',
                      marginTop: '10px'
                    }}>
                      Total Investment: {formatCurrency(totalInvestment)}
                    </div>
                  );
                })()}
              </>
            ) : (
              <p>{selectedTeam} has not entered any pots.</p>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderPayoutsContent = () => {
    // Display loading state
    if (isLoading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
          <Typography variant="h6" sx={{ ml: 2 }}>
            Loading pot leaderboard data...
          </Typography>
        </Box>
      );
    }
  
    // Check if we have results to display
    const hasResults = Array.isArray(resultArray) && resultArray.length > 0;
    const hasValidResults = hasResults && resultArray.some(result => 
      result.rows && Array.isArray(result.rows) && result.rows.length > 0
    );
    
    // Timestamp is always shown
    const timestampDisplay = (
      <Box sx={{ mt: 2, mb: 3 }}>
        <Typography 
          variant="subtitle1" 
          color="textSecondary" 
          sx={{ fontStyle: 'italic' }}
        >
          {timestamp}
        </Typography>
      </Box>
    );
    
    // Tournament status messages
    const tournamentStatusMessage = !tournamentHasStarted ? (
      <Box sx={{ my: 4, textAlign: 'center' }}>
        <Typography variant="h5" color="textPrimary" gutterBottom>
          No catches yet... the tournament will begin soon!
        </Typography>
      </Box>
    ) : !hasResults ? (
      <Box sx={{ my: 4, textAlign: 'center' }}>
        <Typography variant="h5" color="textPrimary" gutterBottom>
          No pot results available yet. Teams have entered pots but no qualifying catches have been recorded.
        </Typography>
      </Box>
    ) : !hasValidResults ? (
      <Box sx={{ my: 4, textAlign: 'center' }}>
        <Typography variant="h5" color="textPrimary" gutterBottom>
          Teams have entered pots, but no qualifying catches have been recorded yet.
        </Typography>
      </Box>
    ) : null;
  
    switch (viewAlignment) {
      case "List":
        return (
          <Box sx={{ mt: 3 }}>
            {timestampDisplay}
            {tournamentStatusMessage}
            
            {hasResults && resultArray.map((result, index) => {
              // Only render if we have rows
              if (result.rows && result.rows.length > 0) {
                return (
                  <ResultTable
                    key={result.title}
                    style={{ width: '100%' }}
                    title={result.title}
                    subtitle={result.subtitle}
                    numPlaces={result.numPlaces}
                    rows={result.rows}
                    columns={matches ? result.desktopColumns : result.mobileColumns}
                    scroll={matches ? null : "scroll"}
                    density="compact"
                    eventSettings={eventSettings}
                  />
                );
              }
              return null;
            })}
          </Box>
        );
  
      case "Slideshow":
        return (
          <Box sx={{ mt: 3 }}>
            {timestampDisplay}
            {tournamentStatusMessage}
            
            {hasResults && (
              hasValidResults ? (
                <Carousel 
                  eventSettings={eventSettings} 
                  results={resultArray.filter(r => r.rows && r.rows.length > 0)} 
                  title="Pot Results"
                  emptyMessage="No qualifying catches have been recorded yet." 
                />
              ) : (
                <Box sx={{ my: 4, textAlign: 'center' }}>
                  <Typography variant="h5" color="textPrimary" gutterBottom>
                    No qualifying catches have been recorded yet.
                  </Typography>
                </Box>
              )
            )}
          </Box>
        );
  
      case "Select":
        return (
          <Box sx={{ mt: 3 }}>
            {timestampDisplay}
            {tournamentStatusMessage}
  
            {hasResults && (
              <Box sx={{ mb: 3 }}>
                <Select
                  labelId="select-category"
                  id="select-category"
                  value={selectedResult[0]?.title || ''}
                  onChange={handleSelectResult}
                  displayEmpty
                  fullWidth={!matches}
                >
                  <MenuItem value="" disabled>Select a Category</MenuItem>
                  {resultArray
                    .filter(result => result.rows && result.rows.length > 0)
                    .map(result => (
                      <MenuItem key={result.title} value={result.title}>
                        {result.title}
                      </MenuItem>
                    ))
                  }
                </Select>
              </Box>
            )}
  
            {hasSelectedResult ? (
              <Box>
                {selectedResult.map(result => (
                  result.rows && result.rows.length > 0 ? (
                    <ResultTable
                      key={result.title}
                      style={{ width: '100%' }}
                      title={result.title}
                      subtitle={result.subtitle}
                      numPlaces={result.numPlaces}
                      rows={result.rows}
                      columns={matches ? result.desktopColumns : result.mobileColumns}
                      scroll={matches ? null : "scroll"}
                      density="compact"
                      eventSettings={eventSettings}
                    />
                  ) : (
                    <Typography key={result.title} variant="h5" align="center" sx={{ mt: 4 }}>
                      No qualifying catches for {result.title} yet.
                    </Typography>
                  )
                ))}
              </Box>
            ) : (
              hasResults && (
                <Typography variant="h5" align="center" sx={{ mt: 4 }}>
                  Please select a category to view results
                </Typography>
              )
            )}
          </Box>
        );
  
      default:
        return null;
    }
  };

  if (isEventSettingsLoading) {
    return (
      <div style={{ textAlign: 'center', padding: '2rem' }}>
        <h2>Loading event settings...</h2>
      </div>
    );
  }

  return (
    <AnimatedPage>
      <main>
        {eventSettings && (
          <>
            <AddPotModal
              eventSettings={eventSettings}
              status={isAddPotModalOpen}
              open={openAddPotModal}
              close={closeAddPotModal}
              isAdmin={false}
            />
            
            <section 
              style={{ backgroundColor: eventSettings.backgroundColor }} 
              className="section-banner"
            >
              <h1 style={{ color: eventSettings.headlineTextColor }}>Pots</h1>
            </section>
  
            {/* Registration Action */}
            <section className="section-contact" style={{ marginBottom: '20px' }}>
              <Box sx={{ width: '90%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={openAddPotModal}
                  disabled={!registrationEnabled}
                  sx={{
                    mb: 2,
                    backgroundColor: '#0281C5',
                    borderRadius: '30px',
                    padding: '10px 25px',
                    fontWeight: 'bold',
                    '&:hover': {
                      backgroundColor: '#026999',
                    },
                    '&.Mui-disabled': {
                      backgroundColor: '#cccccc',
                      color: '#666666'
                    }
                  }}
                >
                  Register for Pots
                </Button>
                
                {tournamentHasStarted && (
                  <Alert severity="info" sx={{ width: '100%', mb: 2 }}>
                    Tournament has started. Pot registration is now closed.
                  </Alert>
                )}
                
              </Box>
            </section>
  
            <section className="section-leaderboard">
              <div>
                {matches ? (
                  <ToggleSliderButton 
                    choice={displaySelection} 
                    choiceList={displayOptions} 
                    alignment={displaySelection} 
                    setAlignment={setDisplaySelection}
                  />
                ) : (
                  <Select
                    value={displaySelection}
                    onChange={(e) => setDisplaySelection(e.target.value)}
                    fullWidth
                  >
                    {displayOptions.map(option => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </div>
            </section>
  
            {displaySelection === "Entries" && (
              <section className="section-leaderboard">
                <div>
                  {matches ? (
                    <ToggleSliderButton 
                      choice={entriesViewSelection} 
                      choiceList={entriesViewOptions} 
                      alignment={entriesViewSelection} 
                      setAlignment={setEntriesViewSelection}
                    />
                  ) : (
                    <Select
                      value={entriesViewSelection}
                      onChange={(e) => setEntriesViewSelection(e.target.value)}
                      fullWidth
                    >
                      {entriesViewOptions.map(option => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </div>
              </section>
            )}
  
            {displaySelection === "Payouts" && (
              <section className="section-leaderboard">
                <div>
                  {matches ? (
                    <ToggleSliderButton 
                      choice={viewAlignment} 
                      choiceList={viewList} 
                      alignment={viewAlignment} 
                      setAlignment={setViewAlignment}
                    />
                  ) : (
                    <Select
                      value={viewAlignment}
                      onChange={(e) => setViewAlignment(e.target.value)}
                      fullWidth
                    >
                      {viewList.map(option => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </div>
              </section>
            )}
  
            <section className="section-view">
              <Box sx={{ width: '90%', typography: 'body1', mx: 'auto' }}>
                {displaySelection === "Entries" && (
                  <>
                    {entriesViewSelection === "Board" && renderEntriesBoard()}
                    {entriesViewSelection === "By Pot" && renderEntriesByPot()}
                    {entriesViewSelection === "By Team" && renderEntriesByTeam()}
                  </>
                )}
  
                {displaySelection === "Payouts" && renderPayoutsContent()}
              </Box>
            </section>
  
            <Footer />
          </>
        )}
      </main>
    </AnimatedPage>
  );
}

export default PotsPage;

