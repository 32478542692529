import React from 'react';
import { TextField, Button, IconButton } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';

const ProfileField = ({
  label,
  value,
  field,
  editField,
  editValue,
  validationError,
  onEdit,
  onSave,
  onCancel,
  onValueChange
}) => {

  const fieldIsEditable = field !== 'username' && field !== 'organization'; // NEW: Organization is now non-editable

  const handleInputChange = (e) => {
    e.preventDefault();
    onValueChange(e.target.value);
  };

  const handleSaveClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onSave();
  };

  const handleCancelClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onCancel();
  };

  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '16px',
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      minHeight: '72px'
    }}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        gap: '24px' // Adds space between label and value
      }}>
        <div style={{
          fontWeight: 500,
          color: '#666',
          flex: '0 0 auto'
        }}>
          <strong>{label}:</strong>
        </div>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          flex: '1',
          justifyContent: 'flex-end', // Aligns content to the right
          gap: '8px' // Space between value and edit button
        }}>
          {editField === field ? (
            <div style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px'
            }}>
              <TextField
                value={editValue}
                onChange={handleInputChange}
                error={!!validationError}
                helperText={validationError}
                size="small"
                autoFocus
                InputProps={{
                  autoComplete: 'off'
                }}
                sx={{
                  minWidth: '200px'
                }}
              />
              <Button
                onClick={handleSaveClick}
                variant="contained"
                color="primary"
                size="small"
              >
                Save
              </Button>
              <Button
                onClick={handleCancelClick}
                variant="outlined"
                color="secondary"
                size="small"
              >
                Cancel
              </Button>
            </div>
          ) : (
            <>
              <span style={{
                color: '#333',
                marginLeft: '8px'
              }}>{value}</span>
              {fieldIsEditable && (
                <IconButton 
                  onClick={() => onEdit(field, value)} 
                  size="small"
                  sx={{
                    ml: 1,
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)'
                    }
                  }}
                >
                  <EditIcon />
                </IconButton>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileField;

