import { Outlet, useLocation } from "react-router-dom";
import { Box, Toolbar } from "@mui/material";
import { useState } from "react";
import { colorConfig, sizeConfig } from "../config/config";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import Authenticate from "../components/authenticate/Authenticate";
import AnimatedPage from "../pages/AnimatedPage";

const MainLayout = () => {
  const [sidebarExpanded, setSidebarExpanded] = useState(true);
  const location = useLocation();
  const currentUser = JSON.parse(window.localStorage.getItem('user')); // Only for JSON objects
  const username = window.localStorage.getItem('username'); // Directly retrieve the plain string, no JSON.parse needed

  // Ensure username exists before using it in pageTitleMap
  if (!username) {
    console.error('Username is missing');
  }

  // Determine the current page title based on the URL path
  const pageTitleMap = {
    [`/${username}/dashboard`]: "My Events",
    [`/${username}/profile`]: "My User Profile",
    [`/${username}/statistics`]: "My Statistics",
  };

  // Compare the current path with the keys in pageTitleMap
  const pageTitle = pageTitleMap[location.pathname] || "Dashboard"; // Default to "Dashboard"

  console.log('pageTitle', pageTitle);
  console.log('location', location);
  console.log('currentUser', currentUser);
  console.log('username', username);

  return currentUser ? (
    <AnimatedPage>
      <Box sx={{ display: "flex" }}>
        <Topbar sidebarExpanded={sidebarExpanded} pageTitle={pageTitle} />
        <Box
          component="nav"
          sx={{
            width: sidebarExpanded ? sizeConfig.sidebar.width : "70px",
            flexShrink: 0,
          }}
        >
          <Sidebar sidebarExpanded={sidebarExpanded} setSidebarExpanded={setSidebarExpanded} />
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: `calc(100% - ${sidebarExpanded ? sizeConfig.sidebar.width : "70px"})`,
            minHeight: "100vh",
            backgroundColor: colorConfig.mainBg,
            transition: "width 0.3s",
          }}
        >
          <Toolbar />
          <Outlet />
        </Box>
      </Box>
    </AnimatedPage>
  ) : (
    <AnimatedPage>
      <Authenticate />
    </AnimatedPage>
  );
};

export default MainLayout;

