import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import './ResultTable.css';

// Extend dayjs with UTC and timezone plugins
dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * Generic result table component for displaying tournament results
 * Used for both leaderboards and pot results
 */
const ResultTable = ({ 
  title,
  subtitle,
  numPlaces,
  rows,
  columns,
  visibility,
  density = 'compact',
  scroll,
  style = {},
  eventSettings
}) => {

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  console.log(`\n----- RENDERING RESULT TABLE: ${title} -----`);
  console.log('Rows count:', rows?.length || 0);
  console.log('Columns count:', columns?.length || 0);
  
  // Debug column configuration
  console.log('Columns configuration:');
  columns.forEach((col, idx) => {
    console.log(`Column ${idx+1}: ${col.field} (${col.headerName}), formatter: ${!!col.valueFormatter}`);
  });
  
  // Debug row data
  if (rows && rows.length > 0) {
    console.log('First row data:');
    console.log(JSON.stringify(rows[0], null, 2));
    
    // Check for points values
    const pointsColumn = columns.find(col => col.field === 'points');
    if (pointsColumn) {
      console.log('Points values in rows:');
      rows.forEach((row, idx) => {
        console.log(`Row ${idx+1} (${row.team}): points=${row.points}, type=${typeof row.points}`);
        
        // If there's a value formatter, show the formatted value as well
        if (pointsColumn.valueFormatter) {
          try {
            const formattedValue = pointsColumn.valueFormatter({ value: row.points });
            console.log(`  Formatted value: ${formattedValue}`);
          } catch (error) {
            console.error(`  Error formatting value: ${error.message}`);
          }
        }
      });
    }
  }

  // Format any date/time values in columns
  const formattedColumns = columns.map((col) => {
    if (col.isDateTime) {
      console.log(`Adding date formatter for column: ${col.field}`);
      return {
        ...col,
        valueFormatter: (params) => {
          console.log(`Formatting date value for ${col.field}:`, params);
          return dayjs(params).format('MMMM Do, YYYY @ hh:mm A');
        }
      };
    }
    return col; // Preserve the original column configuration
  });

  return (
    <div className="result-table" style={{ 
      ...style, 
      overflowX: 'auto', // Always enable horizontal scrolling
      width: '100%' 
    }}>
      <div className="result-table-title-container">
        <h1 style={{ 
          fontSize: '28px', 
          color: "black", 
          margin: '0 0 8px 0',
          fontWeight: 'bold'
        }}>
          {title}
        </h1>
        
        {subtitle && (
          <div className="result-table-subtitle">
            {subtitle}
          </div>
        )}
      </div>
      <DataGrid
        rows={rows || []}
        columns={formattedColumns}
        columnVisibilityModel={visibility}
        sx={{
          // IMPORTANT: These changes will enable horizontal scrolling
          width: matches ? '100%' : 'auto', // Auto width on mobile to allow content to expand
          minWidth: matches ? 'auto' : '100%', // Ensure minimum width on mobile
          overflowX: scroll ? 'auto' : 'visible',
          '.MuiDataGrid-root': {
            overflowX: 'auto !important',
          },
          '.MuiDataGrid-main': {
            overflowX: 'auto !important',
          },
          '.MuiDataGrid-virtualScroller': {
            overflowX: 'auto !important',
          },
          // Keep your existing styling below
          '.MuiDataGrid-row.Mui-odd': {
            backgroundColor: 'rgba(234, 234, 234)',
            fontSize: '16px',
          },
          '.MuiDataGrid-columnHeaderTitleContainer': {
            backgroundColor: eventSettings?.backgroundColor || '#87CEEB',
            color: eventSettings?.headlineTextColor || '#FFFFFF',
            fontSize: '16px',
            ".MuiSvgIcon-root": {
              color: eventSettings?.headlineTextColor || '#FFFFFF',
            }
          },
          '& .super-app-theme--header': {
            backgroundColor: eventSettings?.backgroundColor || '#87CEEB',
            color: eventSettings?.headlineTextColor || '#FFFFFF',
            fontSize: '16px',
          },
          '& .MuiDataGrid-cell': {
            fontSize: '16px',
            color: "black",
          }
        }}
        hideFooter={true}
        density={density}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
        }
        disableColumnMenu
        disableColumnFilter
        disableColumnSelector
        disableColumnSorting
        autoHeight
        onStateChange={(state) => {
          console.log('DataGrid state changed:', state);
        }}
      />
      <br />
      <br />
    </div>
  );

};

export default ResultTable;

