import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  CircularProgress,
  Divider
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

const DeleteSponsorModal = (props) => {
  const [deleteInfo, setDeleteInfo] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    setDeleteInfo(props.deleteInfo);
  }, [props.deleteInfo]);

  // Format currency for display
  const formatPrice = (price) => {
    if (!price && price !== 0) return '';
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(price);
  };

  const handleClose = () => {
    setIsSubmitting(false);
    setIsSubmitted(false);
    props.close();
  };

  const handleDelete = async () => {
    setIsSubmitting(true);

    try {
      let apiUrl = process.env.REACT_APP_NODE_ENV === "production"
        ? process.env.REACT_APP_SERVER_URL_PRODUCTION
        : process.env.REACT_APP_SERVER_URL_STAGING;

      const response = await fetch(`${apiUrl}/api/admin_delete_event_sponsor`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          username: props.username,
          eventId: props.eventId,
          sponsorId: props.deleteInfo.sponsorId,
        })
      });

      if (response.ok) {
        toast.success('Sponsor deleted successfully.');
        setIsSubmitted(true);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        const errorData = await response.json();
        toast.error(`Error: ${errorData.error || 'Error deleting sponsor'}`);
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error('Error deleting sponsor:', error);
      toast.error('Error deleting sponsor.');
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog 
      open={props.status} 
      onClose={handleClose} 
      fullWidth 
      maxWidth="sm"
    >
      <DialogTitle style={{ backgroundColor: '#0281C5', color: 'white', padding: '16px 24px' }}>
        Delete Sponsor
        <IconButton
          onClick={handleClose}
          sx={{ position: 'absolute', right: 8, top: 8, color: 'white' }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {deleteInfo && (
          <Stack spacing={2} sx={{ mt: 3 }}>
            {/* Organization Name */}
            <Typography variant="h6">{deleteInfo.name}</Typography>
            
            <Divider />
            
            {/* Sponsor Details */}
            <Stack spacing={1}>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="body1" fontWeight="medium">Sponsor ID:</Typography>
                <Typography variant="body1">{deleteInfo.sponsorId}</Typography>
              </Box>
              
              <Box display="flex" justifyContent="space-between">
                <Typography variant="body1" fontWeight="medium">Email:</Typography>
                <Typography variant="body1">{deleteInfo.email}</Typography>
              </Box>
              
              {deleteInfo.website && (
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body1" fontWeight="medium">Website:</Typography>
                  <Typography variant="body1">{deleteInfo.website}</Typography>
                </Box>
              )}
              
              <Box display="flex" justifyContent="space-between">
                <Typography variant="body1" fontWeight="medium">Tier:</Typography>
                <Typography variant="body1">{deleteInfo.tier}</Typography>
              </Box>
              
              <Box display="flex" justifyContent="space-between">
                <Typography variant="body1" fontWeight="medium">Amount:</Typography>
                <Typography variant="body1">{formatPrice(deleteInfo.amount)}</Typography>
              </Box>
              
              {deleteInfo.registrationTimestamp && (
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body1" fontWeight="medium">Registration Date:</Typography>
                  <Typography variant="body1">
                    {new Date(deleteInfo.registrationTimestamp).toLocaleDateString()}
                  </Typography>
                </Box>
              )}
            </Stack>
            
            {/* Sponsor Logo */}
            {deleteInfo.logoUrl && (
              <Box mt={2}>
                <Typography variant="body1" fontWeight="medium" gutterBottom>Sponsor Logo:</Typography>
                <Box
                  sx={{
                    width: 150,
                    height: 150,
                    border: '1px solid #ddd',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden',
                    borderRadius: '4px'
                  }}
                >
                  <img
                    src={deleteInfo.logoUrl}
                    alt="Sponsor Logo"
                    style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                    onError={(e) => {
                      e.target.src = "../../images/defaultNoImage.png";
                    }}
                  />
                </Box>
              </Box>
            )}
            
            <Divider />
            
            {/* Warning and Delete Button */}
            <Typography variant="body1" color="error" fontWeight="bold">
              Are you sure you want to delete this sponsor? This action cannot be undone.
            </Typography>
            
            {!isSubmitted ? (
              <Button
                variant="contained"
                color="error"
                onClick={handleDelete}
                disabled={isSubmitting}
                startIcon={isSubmitting ? <CircularProgress size={24} color="inherit" /> : null}
                sx={{
                  py: 1.5,
                  fontWeight: 'bold',
                  mt: 2,
                  mb: 2
                }}
              >
                {isSubmitting ? "Deleting..." : "Yes, Delete This Sponsor"}
              </Button>
            ) : (
              <Box sx={{ textAlign: 'center', mt: 2 }}>
                <Typography variant="subtitle1" color="primary" fontWeight="bold">
                  Sponsor deleted successfully!
                </Typography>
              </Box>
            )}
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DeleteSponsorModal;

