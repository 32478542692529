import jsPDF from 'jspdf';
import 'jspdf-autotable';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const addPageNumbers = (doc) => {
  const pageCount = doc.internal.getNumberOfPages();
  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.setFontSize(10);
    doc.text(
      `${i} of ${pageCount}`,
      doc.internal.pageSize.getWidth() - 25,
      doc.internal.pageSize.getHeight() - 10
    );
  }
};

// Format the subtitle to include payout and winner split message
const formatPotSubtitle = (category) => {
  const parts = [];
  
  // Add payout information if available
  if (category.totalPayout) {
    parts.push(`Total Payout: $${category.totalPayout.toFixed(2)}`);
  }
  
  // Add the note about winner split
  parts.push('Event will determine winner split');
  
  return parts.join(' - ');
};

const generatePotsPDF = (potsData, tournamentName) => {
  const doc = new jsPDF('landscape');
  const currentDate = dayjs().tz('America/Chicago').format('MMMM D, YYYY h:mm A');

  potsData.forEach((category, index) => {
    if (index > 0) doc.addPage();

    // Extract the category name from the title, fixing "undefined" issues
    let categoryTitle = category.title || '';
    
    // If title contains "undefined", extract and fix it
    if (categoryTitle.includes('undefined') && categoryTitle.includes('$')) {
      const potValueMatch = categoryTitle.match(/\$([0-9,]+)/);
      const potValue = potValueMatch ? potValueMatch[0] : '';
      
      // Try to find the category name from any available fields
      const categoryName = category.categoryTitle || 'Pot';
      categoryTitle = `${categoryName} ${potValue}`;
    }

    // Header
    doc.setFontSize(16);
    doc.setFont('helvetica', 'bold');
    doc.text(
      `${categoryTitle} - ${tournamentName}`,
      10,
      10
    );

    // Format subtitle with payout and winner split info
    const subtitle = formatPotSubtitle(category);
    
    // Subtitle
    doc.setFontSize(12);
    doc.text(`Report generated on ${currentDate}`, 10, 20);
    
    if (subtitle) {
      doc.setFontSize(10);
      doc.setFont('helvetica', 'italic');
      doc.text(subtitle, 10, 28);
    }

    // Determine columns dynamically from desktopColumns
    const tableColumns = category.desktopColumns ? 
      category.desktopColumns.map(col => col.headerName) : 
      ['Place', 'Team'];

    if (!category.rows || category.rows.length === 0) {
      // No data message
      doc.setFontSize(12);
      doc.text('No entries for this pot.', 10, subtitle ? 40 : 32);
    } else {
      // Transform row data based on desktopColumns
      const tableRows = category.rows.map(row => 
        (category.desktopColumns || []).map(col => {
          let value = row[col.field];
          
          // Format datetime fields
          if (col.isDateTime && value) {
            value = dayjs(value).format('MM/DD/YYYY hh:mm A');
          }
          
          // Format numbers to 2 decimal places if needed
          if (typeof value === 'number' && !Number.isInteger(value)) {
            value = value.toFixed(2);
          }
          
          return value !== undefined && value !== null ? value : 'N/A';
        })
      );

      doc.autoTable({
        startY: subtitle ? 40 : 32,
        head: [tableColumns],
        body: tableRows,
        theme: 'striped',
        styles: {
          fontSize: 10,
          halign: 'center',
          valign: 'middle',
          overflow: 'linebreak',
        },
        headStyles: {
          fillColor: '#02133E',
          textColor: '#ffffff',
          halign: 'center',
        },
      });
    }
  });

  addPageNumbers(doc);
  doc.save(`${tournamentName} Pots Report.pdf`);
};

export const generatePotsReport = async (username, eventId, tournamentName) => {
  const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
    ? process.env.REACT_APP_SERVER_URL_PRODUCTION
    : process.env.REACT_APP_SERVER_URL_STAGING;

  try {
    const response = await fetch(`${apiUrl}/api/get_event_pot_leaderboard`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, eventId })
    });

    if (!response.ok) {
      throw new Error('Failed to fetch pot leaderboard');
    }

    const data = await response.json();
    
    if (!data.results || !Array.isArray(data.results)) {
      throw new Error('Invalid pot leaderboard data format');
    }

    // Filter for valid results with rows
    const validResults = data.results.filter(result => 
      result && result.rows && Array.isArray(result.rows) && result.rows.length > 0
    );

    // Fix category titles and ensure column definitions exist
    const processedResults = validResults.map(result => {
      // Fix title if it contains "undefined"
      let processedTitle = result.title;
      if (processedTitle && processedTitle.includes('undefined')) {
        const potValueMatch = processedTitle.match(/\$([0-9,]+)/);
        if (potValueMatch) {
          const potValue = potValueMatch[0];
          // Try to extract category name from any available field
          const categoryName = result.categoryTitle || 'Pot';
          processedTitle = `${categoryName} ${potValue}`;
        }
      }

      // Ensure both desktop and mobile columns exist
      if (!result.desktopColumns || !Array.isArray(result.desktopColumns)) {
        result.desktopColumns = [
          { field: 'place', headerName: 'Place', flex: 1 },
          { field: 'team', headerName: 'Team', flex: 2 }
        ];
      }
      
      return {
        ...result,
        title: processedTitle
      };
    });

    generatePotsPDF(processedResults, tournamentName);

  } catch (error) {
    console.error('Error generating pots report:', error);
    throw error;
  }
};

export default { generatePotsReport };

