import React from 'react';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import { colorConfig } from '../../config/config';

// Custom Toolbar Component
function QuickSearchToolbar() {
  return (
    <Box sx={{ p: 1, display: 'flex', justifyContent: 'flex-end' }}>
      <GridToolbarQuickFilter />
    </Box>
  );
}

function CountTable(props) {
  const { 
    rows = [], 
    columns = [], 
    style, 
    visibility, 
    scroll, 
    initialState, 
    pageSizeOptions = [100], 
    eventSettings = props.eventSettings,
    hideFooter = true,
    pagination = false,
    pageSize = 100
  } = props;

  const backgroundColor = eventSettings.backgroundColor;
  const headlineColor = eventSettings.headlineTextColor;

  if (!rows.length) {
    return <div>No data available</div>;
  }

  if (!columns.length) {
    return <div>No columns defined</div>;
  }

  return (
    <div style={style}>
      <DataGrid
        rows={rows}
        columns={columns}
        columnVisibilityModel={visibility}
        slots={{ toolbar: QuickSearchToolbar }}
        sx={{
          overflowX: 'auto',
          '.MuiDataGrid-row': {
            backgroundColor: 'white !important',
            fontSize: '16px',
          },
          '.MuiDataGrid-row.Mui-odd': {
            backgroundColor: 'rgba(234, 234, 234)',
          },
          '.MuiDataGrid-columnHeaders': {
            justifyContent: 'center',
            textAlign: 'center',
            backgroundColor: backgroundColor,
            color: headlineColor,
          },
          '.MuiDataGrid-columnHeaderTitleContainer': {
            justifyContent: 'center',
            backgroundColor: backgroundColor,
            fontSize: '16px',
            color: headlineColor,
            ".MuiSvgIcon-root": {
              color: headlineColor,
            }
          },
          '.MuiDataGrid-iconButtonContainer': {
            backgroundColor: backgroundColor,
            color: headlineColor,
          },
          '& .super-app-theme--header': {
            justifyContent: 'center',
            backgroundColor: backgroundColor,
            fontSize: '16px',
            color: 'white',
          },
          '& .MuiDataGrid-cell': {
            justifyContent: 'center',
            textAlign: 'center',
            fontSize: '16px',
            color: "black",
          },
        }}
        density='compact'
        hideFooter={hideFooter}
        pagination={pagination}
        pageSize={pageSize}
        rowsPerPageOptions={pageSizeOptions}
        autoHeight
      />
    </div>
  );
}

CountTable.defaultProps = {
  hideFooter: true,
  pagination: false,
  pageSize: 100,
  pageSizeOptions: [100],
  eventSettings: {
    backgroundColor: '#FFFFFF',
    headlineColor: '#000000',
  },
};

export default CountTable;

