import React from 'react';
import { Link } from 'react-router-dom';
import { Trophy, Users, Info, Fish, DollarSign, Award, UserPlus } from 'lucide-react';
import './EventFeaturesSection.css';

const EventFeaturesSection = ({ 
  username, 
  eventId, 
  eventSettings, 
  backgroundColor, 
  textColor,
  isRegistrationEnabled,
  isSponsorFeatureEnabled,
  isLeaderboardVisible, 
  isPotsFeatureEnabled 
}) => {
  // If visibility check functions weren't passed as props, create them
  const checkLeaderboardVisible = isLeaderboardVisible || (() => 
    eventSettings?.pageVisibility?.leaderboard !== false
  );
  
  const checkRegistrationEnabled = isRegistrationEnabled || (() => 
    (eventSettings?.features?.onlineTeamRegistration === "customerPays" || 
     eventSettings?.features?.onlineTeamRegistration === "iPay") &&
    (eventSettings?.pageVisibility?.registration !== false)
  );
  
  const checkSponsorFeatureEnabled = isSponsorFeatureEnabled || (() => 
    (eventSettings?.features?.onlineSponsorRegistration === "customerPays" || 
     eventSettings?.features?.onlineSponsorRegistration === "iPay") &&
    (eventSettings?.pageVisibility?.sponsors !== false)
  );
  
  const checkPotsFeatureEnabled = isPotsFeatureEnabled || (() => 
    (eventSettings?.features?.fishPots === "customerPays" || 
     eventSettings?.features?.fishPots === "iPay") &&
    (eventSettings?.pageVisibility?.pots !== false)
  );
  
  // Base features (respecting visibility settings)
  const baseFeatures = [];
  
  // Only add leaderboard if it's visible
  if (checkLeaderboardVisible()) {
    baseFeatures.push({
      icon: <Trophy size={48} />,
      title: 'Leaderboard',
      description: 'Track team progress on the realtime leaderboard.',
      link: `/${username}/${eventId}/leaderboard`,
      isExternal: false,
    });
  }
  
  // Newsfeed is always visible
  baseFeatures.push({
    icon: <Fish size={48} />,
    title: 'Newsfeed',
    description: 'See all the latest catches, team registrations, and other announcement information for the event.',
    link: `/${username}/${eventId}/newsfeed`,
    isExternal: false,
  });

  // Conditional features based on enabled features and visibility settings
  const conditionalFeatures = [];
  
  // Add Team Registration feature if enabled and visible
  if (checkRegistrationEnabled()) {
    conditionalFeatures.push({
      icon: <UserPlus size={48} />,
      title: 'Team Registration',
      description: 'Register your team for this exciting event and prepare to compete.',
      link: `/${username}/${eventId}/register`,
      isExternal: false,
    });
  }

  // Add Sponsors feature if enabled and visible
  if (checkSponsorFeatureEnabled()) {
    conditionalFeatures.push({
      icon: <Award size={48} />,
      title: 'Sponsors',
      description: 'View our amazing sponsors and learn how your organization can support this event.',
      link: `/${username}/${eventId}/sponsors`,
      isExternal: false,
    });
  }

  // Add Pots feature if enabled and visible
  if (checkPotsFeatureEnabled()) {
    conditionalFeatures.push({
      icon: <DollarSign size={48} />,
      title: 'Payouts',
      description: 'See which teams have entered which pots and who is in the running for a payout.',
      link: `/${username}/${eventId}/pots`,
      isExternal: false,
    });
  }

  // Add Info feature if website link exists
  if (eventSettings?.basicInfo?.websiteType === 'link' && eventSettings?.basicInfo?.websiteLink) {
    conditionalFeatures.push({
      icon: <Info size={48} />,
      title: 'Info',
      description: 'View all your event info here.',
      link: eventSettings.basicInfo.websiteLink.startsWith('http') 
        ? eventSettings.basicInfo.websiteLink 
        : `https://${eventSettings.basicInfo.websiteLink}`,
      isExternal: true,
    });
  }

  // Combine all features
  const features = [...baseFeatures, ...conditionalFeatures];

  const handleFeatureClick = (feature, e) => {
    if (feature.isExternal) {
      e.preventDefault();
      window.open(feature.link, '_blank');
    }
  };

  return (
    <section
      className="event-features-section"
      style={{
        backgroundColor: eventSettings?.basicInfo?.backgroundColor || '#87CEEB',
        color: eventSettings?.basicInfo?.headlineTextColor || '#ffffff',
      }}
    >
      <div className="container">
        <h2 className="section-title" style={{ color: textColor || eventSettings?.basicInfo?.headlineTextColor || '#ffffff' }}>
          Explore Event Features
        </h2>
        <div className="features-grid">
          {features.map((feature, index) => (
            feature.isExternal ? (
              <div
                key={index}
                className="feature-card"
                onClick={(e) => handleFeatureClick(feature, e)}
                style={{ cursor: 'pointer' }}
              >
                <div className="icon-wrapper">
                  {feature.icon}
                </div>
                <h3 className="feature-title">{feature.title}</h3>
                <p className="feature-description">{feature.description}</p>
              </div>
            ) : (
              <Link
                to={feature.link}
                className="feature-card"
                key={index}
              >
                <div className="icon-wrapper">
                  {feature.icon}
                </div>
                <h3 className="feature-title">{feature.title}</h3>
                <p className="feature-description">{feature.description}</p>
              </Link>
            )
          ))}
        </div>
      </div>
    </section>
  );
};

export default EventFeaturesSection;

