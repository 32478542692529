import React from 'react';
import './StripePage.css';

function StripeCancelPage() {
  return (
    <>
      <main>
        <div className="message">
          <h2>Connection Cancelled</h2>
          <p>The Stripe connection process was cancelled.</p>
          <p>You can close this tab and return to the event creation page to try again.</p>
          <button className="button" onClick={() => window.close()}>Close This Tab</button>
        </div>
      </main>
    </>
  );
}

export default StripeCancelPage;

