import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { 
  InputLabel, 
  Button, 
  Dialog, 
  DialogContent, 
  DialogTitle, 
  IconButton, 
  Stack, 
  TextField, 
  FormControlLabel, 
  Checkbox, 
  Autocomplete,
  CircularProgress,
  Typography,
  Box,
  Paper,
  Divider
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddPotModal = (props) => {
  const { username, eventId } = useParams();
  const { eventSettings, isAdmin = true } = props;

  // States
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [teamList, setTeamList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedPots, setSelectedPots] = useState([]);
  const [totalFee, setTotalFee] = useState(0);
  const [consentGiven, setConsentGiven] = useState(true);
  const [ageCertified, setAgeCertified] = useState(false);
  
  // Fetch teams on component mount
  useEffect(() => {
    if (props.status) {
      fetchTeams();
    }
  }, [props.status]);
  
  // Calculate total fee whenever selected pots change
  useEffect(() => {
    calculateTotalFee();
  }, [selectedPots]);

  const fetchTeams = async () => {
    setLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
        ? process.env.REACT_APP_SERVER_URL_PRODUCTION
        : process.env.REACT_APP_SERVER_URL_STAGING;
        
      // For both admin and public users, use the admin teams endpoint
      const response = await fetch(`${apiUrl}/api/admin_get_event_teams`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, eventId })
      });
      
      if (!response.ok) {
        throw new Error(`Failed to fetch teams: ${response.status}`);
      }
      
      const teamsData = await response.json();
      
      // Transform the data into an array of objects suitable for the Autocomplete component
      const formattedTeams = Object.entries(teamsData).map(([teamId, teamData]) => ({
        teamId,
        teamName: teamData["Team name"],
        label: teamData["Team name"], // For Autocomplete display
        teamData
      }));
      
      setTeamList(formattedTeams);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching teams:', error);
      toast.error('Failed to load teams list');
      setLoading(false);
    }
  };
  
  const handleTeamChange = (event, value) => {
    if (value) {
      setSelectedTeam(value);
    } else {
      setSelectedTeam(null);
    }
  };
  
  const handlePotCategorySelection = (categoryTitle, isSelected) => {
    // Get the pot configuration from eventSettings
    const potList = eventSettings.pots?.potList || [];
    
    // Find the pot config for this category
    const potConfig = potList.find(pot => pot.categoryTitle === categoryTitle);
    
    // If we can't find the category, log an error and return
    if (!potConfig) {
      console.error(`Pot category not found: ${categoryTitle}`);
      return;
    }
    
    const updatedSelection = [...selectedPots];
    const categoryIndex = updatedSelection.findIndex(item => item.categoryTitle === categoryTitle);
    
    if (isSelected) {
      // If selecting all values for this category
      if (categoryIndex >= 0) {
        // Update existing category
        updatedSelection[categoryIndex] = {
          categoryTitle,
          values: [...potConfig.values]
        };
      } else {
        // Add new category with all values
        updatedSelection.push({
          categoryTitle,
          values: [...potConfig.values]
        });
      }
    } else {
      // If deselecting all values for this category
      if (categoryIndex >= 0) {
        // Remove the category entirely
        updatedSelection.splice(categoryIndex, 1);
      }
    }
    
    setSelectedPots(updatedSelection);
  };
  
  const handlePotValueSelection = (categoryTitle, value, isSelected) => {
    const updatedSelection = [...selectedPots];
    const categoryIndex = updatedSelection.findIndex(item => item.categoryTitle === categoryTitle);
    
    if (isSelected) {
      if (categoryIndex >= 0) {
        // Add value to existing category
        if (!updatedSelection[categoryIndex].values.includes(value)) {
          updatedSelection[categoryIndex].values.push(value);
        }
      } else {
        // Create new category with this value
        updatedSelection.push({
          categoryTitle,
          values: [value]
        });
      }
    } else {
      if (categoryIndex >= 0) {
        // Remove value from category
        updatedSelection[categoryIndex].values = updatedSelection[categoryIndex].values.filter(v => v !== value);
        
        // If no values left, remove the category
        if (updatedSelection[categoryIndex].values.length === 0) {
          updatedSelection.splice(categoryIndex, 1);
        }
      }
    }
    
    setSelectedPots(updatedSelection);
  };
  
  const calculateTotalFee = () => {
    let total = 0;
    
    selectedPots.forEach(pot => {
      pot.values.forEach(value => {
        total += parseFloat(value || 0);
      });
    });
    
    // Ensure total is a number and properly rounded
    total = Math.round(total * 100) / 100;
    setTotalFee(total);
  };
  
  const isValueSelected = (categoryTitle, value) => {
    const categorySelection = selectedPots.find(pot => pot.categoryTitle === categoryTitle);
    return categorySelection ? categorySelection.values.includes(value) : false;
  };
  
  const areCategoryValuesAllSelected = (categoryTitle) => {
    const potList = eventSettings.pots?.potList || [];
    const potConfig = potList.find(pot => pot.categoryTitle === categoryTitle);
    
    if (!potConfig) return false;
    
    const categorySelection = selectedPots.find(pot => pot.categoryTitle === categoryTitle);
    if (!categorySelection) return false;
    
    return potConfig.values.every(value => categorySelection.values.includes(value));
  };
  
  const isSomeCategoryValuesSelected = (categoryTitle) => {
    const categorySelection = selectedPots.find(pot => pot.categoryTitle === categoryTitle);
    return categorySelection && categorySelection.values.length > 0;
  };
  
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);
  };
  
  // Calculate platform fee based on the same logic as sponsorship
  const calculatePlatformFee = (baseAmount) => {
    if (baseAmount < 50) {
      return 3; // Flat $3 fee for amounts under $50
    } else if (baseAmount <= 100) {
      return 5; // Flat $5 fee for amounts between $50-$100
    } else if (baseAmount <= 200) {
      return baseAmount * 0.05; // 5% fee for amounts between $100-$200
    } else {
      return baseAmount * 0.04; // 4% fee for amounts over $200
    }
  };
  
  // Calculate fees for display
  const calculateFees = (baseAmount, featureType = 'customerPays') => {
    // Calculate platform fee
    const platformFee = calculatePlatformFee(baseAmount);
    
    // Define fee description based on amount
    let feeDescription;
    if (baseAmount < 50) {
      feeDescription = 'Flat $3 fee for pots under $50';
    } else if (baseAmount <= 100) {
      feeDescription = 'Flat $5 fee for pots $50-$100';
    } else if (baseAmount <= 200) {
      feeDescription = '5% of pot fee';
    } else {
      feeDescription = '4% of pot fee';
    }
    
    // Define Stripe fee parameters
    const STRIPE_PERCENTAGE = 2.9;
    const STRIPE_FIXED_FEE = 0.30;
    const DESTINATION_PERCENTAGE = 0.5;
    
    // Calculate Stripe processing fees
    let stripeFee;
    
    if (featureType === 'customerPays') {
      // If customer pays fees, Stripe fees are calculated on base + platform fee
      stripeFee = ((baseAmount + platformFee) * (STRIPE_PERCENTAGE + DESTINATION_PERCENTAGE) / 100) + STRIPE_FIXED_FEE;
      
      return {
        baseAmount,
        platformFee,
        feeDescription,
        stripeFee,
        stripeFeeDescription: `${STRIPE_PERCENTAGE}% + $${STRIPE_FIXED_FEE} + ${DESTINATION_PERCENTAGE}% destination fee`,
        total: baseAmount + platformFee + stripeFee
      };
    } else {
      // If organizer pays fees, customer only sees base price
      stripeFee = (baseAmount * (STRIPE_PERCENTAGE + DESTINATION_PERCENTAGE) / 100) + STRIPE_FIXED_FEE;
      
      return {
        baseAmount,
        platformFee,
        feeDescription,
        stripeFee,
        stripeFeeDescription: `${STRIPE_PERCENTAGE}% + $${STRIPE_FIXED_FEE} + ${DESTINATION_PERCENTAGE}% destination fee`,
        total: baseAmount
      };
    }
  };

  const formatPotsDataForSubmission = () => {
    return selectedPots.map(pot => ({
      categoryTitle: pot.categoryTitle,
      values: pot.values
    }));
  };
  
  const validateForm = () => {
    if (!selectedTeam) {
      toast.warning("Please select a team");
      return false;
    }
    
    if (selectedPots.length === 0) {
      toast.warning("Please select at least one pot");
      return false;
    }
    
    // Add age certification check for both admin and regular users
    if (!ageCertified) {
      toast.warning("Please certify that you meet the legal age requirements");
      return false;
    }
    
    // Consent check for regular users
    if (!isAdmin && !consentGiven) {
      toast.warning("Please accept the terms to continue");
      return false;
    }
    
    return true;
  };
  
  const handleSubmit = async () => {
    if (!validateForm()) return;
    
    setIsSubmitting(true);
    
    try {
      const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
        ? process.env.REACT_APP_SERVER_URL_PRODUCTION
        : process.env.REACT_APP_SERVER_URL_STAGING;
      
      // First check if team already has pot registrations
      const checkResponse = await fetch(`${apiUrl}/api/admin_get_team_pots`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          username,
          eventId,
          teamId: selectedTeam.teamId
        })
      });
      
      const potCheckData = await checkResponse.json();
      
      // If this team already has pot registrations
      if (Object.keys(potCheckData).length > 0) {
        // For clarity and security, always direct non-admin users to contact the admin for changes
        if (!isAdmin) {
          toast.warning('This team already has pot registrations. Please contact the tournament admin to make changes.');
          setIsSubmitting(false);
          return;
        } else {
          // For admins, warn them to use Edit instead
          toast.warning('This team already has pot registrations. Please use Edit instead.');
          setIsSubmitting(false);
          return;
        }
      }
      
      // Format the pots data for submission
      const potsData = formatPotsDataForSubmission();
      
      // Determine which endpoint to use based on user type
      if (isAdmin) {
        // Admin direct creation
        const response = await fetch(`${apiUrl}/api/admin_create_event_pots`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            username,
            eventId,
            teamId: selectedTeam.teamId,
            teamName: selectedTeam.teamName,
            pots: potsData,
            totalFee
          })
        });
        
        const data = await response.json();
        
        if (response.ok) {
          toast.success('Pot entry added successfully! Page will refresh...');
          setIsSubmitted(true);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          throw new Error(data.error || 'Failed to add pot entry');
        }
      } else {
        // Regular user - create Stripe session for payment
        const response = await fetch(`${apiUrl}/api/create_pot_registration_session`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            username,
            eventId,
            teamId: selectedTeam.teamId,
            teamName: selectedTeam.teamName,
            pots: potsData,
            totalFee
          })
        });
        
        const data = await response.json();
        
        if (response.ok && data.url) {
          // Redirect to Stripe Checkout
          window.location = data.url;
        } else {
          throw new Error(data.error || 'Failed to create payment session');
        }
      }
    } catch (error) {
      console.error('Error adding pot entry:', error);
      toast.error('Failed to add pot entry. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    setSelectedTeam(null);
    setSelectedPots([]);
    setTotalFee(0);
    setIsSubmitted(false);
    setIsSubmitting(false);
    setConsentGiven(true);
    setAgeCertified(false); // Reset age certification
    props.close();
  };
  
  // Get sponsorship payment feature type for fee calculation
  const potFeatureType = eventSettings?.features?.fishPots || 'customerPays';
  
  // Check if we have a valid pot configuration
  const hasPotConfig = eventSettings?.pots?.potList && Array.isArray(eventSettings.pots.potList) && eventSettings.pots.potList.length > 0;
  
  return (
    <Dialog open={props.status} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle style={{ backgroundColor: '#0281C5', color: 'white', padding: '16px 24px' }}>
        {isAdmin ? 'Add Pot Entry' : 'Register for Pots'}
        <IconButton onClick={handleClose} style={{ position: 'absolute', right: 8, top: 8, color: 'white' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      
      <DialogContent>
        <Stack spacing={3} sx={{ mt: 2 }}>
          {/* Team Selection */}
          <Box>
            <InputLabel required id="team-select-label">Select Team</InputLabel>
            {loading ? (
              <Box display="flex" justifyContent="center" my={2}>
                <CircularProgress size={24} />
              </Box>
            ) : (
              <Autocomplete
                id="team-select"
                options={teamList}
                getOptionLabel={(option) => option.label || ''}
                onChange={handleTeamChange}
                value={selectedTeam}
                renderInput={(params) => (
                  <TextField 
                    {...params} 
                    label="Team Name" 
                    fullWidth 
                    variant="outlined" 
                  />
                )}
              />
            )}
          </Box>
          
          {/* Pot Categories */}
          {hasPotConfig ? (
            <Box>
              <Typography variant="h6" gutterBottom>
                Select Pot Entries
              </Typography>
              
              {eventSettings.pots.potList.map((potCategory, categoryIndex) => (
                <Box key={categoryIndex} sx={{ mb: 2, ml: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={areCategoryValuesAllSelected(potCategory.categoryTitle)}
                        indeterminate={
                          isSomeCategoryValuesSelected(potCategory.categoryTitle) && 
                          !areCategoryValuesAllSelected(potCategory.categoryTitle)
                        }
                        onChange={(e) => 
                          handlePotCategorySelection(potCategory.categoryTitle, e.target.checked)
                        }
                      />
                    }
                    label={<Typography fontWeight="bold">{potCategory.categoryTitle}</Typography>}
                  />
                  
                  <Box sx={{ ml: 4 }}>
                    {potCategory.values.map((value, valueIndex) => (
                      <FormControlLabel
                        key={valueIndex}
                        control={
                          <Checkbox
                            checked={isValueSelected(potCategory.categoryTitle, value)}
                            onChange={(e) => 
                              handlePotValueSelection(potCategory.categoryTitle, value, e.target.checked)
                            }
                          />
                        }
                        label={`${formatCurrency(value)}`}
                      />
                    ))}
                  </Box>
                </Box>
              ))}
            </Box>
          ) : (
            <Typography color="error">
              No pot categories are configured for this event.
            </Typography>
          )}
          
          {/* Fee Breakdown */}
          {isAdmin ? (
            <Paper sx={{ p: 3, bgcolor: 'background.paper', borderRadius: 2 }}>
              <Typography variant="h6" gutterBottom>
                Pot Entry Summary
              </Typography>
              
              {selectedPots.length > 0 ? (
                <>
                  {/* List selected pots in a more compact format */}
                  <Box sx={{ mb: 2 }}>
                    {selectedPots.map((pot, index) => (
                      <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                        <Typography variant="body2">{pot.categoryTitle}:</Typography>
                        <Typography variant="body2" fontWeight="medium">{pot.values.map(value => formatCurrency(value)).join(', ')}</Typography>
                      </Box>
                    ))}
                    <Divider sx={{ my: 1 }} />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                      <Typography variant="subtitle2" fontWeight="bold">Total Pot Entry:</Typography>
                      <Typography variant="subtitle2" fontWeight="bold">{formatCurrency(totalFee)}</Typography>
                    </Box>
                  </Box>

                  <Box sx={{ 
                    p: 2, 
                    mb: 2, 
                    bgcolor: '#e8f4fd', 
                    border: '1px solid #0281C5', 
                    borderRadius: 1,
                    borderLeft: '4px solid #0281C5'
                  }}>
                    <Typography variant="subtitle1" fontWeight="bold" color="#0281C5">
                      Admin Registration - No Payment Taken
                    </Typography>
                    <Typography variant="body2">
                      As an admin, you are adding this pot entry directly to the tournament. 
                      <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                        No payment is taken or processing fees applied
                      </span> for admin-created pot entries.
                    </Typography>
                  </Box>
                  
                  {/* Add back the fee calculation for reference */}
                  {(() => {
                    try {
                      const { platformFee, feeDescription, stripeFee, stripeFeeDescription, total } = calculateFees(totalFee, potFeatureType);
                      
                      return (
                        <Box sx={{ mt: 2 }}>
                          <Typography variant="body2" fontWeight="medium" sx={{ color: '#666', fontStyle: 'italic' }}>
                            Fee information (for reference only - not applied for admin registrations):
                          </Typography>
                          
                          <Typography variant="body2" sx={{ mt: 1, color: '#666' }}>
                            Payment model selected: <span style={{ fontWeight: 'bold' }}>
                              {potFeatureType === 'customerPays' ? 'Customer pays fees' : 'Organizer pays fees'}
                            </span>
                          </Typography>
                          
                          <Box sx={{ mt: 1, display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="body2">Pot Fee Total:</Typography>
                            <Typography variant="body2">${totalFee.toFixed(2)}</Typography>
                          </Box>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="body2">Platform Fee:</Typography>
                            <Typography variant="body2">${platformFee.toFixed(2)} ({feeDescription})</Typography>
                          </Box>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="body2">Stripe Processing Fee:</Typography>
                            <Typography variant="body2">${stripeFee.toFixed(2)} ({stripeFeeDescription})</Typography>
                          </Box>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                            <Typography variant="body2" fontWeight="medium">Total:</Typography>
                            <Typography variant="body2" fontWeight="medium">${total.toFixed(2)}</Typography>
                          </Box>
                        </Box>
                      );
                    } catch (error) {
                      console.error('Error calculating reference fees:', error);
                      return null;
                    }
                  })()}
                </>
              ) : (
                <Typography color="text.secondary" align="center" sx={{ py: 2 }}>
                  Select at least one pot entry to see fee details
                </Typography>
              )}
            </Paper>
          ) : (
            <Paper sx={{ p: 3, bgcolor: 'background.paper', borderRadius: 2 }}>
              <Typography variant="h6" gutterBottom>
                Pot Entry Summary
              </Typography>
              
              {selectedPots.length > 0 ? (
                <>
                  {/* List selected pots for user */}
                  <Box sx={{ mb: 2 }}>
                    {selectedPots.map((pot, index) => (
                      <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                        <Typography variant="body2">{pot.categoryTitle}:</Typography>
                        <Typography variant="body2" fontWeight="medium">{pot.values.map(value => formatCurrency(value)).join(', ')}</Typography>
                      </Box>
                    ))}
                    <Divider sx={{ my: 1 }} />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography variant="subtitle2" fontWeight="bold">Pot Entry Total:</Typography>
                      <Typography variant="subtitle2" fontWeight="bold">{formatCurrency(totalFee)}</Typography>
                    </Box>
                  </Box>
                  
                  {/* Fee breakdown calculation remains the same */}
                  {(() => {
                    try {
                      const customerPaysProcessingFee = potFeatureType === 'customerPays';
                      const { platformFee, feeDescription, stripeFee, total } = calculateFees(totalFee, potFeatureType);

                      return (
                        <Stack spacing={1}>
                          {customerPaysProcessingFee && (
                            <>
                              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography>Custom Tournament Solutions Fee</Typography>
                                <Stack alignItems="flex-end">
                                  <Typography>${platformFee.toFixed(2)}</Typography>
                                  <Typography variant="caption" color="text.secondary">
                                    ({feeDescription})
                                  </Typography>
                                </Stack>
                              </Box>
                              
                              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography>Stripe Processing Fee</Typography>
                                <Typography>${stripeFee.toFixed(2)}</Typography>
                              </Box>
                            </>
                          )}
                          
                          <Divider sx={{ my: 1 }} />
                          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="subtitle1" fontWeight="bold">Total</Typography>
                            <Typography variant="subtitle1" fontWeight="bold">
                              ${total.toFixed(2)}
                            </Typography>
                          </Box>
                        </Stack>
                      );
                    } catch (error) {
                      console.error('Error calculating pot fees:', error);
                      return (
                        <Typography color="error">
                          Unable to calculate pot fees. Please contact support.
                        </Typography>
                      );
                    }
                  })()}
                </>
              ) : (
                <Typography color="text.secondary" align="center" sx={{ py: 2 }}>
                  Select at least one pot entry to see fee details
                </Typography>
              )}
            </Paper>
          )}
          
          {/* Consent checkbox for regular users */}
          {!isAdmin && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={consentGiven}
                  onChange={(e) => setConsentGiven(e.target.checked)}
                />
              }
              label={
                <Typography variant="body2" color="text.secondary">
                  I understand that pot entry fees will be pooled with other participants and distributed according to 
                  the tournament's rules. All fees are final and non-refundable once the tournament begins.
                </Typography>
              }
            />
          )}

          {/* Age Certification Checkbox (for both admin and public) */}
          <FormControlLabel
            control={
              <Checkbox
                checked={ageCertified}
                onChange={(e) => setAgeCertified(e.target.checked)}
              />
            }
            label={
              <Typography variant="body2" color="text.secondary">
                I certify that I am of legal age to participate in this activity and am in compliance with all applicable gambling laws in my jurisdiction.
              </Typography>
            }
          />
          
          {/* Submit Button */}
          {!isSubmitted ? (
            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={isSubmitting || selectedPots.length === 0 || !selectedTeam || !ageCertified || (!isAdmin && !consentGiven)}
              onClick={handleSubmit}
              startIcon={isSubmitting ? <CircularProgress size={24} color="inherit" /> : null}
              sx={{
                backgroundColor: '#0281C5',
                color: 'white',
                borderRadius: '30px',
                py: 1.5,
                fontWeight: 'bold',
                '&:hover': {
                  backgroundColor: '#026999',
                },
              }}
            >
              {isSubmitting ? "Processing..." : isAdmin ? "Add Pot Entry" : "Continue to Payment"}
            </Button>
          ) : (
            <Typography variant="h6" align="center" color="primary">
              Submitted Successfully!
            </Typography>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default AddPotModal;

