import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Fade,
  Alert,
  Paper,
  Stack,
  Grid,
  ToggleButtonGroup,
  ToggleButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Card,
  CardContent,
  Button,
  Divider,
  Tooltip,
  useTheme,
} from '@mui/material';
import StripeConnectSection from '../../payment/StripeConnectSection';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BlockIcon from '@mui/icons-material/Block';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EventIcon from '@mui/icons-material/Event';
import GroupsIcon from '@mui/icons-material/Groups';
import HandshakeIcon from '@mui/icons-material/Handshake';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PersonIcon from '@mui/icons-material/Person';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { availableFeatures, eventCost } from '../../../config/config';

// Define the feature options 
const FEATURE_OPTIONS = {
  NOT_NEEDED: 'notNeeded',
  CUSTOMER_PAYS: 'customerPays',
  I_PAY: 'iPay'
};

const features = [
  {
    id: 'onlineTeamRegistration',
    title: 'Online Team Registration',
    icon: <GroupsIcon />,
    description: 'Allow teams to register and pay online',
  },
  {
    id: 'onlineSponsorRegistration',
    title: 'Online Sponsor Registration',
    icon: <HandshakeIcon />,
    description: 'Allow sponsors to register and pay online',
  },
  {
    id: 'fishPots',
    title: 'Fish Pots',
    icon: <AccountBalanceWalletIcon />,
    description: 'Add optional side pots for your event',
  }
];

const FeatureStep = ({ data, onUpdate, isValid }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [stripeWindowRef, setStripeWindowRef] = useState(null);
  const [packageType, setPackageType] = useState(data.packageType || 'revenue-share');

  const theme = useTheme();
  
  // Calculate if Stripe is needed based on selected features
  const isStripeRequired = () => {
    return Object.entries(data.features || {}).some(([key, value]) => {
      return key !== 'basicEvent' && value !== FEATURE_OPTIONS.NOT_NEEDED;
    });
  };

  // Check if any revenue-generating feature is enabled
  const hasRevenueFeature = () => {
    return ['onlineTeamRegistration', 'onlineSponsorRegistration', 'fishPots'].some(
      key => data.features?.[key] !== FEATURE_OPTIONS.NOT_NEEDED
    );
  };

  // Update packageType and visibleSteps when features change
  useEffect(() => {
    // Only update local packageType state if it's different from data props
    if (data.packageType && data.packageType !== packageType) {
      setPackageType(data.packageType);
    }
    
    // Check if any revenue feature is enabled and update package type if needed
    const hasRevenue = hasRevenueFeature();
    if (hasRevenue && data.packageType !== 'revenue-share') {
      // If we have revenue features but not revenue-share package, update it
      onUpdate({ packageType: 'revenue-share' });
      setPackageType('revenue-share');
    } else if (!hasRevenue && data.packageType !== 'standard') {
      // If we have no revenue features but not standard package, update it
      onUpdate({ packageType: 'standard' });
      setPackageType('standard');
    }
    
    // Close Stripe window if it's open and Stripe is no longer required
    if (!isStripeRequired() && stripeWindowRef && !stripeWindowRef.closed) {
      stripeWindowRef.close();
      setStripeWindowRef(null);
    }
  }, [data.packageType, data.features]);

  // Modified: Handle package type change and set appropriate defaults
  const handlePackageTypeChange = (type) => {
    const updatedData = { packageType: type };
    
    // If switching to standard, disable all revenue features
    if (type === 'standard') {
      updatedData.features = {
        ...data.features,
        onlineTeamRegistration: FEATURE_OPTIONS.NOT_NEEDED,
        onlineSponsorRegistration: FEATURE_OPTIONS.NOT_NEEDED,
        fishPots: FEATURE_OPTIONS.NOT_NEEDED
      };
    }
    // If switching to revenue-share, enable default feature
    else if (type === 'revenue-share') {
      // Check if all features are currently disabled
      const allDisabled = ['onlineTeamRegistration', 'onlineSponsorRegistration', 'fishPots'].every(
        key => data.features?.[key] === FEATURE_OPTIONS.NOT_NEEDED
      );
      
      // If all are disabled, set team registration to default (customer pays)
      if (allDisabled) {
        updatedData.features = {
          ...data.features,
          onlineTeamRegistration: FEATURE_OPTIONS.CUSTOMER_PAYS
        };
      }
    }
    
    onUpdate(updatedData);
    setPackageType(type);
  };

  // Modified: Update features and package type when needed
  const handleFeatureChange = (featureId, value) => {
    const updatedFeatures = {
      ...data.features,
      [featureId]: value
    };
    
    // Determine if this change affects the package type
    const willHaveRevenueFeature = ['onlineTeamRegistration', 'onlineSponsorRegistration', 'fishPots'].some(
      key => key === featureId ? value !== FEATURE_OPTIONS.NOT_NEEDED : data.features?.[key] !== FEATURE_OPTIONS.NOT_NEEDED
    );
    
    // If enabling a revenue feature and not already on revenue-share, update package type too
    if (willHaveRevenueFeature && data.packageType !== 'revenue-share') {
      onUpdate({ 
        features: updatedFeatures,
        packageType: 'revenue-share' 
      });
      setPackageType('revenue-share');
    } 
    // If disabling the last revenue feature and not already on standard, update package type too
    else if (!willHaveRevenueFeature && data.packageType !== 'standard') {
      onUpdate({ 
        features: updatedFeatures,
        packageType: 'standard' 
      });
      setPackageType('standard');
    }
    // Otherwise just update features
    else {
      onUpdate({ features: updatedFeatures });
    }
  };

  const getErrorMessage = () => {
    if (isStripeRequired() && (!data.stripeAccountId || data.stripeAccountStatus !== 'active')) {
      return "Please complete your Stripe account connection to continue with the selected features";
    }
    return "Please complete all required information to continue";
  };

  const getCustomerPaysDescription = (featureKey) => {
    const baseText = "Your customers will pay a small platform fee in addition to your registration fee.";
    
    if (featureKey === 'onlineTeamRegistration') {
      return `${baseText} Stripe's processing fee (2.9% + $0.30) plus their 0.5% destination fee will be added to each team registration transaction.`;
    } 
    else if (featureKey === 'onlineSponsorRegistration') {
      return `${baseText} Stripe's processing fee (2.9% + $0.30) plus their 0.5% destination fee will be added to each sponsor payment transaction.`;
    }
    else if (featureKey === 'fishPots') {
      return `${baseText} Stripe's processing fee (2.9% + $0.30) plus their 0.5% destination fee will be added to each pot entry transaction.`;
    }
    else if (featureKey === 'basicEvent') {
      return `${baseText} Stripe's processing fee (2.9% + $0.30) plus their 0.5% destination fee applies to all transactions.`;
    }
    
    return baseText;
  };

  const getIPayDescription = (featureKey) => {
    const baseText = "You'll be charged for this feature as part of your event creation.";
    
    if (featureKey === 'onlineTeamRegistration') {
      return `${baseText} Stripe's processing fee (2.9% + $0.30) plus their 0.5% destination fee will be deducted from your team registration revenue.`;
    } 
    else if (featureKey === 'onlineSponsorRegistration') {
      return `${baseText} Stripe's processing fee (2.9% + $0.30) plus their 0.5% destination fee will be deducted from your sponsor payment revenue.`;
    }
    else if (featureKey === 'fishPots') {
      return `${baseText} Stripe's processing fee (2.9% + $0.30) plus their 0.5% destination fee will be deducted from your pot entry revenue.`;
    }
    else if (featureKey === 'basicEvent') {
      return `${baseText} Stripe's processing fee (2.9% + $0.30) plus their 0.5% destination fee will be deducted from your transaction revenue.`;
    }
    
    return baseText;
  };

  const getPricingOptionDescription = (option, featureKey) => {
    switch (option) {
      case FEATURE_OPTIONS.CUSTOMER_PAYS:
        return getCustomerPaysDescription(featureKey);
      case FEATURE_OPTIONS.I_PAY:
        return getIPayDescription(featureKey);
      default:
        return "This feature will not be included in your event.";
    }
  };

  const calculateTotalPrice = () => {
    // Standard package has a fixed price
    if (packageType === 'standard') {
      return 89;
    }
    
    // Revenue share package has $0 base price
    return 0;
  };

  const getFeatureLabel = (featureId) => {
    switch(featureId) {
      case 'onlineTeamRegistration': return 'team registration';
      case 'onlineSponsorRegistration': return 'sponsor registration';
      case 'fishPots': return 'fish pots';
      default: return featureId;
    }
  };

  const formatFeaturesList = (featureArray) => {
    if (featureArray.length === 0) return '';
    if (featureArray.length === 1) return featureArray[0];
    if (featureArray.length === 2) return `${featureArray[0]} and ${featureArray[1]}`;
    return `${featureArray.slice(0, -1).join(', ')}, and ${featureArray[featureArray.length - 1]}`;
  };

  const renderWhiteLabelBanner = () => (
    <Paper
      sx={{
        p: 3, 
        mb: 4,
        background: `linear-gradient(45deg, ${theme.palette.primary.lighter} 0%, ${theme.palette.background.paper} 100%)`,
        borderLeft: `4px solid ${theme.palette.primary.main}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <Box>
        <Typography variant="h6" sx={{ fontWeight: 600, color: theme.palette.primary.main }}>
          Want us to do this for you?
        </Typography>
        <Typography variant="body1">
          Ask us about upgrading to our premium white label service.
          <br />
          <Typography component="span" sx={{ fontWeight: 600 }}>
            Contact us at support@customtournamentsolutions.com
          </Typography>
        </Typography>
      </Box>
      <Button 
        variant="contained" 
        color="primary"
        onClick={() => window.open('mailto:support@customtournamentsolutions.com?subject=White Label Service Inquiry')}
      >
        Learn More
      </Button>
    </Paper>
  );
  
  const renderUnifiedFeaturesSection = () => (
    <Paper sx={{ p: 3, mb: 4 }}>
      <Grid container spacing={3}>
        {/* Package selection and cost summary */}
        <Grid item xs={12}>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'flex-start',
            mb: 3,
            pb: 3,
            borderBottom: '1px solid',
            borderColor: 'divider'
          }}>
            <Box>
              <Typography variant="h6" sx={{ mb: 1, fontWeight: 600 }}>
                Select Your Package
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                Choose the option that works best for your tournament
              </Typography>
              
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Button
                  variant={packageType === 'standard' ? 'contained' : 'outlined'}
                  color="primary"
                  onClick={() => handlePackageTypeChange('standard')}
                  sx={{ minWidth: 120 }}
                >
                  Standard
                </Button>
                <Button
                  variant={packageType === 'revenue-share' ? 'contained' : 'outlined'}
                  color="primary"
                  onClick={() => handlePackageTypeChange('revenue-share')}
                  sx={{ minWidth: 120 }}
                >
                  Revenue Share
                </Button>
              </Box>
            </Box>
            
            <Box sx={{ 
              p: 2, 
              bgcolor: 'primary.lighter', 
              borderRadius: 1,
              minWidth: 200,
              textAlign: 'center'
            }}>
              <Typography variant="body2" color="text.secondary">
                Your Event Cost
              </Typography>
              <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                ${calculateTotalPrice()}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {packageType === 'standard' ? 'One-time payment' : 'Plus transaction fees'}
              </Typography>
            </Box>
          </Box>
        </Grid>
        
        {/* Package description */}
        <Grid item xs={12}>
          <Alert 
            severity="info" 
            icon={<InfoOutlinedIcon />}
            sx={{ mb: 3 }}
          >
            <Typography variant="body2">
              <strong>{packageType === 'standard' ? 'Standard Package' : 'Revenue Share Package'}:</strong> {' '}
              {packageType === 'standard' 
                ? 'One-time $89 fee with no online registration features. Best for simple tournaments where you manually add participants.' 
                : 'No upfront cost. Includes online registration with small transaction fees. Best for tournaments where participants register online.'}
            </Typography>
          </Alert>
        </Grid>
        
        {/* Feature toggles */}
        <Grid item xs={12} md={8}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Feature Selection
          </Typography>
          
          <Stack spacing={2}>
            {features.map((feature) => (
              <Box
                key={feature.id}
                sx={{
                  p: 2,
                  border: '1px solid',
                  borderColor: 'divider',
                  borderRadius: 1,
                  bgcolor: data.features?.[feature.id] !== FEATURE_OPTIONS.NOT_NEEDED ? 'primary.lighter' : 'background.paper',
                }}
              >
                <Grid container alignItems="center" spacing={2}>
                  {/* Feature info */}
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box sx={{ mr: 1.5, color: 'primary.main' }}>{feature.icon}</Box>
                      <Box>
                        <Typography variant="subtitle1">{feature.title}</Typography>
                        <Typography variant="caption" color="text.secondary">
                          {feature.description}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  
                  {/* Toggle buttons - never disabled */}
                  <Grid item xs={12} sm={6}>
                    <ToggleButtonGroup
                      value={data.features?.[feature.id] || FEATURE_OPTIONS.NOT_NEEDED}
                      exclusive
                      onChange={(e, newValue) => {
                        if (newValue) handleFeatureChange(feature.id, newValue);
                      }}
                      fullWidth
                      size="small"
                    >
                      <Tooltip title="Disable this feature">
                        <ToggleButton 
                          value={FEATURE_OPTIONS.NOT_NEEDED}
                          sx={{ 
                            flexDirection: 'column', 
                            height: '64px', 
                            width: '33.33%'
                          }}
                        >
                          <BlockIcon fontSize="small" />
                          <Typography variant="caption" sx={{ mt: 0.5 }}>
                            Disabled
                          </Typography>
                        </ToggleButton>
                      </Tooltip>
                      <Tooltip title={getPricingOptionDescription(FEATURE_OPTIONS.CUSTOMER_PAYS, feature.id)}>
                        <ToggleButton 
                          value={FEATURE_OPTIONS.CUSTOMER_PAYS}
                          sx={{ 
                            flexDirection: 'column', 
                            height: '64px',
                            width: '33.33%'
                          }}
                        >
                          <PeopleAltIcon fontSize="small" />
                          <Typography variant="caption" sx={{ mt: 0.5 }}>
                            Customer Pays
                          </Typography>
                        </ToggleButton>
                      </Tooltip>
                      <Tooltip title={getPricingOptionDescription(FEATURE_OPTIONS.I_PAY, feature.id)}>
                        <ToggleButton 
                          value={FEATURE_OPTIONS.I_PAY}
                          sx={{ 
                            flexDirection: 'column', 
                            height: '64px',
                            width: '33.33%'
                          }}
                        >
                          <PersonIcon fontSize="small" />
                          <Typography variant="caption" sx={{ mt: 0.5 }}>
                            I Pay
                          </Typography>
                        </ToggleButton>
                      </Tooltip>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
              </Box>
            ))}
          </Stack>
        </Grid>
        
        {/* Feature List - always show */}
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2, height: '100%', bgcolor: 'grey.50' }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Your Event Features
            </Typography>
            
            <List disablePadding>
              {availableFeatures.map((feature, index) => {
                // Special handling for features with different categories
                const featureCategory = feature.category;
                
                // Check if any revenue features are enabled
                const anyRevenueFeatureEnabled = ['onlineTeamRegistration', 'onlineSponsorRegistration', 'fishPots'].some(
                  key => data.features?.[key] !== FEATURE_OPTIONS.NOT_NEEDED
                );
                
                // For standard package, only show basic features
                const isBasicFeature = featureCategory === 'basicEvent';
                
                // Determine if feature should be active
                let isActive;
                if (isBasicFeature) {
                  // Basic features are always active
                  isActive = true;
                } else if (featureCategory === 'revenueFeature') {
                  // Special category for "fees deposit directly" - active if any revenue feature is enabled
                  isActive = anyRevenueFeatureEnabled;
                } else {
                  // Match other features based on their specific category
                  isActive = data.features?.[featureCategory] !== FEATURE_OPTIONS.NOT_NEEDED;
                }
                  
                return (
                  <ListItem 
                    key={index}
                    sx={{ 
                      py: 1,
                      opacity: isActive ? 1 : 0.5,
                      '&:not(:last-child)': {
                        borderBottom: '1px solid',
                        borderColor: 'divider'
                      }
                    }}
                    disableGutters
                  >
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <CheckCircleIcon 
                        color={isActive ? "success" : "disabled"} 
                        fontSize="small" 
                      />
                    </ListItemIcon>
                    <ListItemText 
                      primary={feature.title} 
                      primaryTypographyProps={{
                        variant: 'body2',
                        color: isActive ? 'text.primary' : 'text.disabled'
                      }}
                    />
                  </ListItem>
                );
              })}
            </List>
          </Paper>
        </Grid>
        
        {/* Fee explanations - conditional display */}
        {(() => {
          // Only show if there are any features with non-default fee options
          const customerPaysFeatures = [];
          const iPayFeatures = [];
          
          Object.entries(data.features || {}).forEach(([key, value]) => {
            if (value === FEATURE_OPTIONS.CUSTOMER_PAYS && key !== 'basicEvent') {
              customerPaysFeatures.push(getFeatureLabel(key));
            } else if (value === FEATURE_OPTIONS.I_PAY) {
              iPayFeatures.push(getFeatureLabel(key));
            }
          });
          
          if (customerPaysFeatures.length === 0 && iPayFeatures.length === 0) {
            return null;
          }
          
          return (
            <Grid item xs={12}>
              <Box sx={{ mt: 2, bgcolor: 'grey.50', p: 2, borderRadius: 1 }}>
                <Typography variant="subtitle2" gutterBottom>
                  Fee Summary
                </Typography>
                
                {customerPaysFeatures.length > 0 && (
                  <Alert severity="info" sx={{ mb: customerPaysFeatures.length > 0 ? 2 : 0 }}>
                    <Typography variant="body2">
                      <strong>Customer pays fees:</strong> Small platform fees will be added to {formatFeaturesList(customerPaysFeatures)} transactions.
                    </Typography>
                  </Alert>
                )}
                
                {iPayFeatures.length > 0 && (
                  <Alert severity="warning">
                    <Typography variant="body2">
                      <strong>You pay fees:</strong> Platform fees for {formatFeaturesList(iPayFeatures)} will be deducted from your revenue.
                    </Typography>
                  </Alert>
                )}
              </Box>
            </Grid>
          );
        })()}
      </Grid>
    </Paper>
  );

  return (
    <Fade in timeout={400}>
      <Box>
        {isValid === false && (
          <Alert 
            severity="error" 
            sx={{ 
              mb: 3,
              '& .MuiAlert-message': {
                fontWeight: 500
              }
            }}
          >
            {getErrorMessage()}
          </Alert>
        )}

        {/* White Label Service Banner */}
        {renderWhiteLabelBanner()}

        {/* Unified Features Section */}
        {renderUnifiedFeaturesSection()}

        {/* Stripe Connect Section */}
        {isStripeRequired() && (
          <Box sx={{ mt: 3 }}>
            <Paper sx={{ p: 3 }}>
              <Typography variant="h6" sx={{ color: theme.palette.primary.main, fontWeight: 600 }}>
                Enable Automatic Payments via Stripe (required)
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                We use Stripe to handle payments. Connect your account to receive your fees directly.
              </Typography>
              <StripeConnectSection 
                data={data} 
                onUpdate={onUpdate} 
              />
            </Paper>
          </Box>
        )}

      </Box>
    </Fade>
  );
};

export default FeatureStep;

