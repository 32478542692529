import React, { useState, useEffect } from 'react';
import { InputLabel, Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, CircularProgress } from "@mui/material";
import defaultNoImage from '../../images/defaultNoImage.png';
import CloseIcon from "@mui/icons-material/Close";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

const DeleteCatchModal = (props) => {
  const [deleteInfo, setDeleteInfo] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    setDeleteInfo(props.deleteInfo);
  }, [props.deleteInfo]);

  const getUnitConfig = () => {
    // Get units from either root level or nested structure
    const units = props.eventSettings?.units || props.eventSettings?.species?.units || "pounds, tenths of inches";
    const [weightUnit, lengthUnit] = units.split(", ");
    
    return {
      weight: {
        unit: weightUnit === "pounds" ? "lb" : "kg"
      },
      length: {
        unit: lengthUnit.includes("inches") ? "in" : 
              lengthUnit === "centimeters" ? "cm" : "mm"
      }
    };
  };

  const unitConfig = getUnitConfig();

  const handleClose = () => {
    setIsSubmitting(false);
    setIsSubmitted(false);
    props.close();
  };

  const delayRefresh = () => {
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const handleDelete = async () => {
    setIsSubmitting(true); 
  
    try {
      let apiUrl = process.env.REACT_APP_NODE_ENV === "staging"
        ? process.env.REACT_APP_SERVER_URL_STAGING
        : process.env.REACT_APP_SERVER_URL_PRODUCTION;
  
      // Log that we're about to delete a catch with a photo
      if (deleteInfo["Catch photo"]) {
        console.log('Deleting catch with photo:', deleteInfo["Catch photo"]);
      }
  
      const response = await fetch(`${apiUrl}/api/admin_delete_event_catches`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          username: props.username,
          eventId: props.eventId,
          catchId: props.deleteInfo.catchId,
        })
      });
  
      if (response.ok) {
        toast.success('Catch and associated photo deleted successfully.');
        setIsSubmitted(true);
        delayRefresh();
      } else {
        const errorData = await response.json();
        toast.error(`Error: ${errorData.error || 'Error deleting catch'}`);
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error('Error deleting catch:', error);
      toast.error('Error deleting catch.');
      setIsSubmitting(false);
    }
  };
  
  const formatLocalDateTime = (utcDateTime) => {
    if (!utcDateTime) return { localDate: 'N/A', localTime: 'N/A' };
    
    const dateObj = new Date(utcDateTime);
    const localDate = dateObj.toLocaleDateString(); // Convert to local date string
    const localTime = dateObj.toLocaleTimeString(); // Convert to local time string
    return { localDate, localTime };
  };

  // Removed getSpeciesType function as it's no longer needed

  return (
    <div>
      {deleteInfo &&
        <Dialog open={props.status} onClose={handleClose} fullWidth maxWidth="sm">
          <form action="/" method="POST" onSubmit={(e) => { e.preventDefault(); handleClose(); }}>
            <DialogTitle>
              Delete Catch
              <IconButton onClick={handleClose} style={{ float: 'right' }}><CloseIcon color="primary"></CloseIcon></IconButton>
            </DialogTitle>
            <DialogContent>
              <Stack spacing={2} margin={2}>
                <div>
                  <InputLabel id="catch-id-label"><strong>Catch ID:</strong> {deleteInfo["catchId"]}</InputLabel>
                  <InputLabel id="species-label"><strong>Species:</strong> {deleteInfo["species"]}</InputLabel>
                  <InputLabel id="name-label"><strong>Team:</strong> {deleteInfo["teamName"]}</InputLabel>
                  
                  {/* Only show length if it has a meaningful value */}
                  {deleteInfo["length"] !== undefined && deleteInfo["length"] !== null && 
                   deleteInfo["length"] !== "" && parseFloat(deleteInfo["length"]) > 0 && (
                    <InputLabel id="length-label">
                      <strong>Length:</strong> {parseFloat(deleteInfo["length"]).toFixed(1)} {unitConfig.length.unit}
                    </InputLabel>
                  )}
                  
                  {/* Only show girth if it has a meaningful value */}
                  {deleteInfo["girth"] !== undefined && deleteInfo["girth"] !== null && 
                   deleteInfo["girth"] !== "" && parseFloat(deleteInfo["girth"]) > 0 && (
                    <InputLabel id="girth-label">
                      <strong>Girth:</strong> {parseFloat(deleteInfo["girth"]).toFixed(1)} {unitConfig.length.unit}
                    </InputLabel>
                  )}
                  
                  {/* Only show weight if it has a meaningful value */}
                  {deleteInfo["weight"] !== undefined && deleteInfo["weight"] !== null && 
                   deleteInfo["weight"] !== "" && parseFloat(deleteInfo["weight"]) > 0 && (
                    <InputLabel id="weight-label">
                      <strong>Weight:</strong> {parseFloat(deleteInfo["weight"]).toFixed(1)} {unitConfig.weight.unit}
                    </InputLabel>
                  )}
                  
                  <InputLabel id="points-label"><strong>Points:</strong> {deleteInfo["points"]}</InputLabel>
                  
                  {/* Show date/time if they exist */}
                  {deleteInfo["dateTime"] && (
                    <>
                      <InputLabel id="date-label">
                        <strong>Date Entered:</strong> {formatLocalDateTime(deleteInfo["dateTime"]).localDate}
                      </InputLabel>
                      <InputLabel id="time-label">
                        <strong>Time Entered:</strong> {formatLocalDateTime(deleteInfo["dateTime"]).localTime}
                      </InputLabel>
                    </>
                  )}
                  
                  {/* Display the scoring method with rounding information */}
                  {deleteInfo["scoringMethod"] && (
                    <InputLabel id="scoring-method-label">
                      <strong>Scoring Method:</strong> {deleteInfo["scoringMethod"].charAt(0).toUpperCase() + deleteInfo["scoringMethod"].slice(1)}
                      {deleteInfo["rounding"] && deleteInfo["rounding"] !== "exact" && (
                        <span> (rounded {deleteInfo["rounding"]})</span>
                      )}
                    </InputLabel>
                  )}
                  
                  <div>
                    {deleteInfo["Catch photo"] && (
                      <Box marginTop={2} marginBottom={2}>
                        <InputLabel id="photo-label"><strong>Catch Photo:</strong></InputLabel>
                        <Box marginTop={1}>
                          <img
                            src={deleteInfo["Catch photo"]}
                            alt="Catch"
                            style={{
                              width: "200px",
                              height: "200px",
                              objectFit: "cover",
                            }}
                            onError={(e) => {
                              e.target.src = defaultNoImage;
                            }}
                          />
                        </Box>
                      </Box>
                    )}
                </div>
                </div>

                <InputLabel style={{ fontWeight: 'bold', color: 'red', wordWrap: 'break-word' }}>Are you sure?</InputLabel>
                
                {!isSubmitted ? (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleDelete}
                    disabled={isSubmitting || isSubmitted}
                    startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
                  >
                    {isSubmitting ? "Deleting..." : "Yes, Delete This Catch"}
                  </Button>
                ) : (
                  <h3>Submitted!</h3>
                )}
              </Stack>
          </DialogContent>
        </form>
      </Dialog>
    }
  </div>
  );
};

export default DeleteCatchModal;

