import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './StripePage.css';

function StripeCompletePage() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Extract account details from URL if needed
    const searchParams = new URLSearchParams(location.search);
    const accountId = searchParams.get('accountId');
    
    // You might want to do something with the accountId here
    console.log('Stripe Account Onboarding Complete:', accountId);

    // Optional: Redirect or perform additional actions
    const timer = setTimeout(() => {
      window.close();
    }, 3000);

    return () => clearTimeout(timer);
  }, [location]);

  return (
    <main>
      <div className="message">
        <h2>? Connection Complete</h2>
        <p>Your Stripe account setup is now complete.</p>
        <p>You can close this tab and return to event creation.</p>
        <button 
          className="button" 
          onClick={() => window.close()}
        >
          Close This Tab
        </button>
      </div>
    </main>
  );
}

export default StripeCompletePage;

