import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from '@mui/material/Box';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { CircularProgress, Typography } from '@mui/material';

import ResultTable from './ResultTable';

/**
 * Generic carousel component for displaying tournament results in a slideshow
 * Used for both leaderboards and pot results
 */
function Carousel({ 
  results, 
  eventSettings, 
  autoPlayInterval = 5000,
  title = "Results",
  emptyMessage = "No results available"
}) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [activeStep, setActiveStep] = useState(0);
  const [maxSteps, setMaxSteps] = useState(0);
  const [filteredResults, setFilteredResults] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Filter results with data
  useEffect(() => { 
    setIsLoading(true);
    // Only include results with rows
    const filtered = Array.isArray(results) 
      ? results.filter(item => item.rows && item.rows.length > 0)
      : [];
    
    setFilteredResults(filtered);
    setMaxSteps(filtered.length);
    setIsLoading(false);
    
    // Reset active step if we have filtered results
    if (filtered.length > 0 && activeStep >= filtered.length) {
      setActiveStep(0);
    }
  }, [results]);

  // Auto-advance slides
  useEffect(() => {
    if (filteredResults.length <= 1) return;
    
    const timer = setTimeout(() => {
      setActiveStep(prevStep => 
        prevStep === maxSteps - 1 ? 0 : prevStep + 1
      );
    }, autoPlayInterval);

    return () => clearTimeout(timer);
  }, [activeStep, maxSteps, autoPlayInterval, filteredResults.length]);

  // Handle manual slide change
  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (filteredResults.length === 0) {
    return (
      <Box sx={{ textAlign: 'center', p: 3 }}>
        <Typography variant="h6" color="textSecondary">
          {emptyMessage}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, fontSize: '16px', margin: 0, padding: 0 }}>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        resistance
        className="carousel-container"
      >
        {filteredResults.map((result, index) => (
          <div 
            key={result.title || `slide-${index}`}
            className={`carousel-slide ${
              Math.abs(activeStep - index) <= 1 
                ? 'carousel-slide-entered' 
                : 'carousel-slide-entering'
            }`}
          >
            {Math.abs(activeStep - index) <= 2 ? (
              <ResultTable
                eventSettings={eventSettings}
                title={result.title}
                subtitle={result.subtitle}
                numPlaces={result.numPlaces}
                rows={result.rows}
                columns={matches ? (result.desktopColumns || []) : (result.mobileColumns || [])}
                scroll={matches ? null : "scroll"}
                density="compact"
              />
            ) : null}
          </div>
        ))}
      </SwipeableViews>
      
      {/* Slide indicators */}
      {filteredResults.length > 1 && (
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'center',
            mt: 1
          }}
        >
          {filteredResults.map((_, index) => (
            <Box
              key={index}
              sx={{
                width: 8,
                height: 8,
                borderRadius: '50%',
                mx: 0.5,
                bgcolor: index === activeStep 
                  ? (eventSettings?.backgroundColor || 'primary.main')
                  : 'grey.300',
                transition: 'background-color 0.3s ease',
                cursor: 'pointer'
              }}
              onClick={() => setActiveStep(index)}
            />
          ))}
        </Box>
      )}
    </Box>
  );
}

export default Carousel;

