import React, { useState, useEffect } from 'react';
import AnimatedPage from '../../pages/AnimatedPage';
import BackToTopButton from '../buttons/BackToTopButton';
import './Footer.css';

function Footer() {
  return (
    <AnimatedPage>
      <footer className="section-footer">
        <div className="section-footer-container">
          <h4 className="bottomLine">CONTACT US</h4>
          <div className="contactInfo">
            <p>New Orleans, LA - Austin, TX</p>
            {/* <p>Phone / Text: (630) 991-3012</p> */}
            <p>Email: support@customtournamentsolutions.com</p>
          </div>
          <div className="copyrightInfoContainer">
            <div className="copyrightTextLeft">
              <p>Note: All content is preliminary. Official results are certified by tournamente committees.</p>
              <p><span>&copy;</span>Custom Tournament Solutions, 2023-Present, All Rights Reserved</p>
            </div>
            <div className="copyrightTextRight">
              <BackToTopButton />
            </div>
          </div>
        </div>
      </footer>
    </AnimatedPage>
  );
}

export default Footer;

