import jsPDF from 'jspdf';
import 'jspdf-autotable';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const addPageNumbers = (doc) => {
  const pageCount = doc.internal.getNumberOfPages();
  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.setFontSize(10);
    doc.text(
      `${i} of ${pageCount}`,
      doc.internal.pageSize.getWidth() - 25,
      doc.internal.pageSize.getHeight() - 10
    );
  }
};

const getUnitLabels = (eventSettings) => {
  // Check both possible paths for units
  const units = eventSettings?.units || eventSettings?.species?.units || "pounds, tenths of inches";
  const [weightUnit, lengthUnit] = units.split(", ");
  
  return {
    weight: weightUnit === "pounds" ? "lb" : "kg",
    length: lengthUnit.includes("inches") ? "in" : 
            lengthUnit === "centimeters" ? "cm" : "mm"
  };
};

// Format numeric values for the report
const formatNumericValue = (value, includeZero = false) => {
  if (value === undefined || value === null || value === '') {
    return 'N/A';
  }
  
  const numValue = parseFloat(value);
  if (isNaN(numValue) || (!includeZero && numValue === 0)) {
    return 'N/A';
  }
  
  // Show decimal places only if needed
  return numValue % 1 === 0 ? numValue.toString() : numValue.toFixed(1);
};

// Generate category subtitle for better description
const generateCategorySubtitle = (category) => {
  if (!category) return '';
  
  const parts = [];
  
  // Add filters
  if (category.filters && category.filters.length > 0) {
    const filterLabels = category.filters.map(filter => {
      switch (filter.type) {
        case 'Division':
          return `Division: ${filter.value.join(' / ')}`;
        case 'Gender':
          return `Gender: ${filter.value.join(' / ')}`;
        case 'AgeBracket':
          return `Age Group: ${filter.value.join(' / ')}`;
        case 'Date':
          return `Date: ${filter.value.join(' / ')}`;
        default:
          return `${filter.type}: ${filter.value.join(' / ')}`;
      }
    });
    
    if (filterLabels.length > 0) {
      parts.push(filterLabels.join(' - '));
    }
  }
  
  // Add scoring method
  const scoringMethod = category.scoringMethod || 'Most Points';
  parts.push(scoringMethod);

  // Add stringer breakdown for Weight or Length Stringer categories
  if ((scoringMethod === 'Weight Stringer' || scoringMethod === 'Length Stringer') && 
    category.stringerQuantities && Object.keys(category.stringerQuantities).length > 0) {
    const stringerParts = [];

    Object.entries(category.stringerQuantities)
    .filter(([_, qty]) => qty > 0)
    .forEach(([species, qty]) => {
      stringerParts.push(`${species} x${qty}`);
    });

    if (stringerParts.length > 0) {
      parts.push(`Stringer: ${stringerParts.join(', ')}`);
    }
  }
  
  // Add tiebreakers with descriptive labels
  if (category.tiebreakers && category.tiebreakers.length > 0) {
    const tiebreakerLabels = category.tiebreakers.map(tiebreaker => {
      switch (tiebreaker) {
        case 'weight':
          return 'Heaviest Fish';
        case 'length':
          return 'Longest Fish';
        case 'girth':
          return 'Largest Girth';
        case 'time':
          // Use more descriptive label based on scoring method
          if (category.scoringMethod === 'Most Points - Single') {
            return 'Earliest Best Catch';
          } else if (category.scoringMethod === 'Most Points - Cumulative') {
            return 'Earliest Last Scored Catch';
          } else if (category.scoringMethod === 'Fastest Catch') {
            return 'Earliest Completion';
          } else {
            return 'Earliest Catch';
          }
        case 'totalWeight':
          return 'Total Weight';
        case 'totalLength':
          return 'Total Length';
        case 'catchCount':
          return 'Most Catches';
        default:
          return tiebreaker;
      }
    });
    
    parts.push(`Tiebreaker${tiebreakerLabels.length > 1 ? 's' : ''}: ${tiebreakerLabels.join(', ')}`);
  }
  
  return parts.join(' - ');
};

// Determine which columns to include based on category type and available data
const determineColumns = (category, firstRowData, units) => {
  // Base columns for all leaderboards
  const columns = ['Place', 'Team'];
  
  // Add additional columns based on scoring method
  switch (category.scoringMethod) {
    case 'Most Points - Single':
    case 'Most Points - Cumulative':
      columns.push('Points');
      break;
    case 'Most Catches':
      columns.push('Catches');
      break;
    case 'Fastest Catch':
      columns.push('Completion Time');
      break;
    case 'Weight Stringer':
      columns.push(`Total Weight (${units.weight})`);
      break;
    case 'Length Stringer':
      columns.push(`Total Length (${units.length})`);
      break;
    default:
      // For legacy or species-based categories
      if (category.categoryType === 'Fastest to Catch') {
        columns.push('Completion Time');
      } else if (category.categoryType === 'Most Points' || 
                category.scoringType?.includes('Catch & Release')) {
        columns.push('Points');
      } else if (category.scoringType?.includes('Weight')) {
        columns.push(`Weight (${units.weight})`);
        columns.push('Points');
      } else if (category.scoringType?.includes('Length')) {
        columns.push(`Length (${units.length})`);
        columns.push('Points');
      }
  }
  
  // Add time-related columns based on available data
  if (firstRowData) {
    // Priority for time fields
    if (firstRowData.bestCatchTime) {
      columns.push('Best Catch Time');
    } else if (firstRowData.lastEligibleCatchTime) {
      columns.push('Last Scored Catch');
    } else if (firstRowData.lastCatchTime) {
      columns.push('Last Catch Time');
    }
    
    // Add weight/length/girth if present in data
    if (firstRowData.weight !== undefined && !columns.includes(`Weight (${units.weight})`)) {
      columns.push(`Weight (${units.weight})`);
    }
    if (firstRowData.length !== undefined && !columns.includes(`Length (${units.length})`)) {
      columns.push(`Length (${units.length})`);
    }
    if (firstRowData.girth !== undefined) {
      columns.push(`Girth (${units.length})`);
    }
  }
  
  return columns;
};

// Format a row of data for the PDF table
const formatRowData = (standing, columns, units) => {
  const row = [];
  
  columns.forEach(col => {
    switch (col) {
      case 'Place':
        row.push(standing.place || 'N/A');
        break;
      case 'Team':
        row.push(standing.team || 'N/A');
        break;
      case 'Points':
        row.push(formatNumericValue(standing.points, true));
        break;
      case 'Catches':
        row.push(standing.catchCount || standing.catches || 'N/A');
        break;
      case 'Completion Time':
      case 'Best Catch Time':
      case 'Last Scored Catch':
      case 'Last Catch Time':
        // Determine which time field to use
        let timeValue = null;
        if (col === 'Completion Time') timeValue = standing.completionTime;
        else if (col === 'Best Catch Time') timeValue = standing.bestCatchTime;
        else if (col === 'Last Scored Catch') timeValue = standing.lastEligibleCatchTime;
        else if (col === 'Last Catch Time') timeValue = standing.lastCatchTime;
        
        row.push(timeValue ? dayjs(timeValue).format('MM/DD/YYYY hh:mm A') : 'N/A');
        break;
      case `Weight (${units.weight})`:
        row.push(formatNumericValue(standing.weight));
        break;
      case `Length (${units.length})`:
        row.push(formatNumericValue(standing.length));
        break;
      case `Girth (${units.length})`:
        row.push(formatNumericValue(standing.girth));
        break;
      case `Total Weight (${units.weight})`:
        row.push(formatNumericValue(standing.totalWeight));
        break;
      case `Total Length (${units.length})`:
        row.push(formatNumericValue(standing.totalLength));
        break;
      default:
        row.push('N/A');
    }
  });
  
  return row;
};

const generateLeaderboardPDF = (leaderboardData, tournamentName, eventSettings) => {
  const doc = new jsPDF('landscape');
  const currentDate = dayjs().tz('America/Chicago').format('MMMM D, YYYY h:mm A');
  const currentYear = new Date().getFullYear();
  const units = getUnitLabels(eventSettings);

  // Generate a page for each category
  leaderboardData.forEach((categoryData, index) => {
    if (index > 0) doc.addPage();

    const { category, standings } = categoryData;
    const categoryTitle = category.title || 'Leaderboard';
    const categorySubtitle = generateCategorySubtitle(category);

    doc.setFontSize(16);
    doc.setFont('helvetica', 'bold');
    doc.text(`${categoryTitle} - ${tournamentName}`, 10, 10);
    doc.setFontSize(12);
    doc.text(`Report generated on ${currentDate}`, 10, 18);
    
    // Add subtitle if available
    if (categorySubtitle) {
      doc.setFontSize(10);
      doc.setFont('helvetica', 'italic');
      doc.text(categorySubtitle, 10, 26);
    }

    // Determine columns based on category type and actual data
    const firstRowData = standings.length > 0 ? standings[0] : null;
    const columns = determineColumns(category, firstRowData, units);
    
    // Format rows for the table
    const rows = standings.map(standing => formatRowData(standing, columns, units));

    // Generate table
    doc.autoTable({
      startY: categorySubtitle ? 32 : 26,
      head: [columns],
      body: rows,
      theme: 'striped',
      styles: {
        fontSize: 10,
        halign: 'center',
        valign: 'middle',
        overflow: 'linebreak',
      },
      headStyles: {
        fillColor: '#02133E',
        textColor: '#ffffff',
        halign: 'center',
      },
    });
  });

  addPageNumbers(doc);
  doc.save(`${tournamentName} Leaderboard Report.pdf`);
};

export const generateLeaderboardReport = async (username, eventId, tournamentName) => {
  const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
    ? process.env.REACT_APP_SERVER_URL_PRODUCTION
    : process.env.REACT_APP_SERVER_URL_STAGING;

  try {
    // Get event settings to determine categories and scoring methods
    const settingsResponse = await fetch(`${apiUrl}/api/admin_get_event_settings`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, eventId }),
    });

    if (!settingsResponse.ok) {
      throw new Error('Failed to fetch event settings');
    }

    const settingsData = await settingsResponse.json();
    const eventSettings = settingsData.settings;
    const { categoryList } = eventSettings;

    // Check if we have the new unified categories structure
    if (categoryList && categoryList.length > 0) {
      // Fetch data for each category using the unified endpoint
      const leaderboardData = await Promise.all(categoryList.map(async (category) => {
        try {
          const response = await fetch(`${apiUrl}/api/get_event_leaderboard`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              username,
              eventId,
              category,
              isReport: true // Request all results, not just top places
            }),
          });

          if (!response.ok) {
            console.error(`Error fetching category ${category.title}: ${response.status}`);
            return null;
          }

          const standings = await response.json();
          return {
            category,
            standings
          };
        } catch (error) {
          console.error(`Error fetching category ${category.title}:`, error);
          return null;
        }
      }));

      // Filter out any null results
      const validData = leaderboardData.filter(data => data !== null && data.standings.length > 0);
      
      if (validData.length > 0) {
        generateLeaderboardPDF(validData, tournamentName, eventSettings);
      } else {
        console.warn('No leaderboard data available');
      }
    } else {
      // Legacy approach for backward compatibility
      // This part is similar to your original code but with updated processing
      const { speciesList } = eventSettings;
      
      // Create a combined array of category data
      const combinedLeaderboardData = [];
      
      // Process species categories if they exist
      if (speciesList && speciesList.length > 0) {
        for (const species of speciesList) {
          let endpoint = '';
          
          // Determine the appropriate endpoint based on scoring method
          switch (species.scoring) {
            case 'Catch & Release (Tiebreaker: Earliest Time)':
              endpoint = 'get_event_leaderboard_for_catch_and_release_species';
              break;
            case 'Weight Only (Tiebreaker: None)':
              endpoint = 'get_event_leaderboard_for_weight_only_species';
              break;
            case 'Weight (Tiebreaker: Length)':
              endpoint = 'get_event_leaderboard_for_weight_and_length_species';
              break;
            case 'Weight (Tiebreaker: Length, Girth)':
              endpoint = 'get_event_leaderboard_for_weight_and_length_and_girth_species';
              break;
            case 'Length Only (Tiebreaker: None)':
              endpoint = 'get_event_leaderboard_for_length_only_species';
              break;
            case 'Length (Tiebreaker: Weight)':
              endpoint = 'get_event_leaderboard_for_length_and_weight_species';
              break;
            case 'Length (Tiebreaker: Girth)':
              endpoint = 'get_event_leaderboard_for_length_and_girth_species';
              break;
            default:
              continue; // Skip if scoring method is not recognized
          }
          
          try {
            const response = await fetch(`${apiUrl}/api/${endpoint}`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                username,
                eventId,
                species: species.species,
                isReport: true
              }),
            });

            if (!response.ok) continue;
            
            const standings = await response.json();
            
            // Skip if no standings data
            if (!standings || standings.length === 0) continue;
            
            // Add to combined data
            combinedLeaderboardData.push({
              category: {
                title: species.species,
                scoringType: species.scoring
              },
              standings
            });
          } catch (error) {
            console.error(`Error fetching leaderboard for ${species.species}:`, error);
          }
        }
      }
      
      // Check if we should also process custom categories
      const customCategories = (eventSettings.categoryList || []).filter(cat => !cat.isSpeciesCategory);
      
      if (customCategories.length > 0) {
        for (const category of customCategories) {
          try {
            const response = await fetch(`${apiUrl}/api/get_event_leaderboard_for_custom_scoring_category`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                username,
                eventId,
                category,
                isReport: true
              }),
            });

            if (!response.ok) continue;
            
            const standings = await response.json();
            
            // Skip if no standings data
            if (!standings || standings.length === 0) continue;
            
            // Add to combined data
            combinedLeaderboardData.push({
              category,
              standings
            });
          } catch (error) {
            console.error(`Error fetching leaderboard for ${category.title}:`, error);
          }
        }
      }
      
      // Generate the PDF if we have data
      if (combinedLeaderboardData.length > 0) {
        generateLeaderboardPDF(combinedLeaderboardData, tournamentName, eventSettings);
      } else {
        console.warn('No leaderboard data available');
      }
    }
  } catch (error) {
    console.error('Error generating leaderboard report:', error);
  }
};

