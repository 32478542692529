import React, { useState, useEffect } from 'react';
import { Box, InputLabel, FormControl, Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, TextField, Select, MenuItem, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Resizer from 'react-image-file-resizer';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

const EditTeamModal = (props) => {
  const [formData, setFormData] = useState({});
  const [teamNames, setTeamNames] = useState([]);
  const [originalTeamName, setOriginalTeamName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [teamPhoto, setTeamPhoto] = useState(null);
  const [teamPhotoName, setTeamPhotoName] = useState("");
  const [photoDeleted, setPhotoDeleted] = useState(false);
  const [categories, setCategories] = useState({
    divisions: [],
  });  

  useEffect(() => {
    fetchTeamNames();
    initializeFormData();
  }, [props.editInfo]);

  const fetchTeamNames = async () => {
    try {
      const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
        ? process.env.REACT_APP_SERVER_URL_PRODUCTION
        : process.env.REACT_APP_SERVER_URL_STAGING;

      const response = await fetch(`${apiUrl}/api/admin_get_event_team_names`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: props.username, eventId: props.eventId })
      });

      if (response.ok) {
        const data = await response.json();
        setTeamNames(data.teamNames || []);
      } else {
        toast.error('Error fetching team names.');
      }
    } catch (error) {
      console.error('Error fetching team names:', error);
      toast.error('Error fetching team names.');
    }
  };

  const initializeFormData = () => {
    if (!props.editInfo || !props.eventSettings) {
      console.error('Missing editInfo or eventSettings');
      return;
    }

    const initialData = {};
    setPhotoDeleted(false);

    // Get categories from event settings - focus only on divisions
    const newCategories = {
      divisions: []
    };

    // Initialize divisions if includeDivisions is true
    if (props.eventSettings?.registration?.includeDivisions === true) {
      if (props.eventSettings?.registration?.divisions && 
          Array.isArray(props.eventSettings.registration.divisions) && 
          props.eventSettings.registration.divisions.length > 0) {
        newCategories.divisions = props.eventSettings.registration.divisions;
      }
    }

    setCategories(newCategories);

    // Handle all regular fields
    const allFields = [
      ...(props.eventSettings.registration.defaultTeamRegistrationFields || []),
      ...(props.eventSettings.registration.userDefinedTeamRegistrationFields || [])
    ];

    allFields.forEach((field) => {
      initialData[field.field] = {
        value: props.editInfo[field.field] ?? (field.type === "Boolean" ? false : ''),
        required: field.required,
        type: field.type,
        isBoolean: field.type === "Boolean" || field.isBoolean,
        isPhoneNumber: field.type === "Phone" || field.isPhoneNumber,
        isEmail: field.type === "Email" || field.isEmail,
        isImage: field.type === "Image" || field.isImage,
        isVisible: field.isVisible !== false
      };
    });

    // Add Division field if it's enabled and not already in allFields
    if (newCategories.divisions.length > 0 && !initialData["Division"]) {
      initialData["Division"] = {
        value: props.editInfo["Division"] || newCategories.divisions[0]?.name || 'Default',
        required: true,
        type: "Select",
        options: newCategories.divisions.map(d => d.name),
        isCategory: true,
        isVisible: true
      };
    }

    // Remove age group and gender fields
    // No longer needed since they've been removed from the data model

    // Handle team photo if allowed
    if (props.eventSettings.registration.allowTeamPhoto) {
      initialData["Team photo"] = {
        value: props.editInfo["Team photo"] || '',
        required: false,
        type: "Image",
        isImage: true,
        isVisible: true
      };
    }

    setFormData(initialData);
    setOriginalTeamName(props.editInfo["Team name"]);
    setIsLoading(false);
  };

  const handleClose = () => {
    setFormData({});
    setTeamNames([]);
    setIsSubmitting(false);
    setIsSubmitted(false);
    setPhotoDeleted(false);
    props.close();
  };

  const handleInputChange = (field, value) => {
    setFormData(prevData => ({
      ...prevData,
      [field]: { ...prevData[field], value: value }
    }));
  };

  const validateUserInput = () => {
    if (isLoading) {
      toast.warning("Please wait while the team data is loading.");
      return false;
    }
  
    let inputIsValid = true;
  
    // Filter visible fields
    const visibleFields = Object.entries(formData)
      .filter(([_, fieldData]) => fieldData.isVisible);
  
    for (const [field, fieldData] of visibleFields) {
      const value = fieldData.value;
      
      if (fieldData.required) {
        if (fieldData.isBoolean) {
          if (value !== true && value !== false) {
            toast.error(`${field} must be set to Yes or No.`);
            inputIsValid = false;
          }
        } else if (!value || (typeof value === 'string' && value.trim() === '')) {
          toast.error(`${field} is required.`);
          inputIsValid = false;
        }
      }
  
      // Additional validation for specific field types
      if (value && typeof value === 'string') {
        if (fieldData.isEmail && !validateEmail(value)) {
          toast.error(`Invalid email format for ${field}.`);
          inputIsValid = false;
        } else if (fieldData.isPhoneNumber && !validatePhone(value)) {
          toast.error(`Invalid phone number for ${field}.`);
          inputIsValid = false;
        }
      }
    }
  
    // Check for duplicate team name
    if (formData["Team name"]?.value !== originalTeamName && 
        teamNames.includes(formData["Team name"]?.value)) {
      toast.error('Team name already exists.');
      inputIsValid = false;
    }
  
    return inputIsValid;
  };

  const handleEdit = async () => {
    if (validateUserInput()) {
      setIsSubmitting(true);
      try {
        const apiUrl = process.env.REACT_APP_NODE_ENV === "staging"
          ? process.env.REACT_APP_SERVER_URL_STAGING
          : process.env.REACT_APP_SERVER_URL_PRODUCTION;
  
        // Create FormData for the request
        const submissionData = new FormData();
        submissionData.append("username", props.username);
        submissionData.append("eventId", props.eventId);
  
        // Transform formData to match expected format
        const updatedTeam = {};
        
        // Add all form data fields except Team photo (handled separately)
        Object.keys(formData).forEach(key => {
          if (key !== "Team photo") {
            updatedTeam[key] = formData[key].value;
          }
        });
        
        // Handle Team photo specifically to ensure correct null handling
        if (photoDeleted || formData["Team photo"]?.value === null) {
          // Explicitly set to null when deleted
          updatedTeam["Team photo"] = null;
          console.log("Setting Team photo to null in submission");
        } else if (formData["Team photo"]) {
          // Keep the existing value or use the new one
          updatedTeam["Team photo"] = formData["Team photo"].value;
        }
  
        // Add teamId
        updatedTeam.teamId = props.editInfo.teamId;
        
        // Log the state of the team photo for debugging
        console.log("Team photo status:", updatedTeam["Team photo"]);
        console.log("Photo deleted flag:", photoDeleted);
  
        // If there's a new photo, append it
        if (teamPhoto) {
          submissionData.append("teamPhoto", teamPhoto, teamPhotoName);
        }
  
        // Add the updated team data
        submissionData.append("updatedTeam", JSON.stringify(updatedTeam));
  
        const response = await fetch(`${apiUrl}/api/admin_edit_event_teams`, {
          method: 'POST',
          body: submissionData,
        });
  
        if (response.ok) {
          toast.success('Team updated successfully!');
          setIsSubmitted(true);
          delayRefresh();
        } else {
          const errorData = await response.json();
          toast.error(`Error: ${errorData.error || 'Error updating team'}`);
        }
      } catch (error) {
        console.error('Error updating team:', error);
        toast.error('Error updating team');
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const delayRefresh = () => {
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePhone = (phone) => {
    const cleaned = phone.replace(/\D/g, "");
    return cleaned.length === 10;
  };

  const handleTeamPhotoUpload = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const fileType = file.type.split("/")[1];
      const fileName = file.name;

      setTeamPhotoName(fileName);
      setPhotoDeleted(false);

      Resizer.imageFileResizer(
        file,
        300,
        300,
        fileType.toUpperCase(),
        80,
        0,
        (uri) => {
          fetch(uri)
            .then((res) => res.blob())
            .then((blob) => {
              const resizedBlob = new Blob([blob], { type: file.type });
              setTeamPhoto(resizedBlob);
              const blobUrl = URL.createObjectURL(resizedBlob);
              setFormData(prevData => ({
                ...prevData,
                "Team photo": { ...prevData["Team photo"], value: blobUrl }
              }));
            })
            .catch((error) => {
              console.error("Error resizing image:", error);
              toast.error("Error resizing image.");
            });
        },
        "base64"
      );
    }
  };

  const handleRemoveTeamPhoto = () => {
    setTeamPhoto(null);
    setTeamPhotoName("");
    setPhotoDeleted(true);
    setFormData(prevData => ({
      ...prevData,
      "Team photo": { ...prevData["Team photo"], value: null }
    }));
    console.log("Team photo marked for deletion");
  };

  const renderField = (fieldName, fieldData) => {
    if (!fieldData.isVisible) return null;
    
    // Handle boolean fields
    if (fieldData.isBoolean) {
      return (
        <FormControl fullWidth required={fieldData.required} margin="normal">
          <InputLabel>{fieldName}</InputLabel>
          <Select
            value={fieldData.value === true ? "true" : "false"}
            onChange={(e) => handleInputChange(fieldName, e.target.value === "true")}
          >
            <MenuItem value="true">Yes</MenuItem>
            <MenuItem value="false">No</MenuItem>
          </Select>
        </FormControl>
      );
    }

    // Handle image fields
    if (fieldData.isImage) {
      const currentPhotoUrl = fieldData.value;
      return (
        <Box>
          <InputLabel sx={{ mb: 1 }}><strong>{fieldName}</strong> {!fieldData.required && "(Optional)"}</InputLabel>
          
          {currentPhotoUrl && !teamPhoto && !photoDeleted && (
            <Box display="flex" alignItems="center" marginBottom={2}>
              <img
                src={currentPhotoUrl}
                alt="Team Thumbnail"
                style={{ width: "100px", height: "100px", objectFit: "cover", marginRight: "10px" }}
                onError={(e) => { e.target.src = "../../images/defaultNoImage.png"; }}
              />
              <IconButton size="small" onClick={handleRemoveTeamPhoto}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Box>
          )}

          <Box>
            <input type="file" accept="image/*" id="edit-team-photo-upload" style={{ display: 'none' }} onChange={handleTeamPhotoUpload} />
            <label htmlFor="edit-team-photo-upload">
              <Button variant="outlined" component="span" startIcon={<CloudUploadIcon />}>
                {currentPhotoUrl && !photoDeleted ? "Replace Photo" : "Upload Photo"}
              </Button>
            </label>

            {/* Show preview of new uploaded photo */}
            {teamPhoto && (
              <Box display="flex" alignItems="center" marginTop={2}>
                <img
                  src={URL.createObjectURL(teamPhoto)}
                  alt="New Team Photo"
                  style={{ width: "100px", height: "100px", objectFit: "cover", marginRight: "10px" }}
                />
                <IconButton size="small" onClick={handleRemoveTeamPhoto}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>
      );
    }

    // Handle Category fields (Division, Age Group, Gender)
    if (fieldData.isCategory) {
      const getCategoryOptions = (fieldName) => {
        if (fieldName === "Division" && categories.divisions.length > 0) {
          return categories.divisions.map(d => d.name);
        } 
        return fieldData.options || [];
      };
      
      return (
        <FormControl fullWidth required={fieldData.required} margin="normal">
          <InputLabel>{fieldName}</InputLabel>
          <Select
            value={fieldData.value || ''}
            onChange={(e) => handleInputChange(fieldName, e.target.value)}
          >
            {getCategoryOptions(fieldName).map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }

    // Default text field for other types
    return (
      <TextField 
        label={fieldName} 
        value={fieldData.value || ''} 
        onChange={(e) => handleInputChange(fieldName, e.target.value)} 
        required={fieldData.required} 
        fullWidth 
        margin="normal" 
      />
    );
  };

  const Typography = ({ variant, children, ...props }) => (
    <Box component={variant === 'subtitle2' ? 'h6' : 'p'} {...props} sx={{ 
      ...(variant === 'subtitle2' && { 
        fontSize: '0.875rem',
        fontWeight: 500,
        marginBottom: '8px'
      }),
      ...props.sx
    }}>
      {children}
    </Box>
  );

  return (
    <Dialog open={props.status} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        Edit Team Information
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Box display="flex" justifyContent="center" my={4}>
            <CircularProgress />
          </Box>
        ) : (
          <Stack spacing={2} sx={{ mt: 2 }}>
            <InputLabel><strong>Team ID:</strong> {props.editInfo?.teamId}</InputLabel>
            
            {/* Render fields in order with Team name first */}
            {Object.entries(formData)
              .filter(([_, fieldData]) => fieldData.isVisible)
              .sort(([keyA], [keyB]) => {
                // Put "Team name" first, "Team photo" last
                if (keyA === "Team name") return -1;
                if (keyB === "Team name") return 1;
                if (keyA === "Team photo") return 1;
                if (keyB === "Team photo") return -1;
                return 0;
              })
              .map(([fieldName, fieldData]) => (
                <Box key={fieldName}>
                  {renderField(fieldName, fieldData)}
                </Box>
              ))}
    
            {!isSubmitted ? (
              <Button
                color="primary"
                variant="contained"
                onClick={handleEdit}
                disabled={isSubmitting}
                startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
                sx={{ mt: 2 }}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            ) : (
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle2">Submitted!</Typography>
              </Box>
            )}
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default EditTeamModal;

