import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Alert,
  Stack,
  Button,
  Paper,
  Tooltip,
  CircularProgress
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const StripeConnectSection = ({ data, onUpdate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [error, setError] = useState(null);
  const [oauthWindow, setOauthWindow] = useState(null);
  const [connectionStatus, setConnectionStatus] = useState(
    data?.stripeAccountId ? 'connected' : 'disconnected'
  );

  useEffect(() => {
    console.log('Initial Stripe connection status:', {
      connectionStatus,
      existingAccountId: data?.stripeAccountId
    });

    const handleMessage = (event) => {
      console.log('Received window message:', event.data);
      
      if (event.data.type === 'STRIPE_CONNECTED') {
        console.log('Stripe OAuth successful:', {
          stripeUserId: event.data.stripeUserId,
          timestamp: new Date().toISOString()
        });
        
        setConnectionStatus('connected');
        setOauthWindow(null);
        
        const updatedData = {
          stripeAccountId: event.data.stripeUserId,
          stripeAccountStatus: 'active',
          stripeConnectedAt: new Date().toISOString()
        };
        
        console.log('Updating form data with Stripe details:', updatedData);
        
        if (onUpdate) {
          onUpdate(updatedData);
        }
      } else if (event.data.type === 'STRIPE_ERROR') {
        console.error('Stripe OAuth error:', event.data.error);
        setError(event.data.error);
        setOauthWindow(null);
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [onUpdate, data?.stripeAccountId]);

  const handleConnectStripe = async () => {
    console.log('Initiating Stripe Connect process...');
    
    try {
      setButtonLoading(true);
      setError(null);

      console.log('Fetching OAuth URL from server...');
      const response = await fetch('/api/get_stripe_oauth_url', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
        throw new Error('Failed to get Stripe OAuth connection URL');
      }

      const { url } = await response.json();
      console.log('Received OAuth URL from server:', url);
      
      // Calculate center position for popup
      const width = 600;
      const height = 600;
      const left = window.screen.width / 2 - width / 2;
      const top = window.screen.height / 2 - height / 2;

      console.log('Opening OAuth popup window...');
      const stripeWindow = window.open(
        url, 
        'StripeConnect', 
        `width=${width},height=${height},left=${left},top=${top}`
      );

      if (stripeWindow) {
        console.log('OAuth window opened successfully');
        setOauthWindow(stripeWindow);
        
        // Poll to check if window is closed
        const pollTimer = setInterval(() => {
          if (stripeWindow.closed) {
            console.log('OAuth window was closed');
            clearInterval(pollTimer);
            setButtonLoading(false);
            setOauthWindow(null);
          }
        }, 500);
      } else {
        console.error('OAuth window was blocked by popup blocker');
        throw new Error('Popup was blocked. Please enable popups for this site.');
      }
      
    } catch (err) {
      console.error('Error in Stripe Connect process:', err);
      setError(err.message || 'Failed to initiate Stripe connection');
    } finally {
      setButtonLoading(false);
    }
  };

  return (
    <Paper sx={{ mt: 4, p: 3, bgcolor: 'grey.50', borderRadius: 2 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Connect with Stripe
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      <Stack spacing={3}>
        {connectionStatus === 'connected' ? (
          <Alert severity="success">
            Your Stripe account is connected successfully!
            {data?.stripeAccountId && (
              <Typography variant="caption" display="block" sx={{ mt: 1 }}>
                Account ID: {data.stripeAccountId}
              </Typography>
            )}
          </Alert>
        ) : (
          <>
            <Alert severity="info">
              Click below to connect your Stripe account. You'll be prompted to create one if you haven't already.
            </Alert>
            <Button
              variant="contained"
              onClick={handleConnectStripe}
              endIcon={buttonLoading ? <CircularProgress size={20} /> : <OpenInNewIcon />}
              disabled={buttonLoading}
              fullWidth
            >
              {buttonLoading ? 'Opening...' : 'Connect Stripe Account'}
            </Button>
          </>
        )}
      </Stack>
    </Paper>
  );
};
 
export default StripeConnectSection;

