import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Alert,
  Fade,
  FormHelperText,
  Slider,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
  Chip,
  Grid,
  Card,
  CardContent
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import PaymentIcon from '@mui/icons-material/Payment';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import BlockIcon from '@mui/icons-material/Block';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { toast } from 'react-toastify';

const PotsStep = ({ data, onUpdate, isValid, featureOption, setActiveStep, categories = [], setFeatureOption }) => {

  const FEATURE_OPTIONS = {
    NOT_NEEDED: 'notNeeded',
    CUSTOMER_PAYS: 'customerPays',
    I_PAY: 'iPay'
  };
  
  const [draftPot, setDraftPot] = useState(null);
  const [tournamentCutWarning, setTournamentCutWarning] = useState(""); 

  useEffect(() => {
    // Always initialize with a consistent structure regardless of feature selection
    onUpdate({
      tournamentPotCut: data.tournamentPotCut || 10,
      potList: data.potList || [],
      draftPot: draftPot
    });
  }, [featureOption, draftPot]);

  useEffect(() => {
    // Only check if there are pots and feature is enabled
    if (data.potList && data.potList.length > 0 && featureOption !== FEATURE_OPTIONS.NOT_NEEDED) {
      const isValid = checkTournamentCut(data.tournamentPotCut);
      // Make sure this updates the isValid prop through the onUpdate callback
      onUpdate({
        tournamentPotCut: data.tournamentPotCut || 10,
        potList: data.potList || [],
        isValid: isValid
      });
    } else {
      // Clear any warnings if feature is disabled
      setTournamentCutWarning("");
    }
  }, [data.potList, data.tournamentCutCut, featureOption]);

  const handleFeatureOptionChange = (event, newOption) => {
    if (newOption !== null) {
      setFeatureOption('fishPots', newOption);
    }
  };

  const getErrorMessage = () => {
    if (draftPot) {
      return "Please save or cancel the draft pot before continuing";
    }
    if (!data.potList || data.potList.length === 0) {
      return "Please add at least one pot to continue";
    }
    if (tournamentCutWarning) {
      return tournamentCutWarning;
    }
    return "Please complete all required information to continue";
  };

  const calculateMinimumCut = (potValue, featureOption) => {
    // Calculate our platform fee based on pot value
    let platformFee;
    if (potValue < 50) {
      platformFee = 3;
    } else if (potValue <= 100) {
      platformFee = 5;
    } else if (potValue <= 200) {
      platformFee = potValue * 0.05; // 5%
    } else {
      platformFee = potValue * 0.04; // 4%
    }
    
    // Define Stripe fee parameters
    const STRIPE_PERCENTAGE = 2.9;
    const STRIPE_FIXED_FEE = 0.30;
    const DESTINATION_CHARGE_PERCENTAGE = 0.5;
    const DESTINATION_CHARGE_FIXED_FEE = 0;
    
    // Calculate total Stripe fees
    const stripeFeePercentage = STRIPE_PERCENTAGE + DESTINATION_CHARGE_PERCENTAGE;
    const stripeFeeFixed = STRIPE_FIXED_FEE + DESTINATION_CHARGE_FIXED_FEE;
    
    // Calculate transaction total based on feature option
    const transactionTotal = featureOption === FEATURE_OPTIONS.CUSTOMER_PAYS ? 
      potValue + platformFee : 
      potValue;
    
    // Calculate Stripe fee based on the total transaction amount
    const stripeFee = (transactionTotal * stripeFeePercentage / 100) + stripeFeeFixed;
    
    // Calculate which fees the organizer needs to cover
    const totalFees = featureOption === FEATURE_OPTIONS.I_PAY ?
      stripeFee + platformFee :
      stripeFee;
      
    // Calculate minimum tournament cut needed with a small buffer
    const minimumCutAmount = totalFees + 1; // Add $1 buffer
    
    // Calculate as percentage of pot value
    const minimumCutPercentage = Math.ceil((minimumCutAmount / potValue) * 100);
    
    return {
      minimumCutPercentage,
      minimumCutAmount,
      platformFee,
      stripeFee,
      totalFees
    };
  };
  
  const checkTournamentCut = (value) => {
    // Check if the tournament cut is sufficient for all pots
    let isValid = true;
    let warningMessage = "";
    
    if (value < 3) {
      // Minimum 3% to cover basic Stripe fees
      warningMessage = "Tournament cut should be at least 3% to cover payment processing fees.";
      isValid = false;
    } else if (data.potList && data.potList.length > 0) {
      // Check each pot value
      data.potList.forEach(pot => {
        pot.values.forEach(potValue => {
          const { minimumCutPercentage, totalFees } = calculateMinimumCut(potValue, featureOption);
          
          if (value <= minimumCutPercentage) {
            const cutAmount = (value/100) * potValue;
            warningMessage = `Warning: Tournament cut of ${value}% (${cutAmount.toFixed(2)}) may be too low for $${potValue} pot. Fees total approximately $${totalFees.toFixed(2)}.`;
            isValid = false;
          }
        });
      });
    }
    
    // Update the warning state
    setTournamentCutWarning(isValid ? "" : warningMessage);
    
    return isValid;
  };

  const handleTournamentCutChange = (value) => {
    // Validate the tournament cut
    checkTournamentCut(value);
    
    // Update the tournament cut value
    handleUpdateField('tournamentPotCut', value);
  };

  const handleUpdateField = (field, value) => {
    onUpdate({ [field]: value });
  };

  // Get selected category data
  const getSelectedCategoryData = (categoryTitle) => {
    return categories.find(cat => cat.title === categoryTitle) || null;
  };

  // Filter available categories
  const getAvailableCategories = () => {
    const usedCategories = new Set((data.potList || []).map(pot => pot.categoryTitle));
    return categories.filter(category => !usedCategories.has(category.title));
  };

  const handleAddPot = () => {
    // Generate incremental values for subpots
    const generateDefaultValues = (count) => {
      return Array.from({ length: count }, (_, index) => (index + 1) * 100);
    };

    setDraftPot({
      categoryTitle: '',
      categoryId: '',
      values: generateDefaultValues(1), // Start with one sub-pot of $100
      subPots: 1,
      categoryData: null // Will store reference to full category object
    });
  };

  // Handle sub-pot count changes with incremental values
  const handleSubPotCountChange = (count) => {
    const numSubPots = Math.min(Math.max(1, count), 10);
    const currentValues = draftPot.values;
    
    // Create new values array with proper length and incremental values for new items
    const newValues = Array(numSubPots).fill(null).map((_, index) => {
      if (index < currentValues.length) {
        // Keep existing values
        return currentValues[index];
      } else {
        // For new items, create incremental values (100, 200, 300, etc.)
        return (index + 1) * 100;
      }
    });

    setDraftPot({
      ...draftPot,
      subPots: numSubPots,
      values: newValues
    });
  };

  const handleSavePot = () => {
    // Validation checks
    if (!draftPot.categoryTitle) {
      toast.error("Please select a category");
      return;
    }
    
    if (draftPot.values.some(value => !value || value <= 0)) {
      toast.error("All pot values must be greater than 0");
      return;
    }
    
    // Check for duplicates within this pot's values
    const uniqueValues = new Set(draftPot.values);
    if (uniqueValues.size !== draftPot.values.length) {
      toast.error("Duplicate values found within the pot");
      return;
    }
    
    // Check for duplicate values across all pots in this category
    const existingValuesForCategory = (data.potList || [])
      .filter(pot => pot.categoryTitle === draftPot.categoryTitle)
      .flatMap(pot => pot.values);
      
    if (draftPot.values.some(value => existingValuesForCategory.includes(value))) {
      toast.error("One or more values already exist for this category");
      return;
    }
    
    // Get the full category data
    const categoryData = getSelectedCategoryData(draftPot.categoryTitle);
    
    // Create the complete pot object with references to category and species
    const newPot = {
      categoryTitle: draftPot.categoryTitle,
      categoryId: draftPot.categoryId || draftPot.categoryTitle.replace(/\s+/g, '_').toLowerCase(),
      values: draftPot.values,
      categoryReference: {
        title: categoryData.title,
        scoringMethod: categoryData.scoringMethod,
        selectedSpecies: categoryData.selectedSpecies,
        filters: categoryData.filters || [],
      }
    };
    
    const newPots = [...(data.potList || []), newPot];
    onUpdate({ potList: newPots });
    setDraftPot(null);
    
    // Validate tournament cut with the new pots
    checkTournamentCut(data.tournamentPotCut);
    
    toast.success(`Pot for ${draftPot.categoryTitle} added successfully`);
  };

  const handleDeletePot = (index) => {
    const potTitle = data.potList[index].categoryTitle;
    const newPots = data.potList.filter((_, i) => i !== index);
    onUpdate({ potList: newPots });
    
    // Re-validate tournament cut after pot deletion
    setTimeout(() => {
      checkTournamentCut(data.tournamentPotCut);
    }, 0);
    
    toast.info(`Pot for ${potTitle} removed`);
  };

  const handlePotValueChange = (subPotIndex, value) => {
    const newValues = [...draftPot.values];
    newValues[subPotIndex] = Number(value);
    setDraftPot({
      ...draftPot,
      values: newValues
    });
  };

  const handleCategoryChange = (categoryTitle) => {
    const categoryData = getSelectedCategoryData(categoryTitle);
    setDraftPot({
      ...draftPot,
      categoryTitle: categoryTitle,
      categoryId: categoryTitle.replace(/\s+/g, '_').toLowerCase(),
      categoryData: categoryData
    });
  };

  const validateStep = () => {
    // If feature is disabled, the step is always valid as we just keep data consistent
    if (featureOption === FEATURE_OPTIONS.NOT_NEEDED) {
      return true;
    }
    
    // Only validate when feature is enabled
    if (draftPot) {
      return false; // Can't proceed with unsaved draft
    }
    
    if (!data.potList || data.potList.length === 0) {
      return false; // Need at least one pot when feature is enabled
    }
    
    // Check if there's a tournament cut warning
    if (tournamentCutWarning) {
      return false; // Tournament cut is insufficient
    }
    
    return true;
  };

  const PotFeeCalculation = ({ amount, whoPays, tournamentCut }) => {
    if (!amount) return null;
    
    const basePrice = parseFloat(amount);
    const tournamentCutPercentage = tournamentCut || 10; // Default to 10% if not provided
    
    // Calculate platform fee based on pot value
    let platformFee;
    let feeDescription;
    
    if (basePrice < 50) {
      platformFee = 3;
      feeDescription = 'Flat $3 fee';
    } else if (basePrice <= 100) {
      platformFee = 5;
      feeDescription = 'Flat $5 fee';
    } else if (basePrice <= 200) {
      platformFee = basePrice * 0.05; // 5%
      feeDescription = '5% fee';
    } else {
      platformFee = basePrice * 0.04; // 4%
      feeDescription = '4% fee';
    }
    
    // Calculate tournament cut amount
    const tournamentCutAmount = (basePrice * tournamentCutPercentage) / 100;
    
    // Calculate pot payout (amount that goes to winner)
    const potPayout = basePrice - tournamentCutAmount;
    
    // Define Stripe fee parameters
    const STRIPE_PERCENTAGE = 2.9;
    const STRIPE_FIXED_FEE = 0.30;
    const DESTINATION_CHARGE_PERCENTAGE = 0.5;
    const DESTINATION_CHARGE_FIXED_FEE = 0;
    
    // Total Stripe fees
    const stripeFeePercentage = STRIPE_PERCENTAGE + DESTINATION_CHARGE_PERCENTAGE;
    const stripeFeeFixed = STRIPE_FIXED_FEE + DESTINATION_CHARGE_FIXED_FEE;
    
    // Determine who is paying which fees based on whoPays option
    const whoPaysFees = whoPays === FEATURE_OPTIONS.CUSTOMER_PAYS ? 
      "Team pays fees" : 
      "You pay fees";
    
    if (whoPays === FEATURE_OPTIONS.CUSTOMER_PAYS) {
      // Customer pays the platform fee and all Stripe fees
      const participantTotal = basePrice + platformFee;
      const stripeFee = (participantTotal * stripeFeePercentage / 100) + stripeFeeFixed;
      const totalParticipantPays = participantTotal + stripeFee;
      
      return (
        <Accordion defaultExpanded sx={{ mt: 1 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle2" fontWeight="medium">
              Transaction Example <Chip 
                label={whoPaysFees} 
                size="small" 
                color="primary" 
                variant="outlined" 
                sx={{ ml: 1, fontSize: '0.7rem' }}
              />
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ pt: 0 }}>
            <Typography variant="subtitle2" color="primary.dark" sx={{ mb: 1, fontWeight: 'bold' }}>
              What the team pays:
            </Typography>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body2">Base Pot Entry Fee</Typography>
              <Typography variant="body2">${basePrice.toFixed(2)}</Typography>
            </Box>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body2">Our Platform Fee ({feeDescription})</Typography>
              <Typography variant="body2">${platformFee.toFixed(2)}</Typography>
            </Box>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body2">Stripe Processing Fee ({STRIPE_PERCENTAGE}% + ${STRIPE_FIXED_FEE.toFixed(2)}) + Destination Fee ({DESTINATION_CHARGE_PERCENTAGE}%)</Typography>
              <Typography variant="body2">${stripeFee.toFixed(2)}</Typography>
            </Box>
            
            <Divider sx={{ my: 1 }} />
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              <Typography variant="body2" fontWeight="bold">Total Participant Payment</Typography>
              <Typography variant="body2" fontWeight="bold">${totalParticipantPays.toFixed(2)}</Typography>
            </Box>
            
            <Typography variant="subtitle2" color="warning.dark" sx={{ mb: 1, fontWeight: 'bold' }}>
              How the pot is distributed:
            </Typography>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body2">Your Tournament Cut ({tournamentCutPercentage}%)</Typography>
              <Typography variant="body2">${tournamentCutAmount.toFixed(2)}</Typography>
            </Box>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body2">Pot Payout to Winner ({100-tournamentCutPercentage}%)</Typography>
              <Typography variant="body2">${potPayout.toFixed(2)}</Typography>
            </Box>
          </AccordionDetails>
        </Accordion>
      );
    } else {
      // Organizer pays all fees (platform fee and Stripe fees)
      const participantTotal = basePrice;
      const stripeFee = (participantTotal * stripeFeePercentage / 100) + stripeFeeFixed;
      
      // Organizer pays all fees from their tournament cut
      const organizerNetRevenue = tournamentCutAmount - platformFee - stripeFee;
      const isCutSufficient = organizerNetRevenue > 0;
      
      return (
        <Accordion defaultExpanded sx={{ mt: 1 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle2" fontWeight="medium">
              Transaction Example <Chip 
                label={whoPaysFees} 
                size="small" 
                color="primary" 
                variant="outlined" 
                sx={{ ml: 1, fontSize: '0.7rem' }}
              />
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ pt: 0 }}>
            <Typography variant="subtitle2" color="primary.dark" sx={{ mb: 1, fontWeight: 'bold' }}>
              What the team pays:
            </Typography>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body2">Base Pot Entry Fee (no added fees)</Typography>
              <Typography variant="body2">${basePrice.toFixed(2)}</Typography>
            </Box>
            
            <Divider sx={{ my: 1 }} />
            
            <Typography variant="subtitle2" color="warning.dark" sx={{ mb: 1, fontWeight: 'bold' }}>
              How the pot is distributed:
            </Typography>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body2">Your Tournament Cut ({tournamentCutPercentage}%)</Typography>
              <Typography variant="body2">${tournamentCutAmount.toFixed(2)}</Typography>
            </Box>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body2">Pot Payout to Winner ({100-tournamentCutPercentage}%)</Typography>
              <Typography variant="body2">${potPayout.toFixed(2)}</Typography>
            </Box>
            
            <Divider sx={{ my: 1 }} />
            
            <Typography variant="subtitle2" color="warning.dark" sx={{ mb: 1, fontWeight: 'bold' }}>
              Your costs (from tournament cut):
            </Typography>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body2">Stripe Processing Fee ({STRIPE_PERCENTAGE}% + ${STRIPE_FIXED_FEE.toFixed(2)}) + Destination Fee ({DESTINATION_CHARGE_PERCENTAGE}%)</Typography>
              <Typography variant="body2" color="error.main">-${stripeFee.toFixed(2)}</Typography>
            </Box>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body2">Our Platform Fee ({feeDescription})</Typography>
              <Typography variant="body2" color="error.main">-${platformFee.toFixed(2)}</Typography>
            </Box>
            
            <Divider sx={{ my: 1 }} />
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body2" fontWeight="bold">Your Net Revenue</Typography>
              <Typography variant="body2" fontWeight="bold" color={isCutSufficient ? "success.main" : "error.main"}>
                {isCutSufficient ? 
                  `$${organizerNetRevenue.toFixed(2)}` : 
                  '$0.00 (fees exceed cut)'}
              </Typography>
            </Box>
            
            {!isCutSufficient && (
              <Alert severity="warning" sx={{ mt: 1, fontSize: '0.8rem' }}>
                Warning: Estimated fees exceed your tournament cut for this pot. Consider increasing your tournament cut percentage.
              </Alert>
            )}
          </AccordionDetails>
        </Accordion>
      );
    }
  };

  const ExampleFeeSection = () => {
    // Get sample pot value from the first pot or use default
    const samplePotValue = data.potList && data.potList.length > 0 && data.potList[0].values.length > 0 
      ? data.potList[0].values[0] 
      : 100;
    
    return (
      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle2" gutterBottom>
          Sample Transaction Breakdown
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          This example shows how fees and payouts work for a ${samplePotValue} pot:
        </Typography>
        
        <PotFeeCalculation 
          amount={samplePotValue}
          whoPays={featureOption}
          tournamentCut={data.tournamentPotCut || 10}
        />
      </Box>
    );
  };

  const PotPreview = ({ pot }) => {
    if (!pot || !pot.categoryTitle) return null;
    
    const categoryData = getSelectedCategoryData(pot.categoryTitle);
    if (!categoryData) return null;
    
    return (
      <Card sx={{ mt: 3, border: '1px solid #e0e0e0' }}>
        <CardContent>
          <Typography variant="subtitle2" gutterBottom>
            Pot Preview
          </Typography>
          
          <Divider sx={{ mb: 2 }} />
          
          <Box sx={{ mb: 2 }}>
            <Typography variant="body2" fontWeight="medium">
              Category: {categoryData.title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Scoring Method: {categoryData.scoringMethod}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Eligible Species: {categoryData.selectedSpecies.join(', ')}
            </Typography>
            
            {categoryData.filters && categoryData.filters.length > 0 && (
              <Box sx={{ mt: 1 }}>
                <Typography variant="body2" color="text.secondary">
                  Filters:
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 0.5 }}>
                  {categoryData.filters.map((filter, i) => (
                    <Chip 
                      key={i} 
                      label={`${filter.type}: ${filter.condition} ${filter.value.join(', ')}`}
                      size="small" 
                      variant="outlined" 
                      sx={{ fontSize: '0.7rem' }}
                    />
                  ))}
                </Box>
              </Box>
            )}
          </Box>
          
          <Typography variant="subtitle2" gutterBottom>
            Pot Values
          </Typography>
          
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {pot.values.map((value, i) => (
              <Chip 
                key={i} 
                label={`$${value}`}
                color="primary" 
                variant="outlined"
              />
            ))}
          </Box>
          
          <Box sx={{ mt: 2, p: 1, bgcolor: '#f5f5f5', borderRadius: 1 }}>
            <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
              <InfoOutlinedIcon fontSize="small" sx={{ mr: 1, color: 'primary.main' }} />
              Teams can enter one or more of these pot values for this category
            </Typography>
          </Box>
        </CardContent>
      </Card>
    );
  };

  const DisabledPotsFeatureMessage = ({ setActiveStep, setFeatureOption }) => (
    <Box sx={{ textAlign: 'center', py: 4 }}>
      <Typography variant="h6" gutterBottom>
        Fish Pots feature is currently disabled
      </Typography>
      <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
        To set up fish pots with payment processing, you need to enable this feature.
      </Typography>
      <Stack direction="row" spacing={2} justifyContent="center">
        <Button
          variant="outlined"
          onClick={() => setActiveStep(0)}
        >
          Go to Features Step
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setFeatureOption('fishPots', 'customerPays');
          }}
        >
          Enable Fish Pots
        </Button>
      </Stack>
    </Box>
  );
  

  return (
    <Fade in timeout={400}>
      <Box>
        {!validateStep() && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {getErrorMessage()}
          </Alert>
        )}

        {/* Fee Configuration */}
        <Paper sx={{ p: 3, mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            Fee Configuration
          </Typography>
          
          <Box sx={{ border: '1px solid #e0e0e0', borderRadius: 1, p: 2, bgcolor: '#f9f9f9' }}>
            <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'medium', color: 'text.primary' }}>
              How should pot fees be handled?
            </Typography>
            
            <ToggleButtonGroup
              value={featureOption}
              exclusive
              onChange={handleFeatureOptionChange}
              aria-label="fee option"
              sx={{ mb: 1 }}
              fullWidth
            >
              <ToggleButton 
                value={FEATURE_OPTIONS.CUSTOMER_PAYS} 
                aria-label="participant pays" 
                sx={{ py: 1 }}
              >
                <Tooltip title="Participants pay our platform fees" placement="top">
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <PaymentIcon sx={{ mb: 0.5 }} />
                    <Typography variant="caption">Participants Pay Fees</Typography>
                  </Box>
                </Tooltip>
              </ToggleButton>
              <ToggleButton 
                value={FEATURE_OPTIONS.I_PAY} 
                aria-label="organizer pays" 
                sx={{ py: 1 }}
              >
                <Tooltip title="You pay our platform fees" placement="top">
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <AccountBalanceWalletIcon sx={{ mb: 0.5 }} />
                    <Typography variant="caption">You Pay Fees</Typography>
                  </Box>
                </Tooltip>
              </ToggleButton>
              <ToggleButton 
                value={FEATURE_OPTIONS.NOT_NEEDED} 
                aria-label="no pots" 
                sx={{ py: 1 }}
              >
                <Tooltip title="Disable fish pots feature" placement="top">
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <BlockIcon sx={{ mb: 0.5 }} />
                    <Typography variant="caption">No Fish Pots</Typography>
                  </Box>
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
            
            <Typography variant="caption" color="text.secondary">
              {featureOption === FEATURE_OPTIONS.CUSTOMER_PAYS ? 
                "Participants will pay the platform fee in addition to their pot entry fee." :
                featureOption === FEATURE_OPTIONS.I_PAY ?
                "You will pay the platform fee from your tournament cut." :
                "Fish pots feature is disabled."}
            </Typography>
          </Box>
          
          {featureOption === FEATURE_OPTIONS.NOT_NEEDED && (
            <Alert severity="info" sx={{ mt: 3 }}>
              <Typography variant="body2">
                The Fish Pots feature is currently disabled for this event. You can still configure 
                pots for reference, but they won't be visible on your event site.
              </Typography>
            </Alert>
          )}
        </Paper>

        {/* Tournament Cut Section */}
        <Paper sx={{ p: 3, mb: 4 }}>
          <Typography variant="h6" sx={{ mb: 3 }}>
            Tournament Cut ({data.tournamentPotCut}%)
          </Typography>
          <Stack spacing={2}>
            <Box sx={{ px: 2 }}>

              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                "Tournament cut" is the percentage of each pot fee that you want to keep. The remaining percentage goes to the pot winner (minus any fees). Any fees are deducted based on your fee selection above.
              </Typography>

              <Slider
                value={data.tournamentPotCut ?? 10}
                onChange={(_, value) => handleTournamentCutChange(value)}
                valueLabelDisplay="auto"
                step={1}
                marks
                min={0}
                max={100}
              />
              
              {tournamentCutWarning && (
                <Alert severity="warning" sx={{ mt: 1 }}>
                  {tournamentCutWarning}
                </Alert>
              )}
              
            </Box>

            <ExampleFeeSection />

          </Stack>
        </Paper>

        {/* Pots Section */}
        <Paper sx={{ p: 3 }}>
          <Typography variant="h6" sx={{ mb: 3 }}>
            Pots
          </Typography>

          {categories.length === 0 ? (
            <Alert severity="warning">
              Please set up scoring categories before creating pots
            </Alert>
          ) : (
            <Stack spacing={2}>
              {/* Existing Pots */}
              {data.potList?.map((pot, index) => (
                <Paper
                  key={index}
                  variant="outlined"
                  sx={{ p: 2 }}
                >
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Typography sx={{ flex: 1, fontWeight: 500 }}>
                      {pot.categoryTitle}
                    </Typography>
                    <Box>
                      <Typography color="text.secondary" variant="body2">
                        Values: {pot.values.map(value => `${value}`).join(', ')}
                      </Typography>
                      {pot.categoryReference && (
                        <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
                          Species: {pot.categoryReference.selectedSpecies.join(', ')}
                        </Typography>
                      )}
                    </Box>
                    <IconButton 
                      size="small" 
                      onClick={() => handleDeletePot(index)}
                      color="error"
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </Stack>
                </Paper>
              ))}

              {/* Draft Pot */}
              {draftPot && (
                <Paper variant="outlined" sx={{ p: 3 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={3}>
                        <FormControl fullWidth error={!draftPot.categoryTitle}>
                          <InputLabel>Select Category</InputLabel>
                          <Select
                            value={draftPot.categoryTitle}
                            onChange={(e) => handleCategoryChange(e.target.value)}
                            label="Select Category"
                          >
                            {getAvailableCategories().map((category) => (
                              <MenuItem key={category.title} value={category.title}>
                                {category.title}
                              </MenuItem>
                            ))}
                          </Select>
                          {!draftPot.categoryTitle && (
                            <FormHelperText>Category is required</FormHelperText>
                          )}
                          {getAvailableCategories().length === 0 && (
                            <FormHelperText error>
                              All categories have pots assigned. Delete existing pots to reassign.
                            </FormHelperText>
                          )}
                        </FormControl>

                        <TextField
                          label="Number of Sub-Pots"
                          type="number"
                          value={draftPot.subPots}
                          onChange={(e) => handleSubPotCountChange(parseInt(e.target.value))}
                          inputProps={{ min: 1, max: 10 }}
                          fullWidth
                          helperText="Create up to 10 different pot values for this category"
                        />

                        {draftPot.values.map((value, index) => (
                          <TextField
                            key={index}
                            label={`Sub-Pot ${index + 1} Value`}
                            type="number"
                            value={value}
                            onChange={(e) => handlePotValueChange(index, e.target.value)}
                            fullWidth
                            error={!value || value <= 0}
                            helperText={!value || value <= 0 ? 
                              "Value must be greater than 0" : 
                              draftPot.values.indexOf(value) !== index ? 
                              "This value is already used in another sub-pot" : ""}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>
                            }}
                            // Fix for initial value deletion issue
                            inputProps={{
                              min: 1,
                              inputMode: 'numeric',
                            }}
                          />
                        ))}

                        <Stack direction="row" spacing={1}>
                          <Button
                            variant="contained"
                            onClick={handleSavePot}
                            disabled={
                              !draftPot.categoryTitle || 
                              draftPot.values.some(value => !value || value <= 0) ||
                              new Set(draftPot.values).size !== draftPot.values.length
                            }
                          >
                            Save Pot
                          </Button>
                          <Button
                            variant="outlined"
                            onClick={() => setDraftPot(null)}
                          >
                            Cancel
                          </Button>
                        </Stack>
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <PotPreview pot={draftPot} />
                    </Grid>
                  </Grid>
                </Paper>
              )}

              {!draftPot && (
                <Button
                  startIcon={<AddIcon />}
                  onClick={handleAddPot}
                  variant="outlined"
                  disabled={getAvailableCategories().length === 0}
                >
                  Add Pot
                </Button>
              )}
            </Stack>
          )}
        </Paper>
      </Box>
    </Fade>
  );

};

export default PotsStep;

