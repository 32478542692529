import { Navigate } from "react-router-dom";
import MainLayout from "../layouts/MainLayout";
import DashboardPage from "../pages/DashboardPage";
import ProfilePage from '../pages/ProfilePage';
import StatisticsPage from "../pages/StatisticsPage";
import SupportPage from "../pages/SupportPage"; // Import new SupportPage
import ErrorPage from "../pages/ErrorPage";
import EventLayout from "../layouts/EventLayout";
import HomePage from "../pages/HomePage";
import AdminPage from "../pages/AdminPage";
import RegisterPage from "../pages/RegisterPage";
import NewsfeedPage from "../pages/NewsfeedPage";
import LeaderboardPage from "../pages/LeaderboardPage";
import PotsPage from "../pages/PotsPage";
import SponsorPage from "../pages/SponsorPage";
import EventSupportPage from "../pages/EventSupportPage"; // Import new EventSupportPage
import RegisterSuccessPage from "../pages/RegisterSuccessPage";
import RegisterErrorPage from "../pages/RegisterErrorPage";
import AdminAuthenticate from "../components/authenticate/AdminAuthenticate";
import Authenticate from "../components/authenticate/Authenticate";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import BarChartIcon from '@mui/icons-material/BarChart';
import EventIcon from '@mui/icons-material/Event';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'; // Import icon for support
import StripeSuccessPage from "../pages/StripeSuccessPage";
import StripeFailurePage from "../pages/StripeFailurePage";
import StripeCancelPage from "../pages/StripeCancelPage";
import StripeRefreshPage from "../pages/StripeRefreshPage";
import StripeCompletePage from "../pages/StripeCompletePage";
import SponsorSuccessPage from "../pages/SponsorSuccessPage";
import SponsorErrorPage from "../pages/SponsorErrorPage";
import PotsSuccessPage from "../pages/PotsSuccessPage";
import PotsErrorPage from "../pages/PotsErrorPage";

// Private Route Component to protect routes
const PrivateRoute = ({ element }) => {
  const currentUser = JSON.parse(window.localStorage.getItem('user'));
  return currentUser ? element : <Navigate to="/" replace />;
};

// Routes definition
export const appRoutes = [
  {
    path: '/',
    element: <Authenticate />, // Default to the Authenticate component
    errorElement: <ErrorPage />,
  },
  {
    path: '/master',
    element: <AdminAuthenticate />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/stripe/success',
    element: <StripeSuccessPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/stripe/failure',
    element: <StripeFailurePage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/stripe/cancel',
    element: <StripeCancelPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/stripe/refresh',
    element: <StripeRefreshPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/stripe/complete',
    element: <StripeCompletePage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/:username/profile',
    element: <PrivateRoute element={<MainLayout />} />, // Use PrivateRoute for MainLayout
    children: [
      { index: true, element: <ProfilePage /> },
    ],
    errorElement: <ErrorPage />,
    state: 'profile',
    sidebarProps: {
      displayText: "My User Profile",
      icon: <ManageAccountsIcon />
    }
  },
  {
    path: '/:username/dashboard',
    element: <PrivateRoute element={<MainLayout />} />, // Use PrivateRoute for MainLayout
    children: [
      { index: true, element: <DashboardPage /> },
    ],
    errorElement: <ErrorPage />,
    state: "dashboard",
    sidebarProps: {
      displayText: "My Events",
      icon: <EventIcon />
    }
  },
  {
    path: '/:username/statistics',
    element: <PrivateRoute element={<MainLayout />} />, // Use PrivateRoute for MainLayout
    children: [
      { index: true, element: <StatisticsPage /> },
    ],
    errorElement: <ErrorPage />,
    state: "statistics",
    sidebarProps: {
      displayText: "My Statistics",
      icon: <BarChartIcon />
    }
  },
  {
    path: '/:username/support', // New route for user support
    element: <PrivateRoute element={<MainLayout />} />,
    children: [
      { index: true, element: <SupportPage /> },
    ],
    errorElement: <ErrorPage />,
    state: "support",
    sidebarProps: {
      displayText: "Need Help?",
      icon: <HelpOutlineIcon />
    }
  },
  {
    path: '*',
    element: <ErrorPage />,
  },
  {
    path: '/:username/:eventId',
    element: <EventLayout />,
    errorElement: <ErrorPage />,
    children: [
      { path: 'home', element: <HomePage /> },
      { path: 'admin', element: <AdminPage /> },
      { path: 'newsfeed', element: <NewsfeedPage /> },
      { path: 'leaderboard', element: <LeaderboardPage /> },
      { path: 'pots', element: <PotsPage /> },
      { path: 'register', element: <RegisterPage /> },
      { path: 'sponsors', element: <SponsorPage /> },
      { path: 'support', element: <EventSupportPage /> }, // New route for event support
      { path: 'register/success', element: <RegisterSuccessPage /> },
      { path: 'register/error', element: <RegisterErrorPage /> },
      { path: 'sponsors/success', element: <SponsorSuccessPage /> },
      { path: 'sponsors/error', element: <SponsorErrorPage /> },
      { path: 'pots/success', element: <PotsSuccessPage /> },
      { path: 'pots/error', element: <PotsErrorPage /> },
    ],
  },
];

