import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  TextField, 
  Button, 
  Container,
  Alert,
  CircularProgress
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SendIcon from '@mui/icons-material/Send';
import AnimatedPage from './AnimatedPage';
import Footer from '../components/footer/Footer';
import './EventSupportPage.css';

const EventSupportPage = () => {
  const { username, eventId } = useParams();
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [replyEmail, setReplyEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [eventSettings, setEventSettings] = useState(null);
  const [isLoadingSettings, setIsLoadingSettings] = useState(true);
  
  // Get user details if logged in
  const currentUser = JSON.parse(window.localStorage.getItem('user'));
  
  useEffect(() => {
    
    const fetchEventSettings = async () => {
      setIsLoadingSettings(true);
      const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
        ? process.env.REACT_APP_SERVER_URL_PRODUCTION
        : process.env.REACT_APP_SERVER_URL_STAGING;

      try {
        const response = await fetch(`${apiUrl}/api/load_event_profile`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username, eventId }),
        });

        const data = await response.json();
        if (data.success) {
          setEventSettings(data.settings);
        } else {
          console.error("Failed to fetch event settings:", data.message);
        }
      } catch (error) {
        console.error('Error fetching event settings:', error);
      } finally {
        setIsLoadingSettings(false);
      }
    };

    fetchEventSettings();
  }, [username, eventId]);
  
  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!subject.trim() || !message.trim()) {
      toast.error("Please fill in all required fields");
      return;
    }
    
    if (!replyEmail.trim() || !validateEmail(replyEmail)) {
      toast.error("Please enter a valid email address");
      return;
    }
    
    setLoading(true);
    
    try {
      const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
        ? process.env.REACT_APP_SERVER_URL_PRODUCTION
        : process.env.REACT_APP_SERVER_URL_STAGING;
      
      const eventTitle = eventSettings?.basicInfo?.title || 'Unknown Event';
      const organization = eventSettings?.basicInfo?.organization || '';
      
      const supportMessage = {
        username,
        eventId,
        eventTitle,
        organization,
        replyEmail, // Using the required reply email
        subject,
        message,
        userId: currentUser?.uid || null,
        type: 'event', // Indicates this is from event page
        timestamp: new Date().toISOString()
      };
      
      const response = await fetch(`${apiUrl}/api/submit_support_message`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(supportMessage),
        credentials: 'include' // Include credentials if you're using cookies for authentication
      });
      
      const data = await response.json();
      
      if (data.success) {
        setSuccess(true);
        setSubject('');
        setMessage('');
        toast.success("Your message has been sent successfully!");
      } else {
        toast.error("Failed to send message: " + (data.message || 'Unknown error'));
      }
    } catch (err) {
      console.error("Error sending support message:", err);
      toast.error("Failed to send message. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  
  const handleReset = () => {
    setSuccess(false);
    setSubject('');
    setMessage('');
  };
  
  if (isLoadingSettings) {
    return (
      <AnimatedPage>
        <div style={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '100vh'
        }}>
          <CircularProgress />
        </div>
      </AnimatedPage>
    );
  }
  
  const backgroundColor = eventSettings?.basicInfo?.backgroundColor || '#ffffff';
  const headlineTextColor = eventSettings?.basicInfo?.headlineTextColor || '#ffffff';
  const accentColor = eventSettings?.basicInfo?.accentColor || '#0281C5';
  
  return (
    <AnimatedPage>
      <main>
        {/* Banner */}
        <section 
          className="section-banner"
          style={{ 
            backgroundColor: backgroundColor,
            color: headlineTextColor 
          }}
        >
          <h1>Support</h1>
        </section>

        <Container maxWidth="md" sx={{ py: 4, minHeight: 'calc(100vh - 200px)' }}>
          <Paper
            elevation={3}
            sx={{
              p: 4,
              borderRadius: '16px',
              backgroundColor: '#ffffff',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
            }}
          >
            <Box sx={{ textAlign: 'center', mb: 4 }}>
              <HelpOutlineIcon
                sx={{
                  fontSize: 60,
                  mb: 2,
                  color: accentColor,
                }}
              />
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 600,
                  mb: 2,
                  color: backgroundColor,
                }}
              >
                Need Help?
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
                Having trouble or have questions about this tournament? We're here to help!
              </Typography>
            </Box>

            {success ? (
              <Box sx={{ mb: 4, textAlign: 'center' }}>
                <Alert severity="success" sx={{ mb: 2 }}>
                  Thank you for contacting us! We've received your message and will respond shortly.
                </Alert>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleReset}
                  sx={{ mt: 2 }}
                >
                  Send Another Message
                </Button>
              </Box>
            ) : (
              <form onSubmit={handleSubmit}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                  <Box
                    sx={{
                      p: 3,
                      bgcolor: 'rgba(0, 0, 0, 0.03)',
                      borderRadius: '8px',
                      mb: 2,
                    }}
                  >
                    <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 500 }}>
                      Event Information
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      <strong>Event:</strong> {eventSettings?.basicInfo?.title || 'Unknown Event'}
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      <strong>Organization:</strong> {eventSettings?.basicInfo?.organization || 'Unknown Organization'}
                    </Typography>
                  </Box>

                  <TextField
                    label="Email Address for Reply"
                    variant="outlined"
                    type="email"
                    fullWidth
                    value={replyEmail}
                    onChange={(e) => setReplyEmail(e.target.value)}
                    required
                    placeholder="Enter an email where we can reply to you"
                    error={replyEmail !== '' && !validateEmail(replyEmail)}
                    helperText={replyEmail !== '' && !validateEmail(replyEmail) ? "Please enter a valid email address" : ""}
                  />

                  <TextField
                    label="Subject"
                    variant="outlined"
                    fullWidth
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    required
                  />

                  <TextField
                    label="Message"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={6}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                    placeholder="Please describe your issue or question in detail..."
                  />

                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    disabled={loading}
                    endIcon={loading ? <CircularProgress size={20} /> : <SendIcon />}
                    sx={{
                      mt: 2,
                      backgroundColor: accentColor,
                      '&:hover': {
                        backgroundColor: `${accentColor}dd`,
                      },
                    }}
                  >
                    {loading ? 'Sending...' : 'Send Message'}
                  </Button>

                  <Typography
                    variant="caption"
                    sx={{ textAlign: 'center', color: 'text.secondary', mt: 1 }}
                  >
                    * Required fields
                  </Typography>

                  <Typography
                    variant="caption"
                    sx={{ textAlign: 'center', color: 'text.secondary' }}
                  >
                    We typically respond within 24-48 business hours.
                  </Typography>
                </Box>
              </form>
            )}
          </Paper>
        </Container>
        
        <Footer />
      </main>
    </AnimatedPage>
  );
};

export default EventSupportPage;

