import React, { useState, useEffect } from 'react';
import { 
  Button, 
  Dialog, 
  DialogContent, 
  DialogTitle, 
  IconButton, 
  Stack, 
  TextField, 
  CircularProgress,
  Typography,
  Box
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddAnnouncementModal = (props) => {
  const [subject, setSubject] = useState('');
  const [hyperlink, setHyperlink] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleClose = () => {
    setSubject('');
    setHyperlink('');
    setMessage('');
    setIsSubmitting(false);
    setIsSubmitted(false);
    props.close();
  };

  const delayRefresh = () => {
    setTimeout(() => {
      console.log('Delaying page refresh...');
      window.location.reload();
    }, 2000);
  };

  const validateUserInput = () => {
    if (!subject) {
      toast.error("Subject cannot be empty.");
      return false;
    }
    if (!message) {
      toast.error("Message cannot be empty.");
      return false;
    }
    return true;
  };

  const handleCreateAnnouncement = async () => {
    if (!validateUserInput()) return;

    setIsSubmitting(true);

    try {
      let apiUrl = process.env.REACT_APP_NODE_ENV === "staging"
        ? process.env.REACT_APP_SERVER_URL_STAGING
        : process.env.REACT_APP_SERVER_URL_PRODUCTION;

      const announcement = {
        type: "Announcement",
        title: "-",
        subtitle: message,
        points: "-",
        timestamp: dayjs().toISOString(),
        subject,
        hyperlink,
      };

      const response = await fetch(`${apiUrl}/api/admin_create_event_announcements`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          username: props.username,
          eventId: props.eventId,
          announcement,
        }),
      });

      if (response.ok) {
        toast.success("Announcement created successfully.");
        setIsSubmitted(true);
        delayRefresh();
      } else {
        toast.error("Error creating announcement.");
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error("Error creating announcement:", error);
      toast.error("Error creating announcement.");
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog 
      open={props.status} 
      onClose={handleClose} 
      fullWidth 
      maxWidth="sm"
      PaperProps={{
        sx: {
          borderRadius: '8px',
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
        }
      }}
    >
      <DialogTitle sx={{ bgcolor: '#f8f9fa', padding: '16px 24px', borderBottom: '1px solid #e0e0e0' }}>
        <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
          Add Announcement
        </Typography>
        <IconButton 
          onClick={handleClose} 
          aria-label="close"
          sx={{ 
            position: 'absolute',
            right: 12,
            top: 12,
            color: 'grey.500'
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: '24px' }}>
        <Stack spacing={3}>
          <TextField 
            label="Subject" 
            value={subject} 
            onChange={(e) => setSubject(e.target.value)} 
            fullWidth
            variant="outlined"
            required
            size="medium"
          />
          <TextField 
            label="Hyperlink" 
            value={hyperlink} 
            onChange={(e) => setHyperlink(e.target.value)} 
            fullWidth
            variant="outlined"
            size="medium"
            placeholder="https://example.com"
          />
          <TextField 
            label="Message" 
            value={message} 
            onChange={(e) => setMessage(e.target.value)} 
            multiline 
            rows={4} 
            fullWidth
            variant="outlined"
            required
            size="medium"
          />
          
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={handleClose}
              sx={{ mr: 2 }}
              variant="outlined"
            >
              Cancel
            </Button>
            {!isSubmitted ? (
              <Button
                color="primary"
                variant="contained"
                onClick={handleCreateAnnouncement}
                disabled={isSubmitting || isSubmitted}
                startIcon={isSubmitting ? <CircularProgress size={20} color="inherit" /> : null}
                sx={{ 
                  minWidth: '120px',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                  textTransform: 'none'
                }}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            ) : (
              <Typography variant="subtitle1" color="success.main" sx={{ fontWeight: 600 }}>
                Submitted!
              </Typography>
            )}
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default AddAnnouncementModal;

