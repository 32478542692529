import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Typography,
  Button,
  Paper,
  Stack,
  Alert,
  CircularProgress,
  Fade,
  Popover,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  InputAdornment,
  IconButton
} from '@mui/material';
import { SketchPicker } from 'react-color';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { toast } from 'react-toastify';
import Resizer from "react-image-file-resizer";
import PageVisibilitySection from '../components/viewers/PageVisibilitySection';

const EventSettingsTab = ({ eventSettings, username, eventId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [saveError, setError] = useState(null);
  const [colorPickerAnchor, setColorPickerAnchor] = useState(null);
  const [activeColorField, setActiveColorField] = useState(null);
  const [localSettings, setLocalSettings] = useState(eventSettings);

  // State for form fields
  const [formData, setFormData] = useState({
    eventTitle: eventSettings?.eventTitle || '',
    homepageSubheadline: eventSettings?.homepageSubheadline || '',
    backgroundColor: eventSettings?.backgroundColor || '#87CEEB',
    headlineTextColor: eventSettings?.headlineTextColor || '#FFFFFF',
    logo: null,
    logoUrl: eventSettings?.eventLogoUrl || null,
    rulesType: eventSettings?.rules?.type || 'none',
    rulesContent: eventSettings?.rules?.content || '',
    rulesLink: eventSettings?.rules?.link || '',
    rulesFile: null,
    rulesFileName: null,
    scheduleType: eventSettings?.schedule?.type || 'none',
    scheduleContent: eventSettings?.schedule?.content || '',
    scheduleLink: eventSettings?.schedule?.link || '',
    scheduleFile: null,
    scheduleFileName: null,
    websiteType: eventSettings?.website?.type || 'none',
    websiteLink: eventSettings?.website?.link || ''
  });

  // Reset form when event settings change
  useEffect(() => {
    if (eventSettings) {
      setFormData({
        eventTitle: eventSettings.eventTitle || '',
        homepageSubheadline: eventSettings.homepageSubheadline || '',
        backgroundColor: eventSettings.backgroundColor || '#87CEEB',
        headlineTextColor: eventSettings.headlineTextColor || '#FFFFFF',
        logo: null,
        logoUrl: eventSettings.eventLogoUrl || null,
        rulesType: eventSettings.rules?.type || 'none',
        rulesContent: eventSettings.rules?.content || '',
        rulesLink: eventSettings.rules?.link || '',
        rulesFile: null,
        rulesFileName: null,
        scheduleType: eventSettings.schedule?.type || 'none',
        scheduleContent: eventSettings.schedule?.content || '',
        scheduleLink: eventSettings.schedule?.link || '',
        scheduleFile: null,
        scheduleFileName: null,
        websiteType: eventSettings.website?.type || 'none',
        websiteLink: eventSettings.website?.link || ''
      });
    }
  }, [eventSettings]);

  const CONTENT_TYPES = {
    RULES: {
      none: "None",
      file: "Upload File (PDF, Image)", // Removed DOC
      link: "Link to Webpage",
      text: "Direct Text Entry"
    },
    SCHEDULE: {
      none: "None",
      file: "Upload File (PDF, Image)", // Removed DOC
      link: "Link to Webpage",
      text: "Direct Text Entry"
    },
    WEBSITE: {
      none: "None",
      link: "Link to Webpage"
    }
  };  

  const handleColorPickerOpen = (event, fieldName) => {
    setColorPickerAnchor(event.currentTarget);
    setActiveColorField(fieldName);
  };

  const handlePageVisibilityChange = (field, value) => {
    setLocalSettings(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleColorPickerClose = () => {
    setColorPickerAnchor(null);
    setActiveColorField(null);
  };

  const handleColorChange = (color) => {
    setFormData({
      ...formData,
      [activeColorField]: color.hex
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleFileUpload = async (event, type) => {
    const file = event.target.files?.[0];
    if (file) {
      const maxSize = 5 * 1024 * 1024; // 5MB limit
      if (file.size > maxSize) {
        toast.error("File size should not exceed 5MB");
        return;
      }
  
      if (type === 'rules') {
        setFormData({
          ...formData,
          rulesFile: file,
          rulesFileName: file.name,
          rulesType: 'file'
        });
      } else if (type === 'schedule') {
        setFormData({
          ...formData,
          scheduleFile: file,
          scheduleFileName: file.name,
          scheduleType: 'file'
        });
      }
    }
  };

  const handleLogoUpload = async (event) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        const resizedImage = await new Promise((resolve) => {
          Resizer.imageFileResizer(
            file,
            300,
            300,
            'PNG',
            80,
            0,
            (uri) => {
              resolve(uri);
            },
            'base64'
          );
        });
  
        // Convert base64 to blob
        const response = await fetch(resizedImage);
        const blob = await response.blob();
  
        setFormData({
          ...formData,
          logo: blob,
          logoName: file.name
        });
      } catch (error) {
        console.error('Error resizing image:', error);
        toast.error("Error resizing logo image");
      }
    }
  };
  
  const handleRemoveLogo = () => {
    setFormData({
      ...formData,
      logo: null,
      logoName: null
    });
  };

  const handleSelectChange = (e, contentType) => {
    const { value } = e.target;
    
    if (contentType === 'rules') {
      setFormData({
        ...formData,
        rulesType: value,
        rulesContent: '',
        rulesLink: '',
        rulesFile: null,
        rulesFileName: null
      });
    } else if (contentType === 'schedule') {
      setFormData({
        ...formData,
        scheduleType: value,
        scheduleContent: '',
        scheduleLink: '',
        scheduleFile: null,
        scheduleFileName: null
      });
    } else if (contentType === 'website') {
      setFormData({
        ...formData,
        websiteType: value,
        websiteLink: ''
      });
    }
  };

  const handleSaveSettings = async () => {
    setIsLoading(true);
    setSaveSuccess(false);
    setError(null);

    try {
      // Create FormData object for the API call
      const formDataToSend = new FormData();
      
      // Add basic form fields
      formDataToSend.append("username", username);
      formDataToSend.append("eventId", eventId);
      formDataToSend.append("eventTitle", formData.eventTitle);
      formDataToSend.append("homepageSubheadline", formData.homepageSubheadline);
      formDataToSend.append("backgroundColor", formData.backgroundColor);
      formDataToSend.append("headlineTextColor", formData.headlineTextColor);

      // Add the page visibility settings from localSettings
      formDataToSend.append("pageVisibility", JSON.stringify(localSettings.pageVisibility || {
        leaderboard: true,
        registration: true,
        sponsors: true,
        pots: true
      }));
      
      // Rules
      formDataToSend.append("rulesType", formData.rulesType);
      formDataToSend.append("rulesContent", formData.rulesContent || "");
      formDataToSend.append("rulesLink", formData.rulesLink || "");
      
      // Schedule
      formDataToSend.append("scheduleType", formData.scheduleType);
      formDataToSend.append("scheduleContent", formData.scheduleContent || "");
      formDataToSend.append("scheduleLink", formData.scheduleLink || "");
      
      // Website
      formDataToSend.append("websiteType", formData.websiteType);
      formDataToSend.append("websiteLink", formData.websiteLink || "");

      // Handle file uploads
      if (formData.logo) {
        formDataToSend.append("logo", formData.logo, formData.logoName);
      }

      if (formData.rulesType === 'file' && formData.rulesFile) {
        formDataToSend.append("rulesFile", formData.rulesFile, formData.rulesFileName);
      }

      if (formData.scheduleType === 'file' && formData.scheduleFile) {
        formDataToSend.append("scheduleFile", formData.scheduleFile, formData.scheduleFileName);
      }

      // Determine API URL based on environment
      const apiUrl = process.env.REACT_APP_NODE_ENV === "staging"
        ? process.env.REACT_APP_SERVER_URL_STAGING
        : process.env.REACT_APP_SERVER_URL_PRODUCTION;

      // Make the API call
      const response = await fetch(`${apiUrl}/api/admin_update_event_settings`, {
        method: "POST",
        body: formDataToSend,
      });

      const result = await response.json();

      if (response.ok && result.success) {
        setSaveSuccess(true);
        toast.success("Event settings updated successfully!");
        
        // Reload the page after a delay to reflect changes
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        setError(result.message || "An error occurred while updating settings");
        toast.error(`Error: ${result.message || "Unknown error"}`);
      }
    } catch (error) {
      console.error("Error updating event settings:", error);
      setError("An unexpected error occurred");
      toast.error("An error occurred while updating settings. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Preview component
  const EventPreview = () => (
    <Paper 
      elevation={1}
      sx={{ 
        p: 3, 
        mb: 4,
        bgcolor: formData.backgroundColor || '#87CEEB',
        color: formData.headlineTextColor || '#FFFFFF',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2
      }}
    >
      {formData.logo ? (
        <Box
          component="img"
          src={URL.createObjectURL(formData.logo)}
          alt="Event Logo"
          sx={{ 
            width: 100,
            height: 100,
            objectFit: 'contain'
          }}
        />
      ) : formData.logoUrl ? (
        <Box
          component="img"
          src={formData.logoUrl}
          alt="Event Logo"
          sx={{ 
            width: 100,
            height: 100,
            objectFit: 'contain'
          }}
        />
      ) : null}
      <Typography variant="h4" align="center" sx={{ fontWeight: 600 }}>
        {formData.eventTitle || 'Your Event Title'}
      </Typography>
      <Typography variant="body1" align="center">
        {formData.homepageSubheadline || 'Event subheadline will appear here'}
      </Typography>
    </Paper>
  );

  return (
    <Fade in={true} timeout={400}>
      <Box sx={{ maxWidth: '800px', mx: 'auto', p: 2 }}>
        {saveSuccess && (
          <Alert severity="success" sx={{ mb: 3 }}>
            Settings saved successfully!
          </Alert>
        )}
        
        {saveError && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {saveError}
          </Alert>
        )}
        
        <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
          Edit Event Website Appearance
        </Typography>
        
        <EventPreview />
        
        <Stack spacing={3}>
          {/* Event Title */}
          <TextField
            label="Event Title"
            name="eventTitle"
            value={formData.eventTitle}
            onChange={handleInputChange}
            fullWidth
            required
            placeholder="e.g. Legends Tournament 2024"
          />

          {/* Subheadline */}
          <TextField
            label="Event Subheadline"
            name="homepageSubheadline"
            value={formData.homepageSubheadline}
            onChange={handleInputChange}
            fullWidth
            required
            multiline
            rows={2}
            placeholder="Add a brief description or welcome message"
          />

          {/* Colors */}
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              label="Background Color"
              value={formData.backgroundColor}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Box
                      onClick={(e) => handleColorPickerOpen(e, 'backgroundColor')}
                      sx={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1
                      }}
                    >
                      <Box
                        sx={{
                          width: 24,
                          height: 24,
                          borderRadius: '4px',
                          bgcolor: formData.backgroundColor,
                          border: '2px solid #e0e0e0'
                        }}
                      />
                      <ColorLensIcon sx={{ color: 'action.active' }} />
                    </Box>
                  </InputAdornment>
                ),
                readOnly: true
              }}
              fullWidth
            />

            <TextField
              label="Text Color"
              value={formData.headlineTextColor}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Box
                      onClick={(e) => handleColorPickerOpen(e, 'headlineTextColor')}
                      sx={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1
                      }}
                    >
                      <Box
                        sx={{
                          width: 24,
                          height: 24,
                          borderRadius: '4px',
                          bgcolor: formData.headlineTextColor,
                          border: '2px solid #e0e0e0'
                        }}
                      />
                      <ColorLensIcon sx={{ color: 'action.active' }} />
                    </Box>
                  </InputAdornment>
                ),
                readOnly: true
              }}
              fullWidth
            />
          </Stack>

          {/* Logo Upload */}
          <Box>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Event Logo
            </Typography>
            <Box
              sx={{
                border: '2px dashed',
                borderColor: 'divider',
                borderRadius: 1,
                p: 3,
                textAlign: 'center',
                position: 'relative'
              }}
            >
              <input
                type="file"
                accept="image/*"
                onChange={handleLogoUpload}
                style={{ display: 'none' }}
                id="logo-upload"
              />
              {!formData.logo && !formData.logoUrl ? (
                <label htmlFor="logo-upload">
                  <Button
                    component="span"
                    variant="outlined"
                    startIcon={<InsertPhotoIcon />}
                  >
                    Upload Logo
                  </Button>
                </label>
              ) : formData.logo ? (
                <Box sx={{ position: 'relative', display: 'inline-block' }}>
                  <Box
                    component="img"
                    src={URL.createObjectURL(formData.logo)}
                    alt="Event Logo"
                    sx={{ 
                      width: 100,
                      height: 100,
                      objectFit: 'contain'
                    }}
                  />
                  <IconButton
                    onClick={handleRemoveLogo}
                    sx={{
                      position: 'absolute',
                      top: -8,
                      right: -8,
                      bgcolor: 'background.paper',
                      boxShadow: 1,
                      '&:hover': {
                        bgcolor: 'error.light',
                        color: 'white'
                      }
                    }}
                    size="small"
                  >
                    <DeleteOutlineIcon fontSize="small" />
                  </IconButton>
                </Box>
              ) : (
                <Box sx={{ position: 'relative', display: 'inline-block' }}>
                  <Box
                    component="img"
                    src={formData.logoUrl}
                    alt="Event Logo"
                    sx={{ 
                      width: 100,
                      height: 100,
                      objectFit: 'contain'
                    }}
                  />
                  <label htmlFor="logo-upload">
                    <IconButton
                      component="span"
                      sx={{
                        position: 'absolute',
                        top: -8,
                        right: -8,
                        bgcolor: 'background.paper',
                        boxShadow: 1
                      }}
                      size="small"
                    >
                      <InsertPhotoIcon fontSize="small" />
                    </IconButton>
                  </label>
                </Box>
              )}
            </Box>
          </Box>

          {/* Rules Section */}
          <FormControl fullWidth>
            <InputLabel>Rules Format</InputLabel>
            <Select
              value={formData.rulesType}
              onChange={(e) => handleSelectChange(e, 'rules')}
              label="Rules Format"
            >
              {Object.entries(CONTENT_TYPES.RULES).map(([value, label]) => (
                <MenuItem key={value} value={value}>{label}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {formData.rulesType === 'file' && (
            <Box>
              <input
                type="file"
                accept=".pdf,.png,.jpg,.jpeg" // Removed .doc,.docx
                onChange={(e) => handleFileUpload(e, 'rules')}
                style={{ display: 'none' }}
                id="rules-upload"
              />
              <label htmlFor="rules-upload">
                <Button
                  component="span"
                  variant="outlined"
                  startIcon={<AttachFileIcon />}
                  fullWidth
                >
                  {formData.rulesFileName || (eventSettings?.rules?.fileUrl ? 'Replace Rules File' : 'Upload Rules File')}
                </Button>
              </label>
              {formData.rulesFileName && (
                <FormHelperText>New file: {formData.rulesFileName}</FormHelperText>
              )}
              {!formData.rulesFileName && eventSettings?.rules?.fileUrl && (
                <FormHelperText>Current file: {eventSettings.rules.fileUrl.split('/').pop()}</FormHelperText>
              )}
            </Box>
          )}

          {formData.rulesType === 'link' && (
            <TextField
              label="Rules Link"
              name="rulesLink"
              value={formData.rulesLink}
              onChange={handleInputChange}
              fullWidth
              placeholder="https://"
            />
          )}

          {formData.rulesType === 'text' && (
            <TextField
              label="Rules Content"
              name="rulesContent"
              value={formData.rulesContent}
              onChange={handleInputChange}
              fullWidth
              multiline
              rows={4}
              placeholder="Enter your rules here..."
            />
          )}

          {/* Schedule Section */}
          <FormControl fullWidth>
            <InputLabel>Schedule Format</InputLabel>
            <Select
              value={formData.scheduleType}
              onChange={(e) => handleSelectChange(e, 'schedule')}
              label="Schedule Format"
            >
              {Object.entries(CONTENT_TYPES.SCHEDULE).map(([value, label]) => (
                <MenuItem key={value} value={value}>{label}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {formData.scheduleType === 'file' && (
            <Box>
              <input
                type="file"
                accept=".pdf,.png,.jpg,.jpeg" // Removed .doc,.docx
                onChange={(e) => handleFileUpload(e, 'schedule')}
                style={{ display: 'none' }}
                id="schedule-upload"
              />
              <label htmlFor="schedule-upload">
                <Button
                  component="span"
                  variant="outlined"
                  startIcon={<AttachFileIcon />}
                  fullWidth
                >
                  {formData.scheduleFileName || (eventSettings?.schedule?.fileUrl ? 'Replace Schedule File' : 'Upload Schedule File')}
                </Button>
              </label>
              {formData.scheduleFileName && (
                <FormHelperText>New file: {formData.scheduleFileName}</FormHelperText>
              )}
              {!formData.scheduleFileName && eventSettings?.schedule?.fileUrl && (
                <FormHelperText>Current file: {eventSettings.schedule.fileUrl.split('/').pop()}</FormHelperText>
              )}
            </Box>
          )}

          {formData.scheduleType === 'link' && (
            <TextField
              label="Schedule Link"
              name="scheduleLink"
              value={formData.scheduleLink}
              onChange={handleInputChange}
              fullWidth
              placeholder="https://"
            />
          )}

          {formData.scheduleType === 'text' && (
            <TextField
              label="Schedule Content"
              name="scheduleContent"
              value={formData.scheduleContent}
              onChange={handleInputChange}
              fullWidth
              multiline
              rows={4}
              placeholder="Enter your schedule here..."
            />
          )}

          {/* Website Section */}
          <FormControl fullWidth>
            <InputLabel>External Website</InputLabel>
            <Select
              value={formData.websiteType}
              onChange={(e) => handleSelectChange(e, 'website')}
              label="External Website"
            >
              {Object.entries(CONTENT_TYPES.WEBSITE).map(([value, label]) => (
                <MenuItem key={value} value={value}>{label}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {formData.websiteType === 'link' && (
            <TextField
              label="Website Link"
              name="websiteLink"
              value={formData.websiteLink}
              onChange={handleInputChange}
              fullWidth
              placeholder="https://"
            />
          )}

          <PageVisibilitySection 
            eventSettings={localSettings} 
            onChange={handlePageVisibilityChange} 
          />

          {/* Save Button */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveSettings}
            disabled={isLoading}
            sx={{ mt: 2, py: 1 }}
          >
            {isLoading ? <CircularProgress size={24} /> : "Save Settings"}
          </Button>
        </Stack>

        {/* Color Picker Popover */}
        <Popover
          open={Boolean(colorPickerAnchor)}
          anchorEl={colorPickerAnchor}
          onClose={handleColorPickerClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box sx={{ p: 1 }}>
            <SketchPicker
              color={activeColorField === 'backgroundColor' ? 
                formData.backgroundColor : 
                formData.headlineTextColor
              }
              onChangeComplete={handleColorChange}
            />
          </Box>
        </Popover>

      </Box>
    </Fade>
  );
};

export default EventSettingsTab;

