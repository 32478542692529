import React, { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  Stack,
  Alert,
  Fade,
  Chip,
  FormControlLabel,
  Checkbox,
  Divider,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { eventCost } from '../../../config/config';

// Define constants at the top of the file before using them
const STRIPE_PERCENTAGE_FEE = 2.9;
const STRIPE_FIXED_FEE = 0.30;
const DESTINATION_PERCENTAGE_FEE = 0.5;

const FEATURE_OPTIONS = {
  NOT_NEEDED: 'notNeeded',
  CUSTOMER_PAYS: 'customerPays',
  I_PAY: 'iPay'
};

const CheckoutStep = ({ formData, onCheckout, isValid }) => {
  const [agreedToTerms, setAgreedToTerms] = useState(true);
  const theme = useTheme();
  
  const StepSummary = ({ title, isValid, summary, error }) => (
    <Paper sx={{ p: 2, mb: 2 }}>
      <Stack direction="row" alignItems="center" spacing={2}>
        {isValid ? (
          <CheckCircleIcon color="success" />
        ) : (
          <ErrorOutlineIcon color="error" />
        )}
        <Box sx={{ flex: 1 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
            {title}
          </Typography>
          <Typography color="text.secondary" variant="body2">
            {isValid ? summary : error}
          </Typography>
        </Box>
      </Stack>
    </Paper>
  );

  const steps = [
    {
      title: "Features",
      isValid: true, // Already validated as a previous step
      summary: `${formData.packageType === 'standard' ? 'Standard package' : 'Revenue Share package'}, ` +
               `${formData.features.onlineTeamRegistration !== FEATURE_OPTIONS.NOT_NEEDED ? 'with' : 'without'} team registration, ` +
               `${formData.features.onlineSponsorRegistration !== FEATURE_OPTIONS.NOT_NEEDED ? 'with' : 'without'} sponsor registration, ` +
               `${formData.features.fishPots !== FEATURE_OPTIONS.NOT_NEEDED ? 'with' : 'without'} fish pots`,
      error: "Please complete feature selection"
    },
    {
      title: "Basic Information",
      isValid: formData.basicInfo.title && formData.basicInfo.startDate && formData.basicInfo.endDate,
      summary: `${formData.basicInfo.title}, ${formData.basicInfo.startDate.format ? formData.basicInfo.startDate.format('MM/DD/YYYY') : new Date(formData.basicInfo.startDate).toLocaleDateString()} - ${formData.basicInfo.endDate.format ? formData.basicInfo.endDate.format('MM/DD/YYYY') : new Date(formData.basicInfo.endDate).toLocaleDateString()}`,
      error: "Please complete event details"
    },
    {
      title: "Team Registration",
      isValid: formData.features.onlineTeamRegistration === FEATURE_OPTIONS.NOT_NEEDED || 
        (formData.registration.registrationDate && formData.registration.divisions && formData.registration.divisions.length > 0),
      summary: formData.features.onlineTeamRegistration !== FEATURE_OPTIONS.NOT_NEEDED ?
        `Registration closes ${formData.registration.registrationDate?.format ? formData.registration.registrationDate.format('MM/DD/YYYY') : (formData.registration.registrationDate ? new Date(formData.registration.registrationDate).toLocaleDateString() : 'N/A')}, ${formData.registration.divisions?.length || 0} division(s) configured` :
        "Online team registration not enabled",
      error: "Please complete team registration setup"
    },
    {
      title: "Sponsor Registration",
      isValid: formData.features.onlineSponsorRegistration === FEATURE_OPTIONS.NOT_NEEDED || 
        formData.sponsors.allowCustomAmount || 
        (formData.sponsors.tiers && formData.sponsors.tiers.length > 0),
      summary: formData.features.onlineSponsorRegistration !== FEATURE_OPTIONS.NOT_NEEDED ? 
        `${formData.sponsors.tiers?.length || 0} sponsor tiers defined, custom amounts ${formData.sponsors.allowCustomAmount ? 'allowed' : 'not allowed'}` :
        "Online sponsor registration not enabled",
      error: "Please complete sponsor registration setup"
    },
    {
      title: "Species",
      isValid: formData.species.speciesList?.length > 0,
      summary: `${formData.species.speciesList?.length || 0} species defined`,
      error: "Please add at least one species"
    },
    {
      title: "Scoring Categories",
      isValid: formData.categories.categoryList?.length > 0,
      summary: `${formData.categories.categoryList?.length || 0} scoring categories defined`,
      error: "Please set up scoring categories"
    },
    {
      title: "Fish Pots",
      isValid: formData.features?.fishPots === FEATURE_OPTIONS.NOT_NEEDED || 
        (formData.pots.potList?.length > 0 && formData.pots.tournamentPotCut >= 0),
      summary: formData.features?.fishPots !== FEATURE_OPTIONS.NOT_NEEDED ? 
        `${formData.pots.potList?.reduce((total, pot) => total + pot.values.length, 0) || 0} total sub-pots defined` : 
        "Fish pots not enabled",
      error: "Please complete fish pots setup"
    }
  ];

  // Filter steps to show only relevant ones
  const filteredSteps = steps.filter(step => {
    if (step.title === "Team Registration" && formData.features.onlineTeamRegistration === FEATURE_OPTIONS.NOT_NEEDED) {
      return false;
    }
    if (step.title === "Sponsor Registration" && formData.features.onlineSponsorRegistration === FEATURE_OPTIONS.NOT_NEEDED) {
      return false;
    }
    if (step.title === "Fish Pots" && formData.features.fishPots === FEATURE_OPTIONS.NOT_NEEDED) {
      return false;
    }
    return true;
  });

  const hasInvalidSteps = filteredSteps.some(step => !step.isValid);

  // Calculate platform fees (our fee)
  const calculatePlatformFee = (basePrice) => {
    let platformFee;
    let feeDescription;
  
    if (basePrice < 50) {
      platformFee = 3;
      feeDescription = 'Flat $3 fee';
    } else if (basePrice <= 100) {
      platformFee = 5;
      feeDescription = 'Flat $5 fee';
    } else if (basePrice <= 200) {
      platformFee = basePrice * 0.05;
      feeDescription = '5% fee';
    } else {
      platformFee = basePrice * 0.04;
      feeDescription = '4% fee';
    }
  
    return {
      platformFee,
      feeDescription
    };
  };

  // Calculate the complete fee structure
  const calculateAllFees = (basePrice, whoPays) => {
    // Calculate our platform fee
    const { platformFee, feeDescription } = calculatePlatformFee(basePrice);
    
    // Calculate total Stripe fees
    const stripeFeePercentage = STRIPE_PERCENTAGE_FEE + DESTINATION_PERCENTAGE_FEE;
    const stripeFeeFixed = STRIPE_FIXED_FEE;
    
    if (whoPays === FEATURE_OPTIONS.CUSTOMER_PAYS) {
      // Customer pays the platform fee and all Stripe fees
      const subtotal = basePrice + platformFee;
      const stripeFee = (subtotal * stripeFeePercentage / 100) + stripeFeeFixed;
      const customerTotal = subtotal + stripeFee;
      
      return {
        basePrice,
        platformFee,
        feeDescription,
        stripeFee,
        stripeFeePercentage,
        stripeFeeFixed,
        customerTotal,
        organizerReceives: basePrice,
        organizerPays: 0
      };
    } else {
      // Organizer pays all fees (platform fee and Stripe fees)
      const customerTotal = basePrice;
      const stripeFee = (customerTotal * stripeFeePercentage / 100) + stripeFeeFixed;
      const organizerPays = platformFee + stripeFee;
      const organizerReceives = basePrice - organizerPays;
      
      return {
        basePrice,
        platformFee,
        feeDescription,
        stripeFee,
        stripeFeePercentage,
        stripeFeeFixed,
        customerTotal,
        organizerReceives,
        organizerPays
      };
    }
  };

  const getSampleRegistrationFee = () => {
    if (formData.registration.registrationPrice) {
      return formData.registration.registrationPrice;
    }
    return 100; // Default sample amount if no registration price
  };

  const getSampleSponsorTier = () => {
    if (formData.sponsors.tiers && formData.sponsors.tiers.length > 0) {
      return formData.sponsors.tiers[0].amount;
    }
    return 500; // Default sample amount if no tiers
  };

  const getSamplePotFee = () => {
    if (formData.pots.potList && formData.pots.potList.length > 0 && formData.pots.potList[0].values.length > 0) {
      return formData.pots.potList[0].values[0];
    }
    return 100; // Default sample amount if no pots
  };

  const CompactFeeCalculation = ({ title, featureType, amount, whoPays }) => {
    if (!amount) return null;
    
    const basePrice = parseFloat(amount);
    const { 
      platformFee, 
      feeDescription, 
      customerTotal, 
      stripeFee,
      stripeFeePercentage,
      stripeFeeFixed, 
      organizerReceives,
      organizerPays
    } = calculateAllFees(basePrice, whoPays);
    
    // Separate Stripe percentage components for display
    const STRIPE_PERCENTAGE = STRIPE_PERCENTAGE_FEE;
    const DESTINATION_PERCENTAGE = DESTINATION_PERCENTAGE_FEE;
    
    // Determine who is paying which fees based on whoPays option
    let whoPaysFees;
    if (whoPays === FEATURE_OPTIONS.CUSTOMER_PAYS) {
      whoPaysFees = featureType === 'Registration' ? "Team pays fees" : "Sponsor pays fees";
    } else {
      whoPaysFees = "You pay fees";
    }
    
    return (
      <Accordion sx={{ mb: 2 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle2" fontWeight="medium">
            {title} <Typography component="span" color="text.secondary" sx={{ fontStyle: 'italic' }}>({whoPaysFees})</Typography>
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ pt: 0 }}>
          {whoPays === FEATURE_OPTIONS.CUSTOMER_PAYS ? (
            <>
              <Typography variant="subtitle2" color="primary.dark" sx={{ mb: 1, fontWeight: 'bold' }}>
                What the {featureType === 'Registration' ? 'Team' : 'Sponsor'} pays:
              </Typography>
              
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Typography variant="body2">Base {featureType} Fee</Typography>
                <Typography variant="body2">${basePrice.toFixed(2)}</Typography>
              </Box>
              
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Typography variant="body2">Our Platform Fee ({feeDescription})</Typography>
                <Typography variant="body2">${platformFee.toFixed(2)}</Typography>
              </Box>
              
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Typography variant="body2">Stripe Processing Fee ({STRIPE_PERCENTAGE_FEE}% + ${STRIPE_FIXED_FEE.toFixed(2)}) + Destination Fee ({DESTINATION_PERCENTAGE_FEE}%)</Typography>
                <Typography variant="body2">${stripeFee.toFixed(2)}</Typography>
              </Box>
              
              <Divider sx={{ my: 1 }} />
              
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <Typography variant="body2" fontWeight="bold">Total {featureType === 'Registration' ? 'Team' : 'Sponsor'} Payment</Typography>
                <Typography variant="body2" fontWeight="bold">${customerTotal.toFixed(2)}</Typography>
              </Box>
              
              <Typography variant="subtitle2" color="success.dark" sx={{ mb: 1, fontWeight: 'bold' }}>
                What you receive:
              </Typography>
              
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Typography variant="body2">Base Fee</Typography>
                <Typography variant="body2">${basePrice.toFixed(2)}</Typography>
              </Box>
              
              <Divider sx={{ my: 1 }} />
              
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Typography variant="body2" fontWeight="bold">Your Net Revenue</Typography>
                <Typography variant="body2" fontWeight="bold" color="success.main">${organizerReceives.toFixed(2)}</Typography>
              </Box>
            </>
          ) : (
            <>
              <Typography variant="subtitle2" color="primary.dark" sx={{ mb: 1, fontWeight: 'bold' }}>
                What the {featureType === 'Registration' ? 'Team' : 'Sponsor'} pays:
              </Typography>
              
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Typography variant="body2">Base {featureType} Fee (no added fees)</Typography>
                <Typography variant="body2">${basePrice.toFixed(2)}</Typography>
              </Box>
              
              <Divider sx={{ my: 1 }} />
              
              <Typography variant="subtitle2" color="warning.dark" sx={{ mb: 1, fontWeight: 'bold' }}>
                Your costs:
              </Typography>
              
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Typography variant="body2">Stripe Processing Fee ({STRIPE_PERCENTAGE}% + ${STRIPE_FIXED_FEE.toFixed(2)}) + Destination Fee ({DESTINATION_PERCENTAGE}%)</Typography>
                <Typography variant="body2" color="error.main">-${stripeFee.toFixed(2)}</Typography>
              </Box>
              
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Typography variant="body2">Our Platform Fee ({feeDescription})</Typography>
                <Typography variant="body2" color="error.main">-${platformFee.toFixed(2)}</Typography>
              </Box>
              
              <Divider sx={{ my: 1 }} />
              
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Typography variant="body2" fontWeight="bold">Your Net Revenue</Typography>
                <Typography variant="body2" fontWeight="bold" color="success.main">${organizerReceives.toFixed(2)}</Typography>
              </Box>
            </>
          )}
        </AccordionDetails>
      </Accordion>
    );
  };

  const PotFeeCalculation = ({ title, amount, whoPays, tournamentCut }) => {
    if (!amount) return null;
    
    const basePrice = parseFloat(amount);
    const tournamentCutPercentage = tournamentCut || 10; // Default to 10% if not provided
    
    // Calculate platform fee based on pot value
    let platformFee;
    let feeDescription;
    
    if (basePrice < 50) {
      platformFee = 3;
      feeDescription = 'Flat $3 fee';
    } else if (basePrice <= 100) {
      platformFee = 5;
      feeDescription = 'Flat $5 fee';
    } else if (basePrice <= 200) {
      platformFee = basePrice * 0.05; // 5%
      feeDescription = '5% fee';
    } else {
      platformFee = basePrice * 0.04; // 4%
      feeDescription = '4% fee';
    }
    
    // Calculate tournament cut amount
    const tournamentCutAmount = (basePrice * tournamentCutPercentage) / 100;
    
    // Calculate pot payout (amount that goes to winner)
    const potPayout = basePrice - tournamentCutAmount;
    
    // Calculate total Stripe fees
    const stripeFeePercentage = STRIPE_PERCENTAGE_FEE + DESTINATION_PERCENTAGE_FEE;
    const stripeFeeFixed = STRIPE_FIXED_FEE;
    
    // Determine who is paying which fees based on whoPays option
    const whoPaysFees = whoPays === FEATURE_OPTIONS.CUSTOMER_PAYS ? 
      "Team pays fees" : 
      "You pay fees";
    
    if (whoPays === FEATURE_OPTIONS.CUSTOMER_PAYS) {
      // Customer pays the platform fee
      const customerTotal = basePrice + platformFee;
      const stripeFee = (customerTotal * stripeFeePercentage / 100) + stripeFeeFixed;
      const totalParticipantPays = customerTotal + stripeFee;
      
      // Organizer receives tournament cut minus stripe fees (proportional)
      const stripeFeeForOrganizer = (tournamentCutAmount / customerTotal) * stripeFee;
      const organizerReceives = tournamentCutAmount - stripeFeeForOrganizer;
      
      return (
        <Accordion sx={{ mb: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle2" fontWeight="medium">
              {title} <Typography component="span" color="text.secondary" sx={{ fontStyle: 'italic' }}>({whoPaysFees})</Typography>
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ pt: 0 }}>
            <Typography variant="subtitle2" color="primary.dark" sx={{ mb: 1, fontWeight: 'bold' }}>
              What the team pays:
            </Typography>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body2">Base Pot Entry Fee</Typography>
              <Typography variant="body2">${basePrice.toFixed(2)}</Typography>
            </Box>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body2">Our Platform Fee ({feeDescription})</Typography>
              <Typography variant="body2">${platformFee.toFixed(2)}</Typography>
            </Box>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body2">Stripe Processing Fee ({STRIPE_PERCENTAGE_FEE}% + ${STRIPE_FIXED_FEE.toFixed(2)}) + Destination Fee ({DESTINATION_PERCENTAGE_FEE}%)</Typography>
              <Typography variant="body2">${stripeFee.toFixed(2)}</Typography>
            </Box>
            
            <Divider sx={{ my: 1 }} />
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              <Typography variant="body2" fontWeight="bold">Total Participant Payment</Typography>
              <Typography variant="body2" fontWeight="bold">${totalParticipantPays.toFixed(2)}</Typography>
            </Box>
            
            <Typography variant="subtitle2" color="warning.dark" sx={{ mb: 1, fontWeight: 'bold' }}>
              How the pot is distributed:
            </Typography>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body2">Your Tournament Cut ({tournamentCutPercentage}%)</Typography>
              <Typography variant="body2">${tournamentCutAmount.toFixed(2)}</Typography>
            </Box>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body2">Pot Payout to Winner ({100-tournamentCutPercentage}%)</Typography>
              <Typography variant="body2">${potPayout.toFixed(2)}</Typography>
            </Box>
            
            <Divider sx={{ my: 1 }} />
            
            <Typography variant="subtitle2" color="success.dark" sx={{ mb: 1, fontWeight: 'bold' }}>
              Your revenue from tournament cut:
            </Typography>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body2">Tournament Cut</Typography>
              <Typography variant="body2">${tournamentCutAmount.toFixed(2)}</Typography>
            </Box>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body2">Less: Stripe Fee (proportional)</Typography>
              <Typography variant="body2" color="error.main">-${stripeFeeForOrganizer.toFixed(2)}</Typography>
            </Box>
            
            <Divider sx={{ my: 1 }} />
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body2" fontWeight="bold">Your Net Revenue</Typography>
              <Typography variant="body2" fontWeight="bold" color="success.main">${organizerReceives.toFixed(2)}</Typography>
            </Box>
          </AccordionDetails>
        </Accordion>
      );
    } else {
      // Organizer pays the platform fee
      const customerTotal = basePrice;
      const stripeFee = (customerTotal * stripeFeePercentage / 100) + stripeFeeFixed;
      
      // Organizer pays platform fee from their cut
      let organizerReceives = tournamentCutAmount - stripeFee - platformFee;
      const isCutSufficient = organizerReceives > 0;
      
      // Safety check - if fees exceed the cut, the organizer gets nothing
      if (!isCutSufficient) {
        organizerReceives = 0;
      }
      
      return (
        <Accordion sx={{ mb: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle2" fontWeight="medium">
              {title} <Typography component="span" color="text.secondary" sx={{ fontStyle: 'italic' }}>({whoPaysFees})</Typography>
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ pt: 0 }}>
            <Typography variant="subtitle2" color="primary.dark" sx={{ mb: 1, fontWeight: 'bold' }}>
              What the team pays:
            </Typography>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body2">Base Pot Entry Fee (no added fees)</Typography>
              <Typography variant="body2">${basePrice.toFixed(2)}</Typography>
            </Box>
            
            <Divider sx={{ my: 1 }} />
            
            <Typography variant="subtitle2" color="warning.dark" sx={{ mb: 1, fontWeight: 'bold' }}>
              How the pot is distributed:
            </Typography>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body2">Your Tournament Cut ({tournamentCutPercentage}%)</Typography>
              <Typography variant="body2">${tournamentCutAmount.toFixed(2)}</Typography>
            </Box>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body2">Pot Payout to Winner ({100-tournamentCutPercentage}%)</Typography>
              <Typography variant="body2">${potPayout.toFixed(2)}</Typography>
            </Box>
            
            <Divider sx={{ my: 1 }} />
            
            <Typography variant="subtitle2" color="warning.dark" sx={{ mb: 1, fontWeight: 'bold' }}>
              Your costs:
            </Typography>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body2">Stripe Processing Fee ({STRIPE_PERCENTAGE_FEE}% + ${STRIPE_FIXED_FEE.toFixed(2)}) + Destination Fee ({DESTINATION_PERCENTAGE_FEE}%)</Typography>
              <Typography variant="body2" color="error.main">-${stripeFee.toFixed(2)}</Typography>
            </Box>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body2">Our Platform Fee ({feeDescription})</Typography>
              <Typography variant="body2" color="error.main">-${platformFee.toFixed(2)}</Typography>
            </Box>
            
            <Divider sx={{ my: 1 }} />
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body2" fontWeight="bold">Your Net Revenue</Typography>
              <Typography variant="body2" fontWeight="bold" color={isCutSufficient ? "success.main" : "error.main"}>
                {isCutSufficient ? 
                  `$${organizerReceives.toFixed(2)}` : 
                  '$0.00 (fees exceed cut)'}
              </Typography>
            </Box>
            
            {!isCutSufficient && (
              <Alert severity="warning" sx={{ mt: 1, fontSize: '0.8rem' }}>
                Warning: Estimated fees exceed your tournament cut for this pot. Consider increasing your tournament cut percentage.
              </Alert>
            )}
          </AccordionDetails>
        </Accordion>
      );
    }
  };

  const renderWhiteLabelBanner = () => (
    <Paper
      sx={{
        p: 3, 
        mb: 4,
        background: `linear-gradient(45deg, ${theme.palette.primary.lighter} 0%, ${theme.palette.background.paper} 100%)`,
        borderLeft: `4px solid ${theme.palette.primary.main}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <Box>
        <Typography variant="h6" sx={{ fontWeight: 600, color: theme.palette.primary.main }}>
          Want us to do this for you?
        </Typography>
        <Typography variant="body1">
          Ask us about upgrading to our premium white label service.
          <br />
          <Typography component="span" sx={{ fontWeight: 600 }}>
            Contact us at support@customtournamentsolutions.com
          </Typography>
        </Typography>
      </Box>
      <Button 
        variant="contained" 
        color="primary"
        onClick={() => window.open('mailto:support@customtournamentsolutions.com?subject=White Label Service Inquiry')}
      >
        Learn More
      </Button>
    </Paper>
  );

  // Calculate total price based on package type
  const calculateTotalPrice = () => {
    return formData.packageType === 'standard' ? 89 : 0;
  };

  return (
    <Fade in timeout={400}>
      <Box>
        {hasInvalidSteps && (
          <Alert severity="error" sx={{ mb: 3 }}>
            Please fix the errors below before proceeding to checkout
          </Alert>
        )}

        {/* White Label Banner */}
        <Paper
          sx={{
            p: 3, 
            mb: 4,
            background: `linear-gradient(45deg, ${theme.palette.primary.lighter} 0%, ${theme.palette.background.paper} 100%)`,
            borderLeft: `4px solid ${theme.palette.primary.main}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Box>
            <Typography variant="h6" sx={{ fontWeight: 600, color: theme.palette.primary.main }}>
              Want us to do this for you?
            </Typography>
            <Typography variant="body1">
              Ask us about upgrading to our premium white label service.
              <br />
              <Typography component="span" sx={{ fontWeight: 600 }}>
                Contact us at support@customtournamentsolutions.com
              </Typography>
            </Typography>
          </Box>
          <Button 
            variant="contained" 
            color="primary"
            onClick={() => window.open('mailto:support@customtournamentsolutions.com?subject=White Label Service Inquiry')}
          >
            Learn More
          </Button>
        </Paper>

        <Paper sx={{ p: 3, mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            Event Summary
          </Typography>
          
          {filteredSteps.map((step, index) => (
            <StepSummary key={index} {...step} />
          ))}
        </Paper>

        <Paper sx={{ p: 3 }}>
          <Typography variant="h6" gutterBottom>
            Payment Summary
          </Typography>
          
          <Stack spacing={2}>
            {/* Event Package Cost */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>
                {formData.packageType === 'standard' ? 'Standard Event Package' : 'Revenue Share Event Package'}
              </Typography>
              <Typography>${formData.packageType === 'standard' ? 89 : 0}</Typography>
            </Box>

            {/* Package Description */}
            <Alert severity="info" sx={{ mb: 2 }}>
              <Typography variant="body2">
                {formData.packageType === 'standard' 
                  ? 'Your Standard Package includes a one-time $89 fee without online registration features. Ideal for simple tournaments where you manually add participants.' 
                  : 'Your Revenue Share Package has no upfront cost. It includes online registration with small transaction fees applied to registrations. All transaction fees are handled as specified in your feature settings.'}
              </Typography>
            </Alert>

            {/* Fees Summary */}
            <Box>
              <Typography variant="subtitle1" sx={{ mb: 2 }}>
                Transaction Fee Samples
              </Typography>
              
              {/* Display compact fee calculations */}
              {formData.features.onlineTeamRegistration !== FEATURE_OPTIONS.NOT_NEEDED && (
                <CompactFeeCalculation 
                  title="Team Registration" 
                  featureType="Registration"
                  amount={getSampleRegistrationFee()}
                  whoPays={formData.features.onlineTeamRegistration}
                />
              )}

              {formData.features.onlineSponsorRegistration !== FEATURE_OPTIONS.NOT_NEEDED && (
                <CompactFeeCalculation 
                  title="Sponsor Registration" 
                  featureType="Sponsorship"
                  amount={getSampleSponsorTier()}
                  whoPays={formData.features.onlineSponsorRegistration}
                />
              )}

              {formData.features.fishPots !== FEATURE_OPTIONS.NOT_NEEDED && (
                <PotFeeCalculation 
                  title="Fish Pot" 
                  amount={getSamplePotFee()}
                  whoPays={formData.features.fishPots}
                  tournamentCut={formData.pots.tournamentPotCut}
                />
              )}
              
              <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 1, fontStyle: 'italic' }}>
                Note: Stripe's processing fee (2.9% + $0.30) plus a 0.5% destination fee is applied to all transactions.
              </Typography>
            </Box>
            
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between',
              borderTop: '1px solid #eee',
              pt: 2,
              mt: 2
            }}>
              <Typography variant="h6">Total Due Now</Typography>
              <Typography variant="h6">
                ${formData.packageType === 'standard' ? 89 : 0}
              </Typography>
            </Box>
          </Stack>
        </Paper>

        {/* Terms and Conditions */}
        <Paper sx={{ p: 3, mt: 3 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={agreedToTerms}
                onChange={(e) => setAgreedToTerms(e.target.checked)}
                color="primary"
              />
            }
            label={
              <Typography variant="body2" color="text.secondary">
                I agree that Custom Tournament Solutions may collect and use tournament-related information, 
                including contact details, for service updates and promotional purposes. This helps us improve 
                our platform and keep you informed about new features and events.
              </Typography>
            }
          />
        </Paper>

        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Button
            variant="contained"
            size="large"
            onClick={onCheckout}
            disabled={hasInvalidSteps || !agreedToTerms}
          >
            Proceed to Checkout
          </Button>
        </Box>
      </Box>
    </Fade>
  );

}

export default CheckoutStep;

