import React from 'react';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const DocumentViewer = ({ open, onClose, documentUrl, documentType, title }) => {
  const getFileExtension = (url) => {
    const parts = url.split('.');
    return parts[parts.length - 1].toLowerCase();
  };

  // Change: Simplify the renderDocument function to remove Word document handling
  const renderDocument = () => {
    const extension = getFileExtension(documentUrl);

    switch (extension) {
      case 'pdf':
        return (
          <iframe
            src={`${documentUrl}#toolbar=0`}
            title={title}
            width="100%"
            height="600px"
            style={{ border: 'none' }}
          />
        );
      case 'jpg':
      case 'jpeg':
      case 'png':
        return (
          <img
            src={documentUrl}
            alt={title}
            style={{ maxWidth: '100%', height: 'auto' }}
          />
        );
      default:
        return (
          <div className="p-4 text-center">
            <p>Unable to preview this file type.</p>
            <a
              href={documentUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:text-blue-700 underline mt-2 inline-block"
            >
              Download File
            </a>
          </div>
        );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
    >
      <DialogContent className="relative">
        <IconButton
          onClick={onClose}
          className="absolute right-2 top-2 z-10"
        >
          <CloseIcon />
        </IconButton>
        <div className="mt-6">
          {documentUrl ? renderDocument() : (
            <div className="p-4 text-center">No document available</div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DocumentViewer;

