import React, { useState, useEffect } from 'react';
import { Box, InputLabel, FormControl, Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, TextField, CircularProgress, Autocomplete, Grid } from "@mui/material";
import Resizer from 'react-image-file-resizer';
import defaultNoImage from '../../images/defaultNoImage.png';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useMediaQuery from '@mui/material/useMediaQuery';

const EditCatchModal = (props) => {
  const [formData, setFormData] = useState({});
  const [speciesConfig, setSpeciesConfig] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [catchPhoto, setCatchPhoto] = useState(null);
  const [catchPhotoName, setCatchPhotoName] = useState("");
  const [photoDeleted, setPhotoDeleted] = useState(false);
  const isMobile = useMediaQuery('(max-width:650px)');
  const [eventStartDate, setEventStartDate] = useState(null);
  const [eventEndDate, setEventEndDate] = useState(null);
  

  useEffect(() => {

    if (props.startDate) {
      let startTimestamp;
      if (typeof props.startDate === 'string') {
        startTimestamp = parseInt(props.startDate, 10);
      } else if (typeof props.startDate === 'number') {
        startTimestamp = props.startDate;
      }
      if (!isNaN(startTimestamp)) {
        setEventStartDate(dayjs(startTimestamp));
        console.log('Set event start date:', dayjs(startTimestamp).format('MM/DD/YYYY hh:mm A'));
      }
    }
    
    if (props.endDate) {
      let endTimestamp;
      if (typeof props.endDate === 'string') {
        endTimestamp = parseInt(props.endDate, 10);
      } else if (typeof props.endDate === 'number') {
        endTimestamp = props.endDate;
      }
      if (!isNaN(endTimestamp)) {
        setEventEndDate(dayjs(endTimestamp));
        console.log('Set event end date:', dayjs(endTimestamp).format('MM/DD/YYYY hh:mm A'));
      }
    }

    if (props.editInfo) {
      initializeFormData();
      findSpeciesConfig();
    }
  }, [props.editInfo, props.eventSettings, props.startDate, props.endDate]);

  useEffect(() => {
    if (speciesConfig && formData.species) {
      // Recalculate points based on current config
      setFormData(prev => ({
        ...prev,
        points: calculatePoints(prev)
      }));
    }
  }, [speciesConfig]);

  const initializeFormData = () => {
    const initialData = {
      species: props.editInfo.species,
      dateTime: props.editInfo.dateTime,
      length: props.editInfo.length,
      girth: props.editInfo.girth,
      weight: props.editInfo.weight,
      teamName: props.editInfo.teamName,
      "Catch photo": props.editInfo["Catch photo"] || null,
    };
    
    // Wait for speciesConfig before calculating points
    // (this will need to be adjusted based on the component flow)
    if (speciesConfig) {
      initialData.points = calculatePoints(initialData);
    } else {
      initialData.points = props.editInfo.points;
    }
    
    setFormData(initialData);
    setPhotoDeleted(false);
    setIsLoading(false);
  };

  const validateDateTime = (dateTime) => {
    // First, ensure dateTime is a valid date object or timestamp
    let catchDate;
    
    if (dateTime instanceof Date) {
      catchDate = dateTime.getTime();
    } else if (typeof dateTime === 'string') {
      catchDate = new Date(dateTime).getTime();
    } else if (typeof dateTime === 'number') {
      catchDate = dateTime;
    } else {
      // If it's none of the above, it's invalid
      return {
        isValid: false,
        errorMessage: "Invalid date format"
      };
    }
    
    // Now handle the start and end dates from props
    // These might be strings, numbers, or Date objects
    let startDate, endDate;
    
    // Handle start date
    if (typeof props.startDate === 'string') {
      // If it's a numeric string (timestamp), convert directly to number
      if (!isNaN(props.startDate)) {
        startDate = parseInt(props.startDate, 10);
      } else {
        // Otherwise parse as date string
        startDate = new Date(props.startDate).getTime();
      }
    } else if (props.startDate instanceof Date) {
      startDate = props.startDate.getTime();
    } else if (typeof props.startDate === 'number') {
      startDate = props.startDate;
    } else {
      // Default to a very old date if undefined/invalid
      startDate = 0;
    }
    
    // Handle end date
    if (typeof props.endDate === 'string') {
      // If it's a numeric string (timestamp), convert directly to number
      if (!isNaN(props.endDate)) {
        endDate = parseInt(props.endDate, 10);
      } else {
        // Otherwise parse as date string
        endDate = new Date(props.endDate).getTime();
      }
    } else if (props.endDate instanceof Date) {
      endDate = props.endDate.getTime();
    } else if (typeof props.endDate === 'number') {
      endDate = props.endDate;
    } else {
      // Default to a far future date if undefined/invalid
      endDate = 32503680000000; // Year 3000
    }
    
    // Now do the comparisons with proper error handling
    if (isNaN(catchDate)) {
      return {
        isValid: false,
        errorMessage: "Please select a valid date and time"
      };
    }
    
    if (isNaN(startDate) || isNaN(endDate)) {
      console.error("Event start or end date is invalid:", { startDate: props.startDate, endDate: props.endDate });
      return {
        isValid: true, // Allow it to pass if we can't validate properly
        errorMessage: ""
      };
    }
    
    // Check if catch date is within event period
    if (catchDate < startDate) {
      return {
        isValid: false,
        errorMessage: "Catch date cannot be before the event start date"
      };
    }
    
    if (catchDate > endDate) {
      return {
        isValid: false,
        errorMessage: "Catch date cannot be after the event end date"
      };
    }

    return {
      isValid: true,
      errorMessage: ""
    };
  };

  const handleCatchPhotoUpload = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const fileType = file.type.split("/")[1];
      const fileName = file.name;
  
      setCatchPhotoName(fileName);
      setPhotoDeleted(false);
  
      Resizer.imageFileResizer(
        file,
        300,
        300,
        fileType.toUpperCase(),
        80,
        0,
        (uri) => {
          fetch(uri)
            .then((res) => res.blob())
            .then((blob) => {
              const resizedBlob = new Blob([blob], { type: file.type });
              setCatchPhoto(resizedBlob);
              // Create a temporary URL for preview
              const blobUrl = URL.createObjectURL(resizedBlob);
              setFormData(prev => ({
                ...prev,
                "Catch photo": blobUrl
              }));
            })
            .catch((error) => {
              console.error("Error resizing image:", error);
              toast.error("Error resizing image.");
            });
        },
        "base64"
      );
    }
  };

  const handleRemoveCatchPhoto = () => {
    setCatchPhoto(null);
    setCatchPhotoName("");
    setPhotoDeleted(true);
    setFormData(prev => ({
      ...prev,
      "Catch photo": null
    }));
    
    // Clear the file input
    const photoInput = document.getElementById('catch-photo-upload');
    if (photoInput) photoInput.value = "";
  };

  const findSpeciesConfig = () => {
    console.log("Finding species config for:", props.editInfo?.species);
    console.log("Event settings structure:", props.eventSettings);
    
    // Check for direct speciesList property first (which appears to be the actual structure used)
    if (props.eventSettings?.speciesList && props.editInfo?.species) {
      console.log("Looking in root level speciesList");
      const matchedSpecies = props.eventSettings.speciesList.find(
        entry => entry.species === props.editInfo.species
      );
      
      if (matchedSpecies) {
        console.log("Found species config at root level:", matchedSpecies);
        setSpeciesConfig(matchedSpecies);
        return;
      }
    }
    
    // Fallback to nested structure
    if (props.eventSettings?.species?.speciesList && props.editInfo?.species) {
      console.log("Looking in nested species.speciesList");
      const matchedSpecies = props.eventSettings.species.speciesList.find(
        entry => entry.species === props.editInfo.species
      );
      
      if (matchedSpecies) {
        console.log("Found species config in nested structure:", matchedSpecies);
        setSpeciesConfig(matchedSpecies);
        return;
      }
    }
    
    console.warn(`Species configuration not found for: ${props.editInfo?.species}`);
    // Fallback to an empty config object to prevent errors
    setSpeciesConfig({
      scoringMethod: "length",
      collectTime: true,
      timeRequired: false,
      collectWeight: true,
      weightRequired: false,
      collectLength: true,
      lengthRequired: true,
      collectGirth: false,
      girthRequired: false,
      includePhoto: false,
      photoRequired: false
    });
  };

  const getUnitConfig = () => {
    // Get the units from the actual structure (directly at root level or nested)
    const units = props.eventSettings?.units || props.eventSettings?.species?.units || "pounds, tenths of inches";
    const [weightUnit, lengthUnit] = units.split(", ");
    
    const config = {
      weight: {
        step: 0.1,
        unit: weightUnit === "pounds" ? "lb" : "kg",
        label: `Weight (by 1/10 ${weightUnit})`
      },
      length: {
        step: lengthUnit === "eighths of inches" ? 0.125 : 
              lengthUnit === "tenths of inches" ? 0.1 : 
              lengthUnit === "millimeters" ? 1 : 1,
        unit: lengthUnit.includes("inches") ? "in" : 
              lengthUnit === "centimeters" ? "cm" : "mm",
        label: `Length (by ${lengthUnit === "eighths of inches" ? "1/8 inch" : 
                            lengthUnit === "tenths of inches" ? "1/10 inch" :
                            lengthUnit === "centimeters" ? "1 cm" : "1 mm"})`
      }
    };
    return config;
  };

  const handleClose = () => {
    // Reset form data
    setFormData({});
    
    // Reset photo states
    setCatchPhoto(null);
    setCatchPhotoName("");
    setPhotoDeleted(false);
  
    // Reset loading and submission states
    setIsLoading(false);
    setIsSubmitting(false);
    setIsSubmitted(false);
  
    // Clear any file input
    const photoInput = document.getElementById('catch-photo-upload');
    if (photoInput) photoInput.value = "";
  
    // Reset species config
    setSpeciesConfig(null);
  
    // Close modal
    props.close();
  };
  
  const handleInputChange = (field, value) => {
    let updatedFormData = { ...formData, [field]: value };
    
    // Recalculate points when weight or length changes
    if (field === 'weight' || field === 'length') {
      updatedFormData.points = calculatePoints(updatedFormData);
    }
    
    setFormData(updatedFormData);
  };

  const calculatePoints = (data) => {
    if (!speciesConfig) return 0.0;
    
    // Get values needed for calculation
    const { scoringMethod, rounding, points } = speciesConfig;
    const weight = parseFloat(data.weight) || 0;
    const length = parseFloat(data.length) || 0;
    
    let calculatedPoints = 0.0;
    
    // Calculate based on scoring method
    if (scoringMethod === "points" || scoringMethod === "custom") {
      // Fixed points
      calculatedPoints = parseFloat(points);
    } else if (scoringMethod === "weight") {
      // Points = weight value
      calculatedPoints = weight;
    } else if (scoringMethod === "length") {
      // Points = length value
      calculatedPoints = length;
    }
    
    // Apply rounding if applicable
    if (rounding === "up") {
      calculatedPoints = Math.ceil(calculatedPoints);
    } else if (rounding === "down") {
      calculatedPoints = Math.floor(calculatedPoints);
    } else if (rounding === "nearest") {
      calculatedPoints = Math.round(calculatedPoints);
    }
    // For "exact", keep the original value
    
    return parseFloat(calculatedPoints);
  };

  const isValidIncrement = (value, increment) => {
    if (!value) return false;
    const remainder = (value * 1000) % (increment * 1000);
    return Math.abs(remainder) < 0.001;
  };
  
  const getValidationConfig = (units) => {
    const [weightUnit, lengthUnit] = (units || "pounds, tenths of inches").split(", ");
    
    return {
      weight: {
        increment: 0.1,
        errorMsg: `Weight must be in increments of 0.1 ${weightUnit}`
      },
      length: {
        increment: lengthUnit === "eighths of inches" ? 0.125 :
                   lengthUnit === "tenths of inches" ? 0.1 :
                   lengthUnit === "millimeters" ? 1 : 1,
        errorMsg: lengthUnit === "eighths of inches" ? "All distance measurements must be in increments of 1/8 inch" :
                  lengthUnit === "tenths of inches" ? "All distance measurements must be in increments of 0.1 inch" :
                  lengthUnit === "millimeters" ? "All distance measurements must be in whole millimeters" :
                  "All distance measurements must be in whole centimeters"
      }
    };
  };

  const validateUserInput = () => {
    if (isLoading) {
      toast.warning("Please wait while the catch data is loading.");
      return false;
    }

    let inputIsValid = true;
    // Get validation config based on units from either direct path or nested path
    const units = props.eventSettings?.units || props.eventSettings?.species?.units;
    const validation = getValidationConfig(units);

    // Validate date/time if required
    if (speciesConfig?.collectTime && speciesConfig?.timeRequired && !formData.dateTime) {
      toast.error("Catch date/time is required.");
      inputIsValid = false;
    }

    // Validate weight if required
    if (speciesConfig?.collectWeight && speciesConfig?.weightRequired) {
      if (!formData.weight || formData.weight <= 0) {
        toast.error("Weight must be greater than zero.");
        inputIsValid = false;
      } else if (!isValidIncrement(formData.weight, validation.weight.increment)) {
        toast.error(validation.weight.errorMsg);
        inputIsValid = false;
      }
    }

    // Validate length if required
    if (speciesConfig?.collectLength && speciesConfig?.lengthRequired) {
      if (!formData.length || formData.length <= 0) {
        toast.error("Length must be greater than zero.");
        inputIsValid = false;
      } else if (!isValidIncrement(formData.length, validation.length.increment)) {
        toast.error(validation.length.errorMsg);
        inputIsValid = false;
      }
    }

    // Validate girth if required
    if (speciesConfig?.collectGirth && speciesConfig?.girthRequired) {
      if (!formData.girth || formData.girth <= 0) {
        toast.error("Girth must be greater than zero.");
        inputIsValid = false;
      } else if (!isValidIncrement(formData.girth, validation.length.increment)) {
        toast.error(validation.length.errorMsg);
        inputIsValid = false;
      }
    }

    // Check for required photo
    if (speciesConfig?.includePhoto && speciesConfig?.photoRequired && 
        !catchPhoto && photoDeleted && !formData["Catch photo"]) {
      toast.error(`Photo is required for ${formData.species} catches.`);
      inputIsValid = false;
    }

    return inputIsValid;
  };

  const handleEdit = async () => {
    if (validateUserInput()) {
      setIsSubmitting(true);
  
      try {
        const apiUrl = process.env.REACT_APP_NODE_ENV === "staging"
          ? process.env.REACT_APP_SERVER_URL_STAGING
          : process.env.REACT_APP_SERVER_URL_PRODUCTION;
  
        const submissionData = new FormData();
        
        // Add basic catch data (excluding Catch photo - it's handled separately)
        const catchData = {
          dateTime: formData.dateTime,
          length: parseFloat(formData.length) || 0,
          girth: parseFloat(formData.girth) || 0,
          weight: parseFloat(formData.weight) || 0,
          points: parseFloat(formData.points) || 0,
          // Add data about what was required for this species (helps with validation on the backend)
          collectTime: speciesConfig?.collectTime || false,
          timeRequired: speciesConfig?.timeRequired || false,
          collectWeight: speciesConfig?.collectWeight || false,
          weightRequired: speciesConfig?.weightRequired || false,
          collectLength: speciesConfig?.collectLength || false,
          lengthRequired: speciesConfig?.lengthRequired || false,
          collectGirth: speciesConfig?.collectGirth || false,
          girthRequired: speciesConfig?.girthRequired || false,
          includePhoto: speciesConfig?.includePhoto || false,
          photoRequired: speciesConfig?.photoRequired || false,
          scoringMethod: speciesConfig?.scoringMethod || "length",
          rounding: speciesConfig?.rounding || "exact",
        };
        
        // Handle photo specifically based on state flags
        if (photoDeleted) {
          // Explicitly set to null when deleted
          catchData["Catch photo"] = null;
        } else if (!catchPhoto) {
          // Keep existing photo if it wasn't changed or deleted
          catchData["Catch photo"] = formData["Catch photo"];
        }
  
        submissionData.append("username", props.username);
        submissionData.append("eventId", props.eventId);
        submissionData.append("catchId", props.editInfo.catchId);
        submissionData.append("catchData", JSON.stringify(catchData));
  
        // Add new photo if it exists
        if (catchPhoto) {
          submissionData.append("catchPhoto", catchPhoto, catchPhotoName);
        }
  
        const response = await fetch(`${apiUrl}/api/admin_edit_event_catches`, {
          method: 'POST',
          body: submissionData,
        });
  
        if (response.ok) {
          toast.success('Catch updated successfully!');
          setIsSubmitted(true);
          delayRefresh();
        } else {
          const errorData = await response.json();
          toast.error(`Error: ${errorData.error || 'Error updating catch'}`);
          setIsSubmitting(false);
        }
      } catch (error) {
        console.error('Error updating catch:', error);
        toast.error('Error updating catch.');
        setIsSubmitting(false);
      }
    }
  };

  const delayRefresh = () => {
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const unitConfig = getUnitConfig();

  return (
    <Dialog open={props.status} onClose={handleClose} fullWidth maxWidth="sm">
      <form onSubmit={(e) => e.preventDefault()}>
        <DialogTitle>
          Edit Catch
          <IconButton onClick={handleClose} style={{ float: 'right' }}>
            <CloseIcon color="primary" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {isLoading ? (
            <Box display="flex" justifyContent="center" m={3}>
              <CircularProgress />
            </Box>
          ) : (
            <Stack spacing={2} margin={2}>
              <InputLabel><strong>Catch ID:</strong> {props.editInfo.catchId}</InputLabel>
              <InputLabel><strong>Species:</strong> {formData.species}</InputLabel>
              <InputLabel><strong>Team:</strong> {formData.teamName}</InputLabel>
              <InputLabel><strong>Points:</strong> {formData.points}</InputLabel>

              {/* Date Time Input */}
              {speciesConfig?.collectTime && (
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label={speciesConfig.timeRequired ? "Catch Date/Time (required)" : "Catch Date/Time (optional)"}
                      value={formData.dateTime ? dayjs(formData.dateTime) : null}
                      onChange={(newValue) => handleInputChange('dateTime', newValue?.toISOString())}
                      minDateTime={eventStartDate}
                      maxDateTime={eventEndDate}
                      timeSteps={{ minutes: 1 }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          helperText: "Select a date/time during the tournament period"
                        }
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
              )}

              {/* Weight, Length, Girth Inputs - Desktop or tablet view */}
              {!isMobile && (
                <Grid container spacing={2}>
                  {speciesConfig?.collectWeight && (
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label={speciesConfig.weightRequired ? 
                          `${unitConfig.weight.label} (required)` : `${unitConfig.weight.label} (optional)`}
                        type="number"
                        value={formData.weight || ''}
                        onChange={(e) => handleInputChange('weight', parseFloat(e.target.value))}
                        InputProps={{ 
                          inputProps: { 
                            step: unitConfig.weight.step, 
                            min: unitConfig.weight.step 
                          } 
                        }}
                        fullWidth
                      />
                    </Grid>
                  )}

                  {speciesConfig?.collectLength && (
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label={speciesConfig.lengthRequired ? 
                          `${unitConfig.length.label} (required)` : `${unitConfig.length.label} (optional)`}
                        type="number"
                        value={formData.length || ''}
                        onChange={(e) => handleInputChange('length', parseFloat(e.target.value))}
                        InputProps={{ 
                          inputProps: { 
                            step: unitConfig.length.step, 
                            min: unitConfig.length.step 
                          } 
                        }}
                        fullWidth
                      />
                    </Grid>
                  )}

                  {speciesConfig?.collectGirth && (
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label={speciesConfig.girthRequired ? 
                          `Girth (by ${unitConfig.length.step} ${unitConfig.length.unit}) (required)` : 
                          `Girth (by ${unitConfig.length.step} ${unitConfig.length.unit}) (optional)`}
                        type="number"
                        value={formData.girth || ''}
                        onChange={(e) => handleInputChange('girth', parseFloat(e.target.value))}
                        InputProps={{ 
                          inputProps: { 
                            step: unitConfig.length.step, 
                            min: unitConfig.length.step 
                          } 
                        }}
                        fullWidth
                      />
                    </Grid>
                  )}
                </Grid>
              )}

              {/* Weight, Length, Girth Inputs - Mobile view */}
              {isMobile && (
                <>
                  {speciesConfig?.collectWeight && (
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <TextField
                        label={speciesConfig.weightRequired ? 
                          `${unitConfig.weight.label} (required)` : `${unitConfig.weight.label} (optional)`}
                        type="number"
                        value={formData.weight || ''}
                        onChange={(e) => handleInputChange('weight', parseFloat(e.target.value))}
                        InputProps={{ 
                          inputProps: { 
                            step: unitConfig.weight.step, 
                            min: unitConfig.weight.step 
                          } 
                        }}
                        fullWidth
                      />
                    </FormControl>
                  )}

                  {speciesConfig?.collectLength && (
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <TextField
                        label={speciesConfig.lengthRequired ? 
                          `${unitConfig.length.label} (required)` : `${unitConfig.length.label} (optional)`}
                        type="number"
                        value={formData.length || ''}
                        onChange={(e) => handleInputChange('length', parseFloat(e.target.value))}
                        InputProps={{ 
                          inputProps: { 
                            step: unitConfig.length.step, 
                            min: unitConfig.length.step 
                          } 
                        }}
                        fullWidth
                      />
                    </FormControl>
                  )}

                  {speciesConfig?.collectGirth && (
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <TextField
                        label={speciesConfig.girthRequired ? 
                          `Girth (by ${unitConfig.length.step} ${unitConfig.length.unit}) (required)` : 
                          `Girth (by ${unitConfig.length.step} ${unitConfig.length.unit}) (optional)`}
                        type="number"
                        value={formData.girth || ''}
                        onChange={(e) => handleInputChange('girth', parseFloat(e.target.value))}
                        InputProps={{ 
                          inputProps: { 
                            step: unitConfig.length.step, 
                            min: unitConfig.length.step 
                          } 
                        }}
                        fullWidth
                      />
                    </FormControl>
                  )}
                </>
              )}

              {/* Photo input - Only show when species config includes photo */}
              {speciesConfig?.includePhoto && (
                <Box marginTop={2}>
                  <InputLabel>
                    <strong>Catch Photo:</strong>
                    {speciesConfig.photoRequired ? ' (required)' : ' (optional)'}
                  </InputLabel>
                  
                  {/* Show current photo if it exists and hasn't been deleted */}
                  {formData["Catch photo"] && !catchPhoto && !photoDeleted && (
                    <Box marginTop={1} marginBottom={2}>
                      <Box display="flex" alignItems="center">
                        <img
                          src={formData["Catch photo"]}
                          alt="Current Catch"
                          style={{
                            width: "200px",
                            height: "200px",
                            objectFit: "cover",
                            marginRight: "10px",
                          }}
                          onError={(e) => {
                            e.target.src = defaultNoImage;
                          }}
                        />
                        <IconButton size="small" onClick={handleRemoveCatchPhoto}>
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </Box>
                  )}

                  {/* Upload new photo section */}
                  <Box marginTop={2}>
                    <input
                      type="file"
                      onChange={handleCatchPhotoUpload}
                      id="catch-photo-upload"
                      accept="image/*"
                      style={{ display: 'none' }}
                    />
                    <label htmlFor="catch-photo-upload">
                      <Button 
                        variant="outlined" 
                        component="span" 
                        startIcon={<CloudUploadIcon />}
                      >
                        {formData["Catch photo"] && !photoDeleted ? "Replace Photo" : "Upload Photo"}
                      </Button>
                    </label>
                    
                    {/* Show preview of new uploaded photo */}
                    {catchPhoto && (
                      <Box display="flex" alignItems="center" marginTop={2}>
                        <img
                          src={URL.createObjectURL(catchPhoto)}
                          alt="New Catch"
                          style={{
                            width: "200px",
                            height: "200px",
                            objectFit: "cover",
                            marginRight: "10px",
                          }}
                        />
                        <IconButton size="small" onClick={handleRemoveCatchPhoto}>
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}

              {!isSubmitted ? (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleEdit}
                  disabled={isSubmitting || isSubmitted}
                  startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
                  sx={{ mt: 2 }}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </Button>
              ) : (
                <Box sx={{ mt: 2 }}>
                  <h3>Submitted!</h3>
                </Box>
              )}
            </Stack>
          )}
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default EditCatchModal;

