import React, { useState, useEffect } from 'react';
import {
  Box,
  Collapse,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Stack,
  Button,
  Fade,
  Alert,
  IconButton,
  Paper,
  InputAdornment,
  Divider,
  Grid,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
  Chip,
  Table, 
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import PaymentIcon from '@mui/icons-material/Payment';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import BlockIcon from '@mui/icons-material/Block';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { teamsConfig } from '../../../config/config';
import { toast } from 'react-toastify';

const RegisterTeamsStep = ({ data, onUpdate, isValid, featureOption, setActiveStep, setFeatureOption }) => {

  const FEATURE_OPTIONS = {
    NOT_NEEDED: 'notNeeded',
    CUSTOMER_PAYS: 'customerPays',
    I_PAY: 'iPay'
  };

  const [draftField, setDraftField] = useState(null);
  const [generalRegistrationPrice, setGeneralRegistrationPrice] = useState();
  const [generalEarlyBirdPrice, setGeneralEarlyBirdPrice] = useState();
  const [newDivision, setNewDivision] = useState({
    name: '',
    registrationPrice: '',
    earlyBirdPrice: ''
  });
  const [rowErrors, setRowErrors] = useState({});
  const newDivisionData = {
    name: newDivision.name.trim()
  };
  
  // Only include pricing fields if online registration is enabled
  if (featureOption !== FEATURE_OPTIONS.NOT_NEEDED) {
    newDivisionData.registrationPrice = newDivision.registrationPrice || '';
    newDivisionData.earlyBirdPrice = data.includeEarlyBird ? newDivision.earlyBirdPrice || '' : '';
  } else {
    // Set empty values for disabled features
    newDivisionData.registrationPrice = '';
    newDivisionData.earlyBirdPrice = '';
  }

  const showPricingColumns = featureOption !== FEATURE_OPTIONS.NOT_NEEDED;

  useEffect(() => {
    // We always initialize the team registration fields, even if the feature is disabled
    const defaultFields = getDefaultFields();
    
    // Initialize with default "Open" division if none exists
    let initialDivisions = data.divisions || [];
    if (initialDivisions.length === 0) {
      initialDivisions = [{
        name: "Open (default)",
        registrationPrice: data.registrationPrice || '',
        earlyBirdPrice: data.earlyBirdPrice || '',
        // Remove isDefault flag to make it editable/deletable
      }];
    }

    onUpdate({
      // These fields depend on online registration feature status
      includeEarlyBird: featureOption !== FEATURE_OPTIONS.NOT_NEEDED ? data.includeEarlyBird || false : false,
      earlyBirdDate: featureOption !== FEATURE_OPTIONS.NOT_NEEDED ? data.earlyBirdDate || null : null,
      registrationDate: featureOption !== FEATURE_OPTIONS.NOT_NEEDED ? data.registrationDate || null : null,
      
      // These fields are always needed, regardless of feature status
      allowTeamPhoto: data.allowTeamPhoto || false,
      registrationMessage: data.registrationMessage || 'Example disclaimer: if the weather is bad, we may need to delay or reschedule this event',
      
      // General pricing fields - only relevant for online registration
      registrationPrice: data.registrationPrice || '',
      earlyBirdPrice: data.earlyBirdPrice || '',
      
      // Always set the team field configurations
      defaultTeamRegistrationFields: defaultFields,
      userDefinedTeamRegistrationFields: data.userDefinedTeamRegistrationFields || [],
      
      // Division structure is always needed
      includeDivisions: true, // Always true, we removed the toggle
      divisions: initialDivisions,
    });
  }, [featureOption]);

  const dataTypes = [
    { value: 'Email', label: 'Email' },
    { value: 'Phone', label: 'Phone Number' },
    { value: 'Text', label: 'Text' },
    { value: 'Number', label: 'Number' },
    { value: 'Boolean', label: 'Yes/No' },
    { value: 'Dropdown', label: 'Dropdown' }
  ];

  const getDefaultFields = () => {
    // Filter fields from config
    let filteredFields = teamsConfig
      .filter(field => 
        field.isVisible && 
        field.field !== "teamId" && 
        // Only filter out Team photo if not allowed
        (field.field !== "Team photo" || data.allowTeamPhoto)
      )
      .map(field => ({
        field: field.field,
        headerName: field.field,
        type: field.isEmail ? 'Email' : 
              field.isPhoneNumber ? 'Phone' : 
              field.isBoolean ? 'Boolean' : 
              field.type === 'Image' ? 'Image' : 'Text',
        isRequired: field.isRequired,
        isEditable: true,
        isVisible: true,
        isBoolean: field.isBoolean,
        isEmail: field.isEmail,
        isPhoneNumber: field.isPhoneNumber,
        
        // Additional UI properties
        flex: field.flex || 2,
        width: field.width || 200,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        align: 'center'
      }));
      
    return filteredFields;
  };
  
  const defaultFields = getDefaultFields();

  const handleUpdateField = (field, value) => {

    // If updating early bird checkbox
    if (field === 'includeEarlyBird') {
      // If turning off early bird, reset all early bird prices in divisions
      if (value === false && data.divisions && data.divisions.length > 0) {
        const updatedDivisions = data.divisions.map(division => ({
          ...division,
          earlyBirdPrice: ''  // Reset early bird price
        }));
        
        onUpdate({ 
          [field]: value,
          divisions: updatedDivisions
        });
        return;
      }
    }

    // If we're updating the team photo setting, also update the defaultTeamRegistrationFields
    if (field === 'allowTeamPhoto') {
      onUpdate({ 
        [field]: value,
        defaultTeamRegistrationFields: teamsConfig
          .filter(configField => 
            configField.isVisible && 
            configField.field !== "teamId" && 
            (configField.field !== "Team photo" || value)
          )
          .map(configField => ({
            field: configField.field,
            headerName: configField.field,
            type: configField.isEmail ? 'Email' : 
                  configField.isPhoneNumber ? 'Phone' : 
                  configField.isBoolean ? 'Boolean' : 
                  configField.type === 'Image' ? 'Image' : 'Text',
            isRequired: configField.isRequired,
            isEditable: true,
            isVisible: true,
            isBoolean: configField.isBoolean,
            isEmail: configField.isEmail,
            isPhoneNumber: configField.isPhoneNumber,
            flex: configField.flex || 2,
            width: configField.width || 200,
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center'
          }))
      });
    } else {
      onUpdate({ [field]: value });
    }
  };

  const handleAddDraftField = () => {
    setDraftField({
      field: '',
      type: 'Text',
      required: false,
      options: [] // For dropdown type
    });
  };
  
  const handleAddDropdownOption = () => {
    if (!draftField.newOption?.trim()) return;
    
    setDraftField({
      ...draftField,
      options: [...(draftField.options || []), draftField.newOption.trim()],
      newOption: '' // Clear the input after adding
    });
  };

  const handleRemoveDropdownOption = (index) => {
    const newOptions = [...draftField.options];
    newOptions.splice(index, 1);
    
    setDraftField({
      ...draftField,
      options: newOptions
    });
  };

  const handleSaveDraftField = () => {
    if (!draftField.field || !draftField.type) return;
    
    // Normalize field creation with consistent structure
    const newField = {
      // Core fields
      field: draftField.field,
      type: draftField.type,
      
      // Standardized properties
      headerName: draftField.field,
      isEditable: true,
      isRequired: draftField.required,
      isVisible: true,
      
      // Type-specific flags
      isBoolean: draftField.type === 'Boolean',
      isEmail: draftField.type === 'Email',
      isPhoneNumber: draftField.type === 'Phone',
      
      // For dropdown type, add options
      options: draftField.type === 'Dropdown' ? draftField.options : undefined,
      
      // UI-related properties (optional but consistent)
      flex: 2,
      width: 200,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      align: 'center'
    };
  
    const newUserDefinedFields = [...(data.userDefinedTeamRegistrationFields || []), newField];
    
    onUpdate({ 
      userDefinedTeamRegistrationFields: newUserDefinedFields
    });
    
    setDraftField(null);
  };

  const handleDeleteField = (index) => {
    const newUserDefinedFields = data.userDefinedTeamRegistrationFields.filter((_, i) => i !== index);
    
    onUpdate({ 
      userDefinedTeamRegistrationFields: newUserDefinedFields
    });
  };

  const handleFeatureOptionChange = (event, newOption) => {
    if (newOption !== null) {
      setFeatureOption('onlineTeamRegistration', newOption);
    }
  };

  const getErrorMessage = () => {

    // Division validation - always required
    if (!data.divisions || data.divisions.length === 0) {
      return "Please add at least one division. If you don't want to separate teams, just create a single division like 'Open' for everyone.";
    }
    
    // Only validate fee section if online registration is enabled
    if (featureOption !== FEATURE_OPTIONS.NOT_NEEDED) {
      // Validate division pricing
      for (const division of data.divisions) {
        if (!division.registrationPrice || parseFloat(division.registrationPrice) <= 0) {
          return `Please set a valid registration price for division "${division.name}"`;
        }
        
        if (data.includeEarlyBird && (!division.earlyBirdPrice || parseFloat(division.earlyBirdPrice) <= 0)) {
          return `Please set a valid early bird price for division "${division.name}"`;
        }
      }
      
      // Validate dates
      if (data.includeEarlyBird) {
        if (!data.earlyBirdDate) {
          return "Please set an early bird cutoff date/time";
        }
        
        if (!data.registrationDate) {
          return "Please set a registration cutoff date/time";
        }
        
        if (data.earlyBirdDate.isAfter(data.registrationDate)) {
          return "Early bird date/time must be before regular registration date/time";
        }
      } else if (!data.registrationDate) {
        return "Please set a registration cutoff date/time";
      }
    }
    
    return "Please complete all required registration information";
  };

  const CompactFeeCalculation = ({ title, featureType, amount, whoPays }) => {
    const [expanded, setExpanded] = useState(false);
    const FEATURE_OPTIONS = {
      NOT_NEEDED: 'notNeeded',
      CUSTOMER_PAYS: 'customerPays',
      I_PAY: 'iPay'
    };
  
    // Calculate approximate fees
    const baseAmount = parseFloat(amount) || 0;
    
    // Calculate platform fee
    let platformFee = 0;
    if (baseAmount < 50) {
      platformFee = 3;
    } else if (baseAmount <= 100) {
      platformFee = 5;
    } else if (baseAmount <= 200) {
      platformFee = baseAmount * 0.05; // 5%
    } else {
      platformFee = baseAmount * 0.04; // 4%
    }
    
    // Round to two decimal places
    platformFee = Math.round(platformFee * 100) / 100;
    
    // Calculate Stripe fee (2.9% + 30�)
    const stripePercent = 2.9;
    const stripeFixed = 0.30;
    
    // Calculate total based on who pays
    let teamAmount, totalAmount, stripeFee;
    
    if (whoPays === FEATURE_OPTIONS.CUSTOMER_PAYS) {
      teamAmount = baseAmount;
      const subTotal = baseAmount + platformFee;
      stripeFee = (subTotal * stripePercent / 100) + stripeFixed;
      totalAmount = subTotal + stripeFee;
    } else if (whoPays === FEATURE_OPTIONS.I_PAY) {
      teamAmount = baseAmount;
      stripeFee = (baseAmount * stripePercent / 100) + stripeFixed;
      totalAmount = baseAmount;
      // Organizer pays both fees
    } else {
      // NOT_NEEDED case
      teamAmount = baseAmount;
      totalAmount = baseAmount;
      stripeFee = 0;
    }
    
    // Round total to two decimal places
    totalAmount = Math.round(totalAmount * 100) / 100;
    stripeFee = Math.round(stripeFee * 100) / 100;
    
    return (
      <Box sx={{ border: '1px solid #e0e0e0', borderRadius: 1, p: 2, bgcolor: 'background.paper' }}>
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            cursor: 'pointer' 
          }}
          onClick={() => setExpanded(!expanded)}
        >
          <Typography variant="subtitle2">
            {title} Fee Breakdown
          </Typography>
          <IconButton size="small">
            {expanded ? <ExpandMoreIcon /> : <ArrowUpwardIcon />}
          </IconButton>
        </Box>
        
        <Collapse in={expanded}>
          <Grid container spacing={1} sx={{ mt: 1 }}>
            <Grid item xs={7}>
              <Typography variant="body2">Base {featureType} Fee:</Typography>
            </Grid>
            <Grid item xs={5} sx={{ textAlign: 'right' }}>
              <Typography variant="body2">${baseAmount.toFixed(2)}</Typography>
            </Grid>
            
            {whoPays !== FEATURE_OPTIONS.NOT_NEEDED && (
              <>
                <Grid item xs={7}>
                  <Typography variant="body2">Platform Fee:</Typography>
                </Grid>
                <Grid item xs={5} sx={{ textAlign: 'right' }}>
                  <Typography variant="body2">${platformFee.toFixed(2)}</Typography>
                </Grid>
                
                <Grid item xs={7}>
                  <Typography variant="body2">Stripe Processing Fee (2.9% + $0.30):</Typography>
                </Grid>
                <Grid item xs={5} sx={{ textAlign: 'right' }}>
                  <Typography variant="body2">${stripeFee.toFixed(2)}</Typography>
                </Grid>
              </>
            )}
            
            <Grid item xs={12}><Divider sx={{ my: 1 }} /></Grid>
            
            <Grid item xs={7}>
              <Typography variant="subtitle2">Team pays:</Typography>
            </Grid>
            <Grid item xs={5} sx={{ textAlign: 'right' }}>
              <Typography variant="subtitle2">
                ${whoPays === FEATURE_OPTIONS.CUSTOMER_PAYS ? totalAmount.toFixed(2) : teamAmount.toFixed(2)}
              </Typography>
            </Grid>
            
            {whoPays === FEATURE_OPTIONS.I_PAY && (
              <>
                <Grid item xs={7}>
                  <Typography variant="subtitle2">You pay:</Typography>
                </Grid>
                <Grid item xs={5} sx={{ textAlign: 'right' }}>
                  <Typography variant="subtitle2" color="error">
                    ${(platformFee + stripeFee).toFixed(2)}
                  </Typography>
                </Grid>
              </>
            )}
            
            <Grid item xs={7}>
              <Typography variant="subtitle2">You receive:</Typography>
            </Grid>
            <Grid item xs={5} sx={{ textAlign: 'right' }}>
              <Typography variant="subtitle2" color="success.main">
                ${whoPays === FEATURE_OPTIONS.I_PAY ? 
                  (teamAmount - platformFee - stripeFee).toFixed(2) : 
                  baseAmount.toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
        </Collapse>
      </Box>
    );
  };
  
  const renderPricingSection = () => {
    // If feature is disabled, show a message with button to jump back
    if (featureOption === FEATURE_OPTIONS.NOT_NEEDED) {
      return (
        <Box sx={{ textAlign: 'center', py: 4 }}>
          <Typography variant="h6" gutterBottom>
            Online Team Registration is currently disabled
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
            To set up online registration with payment processing, you need to enable this feature.
          </Typography>
          <Button
            variant="contained"
            onClick={() => setActiveStep(0)}
            startIcon={<ArrowUpwardIcon />}
          >
            Go to Features Step
          </Button>
        </Box>
      );
    }
  
    return (
      <Stack spacing={3}>
        {/* Fee handling toggle */}
        <Box sx={{ border: '1px solid #e0e0e0', borderRadius: 1, p: 2, bgcolor: '#f9f9f9' }}>
          <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'medium', color: 'text.primary' }}>
            How should fees be handled?
          </Typography>
          
          <ToggleButtonGroup
            value={featureOption}
            exclusive
            onChange={handleFeatureOptionChange}
            aria-label="fee option"
            sx={{ mb: 1 }}
            fullWidth
          >
            <ToggleButton 
              value={FEATURE_OPTIONS.CUSTOMER_PAYS} 
              aria-label="customer pays" 
              sx={{ py: 1 }}
            >
              <Tooltip title="Teams pay our platform fees" placement="top">
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <PaymentIcon sx={{ mb: 0.5 }} />
                  <Typography variant="caption">Teams Pay Fees</Typography>
                </Box>
              </Tooltip>
            </ToggleButton>
            <ToggleButton 
              value={FEATURE_OPTIONS.I_PAY} 
              aria-label="organizer pays" 
              sx={{ py: 1 }}
            >
              <Tooltip title="You pay our platform fees" placement="top">
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <AccountBalanceWalletIcon sx={{ mb: 0.5 }} />
                  <Typography variant="caption">You Pay Fees</Typography>
                </Box>
              </Tooltip>
            </ToggleButton>
            <ToggleButton 
              value={FEATURE_OPTIONS.NOT_NEEDED} 
              aria-label="no registration" 
              sx={{ py: 1 }}
            >
              <Tooltip title="Disable online team registration" placement="top">
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <BlockIcon sx={{ mb: 0.5 }} />
                  <Typography variant="caption">No Online Registration</Typography>
                </Box>
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
          
          <Typography variant="caption" color="text.secondary">
            {featureOption === FEATURE_OPTIONS.CUSTOMER_PAYS ? 
              "Teams will pay the platform fee in addition to your registration fee." :
              featureOption === FEATURE_OPTIONS.I_PAY ?
              "You will pay the platform fee from your portion of the registration fee." :
              "Online registration is disabled. Teams can only be added manually after event creation."}
          </Typography>
        </Box>
        
        {/* Registration dates settings */}
        <Paper sx={{ p: 2 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'medium', mb: 2 }}>
            Registration Dates
          </Typography>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={data.includeEarlyBird || false}
                  onChange={(e) => handleUpdateField('includeEarlyBird', e.target.checked)}
                />
              }
              label="Enable early bird pricing"
            />
          </Grid>

          <br/>
          
          {data.includeEarlyBird && (
            <>
              <Grid item xs={12}>
                <DateTimePicker
                  label="Early Bird Deadline"
                  value={data.earlyBirdDate || null}
                  onChange={(date) => handleUpdateField('earlyBirdDate', date)}
                  format="MM/DD/YYYY hh:mm a"
                  sx={{ width: '100%' }}
                  slotProps={{
                    textField: {
                      required: true,
                      fullWidth: true,
                      error: !data.earlyBirdDate,
                      helperText: !data.earlyBirdDate ? "Required" : ""
                    },
                  }}
                />
              </Grid>
              
              {data.earlyBirdDate && data.registrationDate && 
              data.earlyBirdDate.isAfter(data.registrationDate) && (
                <Grid item xs={12}>
                  <Alert severity="error">
                    Early bird deadline must be before registration close date
                  </Alert>
                </Grid>
              )}
              <br/>
            </>
          )}

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DateTimePicker
                label="Registration Closes"
                value={data.registrationDate || null}
                onChange={(date) => handleUpdateField('registrationDate', date)}
                format="MM/DD/YYYY hh:mm a"
                sx={{ width: '100%' }}
                slotProps={{
                  textField: {
                    required: true,
                    fullWidth: true,
                    error: !data.registrationDate,
                    helperText: !data.registrationDate ? "Required" : ""
                  },
                }}
              />
            </Grid>
            
            
          </Grid>

        </Paper>
        
        {/* Transaction Fee Example */}
        <Paper sx={{ p: 2 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'medium', mb: 2 }}>
            Example Transaction
          </Typography>
          
          <Alert severity="info" sx={{ mb: 2 }}>
            <Typography variant="body2">
              Here's how a sample registration transaction would look with the selected fee option.
              Click to expand for details.
            </Typography>
          </Alert>
          
          <CompactFeeCalculation 
            title="Team Registration" 
            featureType="Registration"
            amount={data.registrationPrice || '100'}
            whoPays={featureOption}
          />
        </Paper>
        
        {/* Registration Message */}
        <Paper sx={{ p: 2 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'medium', mb: 2 }}>
            Registration Message
          </Typography>
          
          <TextField
            label="Message to display on registration page"
            value={data.registrationMessage || ''}
            onChange={(e) => handleUpdateField('registrationMessage', e.target.value)}
            multiline
            rows={3}
            fullWidth
            placeholder="Example: Cancellation policy, weather information, or any special instructions"
          />
        </Paper>
      </Stack>
    );
  };

  const canAddDivision = () => {
    // Division name is always required
    if (!newDivision.name.trim()) {
      return false;
    }
    
    // Check for duplicate names
    if (data.divisions && data.divisions.some(div => 
      div.name.toLowerCase() === newDivision.name.toLowerCase())) {
      return false;
    }
    
    // Only validate prices if online registration is enabled
    if (featureOption !== FEATURE_OPTIONS.NOT_NEEDED) {
      // Registration price is required and must be positive
      if (!newDivision.registrationPrice || parseFloat(newDivision.registrationPrice) <= 0) {
        return false;
      }
      
      // If early bird is enabled, early bird price is also required and must be positive
      if (data.includeEarlyBird && 
          (!newDivision.earlyBirdPrice || parseFloat(newDivision.earlyBirdPrice) <= 0)) {
        return false;
      }
    }
    
    return true;
  };

  const handleDeleteDivision = (index) => {
    const divisionName = data.divisions[index].name;
    const updatedDivisions = data.divisions.filter((_, i) => i !== index);
    
    // No special handling for "Open" division - just update with whatever divisions are left
    onUpdate({ divisions: updatedDivisions });
    toast.info(`Division "${divisionName}" removed`);
  };

  const renderDivisionsTable = () => {
    
    // Helper to validate a division row
    const validateDivisionRow = (division, index) => {
      const errors = {};
      
      // Name is always required
      if (!division.name.trim()) {
        errors.name = "Required";
      }
      
      // Check for duplicate names (excluding self when editing existing)
      // Add a null check here to prevent the error
      const isDuplicateName = data.divisions && data.divisions.some((div, i) => 
        i !== index && div.name.toLowerCase() === division.name.toLowerCase()
      );
      
      if (isDuplicateName) {
        errors.name = "Duplicate name";
      }
      
      // Only validate prices if online registration is enabled
      if (featureOption !== FEATURE_OPTIONS.NOT_NEEDED) {
        if (!division.registrationPrice || parseFloat(division.registrationPrice) <= 0) {
          errors.registrationPrice = "Invalid price";
        }
        
        if (data.includeEarlyBird && (!division.earlyBirdPrice || parseFloat(division.earlyBirdPrice) <= 0)) {
          errors.earlyBirdPrice = "Invalid price";
        }
      }
      
      return errors;
    };
  
    const handleAddDropdownOption = () => {
      // Validate the new division
      const errors = validateDivisionRow(newDivision, -1);
      
      if (Object.keys(errors).length > 0) {
        setRowErrors({ new: errors });
        return;
      }
      
      // Add the new division
      const updatedDivisions = [
        ...(data.divisions || []),
        {
          name: newDivision.name.trim(),
          registrationPrice: newDivision.registrationPrice || '',
          earlyBirdPrice: data.includeEarlyBird ? newDivision.earlyBirdPrice || '' : ''
        }
      ];
      
      onUpdate({ divisions: updatedDivisions });
      
      // Reset the new division form
      setNewDivision({
        name: '',
        registrationPrice: '',
        earlyBirdPrice: ''
      });
      
      setRowErrors({});
      toast.success(`Division "${newDivision.name}" added successfully`);
    };

    const handleUpdateDivision = (index, field, value) => {
      const updatedDivisions = [...data.divisions];
      updatedDivisions[index] = {
        ...updatedDivisions[index],
        [field]: value
      };
      
      onUpdate({ divisions: updatedDivisions });
    };
    
    const handlePriceChange = (index, field, value) => {
      // If dealing with the "Open" default division when it's the only one
      if (index === 0 && data.divisions.length === 1 && data.divisions[0].isDefault) {
        // Update both the division price and the general price
        if (field === 'registrationPrice') {
          setGeneralRegistrationPrice(value);
          onUpdate({ registrationPrice: value });
        } else if (field === 'earlyBirdPrice') {
          setGeneralEarlyBirdPrice(value);
          onUpdate({ earlyBirdPrice: value });
        }
      }
      
      handleUpdateDivision(index, field, value);
    };

    const isNewDivisionValid = () => {
      // Division name is always required
      if (!newDivision.name.trim()) {
        return false;
      }
      
      // Check for duplicate names
      if (data.divisions && data.divisions.some(div => 
        div.name.toLowerCase() === newDivision.name.toLowerCase())) {
        return false;
      }
      
      // Only validate prices if online registration is enabled
      if (featureOption !== FEATURE_OPTIONS.NOT_NEEDED) {
        // Registration price is required and must be positive
        if (!newDivision.registrationPrice || parseFloat(newDivision.registrationPrice) <= 0) {
          return false;
        }
        
        // If early bird is enabled, early bird price is also required and must be positive
        if (data.includeEarlyBird && 
            (!newDivision.earlyBirdPrice || parseFloat(newDivision.earlyBirdPrice) <= 0)) {
          return false;
        }
      }
      
      // All validation passed
      return true;
    };
    
    return (
      <Box sx={{ mt: 2 }}>
        <TableContainer component={Paper} variant="outlined">
          <Table size="small">

            <TableHead>
              <TableRow>
                <TableCell>Division Name</TableCell>
                {showPricingColumns && data.includeEarlyBird && (
                  <TableCell>Early Bird Price ($)</TableCell>
                )}
                {showPricingColumns && (
                  <TableCell>Registration Price ($)</TableCell>
                )}
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              
              {/* Existing divisions */}
              {data.divisions && data.divisions.length > 0 ? (
                data.divisions.map((division, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <TextField
                        size="small"
                        value={division.name}
                        onChange={(e) => handleUpdateDivision(index, 'name', e.target.value)}
                        error={!!rowErrors[index]?.name}
                        helperText={rowErrors[index]?.name}
                        fullWidth
                      />
                    </TableCell>
                    {showPricingColumns && data.includeEarlyBird && (
                      <TableCell>
                        <TextField
                          size="small"
                          type="number"
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                          value={division.earlyBirdPrice || ''}
                          onChange={(e) => handlePriceChange(index, 'earlyBirdPrice', e.target.value)}
                          error={!!rowErrors[index]?.earlyBirdPrice}
                          helperText={rowErrors[index]?.earlyBirdPrice}
                        />
                      </TableCell>
                    )}
                    {showPricingColumns && (
                      <TableCell>
                        <TextField
                          size="small"
                          type="number"
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                          value={division.registrationPrice || ''}
                          onChange={(e) => handlePriceChange(index, 'registrationPrice', e.target.value)}
                          error={!!rowErrors[index]?.registrationPrice}
                          helperText={rowErrors[index]?.registrationPrice}
                        />
                      </TableCell>
                    )}
                    <TableCell align="center">
                      <IconButton 
                        size="small" 
                        color="error"
                        onClick={() => handleDeleteDivision(index)}
                      >
                        <DeleteOutlineIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              
              ) : (
                <TableRow>
                  <TableCell colSpan={showPricingColumns ? (data.includeEarlyBird ? 4 : 3) : 2} align="center">                    
                    <Typography variant="body2" color="text.secondary">
                      No divisions defined. Add your first division below.
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              
              {/* New division form */}
              <TableRow sx={{ bgcolor: 'action.hover' }}>
                <TableCell>
                  <TextField
                    size="small"
                    placeholder="New Division Name"
                    value={newDivision.name}
                    onChange={(e) => setNewDivision({...newDivision, name: e.target.value})}
                    error={!!rowErrors.new?.name}
                    helperText={rowErrors.new?.name}
                    fullWidth
                  />
                </TableCell>
                {showPricingColumns && data.includeEarlyBird && (
                  <TableCell>
                    <TextField
                      size="small"
                      type="number"
                      placeholder="Early Bird Price"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      value={newDivision.earlyBirdPrice}
                      onChange={(e) => setNewDivision({...newDivision, earlyBirdPrice: e.target.value})}
                      error={!!rowErrors.new?.earlyBirdPrice}
                      helperText={rowErrors.new?.earlyBirdPrice}
                    />
                  </TableCell>
                )}
                {showPricingColumns && (
                  <TableCell>
                    <TextField
                      size="small"
                      type="number"
                      placeholder="Registration Price"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      value={newDivision.registrationPrice}
                      onChange={(e) => setNewDivision({...newDivision, registrationPrice: e.target.value})}
                      error={!!rowErrors.new?.registrationPrice}
                      helperText={rowErrors.new?.registrationPrice}
                    />
                  </TableCell>
                )}
                <TableCell align="center">
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={handleAddDropdownOption}
                  >
                    Add
                  </Button>
                </TableCell>
              </TableRow>
                            
            </TableBody>
          </Table>
        </TableContainer>
              
        <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 1 }}>
          {featureOption !== FEATURE_OPTIONS.NOT_NEEDED ? 
            "Each division can have its own pricing structure." :
            "Online registration is disabled, but divisions will still be available for team organization."}
        </Typography>
      </Box>
    );
  };

  const validateStep = () => {
    // Division validation - always required
    if (!data.divisions || data.divisions.length === 0) {
      console.log("No divisions defined");
      return false;
    }
    
    // If online registration is disabled, the rest of validation isn't needed
    if (featureOption === FEATURE_OPTIONS.NOT_NEEDED) {
      return true;
    }
    
    // Validate registration dates
    if (!data.registrationDate) {
      console.log("Missing registration date");
      return false;
    }
    
    // Validate early bird date if enabled
    if (data.includeEarlyBird) {
      if (!data.earlyBirdDate) {
        console.log("Missing early bird date");
        return false;
      }
      if (data.earlyBirdDate.isAfter(data.registrationDate)) {
        console.log("Early bird date must be before registration date");
        return false;
      }
    }
    
    // Validate division pricing
    for (const division of data.divisions) {
      if (!division.registrationPrice || parseFloat(division.registrationPrice) <= 0) {
        console.log(`Missing or invalid registration price for division: ${division.name}`);
        return false;
      }
      
      if (data.includeEarlyBird && (!division.earlyBirdPrice || parseFloat(division.earlyBirdPrice) <= 0)) {
        console.log(`Missing or invalid early bird price for division: ${division.name}`);
        return false;
      }
    }
    
    return true;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Fade in timeout={400}>
        <Box>
          {!validateStep() && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {getErrorMessage()}
            </Alert>
          )}
  
          {/* Step 1: Registration Settings */}
          <Paper 
            sx={{ 
              p: 3, 
              mb: 4,
              position: 'relative',
            }}
          >
            <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
              <strong>Step 1.</strong> Set up your registration process.
            </Typography>
            
            {renderPricingSection()}
          </Paper>
  
          {/* Step 2: Division Management */}
          <Paper 
            sx={{ 
              p: 3, 
              mb: 4,
              position: 'relative',
            }}
          >
            <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
              <strong>Step 2.</strong> Set up your divisions.
            </Typography>
            
            <Box>
              <Typography variant="body2" sx={{ mb: 2 }}>
                Divisions allow teams to register in specific categories (e.g., Offshore, Inshore, Adult, Junior, Male, Female, etc.). At least one division is required, so "Open" is included as a default.
                {featureOption !== FEATURE_OPTIONS.NOT_NEEDED && " Each division can have its own pricing."}
              </Typography>
              
              {/* Directly show divisions table here */}
              {renderDivisionsTable()}
            </Box>
          </Paper>
  
          {/* Step 3: Registration Fields */}
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              <strong>Step 3.</strong> Decide what info your teams will provide.
            </Typography>
  
            {/* Team Photo Option */}
            <Box sx={{ mb: 3, pb: 3, borderBottom: '1px solid #eee' }}>
              <br/>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                Photo Options
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data.allowTeamPhoto || false}
                    onChange={(e) => handleUpdateField('allowTeamPhoto', e.target.checked)}
                  />
                }
                label="Allow teams to upload a photo with their registration"
              />
            </Box>
  
            {/* All Fields List */}
            <Stack spacing={2}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                Additional Field Options
              </Typography>
              {/* Default Fields */}
              {defaultFields.map((field, index) => (
                <Paper
                  key={index}
                  variant="outlined"
                  sx={{ p: 2 }}
                >
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Typography sx={{ flex: 1 }}>
                      {field.field}
                      <Typography 
                        component="span" 
                        sx={{ 
                          ml: 1,
                          color: 'text.secondary',
                          fontStyle: 'italic',
                          fontSize: '0.85em'
                        }}
                      >
                        (default)
                      </Typography>
                    </Typography>
                    <Typography color="text.secondary" variant="body2">
                      {field.type}
                    </Typography>
                    {field.isRequired && (
                      <Typography 
                        variant="caption" 
                        sx={{ 
                          color: 'primary.main',
                          bgcolor: 'primary.lighter',
                          px: 1,
                          py: 0.5,
                          borderRadius: 1
                        }}
                      >
                        Required
                      </Typography>
                    )}
                  </Stack>
                </Paper>
              ))}
  
              {/* Custom Fields */}
              {data.userDefinedTeamRegistrationFields?.map((field, index) => (
                <Paper
                  key={index}
                  variant="outlined"
                  sx={{ p: 2 }}
                >
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Typography sx={{ flex: 1 }}>{field.field}</Typography>
                    <Typography color="text.secondary" variant="body2">
                      {field.type}
                    </Typography>
                    {field.isRequired && (
                      <Typography 
                        variant="caption" 
                        sx={{ 
                          color: 'primary.main',
                          bgcolor: 'primary.lighter',
                          px: 1,
                          py: 0.5,
                          borderRadius: 1
                        }}
                      >
                        Required
                      </Typography>
                    )}
                    <IconButton 
                      size="small" 
                      onClick={() => handleDeleteField(index)}
                      color="error"
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </Stack>
                </Paper>
              ))}
  
              {/* Add Field Button */}
              {!draftField && (
                <Button
                  startIcon={<AddIcon />}
                  onClick={handleAddDraftField}
                  variant="outlined"
                  sx={{ mt: 2 }}
                >
                  Add Custom Field
                </Button>
              )}
  
              {/* Draft Field Form */}
              {draftField && (
                <Paper
                  variant="outlined"
                  sx={{ p: 2, mt: 2 }}
                >
                  <Typography variant="subtitle2" gutterBottom>
                    Add Custom Field
                  </Typography>
                  <Stack spacing={2}>
                    <TextField
                      label="Field Name"
                      value={draftField.field}
                      onChange={(e) => setDraftField({...draftField, field: e.target.value})}
                      fullWidth
                      required
                    />
                    <FormControl fullWidth>
                      <InputLabel>Field Type</InputLabel>
                      <Select
                        value={draftField.type}
                        onChange={(e) => setDraftField({...draftField, type: e.target.value})}
                        label="Field Type"
                      >
                        {dataTypes.map((type) => (
                          <MenuItem key={type.value} value={type.value}>
                            {type.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    
                    {/* Show options input only for Dropdown type */}
                    {draftField.type === 'Dropdown' && (
                      <Box sx={{ border: '1px solid #e0e0e0', borderRadius: 1, p: 2 }}>
                        <Typography variant="subtitle2" gutterBottom>
                          Dropdown Options
                        </Typography>
                        
                        {/* List of current options */}
                        {draftField.options && draftField.options.length > 0 ? (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
                            {draftField.options.map((option, index) => (
                              <Chip 
                                key={index}
                                label={option}
                                onDelete={() => handleRemoveDropdownOption(index)}
                                sx={{ mb: 1 }}
                              />
                            ))}
                          </Box>
                        ) : (
                          <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mb: 2 }}>
                            No options added yet. Add at least one option.
                          </Typography>
                        )}
                        
                        {/* Add option input */}
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <TextField
                            size="small"
                            label="Add option"
                            value={draftField.newOption || ''}
                            onChange={(e) => setDraftField({...draftField, newOption: e.target.value})}
                            sx={{ mr: 1, flex: 1 }}
                          />
                          <Button
                            variant="contained"
                            size="small"
                            startIcon={<AddIcon />}
                            onClick={handleAddDropdownOption}
                            disabled={!canAddDivision()}
                          >
                            Add
                          </Button>
                        </Box>
                      </Box>
                    )}
                    
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={draftField.required || false}
                          onChange={(e) => setDraftField({...draftField, required: e.target.checked})}
                        />
                      }
                      label="Required field"
                    />
                    
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                      <Button 
                        variant="outlined" 
                        onClick={() => setDraftField(null)} 
                        sx={{ mr: 1 }}
                      >
                        Cancel
                      </Button>
                      <Button 
                        variant="contained" 
                        onClick={handleSaveDraftField}
                        disabled={!draftField.field || 
                          (draftField.type === 'Dropdown' && (!draftField.options || draftField.options.length === 0))}
                      >
                        Save Field
                      </Button>
                    </Box>
                  </Stack>
                </Paper>
              )}
            </Stack>
          </Paper>
        </Box>
      </Fade>
    </LocalizationProvider>
  );
}

export default RegisterTeamsStep;

