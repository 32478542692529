import jsPDF from 'jspdf';
import 'jspdf-autotable';
import dayjs from 'dayjs';

const addPageNumbers = (doc) => {
  const pageCount = doc.internal.getNumberOfPages();
  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.setFontSize(10);
    doc.text(
      `${i} of ${pageCount}`,
      doc.internal.pageSize.getWidth() - 15,
      doc.internal.pageSize.getHeight() - 10
    );
  }
};

const formatCurrency = (value) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

const formatPhoneNumber = (phone) => {
  if (!phone) return '';
  const cleaned = phone.toString().replace(/\D/g, ''); // Remove non-numeric characters
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  return match ? `(${match[1]}) ${match[2]}-${match[3]}` : phone; // Format as (xxx) xxx-xxxx
};

// Format boolean values consistently
const formatBoolean = (value) => {
  return value === true ? 'Yes' : value === false ? 'No' : '';
};

// Format division name by removing " (default)" text
const formatDivisionName = (name) => {
  if (!name) return 'Unassigned';
  return name.replace(/\s*\(default\)\s*/g, '');
};

export const generateRegistrationReport = (teamsData, teamsConfig, tournamentName) => {
  const doc = new jsPDF('landscape');
  const currentDate = dayjs().format('MMMM D, YYYY h:mm A [CST]');
  const currentYear = new Date().getFullYear();

  // Get all visible registration fields
  const visibleFields = teamsConfig
    .filter(field => field.isVisible && field.type !== 'Image') // Exclude image fields as they can't be displayed in PDF table
    .map(field => ({
      field: field.field,
      headerName: field.field,
      type: field.type
    }));

  // Always include the checked-in field
  if (!visibleFields.some(field => field.field === 'Checked-In?')) {
    visibleFields.push({
      field: 'Checked-In?',
      headerName: 'Checked-In?',
      type: 'Boolean'
    });
  }

  // Check if Division field exists in any team data
  const hasDivisions = teamsData.some(team => team["Division"]);
  
  // Ensure Division field is included if it exists in data
  if (hasDivisions && !visibleFields.some(f => f.field === "Division")) {
    visibleFields.push({
      field: "Division",
      headerName: "Division",
      type: 'Text'
    });
  }
  
  // Sort fields to put team name and division at the beginning
  visibleFields.sort((a, b) => {
    // Team name comes first
    if (a.field === "Team name") return -1;
    if (b.field === "Team name") return 1;
    
    // Division comes second
    if (a.field === "Division") return -1;
    if (b.field === "Division") return 1;
    
    // For all other fields, maintain original order
    return 0;
  });

  // Create table headers from visible fields
  const tableColumn = visibleFields.map(col => col.headerName);

  const tableRows = [];

  // Group and sort teams by division
  const sortedTeams = [...teamsData].sort((a, b) => {
    // First sort by division if it exists
    if (a["Division"] && b["Division"] && a["Division"] !== b["Division"]) {
      return a["Division"].localeCompare(b["Division"]);
    }
    
    // Then by team name
    if (a["Team name"] && b["Team name"]) {
      return a["Team name"].localeCompare(b["Team name"]);
    }
    
    return 0;
  });

  // Track current division to add section headers
  let currentDivision = null;

  // Calculate total columns
  const totalColumns = visibleFields.length + 1; // +1 for the # column

  // Process each team
  sortedTeams.forEach((team, index) => {
    // Check for division change
    const teamDivision = team["Division"] ? formatDivisionName(team["Division"]) : "Unassigned";
    
    // Add section headers when division changes
    if (teamDivision !== currentDivision) {
      // Add a blank row as a visual separator (except for the first division)
      if (index > 0) {
        tableRows.push([{content: '', colSpan: totalColumns}]);
      }
      
      // Add division header row if divisions are used
      if (hasDivisions) {
        tableRows.push([{
          content: `Division: ${teamDivision}`, 
          colSpan: totalColumns, 
          styles: {fontStyle: 'bold', fillColor: '#e8f4fd'}
        }]);
      }
      currentDivision = teamDivision;
    }
    
    // Format the team data
    const teamData = visibleFields.map(col => {
      const value = team[col.field];
      
      // Format value based on field type
      if (col.type === 'Phone') {
        return formatPhoneNumber(value);
      } else if (col.type === 'Boolean' || col.field === 'Checked-In?') {
        return formatBoolean(value);
      } else if (col.field === 'totalFeePaidAtCheckout') {
        return formatCurrency(value || 0);
      } else if (col.field === 'Division') {
        return formatDivisionName(value || '');
      } else {
        return value || '';
      }
    });
    
    // Add the team row
    tableRows.push([index + 1, ...teamData]);
  });

  // Calculate column widths based on content
  const calculateColumnWidths = (visibleFields, teamsData) => {
    // First, determine the max content length for each field
    const contentLengths = {};
    
    visibleFields.forEach(field => {
      contentLengths[field.field] = field.headerName.length; // Initialize with header length
      
      // Check content length in each team
      teamsData.forEach(team => {
        const value = team[field.field];
        if (value && value.toString().length > contentLengths[field.field]) {
          contentLengths[field.field] = value.toString().length;
        }
      });
    });
    
    // Calculate total content length
    const totalContentLength = Object.values(contentLengths).reduce((sum, len) => sum + len, 0);
    
    // Available width (accounting for margins)
    const availableWidth = doc.internal.pageSize.getWidth() - 20; // 10px margin on each side
    
    // Create column styles
    const columnStyles = {
      0: { cellWidth: 10 } // # column is always smaller
    };
    
    // Allocate width proportionally based on content length
    visibleFields.forEach((field, index) => {
      const columnIndex = index + 1; // +1 because of the # column
      const proportion = contentLengths[field.field] / totalContentLength;
      
      // Cap maximum width and ensure minimum width
      let width = Math.max(Math.min(availableWidth * proportion, 50), 20);
      
      // Special handling for certain fields
      if (field.field === 'Team email' || field.field.includes('Email')) {
        width = Math.max(width, 35); // Ensure emails have enough space
      } else if (field.field === 'Team name' || field.field.includes('Name')) {
        width = Math.max(width, 30); // Ensure names have enough space
      } else if (field.type === 'Boolean' || field.field === 'Checked-In?') {
        width = 15; // Boolean fields can be narrow
      }
      
      columnStyles[columnIndex] = { cellWidth: width };
    });
    
    return columnStyles;
  };

  // Generate the report
  doc.autoTable({
    startY: 20, // Start after the header
    head: [['#', ...tableColumn]],
    body: tableRows,
    theme: 'striped',
    styles: {
      fontSize: 8, // Smaller font to fit more data
      halign: 'left',
      valign: 'middle',
      overflow: 'linebreak',
      cellPadding: 2,
    },
    headStyles: {
      fillColor: '#02133E',
      textColor: '#ffffff',
      halign: 'left',
      fontStyle: 'bold',
    },
    columnStyles: calculateColumnWidths(visibleFields, sortedTeams),
    margin: { left: 5, right: 5 }, // Reduce margins to fit more columns
    didDrawPage: function (data) {
      // Only add the timestamp header to each page
      doc.setFontSize(12);
      doc.setFont('helvetica', 'bold');
      doc.text(
        `Teams registered for ${tournamentName} as of ${currentDate}`,
        10,
        10
      );
    }
  });

  addPageNumbers(doc);

  // Save the PDF
  doc.save(`${tournamentName} ${currentYear} Team Registration Report.pdf`);
};

export const fetchAndGenerateRegistrationReport = async (
  username,
  eventId,
  eventSettings,
  tournamentName
) => {
  console.log('Fetching and generating registration report...');

  // Define environment
  const apiUrl =
    process.env.REACT_APP_NODE_ENV === 'production'
      ? process.env.REACT_APP_SERVER_URL_PRODUCTION
      : process.env.REACT_APP_SERVER_URL_STAGING;

  try {
    const response = await fetch(`${apiUrl}/api/admin_get_event_teams`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, eventId }),
    });

    if (!response.ok) {
      throw new Error(`Error fetching data: ${response.statusText}`);
    }

    const teamsData = await response.json();

    // Assuming teamsData is an object with team IDs as keys, convert it to an array
    const teamsArray = Object.values(teamsData);
    
    // Create teamsConfig from eventSettings
    const teamsConfig = [
      ...(eventSettings?.registration?.defaultTeamRegistrationFields || []),
      ...(eventSettings?.registration?.userDefinedTeamRegistrationFields || [])
    ];

    generateRegistrationReport(teamsArray, teamsConfig, tournamentName);
  } catch (error) {
    console.error('Error fetching team data or generating PDF:', error);
  }
};

