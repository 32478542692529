import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import defaultNoEventImage from '../../images/defaultNoEventImage.png';

function EventCard({ event, cardWidth, cardHeight, onEventDelete }) {
  const [logoImage, setLogoImage] = useState(defaultNoEventImage);
  const [isHovered, setIsHovered] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState('idle'); // 'idle', 'deleting', 'deleted', 'error'
  const isMobile = useMediaQuery('(max-width: 600px)');
  const navigate = useNavigate();
  const username = window.localStorage.getItem('username');

  useEffect(() => {
    if (event.eventLogoUrl) {
      setLogoImage(event.eventLogoUrl);
    }
  }, [event.eventLogoUrl]);

  const formatDate = (epochTime) => {
    const options = { month: 'short', day: 'numeric' };
    const date = new Date(Number(epochTime));
    return `${date.toLocaleDateString('en-US', options)} ${date.getFullYear()}`;
  };

  const handleNavigateToEvent = (e) => {
    e.stopPropagation();
    if (username && event.eventId) {
      navigate(`/${username}/${event.eventId}/home`);
    }
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    setDeleteStatus('deleting');
    
    const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
      ? process.env.REACT_APP_SERVER_URL_PRODUCTION
      : process.env.REACT_APP_SERVER_URL_STAGING;

    try {
      const response = await fetch(`${apiUrl}/api/delete_event`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          username,
          eventId: event.eventId
        })
      });

      if (response.ok) {
        setDeleteStatus('deleted');
        setTimeout(() => {
          setDeleteDialogOpen(false);
          onEventDelete(event.eventId);
        }, 1000); // Keep the dialog open for 1 second to show the "Deleted" message
      } else {
        setDeleteStatus('error');
        console.error('Failed to delete event');
      }
    } catch (error) {
      setDeleteStatus('error');
      console.error('Error deleting event:', error);
    }
  };

  const getDeleteButtonText = () => {
    switch (deleteStatus) {
      case 'deleting':
        return 'Deleting...';
      case 'deleted':
        return 'Deleted!';
      case 'error':
        return 'Error - Try Again';
      default:
        return 'Delete';
    }
  };

  const handleCloseDialog = () => {
    if (deleteStatus !== 'deleting') {
      setDeleteDialogOpen(false);
      // Reset the delete status when closing the dialog
      setTimeout(() => setDeleteStatus('idle'), 300);
    }
  };

  return (
    <div
      style={{
        width: cardWidth,
        height: cardHeight,
        position: 'relative',
        border: '2px solid #cccccc',
        borderRadius: '8px',
        padding: '16px',
        marginBottom: isMobile ? '16px' : '0px',
        transition: 'transform 0.3s ease',
        transform: isHovered ? 'scale(1.05)' : 'scale(1)',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Overlay on hover */}
      {isHovered && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '16px',
            borderRadius: '6px',
            zIndex: 2
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleNavigateToEvent}
            style={{ width: '80%' }}
          >
            Go To Event
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleDeleteClick}
            style={{ width: '80%' }}
          >
            Delete Event
          </Button>
        </div>
      )}

      {/* Event content */}
      <img
        src={logoImage}
        alt={event.eventTitle}
        style={{
          width: '70%',
          height: '140px',
          objectFit: 'contain',
          marginBottom: '16px'
        }}
      />
      <h3 style={{ margin: '8px 0', color: '#333', fontSize: '18px' }}>{event.eventTitle}</h3>
      <h4 style={{ margin: '4px 0', color: '#666' }}>
        {formatDate(event.eventStartDateTimeGMT)} - {formatDate(event.eventEndDateTimeGMT)}
      </h4>

      {/* Delete confirmation dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Delete Event?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure? All data for this event will be permanently deleted and the setup fee will not be refunded. 
            This action cannot be undone. If you are having technical issues and would like some assistance, 
            please contact support@customtournamentsolutions.com.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleCloseDialog} 
            disabled={deleteStatus === 'deleting'}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleConfirmDelete} 
            color={deleteStatus === 'deleted' ? 'success' : deleteStatus === 'error' ? 'error' : 'error'} 
            disabled={deleteStatus === 'deleting' || deleteStatus === 'deleted'}
            autoFocus
          >
            {getDeleteButtonText()}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EventCard;

