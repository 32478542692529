import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Alert,
  Fade,
  FormControlLabel,
  Checkbox,
  Switch,
  Card,
  CardContent,
  Grid,
  Divider,
  Radio,
  RadioGroup,
  FormLabel,
  FormGroup,
  Tooltip,
  Chip
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { toast } from 'react-toastify';

const unitSystems = [
  { value: "pounds, tenths of inches", label: "English (pounds, tenths of inches)" },
  { value: "pounds, eighths of inches", label: "English (pounds, eighths of inches)" },
  { value: "kilograms, centimeters", label: "Metric (kilograms, centimeters)" },
  { value: "kilograms, millimeters", label: "Metric (kilograms, millimeters)" }
];

const SpeciesStep = ({ data, onUpdate, isValid, featureOption, setActiveStep }) => {
  const [draftSpecies, setDraftSpecies] = useState(null);
  const [editingIndex, setEditingIndex] = useState(null); // Add state to track which species is being edited

  const FEATURE_OPTIONS = {
    NOT_NEEDED: 'notNeeded',
    CUSTOMER_PAYS: 'customerPays',
    I_PAY: 'iPay'
  };

  // Initialize with consistent data structure
  useEffect(() => {
    if (featureOption !== FEATURE_OPTIONS.NOT_NEEDED) {
      // Keep existing values if they exist
      onUpdate({
        units: data.units || 'pounds, tenths of inches',
        allowCatchPhotoUpload: data.allowCatchPhotoUpload || false,
        speciesList: data.speciesList || []
      });
    } else {
      // Reset to defaults when feature is disabled
      onUpdate({
        units: 'pounds, tenths of inches',
        allowCatchPhotoUpload: false,
        speciesList: []
      });
    }
  }, [featureOption]);

  // Update fields in parent form
  const handleUpdateField = (field, value) => {
    onUpdate({ [field]: value });
  };

  // Add new species draft
  const handleAddSpecies = () => {
    setEditingIndex(null); // Reset editing index when adding new species
    setDraftSpecies({
      species: '',
      // Scoring configuration
      scoringMethod: 'custom', // 'custom', 'weight', or 'length'
      rounding: 'down', // 'up', 'down', or 'exact'
      points: 0, // Used for custom points

      // Data collection fields
      collectTime: true,
      timeRequired: true,
      collectWeight: false,
      weightRequired: false,
      collectLength: false,
      lengthRequired: false,
      collectGirth: false,
      girthRequired: false,
      includePhoto: false,
      photoRequired: false
    });
  };

  // Update draft species fields
  const handleDraftChange = (field, value) => {
    setDraftSpecies(prev => {
      const updated = { ...prev, [field]: value };
      
      // Handle automatic requirements based on scoring method
      if (field === 'scoringMethod') {
        if (value === 'weight') {
          updated.collectWeight = true;
          updated.weightRequired = true;
        } else if (value === 'length') {
          updated.collectLength = true;
          updated.lengthRequired = true;
        }
      }

      return updated;
    });
  };

  // Save draft species to species list
  const handleSaveSpecies = () => {
    // Base validation - species name must exist
    if (!draftSpecies.species) {
      toast.error("Species name is required");
      return;
    }
    
    // For custom scoring, points must be > 0
    if (draftSpecies.scoringMethod === 'custom' && (!draftSpecies.points || draftSpecies.points <= 0)) {
      toast.error("Custom scoring requires points greater than 0");
      return;
    }
    
    // Check if at least one data collection field is selected and required
    const hasRequiredField = 
      (draftSpecies.collectTime && draftSpecies.timeRequired) ||
      (draftSpecies.collectWeight && draftSpecies.weightRequired) ||
      (draftSpecies.collectLength && draftSpecies.lengthRequired) ||
      (draftSpecies.collectGirth && draftSpecies.girthRequired) ||
      (draftSpecies.includePhoto && draftSpecies.photoRequired);
    
    if (!hasRequiredField) {
      toast.error("At least one data collection field must be marked as required");
      return; // Cannot save without at least one required data field
    }
    
    // Check for duplicate species name (except when editing the same species)
    const isDuplicate = data.speciesList?.some((species, index) => 
      species.species.toLowerCase() === draftSpecies.species.toLowerCase() && 
      index !== editingIndex
    );
    
    if (isDuplicate) {
      toast.error(`A species named "${draftSpecies.species}" already exists. Please use a different name.`);
      return;
    }
    
    // Create cleaned species object with only necessary data
    const newSpeciesData = {
      species: draftSpecies.species,
      scoringMethod: draftSpecies.scoringMethod,
      rounding: draftSpecies.rounding,
      points: draftSpecies.scoringMethod === 'custom' ? Number(draftSpecies.points) : 0,
      
      collectTime: draftSpecies.collectTime,
      timeRequired: draftSpecies.timeRequired,
      collectWeight: draftSpecies.collectWeight,
      weightRequired: draftSpecies.weightRequired,
      collectLength: draftSpecies.collectLength,
      lengthRequired: draftSpecies.lengthRequired,
      collectGirth: draftSpecies.collectGirth,
      girthRequired: draftSpecies.girthRequired,
      includePhoto: draftSpecies.includePhoto,
      photoRequired: draftSpecies.photoRequired
    };
    
    // If editing existing species, update it rather than adding new one
    if (editingIndex !== null) {
      const updatedSpeciesList = [...data.speciesList];
      updatedSpeciesList[editingIndex] = newSpeciesData;
      onUpdate({ speciesList: updatedSpeciesList });
      toast.success(`Species "${draftSpecies.species}" updated successfully`);
    } else {
      // Add as new species
      const newSpecies = [...(data.speciesList || []), newSpeciesData];
      onUpdate({ speciesList: newSpecies });
      toast.success(`Species "${draftSpecies.species}" added successfully`);
    }
    
    // Reset draft and editing state
    setDraftSpecies(null);
    setEditingIndex(null);
  };

  // Delete species from list
  const handleDeleteSpecies = (index) => {
    const speciesName = data.speciesList[index].species;
    const newSpecies = data.speciesList.filter((_, i) => i !== index);
    onUpdate({ speciesList: newSpecies });
    toast.info(`Species "${speciesName}" deleted`);
  };

  // Set up species for editing
  const handleEditSpecies = (index) => {
    setEditingIndex(index);
    setDraftSpecies({ ...data.speciesList[index] });
  };

  // Handle feature upgrade click
  const handleUpgradeClick = () => {
    setActiveStep(0); // Go back to Features step
  };

  // Validation error messages
  const getErrorMessage = () => {
    if (!data.speciesList || data.speciesList.length === 0) {
      return "Please add at least one species to continue";
    }
  
    if (draftSpecies) {
      // Check if draft species has a name
      if (!draftSpecies.species) {
        return "Species name is required";
      }
      
      // Check if species name already exists (except when editing the same species)
      const isDuplicate = data.speciesList?.some((species, index) => 
        species.species.toLowerCase() === draftSpecies.species.toLowerCase() && 
        index !== editingIndex
      );
      
      if (isDuplicate) {
        return `A species named "${draftSpecies.species}" already exists`;
      }
      
      // Check if at least one field is required
      const hasRequiredField = 
        (draftSpecies.collectTime && draftSpecies.timeRequired) ||
        (draftSpecies.collectWeight && draftSpecies.weightRequired) ||
        (draftSpecies.collectLength && draftSpecies.lengthRequired) ||
        (draftSpecies.collectGirth && draftSpecies.girthRequired) ||
        (draftSpecies.includePhoto && draftSpecies.photoRequired);
        
      if (!hasRequiredField) {
        return "At least one data collection field must be marked as required";
      }
        
      if (draftSpecies.scoringMethod === 'custom' && (!draftSpecies.points || draftSpecies.points <= 0)) {
        return "Custom scoring requires points greater than 0";
      }
        
      return "Please save or cancel your draft species to continue";
    }
  
    return "Please complete all required information to continue";
  };

  // Get weight and length units from the selected unit system
  const getUnitLabels = () => {
    const [weightUnit, lengthUnit] = (data.units || 'pounds, tenths of inches').split(', ');
    
    const weightLabel = weightUnit === 'pounds' ? 'lb' : 'kg';
    const lengthLabel = lengthUnit.includes('inches') ? 'in' : 
                        lengthUnit === 'centimeters' ? 'cm' : 'mm';
    
    return { weightLabel, lengthLabel };
  };

  // Generate a summary of data being collected for a species
  const getSpeciesSummary = (species) => {
    const { weightLabel, lengthLabel } = getUnitLabels();
    const dataFields = [];
    
    if (species.collectTime) {
      dataFields.push(`Time${species.timeRequired ? '*' : ''}`);
    }
    
    if (species.collectWeight) {
      dataFields.push(`Weight${species.weightRequired ? '*' : ''} (${weightLabel})`);
    }
    
    if (species.collectLength) {
      dataFields.push(`Length${species.lengthRequired ? '*' : ''} (${lengthLabel})`);
    }
    
    if (species.collectGirth) {
      dataFields.push(`Girth${species.girthRequired ? '*' : ''} (${lengthLabel})`);
    }
    
    if (species.includePhoto) {
      dataFields.push(`Photo${species.photoRequired ? '*' : ''}`);
    }
    
    return dataFields;
  };

  // Get scoring description
  const getScoringDescription = (species) => {
    if (species.scoringMethod === 'custom') {
      return `${species.points} points per catch`;
    } else if (species.scoringMethod === 'weight') {
      return `Points by weight (${species.rounding})`;
    } else {
      return `Points by length (${species.rounding})`;
    }
  };

  // Preview catch entry form for the current species configuration
  const CatchEntryPreview = ({ species }) => {
    const { weightLabel, lengthLabel } = getUnitLabels();
    
    // Calculate example points based on scoring method
    let examplePoints = 0;
    let exampleValue = '';
    
    if (species.scoringMethod === 'custom') {
      examplePoints = species.points;
      exampleValue = `Fixed ${examplePoints} points per catch`;
    } else if (species.scoringMethod === 'weight') {
      const exampleWeight = 1.7;
      exampleValue = `${exampleWeight} ${weightLabel}`;
      
      if (species.rounding === 'up') {
        examplePoints = Math.ceil(exampleWeight);
      } else if (species.rounding === 'down') {
        examplePoints = Math.floor(exampleWeight);
      } else { // exact
        examplePoints = exampleWeight;
      }
    } else if (species.scoringMethod === 'length') {
      const exampleLength = 12.3;
      exampleValue = `${exampleLength} ${lengthLabel}`;
      
      if (species.rounding === 'up') {
        examplePoints = Math.ceil(exampleLength);
      } else if (species.rounding === 'down') {
        examplePoints = Math.floor(exampleLength);
      } else { // exact
        examplePoints = exampleLength;
      }
    }
    
    return (
      <Card variant="outlined" sx={{ mt: 2, bgcolor: '#f9f9f9' }}>
        <CardContent>
          <Typography variant="subtitle2" gutterBottom>
            Preview: Catch Entry Form for {species.species || "This Species"}
          </Typography>
          
          <Divider sx={{ mb: 2 }} />
          
          <Grid container spacing={2}>
            {/* Points Example */}
            <Grid item xs={12}>
              <Box sx={{ 
                p: 1, 
                mb: 2, 
                border: '1px dashed #ccc', 
                borderRadius: 1, 
                bgcolor: '#f0f8ff'
              }}>
                <Typography variant="body2" fontWeight="bold">
                  Scoring Example: {exampleValue} = {examplePoints} points
                </Typography>
              </Box>
            </Grid>
            
            {/* Date/Time field (if enabled) */}
            {species.collectTime && (
              <Grid item xs={12}>
                <Box sx={{ 
                  p: 1,
                  border: '1px solid #e0e0e0',
                  borderRadius: '4px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  bgcolor: '#f5f5f5'
                }}>
                  <Typography variant="body2" color="text.secondary">
                    Date/Time Caught{species.timeRequired ? ' *' : ''}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box 
                      component="span" 
                      sx={{ 
                        mr: 1,
                        p: 0.5,
                        border: '1px solid #ccc',
                        borderRadius: 1,
                        fontSize: '0.75rem',
                        bgcolor: 'white'
                      }}
                    >
                      03/15/2025
                    </Box>
                    <Box 
                      component="span" 
                      sx={{ 
                        p: 0.5,
                        border: '1px solid #ccc',
                        borderRadius: 1,
                        fontSize: '0.75rem',
                        bgcolor: 'white'
                      }}
                    >
                      10:30 AM
                    </Box>
                  </Box>
                </Box>
              </Grid>
            )}
            
            {/* Stack the measurement fields vertically instead of side by side */}
            {/* Weight field (if enabled) */}
            {species.collectWeight && (
              <Grid item xs={12}>
                <TextField
                  disabled
                  label={`Weight${species.weightRequired ? ' *' : ''} (${weightLabel})`}
                  placeholder="0.0"
                  fullWidth
                  size="small"
                />
              </Grid>
            )}
            
            {/* Length field (if enabled) */}
            {species.collectLength && (
              <Grid item xs={12}>
                <TextField
                  disabled
                  label={`Length${species.lengthRequired ? ' *' : ''} (${lengthLabel})`}
                  placeholder="0.0"
                  fullWidth
                  size="small"
                />
              </Grid>
            )}
            
            {/* Girth field (if enabled) */}
            {species.collectGirth && (
              <Grid item xs={12}>
                <TextField
                  disabled
                  label={`Girth${species.girthRequired ? ' *' : ''} (${lengthLabel})`}
                  placeholder="0.0"
                  fullWidth
                  size="small"
                />
              </Grid>
            )}
            
            {/* Photo upload (if enabled) */}
            {species.includePhoto && (
              <Grid item xs={12}>
                <Button
                  disabled
                  variant="outlined"
                  startIcon={<PhotoCameraIcon />}
                  size="small"
                >
                  Upload Photo{species.photoRequired ? ' *' : ''}
                </Button>
              </Grid>
            )}
          </Grid>
          
          <Box mt={2}>
            <Typography variant="caption" color="text.secondary">
              * Required fields
            </Typography>
          </Box>
        </CardContent>
      </Card>
    );
  };

  return (
    <Fade in timeout={400}>
      <Box>
        {isValid === false && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {getErrorMessage()}
          </Alert>
        )}

        {/* Units Selection */}
        <Paper sx={{ p: 3, mb: 4 }}>
          <Typography variant="h6" sx={{ mb: 3 }}>
           <strong>Step 1. </strong> Select units of measurement
          </Typography>
          <FormControl fullWidth>
            <InputLabel>Units</InputLabel>
            <Select
              value={data.units || 'pounds, tenths of inches'}
              onChange={(e) => handleUpdateField('units', e.target.value)}
              label="Units"
            >
              {unitSystems.map(unit => (
                <MenuItem key={unit.value} value={unit.value}>{unit.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Paper>

        {/* Species List Paper now becomes Step 2 */}

        {/* Species List */}
        <Paper sx={{ p: 3 }}>
          <Typography variant="h6" sx={{ mb: 3 }}>
            <strong>Step 2.</strong> Create species (at least one is required)
          </Typography>

          {/* Existing Species */}
          <Stack spacing={2}>
            {data.speciesList?.map((species, index) => (
              <Paper
                key={index}
                variant="outlined"
                sx={{ p: 2 }}
              >
                <Stack direction="column" spacing={1}>
                  <Stack direction="row" alignItems="center" spacing={2} justifyContent="space-between">
                    <Typography sx={{ fontWeight: 500 }}>
                      {species.species}
                    </Typography>
                    
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography color="text.secondary" variant="body2" sx={{ mr: 1 }}>
                        {getScoringDescription(species)}
                      </Typography>
                      
                      <Tooltip title="Edit species">
                        <IconButton 
                          size="small"
                          onClick={() => handleEditSpecies(index)}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      
                      <IconButton 
                        size="small" 
                        onClick={() => handleDeleteSpecies(index)}
                        color="error"
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </Box>
                  </Stack>
                  
                  {/* Species data summary */}
                  <Box>
                    <Typography variant="body2" color="text.secondary">
                      Data collected:
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 0.5 }}>
                      {getSpeciesSummary(species).map((field, i) => (
                        <Chip 
                          key={i} 
                          label={field} 
                          size="small" 
                          variant="outlined" 
                          sx={{ fontSize: '0.7rem' }}
                        />
                      ))}
                    </Box>
                  </Box>
                </Stack>
              </Paper>
            ))}

            {/* Draft Species */}
            {draftSpecies && (
              <Paper
                variant="outlined"
                sx={{ p: 2 }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} md={7}>
                    <Stack spacing={3}>
                      <TextField
                        label="Species Name"
                        value={draftSpecies.species}
                        onChange={(e) => handleDraftChange('species', e.target.value)}
                        fullWidth
                        required
                      />
                      
                      <Box>
                        <FormLabel component="legend">Scoring Method</FormLabel>
                        <RadioGroup
                          value={draftSpecies.scoringMethod}
                          onChange={(e) => handleDraftChange('scoringMethod', e.target.value)}
                        >
                          <FormControlLabel 
                            value="custom" 
                            control={<Radio />} 
                            label="Custom Points" 
                          />
                          
                          {draftSpecies.scoringMethod === 'custom' && (
                            <Box sx={{ ml: 4, mt: 1, mb: 2 }}>
                              <TextField
                                label="Points per Catch"
                                type="number"
                                value={draftSpecies.points === 0 ? '' : draftSpecies.points}
                                onChange={(e) => handleDraftChange('points', e.target.value === '' ? 0 : Number(e.target.value))}
                                fullWidth
                                inputProps={{ min: 0, step: 1 }}
                                error={!draftSpecies.points || draftSpecies.points <= 0}
                                helperText={(!draftSpecies.points || draftSpecies.points <= 0) ? 
                                  "Points must be greater than 0" : ""}
                              />
                            </Box>
                          )}
                          
                          <FormControlLabel 
                            value="weight" 
                            control={<Radio />} 
                            label="Points Equal to Weight" 
                          />
                          
                          <FormControlLabel 
                            value="length" 
                            control={<Radio />} 
                            label="Points Equal to Length" 
                          />
                        </RadioGroup>
                        
                        {(draftSpecies.scoringMethod === 'weight' || draftSpecies.scoringMethod === 'length') && (
                          <Box sx={{ ml: 4, mt: 1, mb: 2 }}>
                            <FormLabel component="legend">Rounding Method</FormLabel>
                            <Box sx={{ mb: 1 }}>
                              <Typography variant="caption" color="text.secondary">
                                This determines how points are calculated from {draftSpecies.scoringMethod}:
                                <ul style={{ margin: '4px 0', paddingLeft: '20px' }}>
                                  <li><strong>Round Up</strong> - Example: {draftSpecies.scoringMethod === 'weight' ? '1.1 lb = 2 points' : '10.1 in = 11 points'}</li>
                                  <li><strong>Round Down</strong> - Example: {draftSpecies.scoringMethod === 'weight' ? '1.9 lb = 1 point' : '10.9 in = 10 points'}</li>
                                  <li><strong>Exact Value</strong> - Example: {draftSpecies.scoringMethod === 'weight' ? '1.5 lb = 1.5 points' : '10.5 in = 10.5 points'}</li>
                                </ul>
                              </Typography>
                            </Box>
                            <RadioGroup
                              row
                              value={draftSpecies.rounding}
                              onChange={(e) => handleDraftChange('rounding', e.target.value)}
                            >
                              <FormControlLabel 
                                value="up" 
                                control={<Radio />} 
                                label="Round Up" 
                              />
                              <FormControlLabel 
                                value="down" 
                                control={<Radio />} 
                                label="Round Down" 
                              />
                              <FormControlLabel 
                                value="exact" 
                                control={<Radio />} 
                                label="Exact Value" 
                              />
                            </RadioGroup>
                          </Box>
                        )}
                      </Box>
                      
                      <Box>
                        <FormLabel component="legend">Data Collection</FormLabel>
                        <Typography variant="caption" color="text.secondary">
                          Select data to collect for this species
                        </Typography>
                        
                        <FormGroup sx={{ mt: 1 }}>
                          {/* Date/Time */}
                          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={draftSpecies.collectTime}
                                  onChange={(e) => handleDraftChange('collectTime', e.target.checked)}
                                  disabled={false}
                                />
                              }
                              label="Date/Time Caught"
                            />
                            {draftSpecies.collectTime && (
                              <FormControlLabel
                                control={
                                  <Switch
                                    size="small"
                                    checked={draftSpecies.timeRequired}
                                    onChange={(e) => handleDraftChange('timeRequired', e.target.checked)}
                                  />
                                }
                                label="Required"
                              />
                            )}
                          </Box>
                          
                          {/* Weight */}
                          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={draftSpecies.collectWeight}
                                  onChange={(e) => handleDraftChange('collectWeight', e.target.checked)}
                                  disabled={draftSpecies.scoringMethod === 'weight'}
                                />
                              }
                              label="Weight"
                            />
                            {draftSpecies.collectWeight && (
                              <FormControlLabel
                                control={
                                  <Switch
                                    size="small"
                                    checked={draftSpecies.weightRequired}
                                    onChange={(e) => handleDraftChange('weightRequired', e.target.checked)}
                                    disabled={draftSpecies.scoringMethod === 'weight'}
                                  />
                                }
                                label="Required"
                              />
                            )}
                          </Box>
                          
                          {/* Length */}
                          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={draftSpecies.collectLength}
                                  onChange={(e) => handleDraftChange('collectLength', e.target.checked)}
                                  disabled={draftSpecies.scoringMethod === 'length'}
                                />
                              }
                              label="Length"
                            />
                            {draftSpecies.collectLength && (
                              <FormControlLabel
                                control={
                                  <Switch
                                    size="small"
                                    checked={draftSpecies.lengthRequired}
                                    onChange={(e) => handleDraftChange('lengthRequired', e.target.checked)}
                                    disabled={draftSpecies.scoringMethod === 'length'}
                                  />
                                }
                                label="Required"
                              />
                            )}
                          </Box>
                          
                          {/* Girth */}
                          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={draftSpecies.collectGirth}
                                  onChange={(e) => handleDraftChange('collectGirth', e.target.checked)}
                                />
                              }
                              label="Girth"
                            />
                            {draftSpecies.collectGirth && (
                              <FormControlLabel
                                control={
                                  <Switch
                                    size="small"
                                    checked={draftSpecies.girthRequired}
                                    onChange={(e) => handleDraftChange('girthRequired', e.target.checked)}
                                  />
                                }
                                label="Required"
                              />
                            )}
                          </Box>
                          
                          {/* Photo */}
                          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={draftSpecies.includePhoto}
                                  onChange={(e) => handleDraftChange('includePhoto', e.target.checked)}
                                />
                              }
                              label="Catch Photo"
                            />
                            {draftSpecies.includePhoto && (
                              <FormControlLabel
                                control={
                                  <Switch
                                    size="small"
                                    checked={draftSpecies.photoRequired}
                                    onChange={(e) => handleDraftChange('photoRequired', e.target.checked)}
                                  />
                                }
                                label="Required"
                              />
                            )}
                          </Box>
                        </FormGroup>
                      </Box>
                      
                      <Stack direction="row" spacing={1}>
                        <Button
                          variant="contained"
                          onClick={handleSaveSpecies}
                          disabled={
                            !draftSpecies.species || 
                            (draftSpecies.scoringMethod === 'custom' && 
                            (!draftSpecies.points || draftSpecies.points <= 0)) ||
                            // Check if at least one data collection field is required
                            !((draftSpecies.collectTime && draftSpecies.timeRequired) ||
                              (draftSpecies.collectWeight && draftSpecies.weightRequired) ||
                              (draftSpecies.collectLength && draftSpecies.lengthRequired) ||
                              (draftSpecies.collectGirth && draftSpecies.girthRequired) ||
                              (draftSpecies.includePhoto && draftSpecies.photoRequired))
                          }
                        >
                          {editingIndex !== null ? 'Update Species' : 'Save Species'}
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setDraftSpecies(null);
                            setEditingIndex(null);
                          }}
                        >
                          Cancel
                        </Button>
                      </Stack>
                    </Stack>
                  </Grid>
                  
                  <Grid item xs={12} md={5}>
                    <Paper 
                      elevation={0} 
                      sx={{ bgcolor: '#f5f5f5', p: 2, height: '100%', borderRadius: 1 }}
                    >
                      <Typography variant="subtitle2" gutterBottom>
                        Catch Entry Preview
                      </Typography>
                      <Typography variant="caption" gutterBottom>
                        This shows how the catch entry form will look for this species
                      </Typography>
                      
                      <Divider sx={{ my: 1 }} />
                      
                      <CatchEntryPreview species={draftSpecies} />
                      
                      <Box mt={3}>
                        <Alert severity="info" icon={<HelpOutlineIcon />}>
                          <Typography variant="caption">
                            <strong>Scoring method:</strong>{' '}
                            {draftSpecies.scoringMethod === 'custom' ? 
                              `Fixed ${draftSpecies.points} points per catch` : 
                              draftSpecies.scoringMethod === 'weight' ?
                                `Points equal to weight (${draftSpecies.rounding === 'up' ? 'rounded up' : 
                                 draftSpecies.rounding === 'down' ? 'rounded down' : 'exact value'})` :
                                `Points equal to length (${draftSpecies.rounding === 'up' ? 'rounded up' : 
                                 draftSpecies.rounding === 'down' ? 'rounded down' : 'exact value'})`
                            }
                          </Typography>
                        </Alert>
                      </Box>
                    </Paper>
                  </Grid>
                </Grid>
              </Paper>
            )}

            {!draftSpecies && (
              <Button
                startIcon={<AddIcon />}
                onClick={handleAddSpecies}
                variant="outlined"
              >
                Add Species
              </Button>
            )}
          </Stack>
        </Paper>
      </Box>
    </Fade>
  );

};

export default SpeciesStep;

