import React, { useEffect, useState } from 'react';
import { 
  Drawer, 
  ListItemButton, 
  ListItemIcon, 
  Toolbar, 
  IconButton, 
  Box, 
  Stack, 
  CircularProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { signOut } from 'firebase/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from "../firebase";
import { toast } from 'react-toastify';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';
import { colorConfig, sizeConfig } from "../config/config";
import { appRoutes } from "../routes/routes";

const Sidebar = ({ sidebarExpanded, setSidebarExpanded }) => {
  const [sidebarInitialized, setSidebarInitialized] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const username = window.localStorage.getItem('username');

  useEffect(() => {
    if (!isMobile) {
      const storedSidebarState = localStorage.getItem("sidebarExpanded");
      if (storedSidebarState !== null) {
        setSidebarExpanded(JSON.parse(storedSidebarState));
      }
    } else {
      setSidebarExpanded(false);
    }
    setSidebarInitialized(true);
  }, [setSidebarExpanded, isMobile]);

  const handleLogout = () => {
    const user = JSON.parse(window.localStorage.getItem('user'));
    if (user?.isMasterAdmin) {
      window.localStorage.setItem('user', null);
      window.localStorage.setItem('username', null);
      toast.success('Admin logout successful! Redirecting...', { autoClose: 2000 });
      setTimeout(() => navigate('/'), 2000);
    } else {
      signOut(auth)
        .then(() => {
          window.localStorage.setItem('user', null);
          window.localStorage.setItem('username', null);
          toast.success('Logout successful! Redirecting...', { autoClose: 2000 });
          setTimeout(() => navigate('/'), 2000);
        })
        .catch(() => {
          toast.error("There was an error while attempting to log you out. Please contact the site administrator.");
        });
    }
  };

  if (!sidebarInitialized) return <CircularProgress />;

  return (
    <Drawer
      variant="permanent"
      open={true}
      sx={{
        width: isMobile ? "70px" : sidebarExpanded ? sizeConfig.sidebar.width : "70px",
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: isMobile ? "70px" : sidebarExpanded ? sizeConfig.sidebar.width : "70px",
          boxSizing: "border-box",
          borderRight: "0px",
          background: 'linear-gradient(180deg, #2C3E50 0%, #3A506B 100%)',
          color: colorConfig.sidebar.color,
          transition: "all 0.3s ease-in-out",
          boxShadow: '4px 0 15px rgba(0, 0, 0, 0.1)',
          display: 'flex',
          flexDirection: 'column',
        }
      }}
    >
      {/* Header */}
      <Toolbar
        sx={{
          justifyContent: isMobile ? 'center' : sidebarExpanded ? 'space-between' : 'center',
          padding: "24px 16px",
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
          flexShrink: 0,
        }}
      >
        {!isMobile && !sidebarExpanded ? (
          <IconButton
            onClick={() => setSidebarExpanded(true)}
            sx={{
              color: 'white',
              '&:hover': { background: 'rgba(255, 255, 255, 0.1)' },
            }}
          >
            <MenuIcon />
          </IconButton>
        ) : (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: "100%" }}
          >
            {/* Company Name Display */}
            <Box
              component="span"
              sx={{
                fontSize: sidebarExpanded ? '1.2rem' : '1rem',
                fontWeight: 700,
                color: '#fff',
                letterSpacing: '0.5px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {sidebarExpanded ? "CTS" : "CTS"}
            </Box>
            {!isMobile && (
              <IconButton
                onClick={() => setSidebarExpanded(false)}
                sx={{
                  color: 'white',
                  '&:hover': { background: 'rgba(255, 255, 255, 0.1)' },
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </Stack>
        )}
      </Toolbar>

      {/* Main Menu Items */}
      <Box sx={{ flexGrow: 1, overflow: 'auto', pt: 2 }}>
        {appRoutes.map((route, index) =>
          route.sidebarProps ? (
            <ListItemButton
              key={index}
              onClick={() => {
                const pathWithUsername = username
                  ? route.path.replace(':username', username)
                  : route.path;
                navigate(pathWithUsername);
              }}
              sx={{
                my: 0.5,
                mx: 1,
                borderRadius: '8px',
                transition: 'all 0.2s ease',
                "&:hover": { background: 'rgba(255, 255, 255, 0.1)', transform: 'translateX(4px)' },
                paddingY: "12px",
                paddingX: sidebarExpanded ? "16px" : "12px",
                justifyContent: sidebarExpanded || isMobile ? "initial" : "center",
                backgroundColor: location.pathname.includes(route.path) ? 'rgba(255, 255, 255, 0.15)' : "transparent",
                ...(location.pathname.includes(route.path) && {
                  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
                }),
              }}
            >
              <ListItemIcon
                sx={{
                  color: 'white',
                  justifyContent: sidebarExpanded ? 'left' : 'center',
                  minWidth: sidebarExpanded ? 40 : 'auto',
                }}
              >
                {route.sidebarProps.icon}
              </ListItemIcon>
              {sidebarExpanded && (
                <span style={{
                  fontSize: '0.95rem',
                  fontWeight: location.pathname.includes(route.path) ? 500 : 400,
                }}>
                  {route.sidebarProps.displayText}
                </span>
              )}
            </ListItemButton>
          ) : null
        )}
      </Box>

      {/* Footer/Logout Section */}
      <Box sx={{ flexShrink: 0, borderTop: '1px solid rgba(255, 255, 255, 0.1)', mt: 'auto' }}>
        <ListItemButton
          onClick={handleLogout}
          sx={{
            "&:hover": { background: 'rgba(255, 255, 255, 0.1)' },
            paddingY: "16px",
            paddingX: sidebarExpanded ? "24px" : "12px",
            justifyContent: sidebarExpanded || isMobile ? "initial" : "center",
          }}
        >
          <ListItemIcon
            sx={{
              color: 'rgba(255, 255, 255, 0.8)',
              justifyContent: sidebarExpanded ? 'left' : 'center',
            }}
          >
            <LogoutIcon />
          </ListItemIcon>
          {sidebarExpanded && (
            <span style={{ color: 'rgba(255, 255, 255, 0.8)' }}>Logout</span>
          )}
        </ListItemButton>
      </Box>
    </Drawer>
  );
};

export default Sidebar;

