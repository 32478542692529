import React from 'react';
import { 
  Typography, 
  Box, 
  FormGroup, 
  FormControlLabel, 
  Switch, 
  Paper, 
  Divider,
  Alert 
} from '@mui/material';

const PageVisibilitySection = ({ eventSettings, onChange }) => {
  // Initialize pageVisibility with defaults if not present
  const pageVisibility = eventSettings.pageVisibility || {
    leaderboard: true,
    registration: true,
    sponsors: true,
    pots: true
  };
  
  // Handle toggle changes
  const handleToggleChange = (page) => (event) => {
    const updatedVisibility = {
      ...pageVisibility,
      [page]: event.target.checked
    };
    
    onChange('pageVisibility', updatedVisibility);
  };

  // Determine if features are enabled (to show appropriate context)
  const isRegistrationEnabled = 
    eventSettings.features?.onlineTeamRegistration === "customerPays" || 
    eventSettings.features?.onlineTeamRegistration === "iPay";
    
  const isSponsorFeatureEnabled = 
    eventSettings.features?.onlineSponsorRegistration === "customerPays" || 
    eventSettings.features?.onlineSponsorRegistration === "iPay";
    
  const isPotsFeatureEnabled = 
    eventSettings.features?.fishPots === "customerPays" || 
    eventSettings.features?.fishPots === "iPay";

  return (
    <Paper elevation={2} sx={{ p: 3, mb: 3 }}>
      <Typography variant="h6" gutterBottom>
        Page Visibility Settings
      </Typography>
      <Typography variant="body2" color="text.secondary" paragraph>
        Something not looking right? Control which pages are visible to users on your tournament website. 
        Toggling a page "off" will hide it from the navigation menu and home page until you decide to turn it back "on" again.
      </Typography>
      
      <Divider sx={{ mb: 2 }} />
      
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={pageVisibility.leaderboard}
              onChange={handleToggleChange('leaderboard')}
              name="leaderboard"
            />
          }
          label="Leaderboard"
        />
        
        {isRegistrationEnabled && (
          <FormControlLabel
            control={
              <Switch
                checked={pageVisibility.registration}
                onChange={handleToggleChange('registration')}
                name="registration"
              />
            }
            label="Registration"
          />
        )}
        
        {isSponsorFeatureEnabled && (
          <FormControlLabel
            control={
              <Switch
                checked={pageVisibility.sponsors}
                onChange={handleToggleChange('sponsors')}
                name="sponsors"
              />
            }
            label="Sponsors"
          />
        )}
        
        {isPotsFeatureEnabled && (
          <FormControlLabel
            control={
              <Switch
                checked={pageVisibility.pots}
                onChange={handleToggleChange('pots')}
                name="pots"
              />
            }
            label="Pots"
          />
        )}
      </FormGroup>
      
      {(!pageVisibility.leaderboard || !pageVisibility.registration || !pageVisibility.sponsors || !pageVisibility.pots) && (
        <Alert severity="info" sx={{ mt: 2 }}>
          Hidden pages will not be accessible from the navigation menu or home page, but direct URLs will still work. This feature is intended for temporarily hiding pages during setup or when resolving issues.
        </Alert>
      )}
    </Paper>
  );
};

export default PageVisibilitySection;

