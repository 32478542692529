import { AppBar, Toolbar, Typography, Box } from "@mui/material";
import { colorConfig, sizeConfig } from "../config/config";
import AnimatedPage from "../pages/AnimatedPage";

const Topbar = ({ sidebarExpanded, pageTitle }) => {
  return (
    <AnimatedPage>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          width: `calc(100% - ${sidebarExpanded ? sizeConfig.sidebar.width : "70px"})`,
          ml: sidebarExpanded ? sizeConfig.sidebar.width : "70px",
          background: '#ffffff',
          borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.03)',
          transition: 'all 0.3s ease-in-out',
          pb: 2, // Add padding bottom
        }}
      >
        <Toolbar sx={{ 
          minHeight: { xs: '48px', sm: '64px' }, // Reduced height
          justifyContent: "center",
          px: 4
        }}>
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              py: 1,
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: '-4px', // Moved up slightly
                left: '50%',
                transform: 'translateX(-50%)',
                width: '40%',
                height: '2px', // Slightly thinner line
                background: 'linear-gradient(90deg, #2C3E50 0%, #3A506B 100%)',
                borderRadius: '2px',
                opacity: 0.7,
                transition: 'width 0.3s ease'
              },
              '&:hover::after': {
                width: '60%',
              }
            }}
          >
            <Typography 
              variant="h5" // Reduced from h4 to h5
              sx={{ 
                fontWeight: 600, // Slightly reduced weight
                background: 'linear-gradient(45deg, #2C3E50 30%, #3A506B 90%)',
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                color: 'transparent',
                textTransform: 'uppercase',
                letterSpacing: '0.5px',
                mb: 0.5
              }}
            >
              {pageTitle}
            </Typography>
            <Typography 
              variant="caption" // Reduced from subtitle2 to caption
              sx={{
                color: '#666',
                fontWeight: 500,
                letterSpacing: '1px',
                textTransform: 'uppercase'
              }}
            >
              Custom Tournament Solutions
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
    </AnimatedPage>
  );
};

export default Topbar;

