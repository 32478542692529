import React, { useState } from 'react';
import { 
  Card, 
  CardContent, 
  Typography, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  Grid, 
  Card as MuiCard,
  Box,
  IconButton,
  DialogActions,
  Button
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const CreateEventCard = ({ 
  cardWidth, 
  cardHeight, 
  open, 
  close, 
  pastEvents = [] 
}) => {
  const [selectedAction, setSelectedAction] = useState(null);

  const handleCreateNewEvent = () => {
    open(null);
    setSelectedAction(null);
  };

  const handleCopyEvent = (event) => {
    open(event);
    setSelectedAction(null);
  };

  const handleClose = () => {
    setSelectedAction(null);
  };

  return (
    <>
      <Card 
        onClick={() => setSelectedAction('options')}
        sx={{
          width: cardWidth,
          height: cardHeight,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          transition: 'all 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
            boxShadow: 3
          }
        }}
      >
        <CardContent sx={{ textAlign: 'center' }}>
          <AddCircleOutlineIcon 
            sx={{ 
              fontSize: 80, 
              color: 'primary.main',
              mb: 2
            }} 
          />
          <Typography variant="h6">Create New Event</Typography>
          
          {pastEvents.length > 0 && (
            <Typography 
              variant="body2" 
              color="text.secondary" 
              sx={{ mt: 1 }}
            >
              Click to create or copy an event
            </Typography>
          )}
        </CardContent>
      </Card>

      {/* Create Event Modal */}
      <Dialog 
        open={selectedAction !== null} 
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        {selectedAction === 'options' && (
          <>
            <DialogTitle>Create Event</DialogTitle>
            <DialogContent>
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                gap: 4,
                mt: 2
              }}>
                {/* New Event Option */}
                <MuiCard 
                  onClick={handleCreateNewEvent}
                  sx={{ 
                    width: 250,
                    cursor: 'pointer',
                    p: 3,
                    textAlign: 'center',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      boxShadow: 4,
                      transform: 'scale(1.05)',
                      backgroundColor: 'action.hover'
                    }
                  }}
                >
                  <AddCircleOutlineIcon 
                    sx={{ 
                      fontSize: 60, 
                      color: 'primary.main',
                      mb: 2
                    }} 
                  />
                  <Typography variant="h6" sx={{ mb: 1 }}>
                    Start from Scratch
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Create a brand new event with no prior template
                  </Typography>
                </MuiCard>

                {/* Copy Past Event Option */}
                <MuiCard 
                  onClick={pastEvents.length > 0 ? () => setSelectedAction('copyEvents') : null}
                  sx={{ 
                    width: 250,
                    cursor: pastEvents.length > 0 ? 'pointer' : 'default',
                    p: 3,
                    textAlign: 'center',
                    transition: 'all 0.3s ease',
                    opacity: pastEvents.length > 0 ? 1 : 0.5,
                    '&:hover': pastEvents.length > 0 ? {
                      boxShadow: 4,
                      transform: 'scale(1.05)',
                      backgroundColor: 'action.hover'
                    } : {}
                  }}
                >
                  <ContentCopyIcon 
                    sx={{ 
                      fontSize: 60, 
                      color: pastEvents.length > 0 ? 'secondary.main' : 'text.disabled',
                      mb: 2
                    }} 
                  />
                  <Typography variant="h6" sx={{ mb: 1, color: pastEvents.length > 0 ? 'inherit' : 'text.disabled' }}>
                    Copy Past Event
                  </Typography>
                  <Typography variant="body2" color={pastEvents.length > 0 ? 'text.secondary' : 'text.disabled'}>
                    {pastEvents.length > 0 
                      ? 'Use a previous event as a template'
                      : 'No past events available'}
                  </Typography>
                </MuiCard>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
          </>
        )}

        {/* Past Events List */}
        {selectedAction === 'copyEvents' && (
          <>
            <DialogTitle>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton 
                  onClick={() => setSelectedAction('options')} 
                  sx={{ mr: 2 }}
                >
                  <ArrowBackIcon />
                </IconButton>
                Select an Event to Copy
              </Box>
            </DialogTitle>
            <DialogContent>
              <Typography 
                variant="body2" 
                color="warning.main" 
                sx={{ 
                  mb: 2, 
                  textAlign: 'center',
                  fontStyle: 'italic'
                }}
              >
                Note: Event dates will not be copied. You'll need to set new dates.
              </Typography>
              {pastEvents.length === 0 ? (
                <Typography variant="body1" sx={{ textAlign: 'center', color: 'text.secondary' }}>
                  No past events available to copy.
                </Typography>
              ) : (
                <Grid container spacing={2} justifyContent="center">
                  {pastEvents.map((event) => (
                    <Grid item xs={12} sm={6} md={4} key={event.eventId}>
                      <MuiCard 
                        onClick={() => handleCopyEvent(event)}
                        sx={{ 
                          cursor: 'pointer',
                          transition: 'all 0.3s ease',
                          '&:hover': {
                            boxShadow: 3,
                            transform: 'scale(1.02)',
                            backgroundColor: 'action.hover'
                          }
                        }}
                      >
                        <CardContent>
                          <Typography variant="h6" sx={{ mb: 1 }}>
                            {event.eventTitle}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {new Date(Number(event.eventStartDateTimeGMT)).toLocaleDateString()} - 
                            {new Date(Number(event.eventEndDateTimeGMT)).toLocaleDateString()}
                          </Typography>
                        </CardContent>
                      </MuiCard>
                  </Grid>
                  ))}
                </Grid>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setSelectedAction('options')}>Back</Button>
              <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export default CreateEventCard;

