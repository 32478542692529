import React, { useState, useEffect } from 'react';
import { Grid, Paper, Avatar, TextField, Button, Typography, CircularProgress } from '@mui/material';
import { AdminPanelSettings } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AnimatedPage from '../../pages/AnimatedPage';
import { colorConfig } from '../../config/config';

const AdminAuthenticate = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [targetUsername, setTargetUsername] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [existingUsernames, setExistingUsernames] = useState([]);

  const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
    ? process.env.REACT_APP_SERVER_URL_PRODUCTION
    : process.env.REACT_APP_SERVER_URL_STAGING;

  const paperStyle = {
    padding: 20,
    height: 'auto',
    width: '90%',
    maxWidth: '600px',
    margin: '20px auto'
  };

  const avatarStyle = { 
    backgroundColor: colorConfig.sidebar.bg, 
    margin: 'auto' 
  };

  const buttonStyle = { 
    backgroundColor: colorConfig.sidebar.bg 
  };

  const gridContainerStyle = {
    minHeight: '100vh',
    backgroundColor: colorConfig.authenticateBg,
  };

  // Fetch existing usernames on component mount
  useEffect(() => {
    const fetchUsernames = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/get_usernames`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
        if (data.success) {
          setExistingUsernames(data.usernames);
        } else {
          throw new Error(data.message || 'Failed to fetch usernames');
        }
      } catch (error) {
        console.error('Error fetching usernames:', error);
        toast.error('Error loading user data');
      }
    };

    fetchUsernames();
  }, [apiUrl]);

  const handleAdminLogin = async () => {
    if (!email || !password || !targetUsername) {
      toast.error('Please fill in all fields');
      return;
    }

    if (!existingUsernames.includes(targetUsername)) {
      toast.error('Target username does not exist');
      return;
    }

    setIsSubmitting(true);

    try {
      // Check if credentials match master admin
      if (email === process.env.REACT_APP_ADMIN_USERNAME && 
          password === process.env.REACT_APP_ADMIN_PASSWORD) {
        
        // Get the user's profile data first
        const userProfileResponse = await fetch(`${apiUrl}/api/load_user_profile_by_username`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username: targetUsername })
        });

        const profileData = await userProfileResponse.json();
        
        if (!profileData.success) {
          throw new Error('Failed to load user profile data');
        }

        // Store admin session data with the user's profile info
        window.localStorage.setItem('user', JSON.stringify({
          email: process.env.REACT_APP_ADMIN_USERNAME,
          isMasterAdmin: true,
          uid: profileData.userData.userId  // Store the actual user's ID
        }));
        window.localStorage.setItem('username', targetUsername);

        toast.success('Admin login successful! Redirecting...', { autoClose: 2000 });
        setTimeout(() => navigate(`/${targetUsername}/dashboard`), 2000);
      } else {
        throw new Error('Invalid admin credentials');
      }
    } catch (error) {
      console.error('Admin login error:', error);
      toast.error(error.message);
      setIsSubmitting(false);
    }
  };

  return (
    <AnimatedPage>
      <Grid container justifyContent="center" alignItems="center" style={gridContainerStyle}>
        <Paper elevation={10} style={paperStyle}>
          <Grid align='center'>
            <Avatar style={avatarStyle}>
              <AdminPanelSettings />
            </Avatar>
          </Grid>
          <br />
          <Typography variant="h5" align="center">
            Master Admin Login
          </Typography>
          <br />
          <TextField
            label='Admin Email'
            placeholder='Enter admin email'
            fullWidth
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={isSubmitting}
            margin="normal"
          />
          <TextField
            label='Admin Password'
            placeholder='Enter admin password'
            type='password'
            fullWidth
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={isSubmitting}
            margin="normal"
          />
          <TextField
            label='Target Username'
            placeholder='Enter user profile to access'
            fullWidth
            required
            value={targetUsername}
            onChange={(e) => setTargetUsername(e.target.value)}
            disabled={isSubmitting}
            margin="normal"
          />
          <br />
          <br />
          <Button
            type='submit'
            variant='contained'
            style={buttonStyle}
            fullWidth
            onClick={handleAdminLogin}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} /> : "Login as Admin"}
          </Button>
        </Paper>
      </Grid>
    </AnimatedPage>
  );
};

export default AdminAuthenticate;

