import React, { useState, useEffect } from 'react';
import { Box, InputLabel, Button, Dialog, DialogContent, DialogTitle, IconButton, Stack } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from "@mui/icons-material/Close";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

const DeleteTeamModal = (props) => {
  const [deleteInfo, setDeleteInfo] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    setDeleteInfo(props.deleteInfo);
    console.log(props.deleteInfo)
  }, [props.deleteInfo]);

  const formatPhoneNumber = (value) => {
    if (!value) return '';
    const cleaned = value.toString().replace(/\D/g, '');
    if (cleaned.length !== 10) return value;
    return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
  };

  const renderFieldValue = (key, value) => {
    // Special handling for division fields
    if (["Division", "Age Group", "Gender"].includes(key)) {
      return value || 'Not specified';
    }
  
    const allFields = [
      ...(props.eventSettings?.registration?.defaultTeamRegistrationFields || []), 
      ...(props.eventSettings?.registration?.userDefinedTeamRegistrationFields || [])
    ];
    
    const fieldConfig = allFields.find(field => field.field === key);
  
    if (!fieldConfig) return value || ''; // If the field is not part of registration, return the value anyway
  
    // Handle different field types
    if (fieldConfig.isBoolean || fieldConfig.type === 'Boolean') {
      return value ? 'Yes' : 'No';
    }
  
    if (fieldConfig.isPhoneNumber || fieldConfig.type === 'Phone') {
      return formatPhoneNumber(value);
    }
  
    return value || '';
  };
  
  const handleClose = () => {
    setIsSubmitting(false);
    setIsSubmitted(false);
    props.close();
  };

  const delayRefresh = () => {
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const handleDelete = async () => {
    setIsSubmitting(true);

    try {
      let apiUrl = process.env.REACT_APP_NODE_ENV === "staging"
        ? process.env.REACT_APP_SERVER_URL_STAGING
        : process.env.REACT_APP_SERVER_URL_PRODUCTION;

      const response = await fetch(`${apiUrl}/api/admin_delete_event_teams`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          username: props.username,
          eventId: props.eventId,
          teamId: props.deleteInfo.teamId,
        })
      });

      if (response.ok) {
        toast.success('Team deleted successfully.');
        setIsSubmitted(true);
        delayRefresh();
      } else {
        toast.error('Error deleting team.');
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error('Error deleting team:', error);
      toast.error('Error deleting team.');
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      {deleteInfo &&
        <Dialog open={props.status} onClose={handleClose} fullWidth maxWidth="sm">
          <form action="/" method="POST" onSubmit={(e) => { e.preventDefault(); handleClose(); }}>
            <DialogTitle>
              Delete Team
              <IconButton onClick={handleClose} style={{ float: 'right' }}>
                <CloseIcon color="primary" />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Stack spacing={2} margin={2}>
                {/* Display only fields from allFields */}
                {Object.entries(deleteInfo)
                  .filter(([key]) => {
                    // Always include Division and standard Team fields
                    if (["Division", "Team name", "Team photo"].includes(key)) {
                      return true;
                    }
                    
                    // Include fields from registration configuration
                    const allFields = [
                      ...(props.eventSettings?.registration?.defaultTeamRegistrationFields || []), 
                      ...(props.eventSettings?.registration?.userDefinedTeamRegistrationFields || [])
                    ];
                    return allFields.some(field => field.field === key);
                  })
                  .sort(([keyA], [keyB]) => {
                    // Put "Team name" first, then Division, then "Team photo" last
                    if (keyA === "Team name") return -1;
                    if (keyB === "Team name") return 1;
                    if (keyA === "Division" && keyB !== "Team name") return -1;
                    if (keyB === "Division" && keyA !== "Team name") return 1;
                    if (keyA === "Team photo") return 1;
                    if (keyB === "Team photo") return -1;
                    return 0;
                  })
                  .map(([key, value]) => (
                    <div key={key}>
                      <strong>{key}:</strong>{' '}
                      {key === "Team photo" && value ? (
                        <Box mt={1}>
                          <img
                            src={value}
                            alt="Team Thumbnail"
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                            }}
                            onError={(e) => {
                              e.target.src = "../../images/defaultNoImage.png";
                            }}
                          />
                        </Box>
                      ) : (
                        renderFieldValue(key, value)
                      )}
                    </div>
                  ))}

                <InputLabel style={{ fontWeight: 'bold', color: 'red', wordWrap: 'break-word' }}>
                  Are you sure you want to delete this team?
                </InputLabel>

                {!isSubmitted ? (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleDelete}
                    disabled={isSubmitting}
                    startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
                  >
                    {isSubmitting ? "Deleting..." : "Yes, Delete This Team"}
                  </Button>
                ) : (
                  <h3>Deleted successfully!</h3>
                )}
              </Stack>
            </DialogContent>
          </form>
        </Dialog>
      }
    </div>
  );
};

export default DeleteTeamModal;

