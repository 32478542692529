import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import AddTeamModal from '../modals/AddTeamModal';
import EditTeamModal from '../modals/EditTeamModal';
import DeleteTeamModal from '../modals/DeleteTeamModal';

import AddCatchModal from '../modals/AddCatchModal';
import EditCatchModal from '../modals/EditCatchModal';
import DeleteCatchModal from '../modals/DeleteCatchModal';

import AddAnnouncementModal from '../modals/AddAnnouncementModal';
import EditAnnouncementModal from '../modals/EditAnnouncementModal';
import DeleteAnnouncementModal from '../modals/DeleteAnnouncementModal';

import AddPotModal from '../modals/AddPotModal';
import EditPotModal from '../modals/EditPotModal';
import DeletePotModal from '../modals/DeletePotModal';

import AddSponsorModal from '../modals/AddSponsorModal';
import EditSponsorModal from '../modals/EditSponsorModal';
import DeleteSponsorModal from '../modals/DeleteSponsorModal';

import AdminToolbar from '../toolbars/AdminToolbar';

import defaultNoImage from '../../images/defaultNoImage.png';
import { catchesConfig, announcementsConfig, potsConfig } from "../../config/config";

function CrudTable(props) {

  const { username, eventId } = useParams(); // Get username and eventId from the URL

  // STATE - STYLING
  const [buttonLabel, setButtonLabel] = useState();
  const [style, setStyle] = useState();
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [tableProperties, setTableProperties] = useState([]);
  const [scroll, setScroll] = useState();
  const [initialState, setInitialState] = useState();
  const [pageSizeOptions, setPageSizeOptions] = useState();
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

  // STATE - DATA
  const [editInfo, setEditInfo] = useState();
  const [isEditModalOpen, setIsEditModalOpen] = useState();
  const [deleteInfo, setDeleteInfo] = useState();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState();

  // STATE - TEAMS
  const teamsConfig = [
    ...(props.eventSettings?.registration?.defaultTeamRegistrationFields || []),
    ...(props.eventSettings?.registration?.userDefinedTeamRegistrationFields || [])
  ];

  // STATE - SPONSORS
  const sponsorsConfig = [
    { field: "name", headerName: "Sponsor Name", type: "Text", isRequired: true, isVisible: true, flex: 3, width: 300 },
    { field: "email", headerName: "Contact Email", type: "Email", isRequired: true, isVisible: true, flex: 3, width: 300 },
    { field: "website", headerName: "Website", type: "Text", isRequired: false, isVisible: true, flex: 3, width: 300 },
    { field: "tier", headerName: "Tier", type: "Text", isRequired: true, isVisible: true, flex: 2, width: 150 },
    { field: "amount", headerName: "Amount", type: "Number", isRequired: true, isVisible: true, isCurrency: true, flex: 2, width: 150 },
    { field: "registrationTimestamp", headerName: "Registration Date", type: "DateTime", isRequired: false, isVisible: true, flex: 3, width: 250 },
    { field: "logoUrl", headerName: "Logo", type: "Image", isRequired: false, isVisible: true, flex: 2, width: 150 }
  ];

  const isManualCreationAllowed = () => {
    // If it's not a revenue-share package, always allow manual creation
    if (props.eventSettings?.packageType !== 'revenue-share') {
      return true;
    }

    // Feature-specific checks
    if (props.tableType === 'Teams') {
      // Allow manual team creation if team registration is not needed or 
      // if neither sponsors nor pots are enabled with revenue share but at least one is needed
      return props.eventSettings?.features?.onlineTeamRegistration === 'notNeeded' || 
            ((props.eventSettings?.features?.onlineSponsorRegistration === 'customerPays' || 
              props.eventSettings?.features?.onlineSponsorRegistration === 'iPay' || 
              props.eventSettings?.features?.fishPots === 'customerPays' || 
              props.eventSettings?.features?.fishPots === 'iPay') && 
              props.eventSettings?.features?.onlineTeamRegistration === 'notNeeded');
    } else if (props.tableType === 'Sponsors') {
      // For Sponsors, check if sponsor registration is disabled
      return props.eventSettings?.features?.onlineSponsorRegistration === 'notNeeded';
    } else if (props.tableType === 'Pots') {
      // For Pots, check if pots feature is disabled
      return props.eventSettings?.features?.fishPots === 'notNeeded';
    }
    
    // For other table types, always allow
    return true;
  };

  // INITIALIZE
  useEffect(() => {
    console.log('Here are the crud table rows:', props.rows)
    fetchConfigAndData(); // Load config and fetch data
  }, []);  // add tabName as a dependency to re-fetch when the tab changes

  const fetchConfigAndData = async () => {
    try {
      let rawColumns;

      if (props.tableType === "Teams") {

        console.log('here is teamsConfig', teamsConfig)

        // Initial raw column mapping
        rawColumns = teamsConfig.map((column) => {
          const updatedColumn = {
            ...column,
            field: column.field,  // Ensure field is set correctly
            headerName: column.headerName || column.field,  // Use headerName if available
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
          };

          // Handle phone numbers
          if (column.type === "Phone") {
            updatedColumn.valueFormatter = (value) => {
              if (!value) return '';
              
              // Convert to string and clean the phone number
              const cleaned = value.toString().replace(/\D/g, '');
              if (cleaned.length !== 10) return value.toString();
              
              return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
            };
          }

          // Handle images
          if (column.type === "Image") {
            updatedColumn.renderCell = (params) => {
              const imageUrl = params.value || defaultNoImage;
              return (
                <img
                  src={imageUrl}
                  alt="Team Thumbnail"
                  style={{ width: "50px", height: "50px", objectFit: "cover" }}
                  onError={(e) => {
                    e.target.src = defaultNoImage;
                  }}
                />
              );
            };
          }

          // Handle dateTime
          if (column.field === "dateTime" || column.field === "timestamp") {
            updatedColumn.type = 'dateTime';
            updatedColumn.valueGetter = (value) => {
              return value ? new Date(value) : null;
            }
            updatedColumn.valueFormatter = (value) => {
              return dayjs(value).format('hh:mm A, MMM Do YYYY'); 
            };
          }

          return updatedColumn;
        });

        // Add division columns if they're used in the event
        if (props.eventSettings?.registration?.includeDivisions) {
          rawColumns.push({
            field: "Division",
            headerName: "Division",
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
            flex: 2,
            width: 150,
            type: "string"
          });
        }
        
        // Add age group column if it's used in the event
        if (props.eventSettings?.registration?.includeAgeBrackets) {
          rawColumns.push({
            field: "Age Group",
            headerName: "Age Group",
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
            flex: 2,
            width: 150,
            type: "string"
          });
        }
        
        // Add gender column if it's used in the event
        if (props.eventSettings?.registration?.includeGenders) {
          rawColumns.push({
            field: "Gender",
            headerName: "Gender",
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
            flex: 2,
            width: 120,
            type: "string"
          });
        }

        // Add action column
        rawColumns.push({
          field: 'actions',
          type: 'actions',
          headerName: 'Actions',
          headerClassName: 'super-app-theme--header',
          width: 100,
          cellClassName: 'actions',
          getActions: ({ id }) => [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={() => handleEdit(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => handleDelete(id)}
              color="inherit"
            />,
          ],
        });
      } else if (props.tableType === "Catches") {

        const [weightUnit, lengthUnit] = (props.eventSettings?.units || "pounds, eighths of inches").split(", ");
        const weightLabel = weightUnit === "pounds" ? "lb" : "kg";
        const lengthLabel = lengthUnit.includes("inches") ? "in" : 
                          lengthUnit === "centimeters" ? "cm" : "mm";
        
        rawColumns = catchesConfig.map((column) => {
          const updatedColumn = {
            ...column,
            field: column.field,  // Ensure field is set correctly
            headerName: column.headerName || column.field,  // Use headerName if available
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
          };

          // Handle images
          if (column.type === "Image") {
            updatedColumn.renderCell = (params) => {
              const imageUrl = params.value || defaultNoImage;
              return (
                <img
                  src={imageUrl}
                  alt="Team Thumbnail"
                  style={{ width: "50px", height: "50px", objectFit: "cover" }}
                  onError={(e) => {
                    e.target.src = defaultNoImage;
                  }}
                />
              );
            };
          }

          // Handle dateTime column
          if (column.field === "dateTime" || column.field === "timestamp") {
            updatedColumn.type = 'dateTime';
            updatedColumn.valueGetter = (value) => {
              return value ? new Date(value) : null;
            }
            updatedColumn.valueFormatter = (value) => {
              return dayjs(value).format('hh:mm A, MMM Do YYYY'); 
            };
          }

          // Handle weight, length, and girth columns
          if (column.field === "weight") {
            updatedColumn.headerName = `Weight (${weightLabel})`;
          }
          if (column.field === "length") {
            updatedColumn.headerName = `Length (${lengthLabel})`;
          }
          if (column.field === "girth") {
            updatedColumn.headerName = `Girth (${lengthLabel})`;
          }

          return updatedColumn;
        });

        // Add action column
        rawColumns.push({
          field: 'actions',
          type: 'actions',
          headerName: 'Actions',
          headerClassName: 'super-app-theme--header',
          width: 100,
          cellClassName: 'actions',
          getActions: ({ id }) => [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={() => handleEdit(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => handleDelete(id)}
              color="inherit"
            />,
          ],
        });
      } else if (props.tableType === "Announcements") {
        rawColumns = announcementsConfig.map((column) => {
          const updatedColumn = {
            ...column,
            field: column.field,
            headerName: column.headerName || column.field,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
          };
      
          // Handle dateTime column
          if (column.field === "dateTime" || column.field === "timestamp") {
            updatedColumn.type = 'dateTime';
            updatedColumn.valueGetter = (value) => {
              return value ? new Date(value) : null;
            }
            updatedColumn.valueFormatter = (value) => {
              return dayjs(value).format('hh:mm A, MMM Do YYYY'); 
            };
          }
      
          return updatedColumn;
        });

        rawColumns.push({
          field: 'actions',
          type: 'actions',
          headerName: 'Actions',
          headerClassName: 'super-app-theme--header',
          width: 100,
          cellClassName: 'actions',
          getActions: ({ id }) => [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={() => handleEdit(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => handleDelete(id)}
              color="inherit"
            />,
          ],
        });
        
      } else if (props.tableType === "Pots") {
        rawColumns = potsConfig.map((column) => {
          const updatedColumn = {
            ...column,
            field: column.field,
            headerName: column.headerName || column.field,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
          };
      
          // Handle dateTime column
          if (column.field === "dateTime" || column.field === "registrationTimestamp") {
            updatedColumn.type = 'dateTime';
            updatedColumn.valueGetter = (value) => {
              return value ? new Date(value) : null;
            }
            updatedColumn.valueFormatter = (value) => {
              return dayjs(value).format('hh:mm A, MMM Do YYYY'); 
            };
          }

          // Handle currency columns for Pots
          if (column.isCurrency) {
            updatedColumn.valueFormatter = (value) => {
              return formatCurrency(value);
            };
          }
      
          return updatedColumn;
        });
      
        // Add action column
        rawColumns.push({
          field: 'actions',
          type: 'actions',
          headerName: 'Actions',
          headerClassName: 'super-app-theme--header',
          width: 100,
          cellClassName: 'actions',
          getActions: ({ id }) => [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={() => handleEdit(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => handleDelete(id)}
              color="inherit"
            />,
          ],
        });
      } else if (props.tableType === "Sponsors") {
        rawColumns = sponsorsConfig.map((column) => {
          const updatedColumn = {
            ...column,
            field: column.field,
            headerName: column.headerName || column.field,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
          };
      
          // Handle dateTime column for registration timestamp
          if (column.field === "registrationTimestamp") {
            updatedColumn.type = 'dateTime';
            updatedColumn.valueGetter = (value) => {
              return value ? new Date(value) : null;
            }
            updatedColumn.valueFormatter = (value) => {
              return dayjs(value).format('hh:mm A, MMM Do YYYY'); 
            };
          }

          // Handle currency columns for amount
          if (column.isCurrency) {
            updatedColumn.valueFormatter = (value) => {
              return formatCurrency(value);
            };
          }
      
          // Handle logo image
          if (column.type === "Image") {
            updatedColumn.renderCell = (params) => {
              const imageUrl = params.value || defaultNoImage;
              return (
                <img
                  src={imageUrl}
                  alt="Sponsor Logo"
                  style={{ width: "50px", height: "50px", objectFit: "contain" }}
                  onError={(e) => {
                    e.target.src = defaultNoImage;
                  }}
                />
              );
            };
          }
      
          return updatedColumn;
        });
      
        // Add action column
        rawColumns.push({
          field: 'actions',
          type: 'actions',
          headerName: 'Actions',
          headerClassName: 'super-app-theme--header',
          width: 100,
          cellClassName: 'actions',
          getActions: ({ id }) => [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={() => handleEdit(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => handleDelete(id)}
              color="inherit"
            />,
          ],
        });
      }

      // Dynamically adjust column width based on screen size
      const screenWidth = window.innerWidth;
      rawColumns.forEach(column => {
        if (screenWidth <= 750) { // Mobile
          column.width = column.width || 150; // Default to 150px if no width is set
          delete column.flex; // No flex on mobile
        } else if (screenWidth <= 1024) { // Tablet
          column.width = column.width || 200; // Default to 200px if no width is set
          delete column.flex; // No flex on tablet
        } else { // Desktop and larger
          column.flex = column.flex || 1; // Flex instead of fixed width
          delete column.width; // Remove width property on desktop
        }
      });

      // Update state
      setButtonLabel(props.buttonLabel);
      setStyle(props.style);
      setTableProperties(props.tableProperties);
      setScroll(props.scroll);
      setInitialState(props.initialState);
      setRows(props.rows);
      setColumns(rawColumns);
      setPageSizeOptions(props.pageSizeOptions);

      // Dynamically set column visibility
      const visibilityModel = {};
      rawColumns.forEach(col => {
        visibilityModel[col.field] = col.isVisible !== false;
      });
      setColumnVisibilityModel(visibilityModel);

    } catch (error) {
      console.log('There was an error loading the data for the CRUD Table: ' + error);
    }
  };

  // HELPERS
  const handleOpenAddModal = () => {
    if (isManualCreationAllowed()) {
      props.openAddModal();
    } else {
      // Show message for revenue share events
      const featureType = props.tableType.toLowerCase();
      let message = '';
      
      if (props.tableType === 'Teams') {
        message = 'Manual team creation is disabled for revenue share events. Teams must register through the public registration page.';
      } else if (props.tableType === 'Sponsors') {
        message = 'Manual sponsor creation is disabled for revenue share events. Sponsors must register through the public sponsors page.';
      } else if (props.tableType === 'Pots') {
        message = 'Manual pot entry is disabled for revenue share events. Teams must register for pots through the public pots page.';
      } else {
        message = `Manual ${featureType} creation is disabled for revenue share events.`;
      }
      
      toast.info(message);
    }
  };

  const handleCloseAddModal = () => {props.closeAddModal()};

  const openEditModal = () => {setIsEditModalOpen(true)};    // Edit

  const closeEditModal = () => {
    setEditInfo();
    setIsEditModalOpen(false);
  }

  const handleEdit = async (id) => {
    const rowToEdit = props.rows.find((row) => row.announcementId === id || row.teamId === id || row.catchId === id || row.potId === id || row.sponsorId === id);
    if (rowToEdit) {
      console.log("Selected row " + id + " to edit. The info is: ", rowToEdit);
      setEditInfo(rowToEdit);
      openEditModal();
    } else {
      console.error("Row with id " + id + " not found.");
    }
  };

  const openDeleteModal = () => {setIsDeleteModalOpen(true)};    // Delete

  const closeDeleteModal = () => {
    setDeleteInfo();
    setIsDeleteModalOpen(false);
  } 

  const handleDelete = async (id) => {
    const rowToDelete = props.rows.find((row) => row.announcementId === id || row.teamId === id || row.catchId === id || row.potId === id || row.sponsorId === id);
    if (rowToDelete) {
      console.log("Selected row " + id + " to delete. The info is: ", rowToDelete);
      setDeleteInfo(rowToDelete);
      openDeleteModal();
    } else {
      console.error("Row with id " + id + " not found.");
    }
  };

  const formatCurrency = (value) => {
    if (!value && value !== 0) return '';
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);
  };

  return (
    <div style={style}>
      {/* Custom Add Button with Revenue Share Check */}
      <div className="button-container" style={{ margin: '20px' }}>
        {props.buttonLabel && (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenAddModal}
              style={{ marginBottom: '10px' }}
              disabled={!isManualCreationAllowed()}
            >
              {props.buttonLabel}
            </Button>
            
            {/* Revenue Share Message */}
            {!isManualCreationAllowed() && (
              <Typography 
                variant="caption" 
                color="textSecondary" 
                style={{ 
                  display: 'block', 
                  marginBottom: '20px',
                  backgroundColor: '#f8f9fa',
                  padding: '10px',
                  borderRadius: '4px',
                  border: '1px solid #e0e0e0'
                }}
              >
                Manual creation is disabled because you've selected revenue sharing for this feature.
                {props.tableType === 'Teams' ? ' Teams must register through the public registration page.' : 
                props.tableType === 'Sponsors' ? ' Sponsors must register through the public sponsors page.' : 
                props.tableType === 'Pots' ? ' Teams must register for pots through the public pots page.' : ''}
              </Typography>
            )}
          </>
        )}
      </div>

      {/* DELETE */}
      { (deleteInfo && props.tableType === "Teams") && 
        <DeleteTeamModal 
          eventSettings={props.eventSettings}
          username={username}
          eventId={eventId}
          tableType={props.tableType}
          tableProperties={tableProperties}
          deleteInfo={deleteInfo} 
          status={isDeleteModalOpen} 
          open={openDeleteModal} 
          close={closeDeleteModal} 
        />
      }
      { (deleteInfo && props.tableType === "Catches") && 
        <DeleteCatchModal 
          eventSettings={props.eventSettings}
          username={username}
          eventId={eventId}
          tableType={props.tableType}
          tableProperties={tableProperties}
          deleteInfo={deleteInfo} 
          status={isDeleteModalOpen} 
          open={openDeleteModal} 
          close={closeDeleteModal} 
        />
      }
      { (deleteInfo && props.tableType === "Announcements") &&  
        <DeleteAnnouncementModal 
          eventSettings={props.eventSettings}  
          username={username}
          eventId={eventId}
          tableType={props.tableType}
          tableProperties={tableProperties}
          deleteInfo={deleteInfo} 
          status={isDeleteModalOpen} 
          open={openDeleteModal} 
          close={closeDeleteModal} 
        />
      }
      { (deleteInfo && props.tableType === "Pots") && 
        <DeletePotModal 
          eventSettings={props.eventSettings}
          username={username}
          eventId={eventId}
          tableType={props.tableType}
          tableProperties={tableProperties}
          deleteInfo={deleteInfo} 
          status={isDeleteModalOpen} 
          open={openDeleteModal} 
          close={closeDeleteModal} 
        />
      }
      { (deleteInfo && props.tableType === "Sponsors") && 
        <DeleteSponsorModal 
          eventSettings={props.eventSettings}
          username={username}
          eventId={eventId}
          tableType={props.tableType}
          tableProperties={tableProperties}
          deleteInfo={deleteInfo} 
          status={isDeleteModalOpen} 
          open={openDeleteModal} 
          close={closeDeleteModal} 
        />
      }

      {/* EDIT */}
      { (editInfo && props.tableType === "Teams") && 
        <EditTeamModal 
          eventSettings={props.eventSettings}
          username={username}
          eventId={eventId}
          tableType={props.tableType}
          tableProperties={tableProperties}
          editInfo={editInfo} 
          status={isEditModalOpen} 
          open={openEditModal} 
          close={closeEditModal} 
          startDate={props.startDate}
          endDate={props.endDate} 
        />
      }
      { (editInfo && props.tableType === "Catches") && 
        <EditCatchModal 
          eventSettings={props.eventSettings}
          username={username}
          eventId={eventId}
          tableType={props.tableType}
          tableProperties={tableProperties}
          editInfo={editInfo} 
          status={isEditModalOpen} 
          open={openEditModal} 
          close={closeEditModal} 
          startDate={props.startDate}
          endDate={props.endDate} 
        />
      }
      { (editInfo && props.tableType === "Announcements") && 
        <EditAnnouncementModal  
          eventSettings={props.eventSettings}
          username={username}
          eventId={eventId}
          tableType={props.tableType}
          tableProperties={tableProperties}
          editInfo={editInfo} 
          status={isEditModalOpen} 
          open={openEditModal} 
          close={closeEditModal} 
          startDate={props.startDate}
          endDate={props.endDate} 
        />
      }
      { (editInfo && props.tableType === "Pots") && 
        <EditPotModal 
          eventSettings={props.eventSettings}
          username={username}
          eventId={eventId}
          tableType={props.tableType}
          tableProperties={tableProperties}
          editInfo={editInfo} 
          status={isEditModalOpen} 
          open={openEditModal} 
          close={closeEditModal} 
          startDate={props.startDate}
          endDate={props.endDate} 
        />
      }
      { (editInfo && props.tableType === "Sponsors") && 
        <EditSponsorModal 
          eventSettings={props.eventSettings}
          username={username}
          eventId={eventId}
          tableType={props.tableType}
          tableProperties={tableProperties}
          editInfo={editInfo} 
          status={isEditModalOpen} 
          open={openEditModal} 
          close={closeEditModal} 
          startDate={props.startDate}
          endDate={props.endDate} 
        />
      }
   
      <div style={style}>

        {/* ADD */}
        { (props.tableType === "Teams") && 
          <AddTeamModal 
            eventSettings={props.eventSettings}
            username={username}
            eventId={eventId}
            isAdmin={true}
            tableStyle={props.tableStyle}
            today={props.today} 
            startDate={props.startDate}
            endDate={props.endDate} 
            status={props.addStatus} 
            open={props.openAddModal} 
            close={props.closeAddModal}  
          />
        }
        { (props.tableType === "Catches") && 
          <AddCatchModal 
            eventSettings={props.eventSettings}
            username={username}
            eventId={eventId}
            isAdmin={true}
            tableStyle={props.tableStyle}
            today={props.today} 
            startDate={props.startDate}
            endDate={props.endDate} 
            status={props.addStatus} 
            open={props.openAddModal} 
            close={props.closeAddModal}
            catchRows={props.rows}  
          />
        }
        { (props.tableType === "Announcements") && 
          <AddAnnouncementModal 
            eventSettings={props.eventSettings}
            username={username}
            eventId={eventId}
            isAdmin={true}
            tableStyle={props.tableStyle}
            today={props.today} 
            startDate={props.startDate}
            endDate={props.endDate} 
            status={props.addStatus} 
            open={props.openAddModal} 
            close={props.closeAddModal}  
          />
        }
        { (props.tableType === "Pots") && 
          <AddPotModal 
            eventSettings={props.eventSettings}
            username={username}
            eventId={eventId}
            isAdmin={true}
            tableStyle={props.tableStyle}
            today={props.today} 
            startDate={props.startDate}
            endDate={props.endDate} 
            status={props.addStatus} 
            open={props.openAddModal} 
            close={props.closeAddModal}  
          />
        }
        { (props.tableType === "Sponsors") && 
          <AddSponsorModal 
            eventSettings={props.eventSettings}
            username={username}
            eventId={eventId}
            isAdmin={true}
            tableStyle={props.tableStyle}
            today={props.today} 
            startDate={props.startDate}
            endDate={props.endDate} 
            status={props.addStatus} 
            open={props.openAddModal} 
            close={props.closeAddModal}  
          />
        }

        {/* TABLE */}
        <DataGrid
          VerticalContentAlignment="center"
          rows={rows || []}
          columns={columns || []}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
          getRowId={row => {
            if (props.tableType === "Teams") return row.teamId;
            if (props.tableType === "Catches") return row.catchId;
            if (props.tableType === "Announcements") return row.announcementId;
            if (props.tableType === "Pots") return row.potId;
            if (props.tableType === "Sponsors") return row.sponsorId;
            return row.id; // As a fallback, but ideally every row should have a unique identifier
          }}
          sx={{
            overflowX: scroll,
            '.MuiDataGrid-toolbarContainer .MuiButton-root': {
                backgroundColor: 'white', // Button background color
                color: "black",
                '&:hover': {
                  backgroundColor: '#d3d3d3', // Hover background color
                },
              },
              // Other customizations
              '.MuiDataGrid-toolbarContainer': {
                color: "black",
              },
            '.MuiDataGrid-row.Mui-odd': {
              backgroundColor: 'rgba(234, 234, 234)',
            },
            '.MuiDataGrid-columnHeaderTitleContainer': {
              fontSize: '16px',
              backgroundColor: props.eventSettings.backgroundColor,
              color: props.eventSettings.headlineTextColor,
              ".MuiSvgIcon-root": {
                color: props.eventSettings.headlineTextColor,
              }
            },
            '& .super-app-theme--header': {
              backgroundColor: props.eventSettings.backgroundColor,
              color: 'white',
              fontSize: '16px',
            },
            '& .MuiDataGrid-cell': {
              justifyContent: 'center',
              textAlign: 'center',
              fontSize: '16px',
              color: "black",
            },
          }}
          initialState={initialState}
          pageSizeOptions={pageSizeOptions}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
          }
          slots={{ toolbar: AdminToolbar }}
          slotProps={{
            toolbar: { 
              handleOpenAddModal,
              handleCloseAddModal, 
              buttonLabel,
            },
          }}
        />
      </div>
    </div>
  );
}

export default CrudTable;

