import React, { useState } from 'react';
import {
  Box,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Alert,
  Stack,
  Fade,
  Button,
  InputAdornment,
  IconButton,
  Paper,
  Popover,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SketchPicker } from 'react-color';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import InfoIcon from '@mui/icons-material/Info';
import Resizer from "react-image-file-resizer";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { toast } from 'react-toastify';

const BasicInfoStep = ({ data, onUpdate, isValid }) => {

  const [colorPickerAnchor, setColorPickerAnchor] = useState(null);
  const [activeColorField, setActiveColorField] = useState(null);

  const CONTENT_TYPES = {
    RULES: {
      none: "None",
      file: "Upload File (PDF, Image)", // Removed DOC
      link: "Link to Webpage",
      text: "Direct Text Entry"
    },
    SCHEDULE: {
      none: "None",
      file: "Upload File (PDF, Image)", // Removed DOC
      link: "Link to Webpage",
      text: "Direct Text Entry"
    },
    WEBSITE: {
      none: "None",
      link: "Link to Webpage"
    }
  };

  const handleColorPickerOpen = (event, fieldName) => {
    setColorPickerAnchor(event.currentTarget);
    setActiveColorField(fieldName);
  };

  const handleColorPickerClose = () => {
    setColorPickerAnchor(null);
    setActiveColorField(null);
  };

  const handleColorChange = (color) => {
    onUpdate({ [activeColorField]: color.hex });
  };

  const handleFileUpload = async (event, type) => {
    const file = event.target.files?.[0];
    if (file) {
      const maxSize = 5 * 1024 * 1024; // 5MB limit
      if (file.size > maxSize) {
        toast.error("File size should not exceed 5MB");
        return;
      }
  
      if (type === 'rules') {
        onUpdate({
          rulesFile: file,
          rulesFileName: file.name,
          rulesType: 'file'
        });
      } else if (type === 'schedule') {
        onUpdate({
          scheduleFile: file,
          scheduleFileName: file.name,
          scheduleType: 'file'
        });
      }
    }
  };

  const handleLogoUpload = async (event) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        const resizedImage = await new Promise((resolve) => {
          Resizer.imageFileResizer(
            file,
            300,
            300,
            'PNG',
            80,
            0,
            (uri) => {
              resolve(uri);
            },
            'base64'
          );
        });
  
        // Convert base64 to blob
        const response = await fetch(resizedImage);
        const blob = await response.blob();
  
        onUpdate({
          logo: blob,
          logoName: file.name
        });
      } catch (error) {
        console.error('Error resizing image:', error);
      }
    }
  };
  
  const handleRemoveLogo = () => {
    onUpdate({
      logo: null,
      logoName: null
    });
  };

  const getErrorMessage = () => {
    const basicInfo = data;
  
    if (!basicInfo.title?.trim()) {
      return "Please enter an event title";
    }
    if (!basicInfo.subheadline?.trim()) {
      return "Please enter an event subheadline";
    }
    if (!basicInfo.startDate || !basicInfo.endDate) {
      return "Please select both start and end dates with times";
    }
    if (basicInfo.endDate.isBefore(basicInfo.startDate)) {
      return "End date and time cannot be before start date and time";
    }
  
    return "Please complete all required fields to continue";
  };
  
  // Preview component remains the same as before
  const EventPreview = () => (
    <Paper 
      elevation={1}
      sx={{ 
        p: 3, 
        mb: 4,
        bgcolor: data.backgroundColor || '#87CEEB',
        color: data.headlineTextColor || '#000000',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2
      }}
    >
      {data.logo && (
        <Box
          component="img"
          src={URL.createObjectURL(data.logo)}
          alt="Event Logo"
          sx={{ 
            width: 100,
            height: 100,
            objectFit: 'contain'
          }}
        />
      )}
      <Typography variant="h4" align="center" sx={{ fontWeight: 600 }}>
        {data.title || 'Your Event Title'}
      </Typography>
      <Typography variant="body1" align="center">
        {data.subheadline || 'Event subheadline will appear here'}
      </Typography>
    </Paper>
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Fade in timeout={400}>
        <Box>
          {isValid === false && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {getErrorMessage()}
            </Alert>
          )}

          <EventPreview />

          <Stack spacing={3}>
            {/* Event Title */}
            <TextField
              label="Event Title"
              value={data.title || ''}
              onChange={(e) => onUpdate({ title: e.target.value })}
              fullWidth
              required
              placeholder="e.g. Legends Tournament 2024"
            />

            {/* Subheadline */}
            <TextField
              label="Event Subheadline"
              value={data.subheadline || ''}
              onChange={(e) => onUpdate({ subheadline: e.target.value })}
              fullWidth
              required
              multiline
              rows={2}
              placeholder="Add a brief description or welcome message"
            />

            {/* Colors */}
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                label="Background Color"
                value={data.backgroundColor || '#87CEEB'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Box
                        onClick={(e) => handleColorPickerOpen(e, 'backgroundColor')}
                        sx={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1
                        }}
                      >
                        <Box
                          sx={{
                            width: 24,
                            height: 24,
                            borderRadius: '4px',
                            bgcolor: data.backgroundColor || '#87CEEB',
                            border: '2px solid #e0e0e0'
                          }}
                        />
                        <ColorLensIcon sx={{ color: 'action.active' }} />
                      </Box>
                    </InputAdornment>
                  ),
                  readOnly: true
                }}
                fullWidth
              />

              <TextField
                label="Text Color"
                value={data.headlineTextColor || '#000000'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Box
                        onClick={(e) => handleColorPickerOpen(e, 'headlineTextColor')}
                        sx={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1
                        }}
                      >
                        <Box
                          sx={{
                            width: 24,
                            height: 24,
                            borderRadius: '4px',
                            bgcolor: data.headlineTextColor || '#000000',
                            border: '2px solid #e0e0e0'
                          }}
                        />
                        <ColorLensIcon sx={{ color: 'action.active' }} />
                      </Box>
                    </InputAdornment>
                  ),
                  readOnly: true
                }}
                fullWidth
              />
            </Stack>

            {/* Logo Upload */}
            <Box>
              <Typography variant="subtitle2" sx={{ mb: 1 }}>
                Event Logo (Optional)
              </Typography>
              <Box
                sx={{
                  border: '2px dashed',
                  borderColor: 'divider',
                  borderRadius: 1,
                  p: 3,
                  textAlign: 'center',
                  position: 'relative'
                }}
              >
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleLogoUpload}
                  style={{ display: 'none' }}
                  id="logo-upload"
                />
                {!data.logo ? (
                  <label htmlFor="logo-upload">
                    <Button
                      component="span"
                      variant="outlined"
                      startIcon={<InsertPhotoIcon />}
                    >
                      Upload Logo
                    </Button>
                  </label>
                ) : (
                  <Box sx={{ position: 'relative', display: 'inline-block' }}>
                    <Box
                      component="img"
                      src={URL.createObjectURL(data.logo)}
                      alt="Event Logo"
                      sx={{ 
                        width: 100,
                        height: 100,
                        objectFit: 'contain'
                      }}
                    />
                    <IconButton
                      onClick={handleRemoveLogo}
                      sx={{
                        position: 'absolute',
                        top: -8,
                        right: -8,
                        bgcolor: 'background.paper',
                        boxShadow: 1,
                        '&:hover': {
                          bgcolor: 'error.light',
                          color: 'white'
                        }
                      }}
                      size="small"
                    >
                      <DeleteOutlineIcon fontSize="small" />
                    </IconButton>
                  </Box>
                )}
              </Box>
            </Box>

            {/* Event Dates with Time Selection */}
            <Box>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <Box sx={{ flex: 1 }}>
                  <DateTimePicker
                    label="Event Start Date & Time"
                    value={data.startDate || null}
                    onChange={(date) => onUpdate({ startDate: date })}
                    sx={{ width: '100%' }}
                    timeSteps={{ minutes: 1 }}
                    slotProps={{
                      textField: {
                        required: true,
                        fullWidth: true,
                        helperText: "Select start date and time"
                      },
                    }}
                  />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <DateTimePicker
                    label="Event End Date & Time"
                    value={data.endDate || null}
                    onChange={(date) => onUpdate({ endDate: date })}
                    minDateTime={data.startDate || null}
                    sx={{ width: '100%' }}
                    timeSteps={{ minutes: 1 }}
                    slotProps={{
                      textField: {
                        required: true,
                        fullWidth: true,
                        helperText: data.startDate ? "Must be after start date and time" : "Select a start date and time first"
                      },
                    }}
                  />
                </Box>
              </Stack>
              
              {/* Date Change Disclaimer */}
              <Alert 
                severity="info" 
                icon={<InfoIcon />}
                sx={{ 
                  mt: 2,
                  '& .MuiAlert-message': {
                    width: '100%',
                  }
                }}
              >
                <Typography variant="body2">
                  <strong>Important:</strong> Event dates and times cannot be edited after event creation. Please ensure they are correct.
                </Typography>
              </Alert>
            </Box>

            {/* Countdown Timer Option */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={data.showCountdown || false}
                  onChange={(e) => onUpdate({ showCountdown: e.target.checked })}
                />
              }
              label="Show countdown timer on event page"
            />
          </Stack>

          <Stack spacing={3} sx={{ mt: 4 }}>
            {/* Rules Section */}
            <FormControl fullWidth>
              <InputLabel>Rules Format</InputLabel>
              <Select
                value={data.rulesType || 'none'}
                onChange={(e) => onUpdate({ 
                  rulesType: e.target.value,
                  rulesContent: '',
                  rulesLink: '',
                  rulesFile: null,
                  rulesFileName: null
                })}
                label="Rules Format"
              >
                {Object.entries(CONTENT_TYPES.RULES).map(([value, label]) => (
                  <MenuItem key={value} value={value}>{label}</MenuItem>
                ))}
              </Select>
            </FormControl>

            {data.rulesType === 'file' && (
              <Box>
                <input
                  type="file"
                  accept=".pdf,.png,.jpg,.jpeg"
                  onChange={(e) => handleFileUpload(e, 'rules')}
                  style={{ display: 'none' }}
                  id="rules-upload"
                />
                <label htmlFor="rules-upload">
                  <Button
                    component="span"
                    variant="outlined"
                    startIcon={<AttachFileIcon />}
                    fullWidth
                  >
                    {data.rulesFileName || 'Upload Rules File'}
                  </Button>
                </label>
                {data.rulesFileName && (
                  <FormHelperText>Current file: {data.rulesFileName}</FormHelperText>
                )}
              </Box>
            )}

            {data.rulesType === 'link' && (
              <TextField
                label="Rules Link"
                value={data.rulesLink || ''}
                onChange={(e) => onUpdate({ rulesLink: e.target.value })}
                fullWidth
                placeholder="https://"
              />
            )}

            {data.rulesType === 'text' && (
              <TextField
                label="Rules Content"
                value={data.rulesContent || ''}
                onChange={(e) => onUpdate({ rulesContent: e.target.value })}
                fullWidth
                multiline
                rows={4}
                placeholder="Enter your rules here..."
              />
            )}

            {/* Schedule Section */}
            <FormControl fullWidth>
              <InputLabel>Schedule Format</InputLabel>
              <Select
                value={data.scheduleType || 'none'}
                onChange={(e) => onUpdate({ 
                  scheduleType: e.target.value,
                  scheduleContent: '',
                  scheduleLink: '',
                  scheduleFile: null,
                  scheduleFileName: null
                })}
                label="Schedule Format"
              >
                {Object.entries(CONTENT_TYPES.SCHEDULE).map(([value, label]) => (
                  <MenuItem key={value} value={value}>{label}</MenuItem>
                ))}
              </Select>
            </FormControl>

            {data.scheduleType === 'file' && (
              <Box>
                <input
                  type="file"
                  accept=".pdf,.png,.jpg,.jpeg"
                  onChange={(e) => handleFileUpload(e, 'schedule')}
                  style={{ display: 'none' }}
                  id="schedule-upload"
                />
                <label htmlFor="schedule-upload">
                  <Button
                    component="span"
                    variant="outlined"
                    startIcon={<AttachFileIcon />}
                    fullWidth
                  >
                    {data.scheduleFileName || 'Upload Schedule File'}
                  </Button>
                </label>
                {data.scheduleFileName && (
                  <FormHelperText>Current file: {data.scheduleFileName}</FormHelperText>
                )}
              </Box>
            )}

            {data.scheduleType === 'link' && (
              <TextField
                label="Schedule Link"
                value={data.scheduleLink || ''}
                onChange={(e) => onUpdate({ scheduleLink: e.target.value })}
                fullWidth
                placeholder="https://"
              />
            )}

            {data.scheduleType === 'text' && (
              <TextField
                label="Schedule Content"
                value={data.scheduleContent || ''}
                onChange={(e) => onUpdate({ scheduleContent: e.target.value })}
                fullWidth
                multiline
                rows={4}
                placeholder="Enter your schedule here..."
              />
            )}

            {/* Website Section */}
            <FormControl fullWidth>
              <InputLabel>External Website</InputLabel>
              <Select
                value={data.websiteType || 'none'}
                onChange={(e) => onUpdate({ 
                  websiteType: e.target.value,
                  websiteLink: ''
                })}
                label="External Website"
              >
                {Object.entries(CONTENT_TYPES.WEBSITE).map(([value, label]) => (
                  <MenuItem key={value} value={value}>{label}</MenuItem>
                ))}
              </Select>
            </FormControl>

            {data.websiteType === 'link' && (
              <TextField
                label="Website Link"
                value={data.websiteLink || ''}
                onChange={(e) => onUpdate({ websiteLink: e.target.value })}
                fullWidth
                placeholder="https://"
              />
            )}
          </Stack>

          {/* Color Picker Popover */}
          <Popover
            open={Boolean(colorPickerAnchor)}
            anchorEl={colorPickerAnchor}
            onClose={handleColorPickerClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Box sx={{ p: 1 }}>
              <SketchPicker
                color={activeColorField === 'backgroundColor' ? 
                  data.backgroundColor || '#87CEEB' : 
                  data.headlineTextColor || '#000000'
                }
                onChangeComplete={(color) => handleColorChange(color)}
              />
            </Box>
          </Popover>
        </Box>
      </Fade>
    </LocalizationProvider>
  );
};

export default BasicInfoStep;

