import React, { useEffect, useState } from 'react';
import './TeamsHeroSection.css';

const TeamsHeroSection = ({ username, eventId }) => {
  const [teamNames, setTeamNames] = useState([]);
  const [totalTeams, setTotalTeams] = useState(0);
  const [checkedInTeams, setCheckedInTeams] = useState(0);

  useEffect(() => {
    const fetchTeamsData = async () => {
      const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
        ? process.env.REACT_APP_SERVER_URL_PRODUCTION
        : process.env.REACT_APP_SERVER_URL_STAGING;

      try {
        const response = await fetch(`${apiUrl}/api/admin_get_event_teams`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username, eventId }),
        });

        const data = await response.json();
        if (data) {
          const teamNamesList = Object.values(data).map((team) => team['Team name'] || 'Unknown Team');
          setTeamNames(teamNamesList);
          setTotalTeams(teamNamesList.length);
          const checkedInCount = Object.values(data).filter((team) => team['Checked-In?']).length;
          setCheckedInTeams(checkedInCount);
        } else {
          console.error('Error fetching teams data');
        }
      } catch (error) {
        console.error('Error fetching teams data:', error);
      }
    };

    fetchTeamsData();
  }, [username, eventId]);

  return (
    <section className="teams-hero-section">
      <div className="container">
        {/* Left Side: Title and Subheadline */}
        <div className="teams-text">
          <h1 className="title">Meet the Teams</h1>
          <p className="subheadline">
            Total registered: {totalTeams}
            <br />
            Total checked-in: {checkedInTeams}
          </p>
        </div>

        {/* Right Side: Vertical Scrolling Chiron */}
        <div className="teams-chiron">
          <div className="chiron-wrapper">
            {teamNames.length > 0 ? (
              teamNames.map((team, index) => (
                <div key={index} className="chiron-item">
                  {team}
                </div>
              ))
            ) : (
              <p>No teams registered yet.</p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamsHeroSection;

