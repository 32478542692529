import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { auth } from '../../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { Grid, Paper, Avatar, TextField, Button, CircularProgress } from '@mui/material';
import { LockOutlined } from '@mui/icons-material';

const EventAuthenticate = () => {
  const { username, eventId } = useParams();
  const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
    ? process.env.REACT_APP_SERVER_URL_PRODUCTION
    : process.env.REACT_APP_SERVER_URL_STAGING;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const paperStyle = { padding: 20, height: 400, width: 350, margin: 20 };
  const avatarStyle = { backgroundColor: '#1876D1' };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
  
    try {
      // Check if credentials match master admin
      if (email === process.env.REACT_APP_ADMIN_USERNAME && 
          password === process.env.REACT_APP_ADMIN_PASSWORD) {
        // Store master admin auth
        window.localStorage.setItem(`eventAuth_${username}_${eventId}`, JSON.stringify({
          username,
          eventId,
          isMasterAdmin: true,
          email: process.env.REACT_APP_ADMIN_USERNAME,
          timestamp: Date.now()
        }));
        
        window.location.reload();
        return;
      }

      // Regular firebase auth flow
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const verifyResponse = await fetch(`${apiUrl}/api/verify_event_ownership`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          userId: userCredential.user.uid,
          username,
          eventId
        })
      });
  
      const verifyData = await verifyResponse.json();
      if (!verifyData.success) {
        throw new Error("User does not have access to this event");
      }
  
      // Store regular auth
      window.localStorage.setItem(`eventAuth_${username}_${eventId}`, JSON.stringify({
        username,
        eventId,
        userId: userCredential.user.uid,
        email: userCredential.user.email,
        timestamp: Date.now()
      }));
  
      window.location.reload();
  
    } catch (error) {
      toast.error(error.message);
      setIsSubmitting(false);
    }
  };

  return (
    <Grid>
      <Paper variant='elevation' elevation={10} style={paperStyle}>
        <Grid align='center'>
          <Avatar style={avatarStyle}>
            <LockOutlined />
          </Avatar>
        </Grid>
        <br />
        <h2>Administrator Login</h2>
        <br />
        <TextField
          variant="outlined"
          label='Email'
          placeholder="Enter admin email"
          fullWidth
          required
          onChange={(e) => setEmail(e.target.value)}
          disabled={isSubmitting || isSubmitted}
        />
        <br /><br />
        <TextField
          variant="outlined"
          label='Password'
          placeholder="Enter admin password"
          fullWidth
          required
          type='password'
          onChange={(e) => setPassword(e.target.value)}
          disabled={isSubmitting || isSubmitted}
        />
        <br /><br /><br />
        
        {!isSubmitted ? (
          <Button
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
            onClick={handleLogin}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} /> : "Sign In"}
          </Button>
        ) : (
          <Button
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
            disabled
          >
            Loading...
          </Button>
        )}
      </Paper>
    </Grid>
  );
};

export default EventAuthenticate;

