import jsPDF from 'jspdf';
import 'jspdf-autotable';
import dayjs from 'dayjs';

const addPageNumbers = (doc) => {
  const pageCount = doc.internal.getNumberOfPages();
  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.setFontSize(10);
    doc.text(
      `${i} of ${pageCount}`,
      doc.internal.pageSize.getWidth() - 25,
      doc.internal.pageSize.getHeight() - 10
    );
  }
};

const getUnitLabels = (eventSettings) => {
  // Check both possible paths for units
  const units = eventSettings?.units || eventSettings?.species?.units || "pounds, tenths of inches";
  const [weightUnit, lengthUnit] = units.split(", ");
  
  return {
    weight: weightUnit === "pounds" ? "lb" : "kg",
    length: lengthUnit.includes("inches") ? "in" : 
            lengthUnit === "centimeters" ? "cm" : "mm"
  };
};

// Format numeric values for the report
const formatNumericValue = (value, includeZero = false) => {
  if (value === undefined || value === null || value === '') {
    return 'N/A';
  }
  
  const numValue = parseFloat(value);
  if (isNaN(numValue) || (!includeZero && numValue === 0)) {
    return 'N/A';
  }
  
  // Show decimal places only if needed
  return numValue % 1 === 0 ? numValue.toString() : numValue.toFixed(1);
};

export const generateCatchesByTeamReport = (catchesData, tournamentName, eventSettings) => {
  const doc = new jsPDF('landscape');
  const currentDate = dayjs().format('MMMM D, YYYY h:mm A [CST]');
  const currentYear = new Date().getFullYear();
  const units = getUnitLabels(eventSettings);

  // Group data by team
  const teamGroups = {};
  catchesData.forEach((catchItem) => {
    if (!teamGroups[catchItem.teamName]) {
      teamGroups[catchItem.teamName] = [];
    }
    teamGroups[catchItem.teamName].push(catchItem);
  });

  Object.entries(teamGroups).forEach(([team, catches], index) => {
    if (index > 0) doc.addPage();

    // Sort catches by dateTime in descending order
    catches.sort((a, b) => new Date(b.dateTime || 0) - new Date(a.dateTime || 0));

    doc.setFontSize(16);
    doc.setFont('helvetica', 'bold');
    doc.text(
      `Catches for ${team} - ${tournamentName} ${currentYear} (${catches.length} total)`,
      10,
      10
    );
    doc.text(`Report generated on ${currentDate}`, 10, 18);

    const tableColumn = [
      'No.',
      'Species',
      'Time of Catch',
      `Weight (${units.weight})`,
      `Length (${units.length})`,
      `Girth (${units.length})`,
      'Points',
      'Scoring'
    ];

    const tableRows = catches.map((catchItem, idx) => [
      idx + 1,
      catchItem.species || 'N/A',
      catchItem.dateTime ? dayjs(catchItem.dateTime).format('MM/DD/YYYY h:mm A') : 'N/A',
      formatNumericValue(catchItem.weight),
      formatNumericValue(catchItem.length),
      formatNumericValue(catchItem.girth),
      formatNumericValue(catchItem.points, true),
      catchItem.scoringMethod ? `${catchItem.scoringMethod}${catchItem.rounding && catchItem.rounding !== 'exact' ? ` (${catchItem.rounding})` : ''}` : 'N/A'
    ]);

    doc.autoTable({
      startY: 30,
      head: [tableColumn],
      body: tableRows,
      theme: 'striped',
      styles: {
        fontSize: 10,
        halign: 'center',
        valign: 'middle',
        overflow: 'linebreak'
      },
      headStyles: {
        fillColor: '#02133E',
        textColor: '#ffffff',
        halign: 'center'
      }
    });
  });

  addPageNumbers(doc);
  doc.save(`${tournamentName}_${currentYear} Catches (By Team).pdf`);
};

export const generateCatchesBySpeciesReport = (catchesData, tournamentName, eventSettings) => {
  const doc = new jsPDF('landscape');
  const currentDate = dayjs().format('MMMM D, YYYY h:mm A [CST]');
  const currentYear = new Date().getFullYear();
  const units = getUnitLabels(eventSettings);

  // Group data by species
  const speciesGroups = {};
  catchesData.forEach((catchItem) => {
    if (catchItem.species) {
      if (!speciesGroups[catchItem.species]) {
        speciesGroups[catchItem.species] = [];
      }
      speciesGroups[catchItem.species].push(catchItem);
    }
  });

  Object.entries(speciesGroups).forEach(([species, catches], index) => {
    if (index > 0) doc.addPage();

    // Sort catches by dateTime in descending order
    catches.sort((a, b) => new Date(b.dateTime || 0) - new Date(a.dateTime || 0));

    doc.setFontSize(16);
    doc.setFont('helvetica', 'bold');
    doc.text(
      `Catches for ${species} - ${tournamentName} ${currentYear}`,
      10,
      10
    );
    doc.text(`Report generated on ${currentDate}`, 10, 18);

    const tableColumn = [
      'No.',
      'Team Name',
      'Time of Catch',
      `Weight (${units.weight})`,
      `Length (${units.length})`,
      `Girth (${units.length})`,
      'Points',
      'Scoring'
    ];

    const tableRows = catches.map((catchItem, idx) => [
      idx + 1,
      catchItem.teamName || 'N/A',
      catchItem.dateTime ? dayjs(catchItem.dateTime).format('MM/DD/YYYY h:mm A') : 'N/A',
      formatNumericValue(catchItem.weight),
      formatNumericValue(catchItem.length),
      formatNumericValue(catchItem.girth),
      formatNumericValue(catchItem.points, true),
      catchItem.scoringMethod ? `${catchItem.scoringMethod}${catchItem.rounding && catchItem.rounding !== 'exact' ? ` (${catchItem.rounding})` : ''}` : 'N/A'
    ]);

    doc.autoTable({
      startY: 30,
      head: [tableColumn],
      body: tableRows,
      theme: 'striped',
      styles: {
        fontSize: 10,
        halign: 'center',
        valign: 'middle',
        overflow: 'linebreak'
      },
      headStyles: {
        fillColor: '#02133E',
        textColor: '#ffffff',
        halign: 'center'
      }
    });
  });

  addPageNumbers(doc);
  doc.save(`${tournamentName}_${currentYear} Catches (By Species).pdf`);
};

export const fetchAndGenerateCatchesByTeamReport = async (username, eventId, tournamentName) => {
  const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
    ? process.env.REACT_APP_SERVER_URL_PRODUCTION
    : process.env.REACT_APP_SERVER_URL_STAGING;

  try {
    const [catchesResponse, settingsResponse] = await Promise.all([
      fetch(`${apiUrl}/api/admin_get_event_catches`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, eventId })
      }),
      fetch(`${apiUrl}/api/admin_get_event_settings`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, eventId })
      })
    ]);

    if (!catchesResponse.ok || !settingsResponse.ok) {
      throw new Error('Error fetching data');
    }

    const catchesResponseData = await catchesResponse.json();
    const settingsData = await settingsResponse.json();
    
    // Handle both the old and new response formats
    const catchesData = catchesResponseData.catches 
      ? Object.values(catchesResponseData.catches) 
      : Object.values(catchesResponseData);

    // Pass the settings object to the report generator
    generateCatchesByTeamReport(
      catchesData,
      tournamentName,
      settingsData.settings
    );
  } catch (error) {
    console.error('Error fetching data for catches report:', error);
  }
};

export const fetchAndGenerateCatchesBySpeciesReport = async (username, eventId, tournamentName) => {
  const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
    ? process.env.REACT_APP_SERVER_URL_PRODUCTION
    : process.env.REACT_APP_SERVER_URL_STAGING;

  try {
    const [catchesResponse, settingsResponse] = await Promise.all([
      fetch(`${apiUrl}/api/admin_get_event_catches`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, eventId })
      }),
      fetch(`${apiUrl}/api/admin_get_event_settings`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, eventId })
      })
    ]);

    if (!catchesResponse.ok || !settingsResponse.ok) {
      throw new Error('Error fetching data');
    }

    const catchesResponseData = await catchesResponse.json();
    const settingsData = await settingsResponse.json();
    
    // Handle both the old and new response formats
    const catchesData = catchesResponseData.catches 
      ? Object.values(catchesResponseData.catches) 
      : Object.values(catchesResponseData);

    // Pass the settings object to the report generator
    generateCatchesBySpeciesReport(
      catchesData,
      tournamentName,
      settingsData.settings
    );
  } catch (error) {
    console.error('Error fetching data for catches report:', error);
  }
};

