import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  IconButton,
  Stack,
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  Divider,
  Paper
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { 
  Close as CloseIcon,
  CloudUpload as CloudUploadIcon,
  Delete as DeleteIcon 
} from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
import Resizer from "react-image-file-resizer";

const AddTeamModal = (props) => {
  const [isSubmitting, setIsSubmitting] = useState(false); 
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { username, eventId } = useParams(); // Move this to component level
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [consentGiven, setConsentGiven] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  // Add local registration status that defaults to "open" if not provided via props
  const [localRegistrationStatus, setLocalRegistrationStatus] = useState("open");
  // Add refs to store file input references
  const fileInputRefs = useRef({});
  const [showRevenueShareMessage, setShowRevenueShareMessage] = useState(false);
  const [categories, setCategories] = useState({
    divisions: [],
  });
  const [selectedCategories, setSelectedCategories] = useState({
    division: '',
  });

  useEffect(() => {
    console.log('AddTeamModal useEffect triggered');
    console.log('Props received:', {
      eventSettings: props.eventSettings ? 'PRESENT' : 'MISSING',
      status: props.status,
      open: props.open,
      selectedDivision: props.selectedDivision // Log selectedDivision
    });
    console.log('props.eventSettings received:', props.eventSettings)
    console.log('isAdmin value:', props.isAdmin)
  
    if (!props.eventSettings || !props.eventSettings.registration) {
      console.warn('Event settings or register is undefined');
      setIsLoading(true);
      return;
    }
  
    const initialData = {};
    
    const defaultFields = props.eventSettings.registration.defaultTeamRegistrationFields || [];
    const userDefinedFields = props.eventSettings.registration.userDefinedTeamRegistrationFields || [];
  
    console.log('Default Fields:', defaultFields);
    console.log('User Defined Fields:', userDefinedFields);
  
    const allFields = [...defaultFields, ...userDefinedFields];
  
    console.log('All Fields:', allFields);
  
    allFields
      .filter(field => field.isVisible && !field.isId && field.field !== "Checked-In?")
      .forEach(field => {
        console.log('Initializing field:', field);
        switch(field.type) {
          case 'Boolean':
            initialData[field.field] = false;
            break;
          case 'Image':
            initialData[field.field] = null;
            break;
          case 'Number':
            initialData[field.field] = '';
            break;
          default:
            initialData[field.field] = '';
        }
      });
  
    // Initialize categories object for state
    const newCategories = {
      divisions: [],
    };
  
    // Initialize selected categories
    const newSelectedCategories = {
      division: '',
    };
      
    // Add divisions
    if (props.eventSettings?.registration?.includeDivisions === true && 
      props.eventSettings?.registration?.divisions && 
      Array.isArray(props.eventSettings.registration.divisions) && 
      props.eventSettings.registration.divisions.length > 0) {
  
      newCategories.divisions = props.eventSettings.registration.divisions;
  
      // If a specific division is selected from RegisterPage, use that
      if (typeof props.selectedDivision === 'number' && 
          props.selectedDivision >= 0 && 
          props.selectedDivision < props.eventSettings.registration.divisions.length) {
        const selectedDivisionData = props.eventSettings.registration.divisions[props.selectedDivision];
        newSelectedCategories.division = selectedDivisionData.name;
        initialData["Division"] = selectedDivisionData.name;
      } 
      // Otherwise use default division
      else if (props.eventSettings.registration.divisions.length > 0) {
        const defaultDivision = props.eventSettings.registration.divisions[0]?.name || '';
        newSelectedCategories.division = defaultDivision;
        initialData["Division"] = defaultDivision;
      }
    }
  
    setCategories(newCategories);
    setSelectedCategories(newSelectedCategories);
  
    console.log('Initialized Data:', initialData);
  
    setFormData(initialData);
    setIsLoading(false);
  }, [props.eventSettings, props.status, props.selectedDivision]); // Add props.selectedDivision to dependencies
  
  useEffect(() => {
    // Skip if registration status is already provided by parent
    if (props.registrationStatus) return;
    
    // Skip if no event settings
    if (!props.eventSettings || !props.eventSettings.registration) return;
    
    // Calculate registration status locally (only if not provided by parent)
    updateLocalRegistrationStatus();
    
    // Optional: Set up interval for local updates (only if this component needs to manage its own timing)
    // Only needed if this modal stays open for extended periods from AdminPage
    const interval = setInterval(updateLocalRegistrationStatus, 60000);
    return () => clearInterval(interval);
  }, [props.eventSettings, props.registrationStatus]);
  
  useEffect(() => {
    if (props.status && props.isAdmin && !isManualCreationAllowed()) {
      setShowRevenueShareMessage(true);
    } else {
      setShowRevenueShareMessage(false);
    }
  }, [props.status, props.isAdmin, props.eventSettings]);

  const isManualCreationAllowed = () => {
    // If not admin, always allow (public registration)
    if (!props.isAdmin) {
      return true;
    }
    
    // If it's not a revenue-share package, always allow manual creation
    if (!props.eventSettings || props.eventSettings.packageType !== 'revenue-share') {
      return true;
    }
    
    // For admin: check if team registration feature is disabled OR 
    // if neither sponsors nor pots are enabled with revenue share
    return props.eventSettings.features?.onlineTeamRegistration === 'notNeeded' || 
           ((props.eventSettings.features?.onlineSponsorRegistration === 'customerPays' || 
             props.eventSettings.features?.onlineSponsorRegistration === 'iPay' || 
             props.eventSettings.features?.fishPots === 'customerPays' || 
             props.eventSettings.features?.fishPots === 'iPay') && 
            props.eventSettings.features?.onlineTeamRegistration === 'notNeeded');
  };
  
  const updateLocalRegistrationStatus = () => {
    if (!props.eventSettings || !props.eventSettings.registration) {
      setLocalRegistrationStatus("closed");
      return;
    }
    
    const now = new Date().getTime();
    
    // Check if registration is closed (after registration end date)
    const registrationDate = props.eventSettings.registration?.registrationDate
      ? parseInt(props.eventSettings.registration.registrationDate)
      : 0;
      
    if (registrationDate && now > registrationDate) {
      setLocalRegistrationStatus("closed");
      return;
    }
    
    // Check if we're in early bird period
    const includeEarlyBird = props.eventSettings.registration.includeEarlyBird === true || 
                            props.eventSettings.registration.includeEarlyBird === "true";
    const earlyBirdDate = props.eventSettings.registration?.earlyBirdDate
      ? parseInt(props.eventSettings.registration.earlyBirdDate)
      : 0;
    
    if (includeEarlyBird && now < earlyBirdDate) {
      setLocalRegistrationStatus("earlyBird");
    } else {
      setLocalRegistrationStatus("open");
    }
  };
  
  const getRegistrationStatus = () => {
    // If admin is using the modal, treat registration as always open
    if (props.isAdmin) return "open";
    
    // Use props if available, otherwise use local status
    return props.registrationStatus || localRegistrationStatus;
  };

  const validateField = (field, value) => {
    if (!value && field.isRequired) {
      return 'This field is required';
    }

    if (field.isEmail && value) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        return 'Please enter a valid email address';
      }
    }

    if (field.isPhoneNumber && value) {
      const phoneRegex = /^\d{10}$/;
      const cleanedPhone = value.replace(/\D/g, '');
      if (!phoneRegex.test(cleanedPhone)) {
        return 'Please enter a valid 10-digit phone number';
      }
    }

    if (field.type === 'Number' && value) {
      if (isNaN(value) || value < 0) {
        return 'Please enter a valid number';
      }
    }

    return null;
  };

  const calculateFees = (basePrice, featureType = 'customerPays') => {
    // Calculate platform fee based on registration amount
    let platformFee;
    let feeDescription;
  
    if (basePrice < 50) {
      platformFee = 3;
      feeDescription = 'Flat $3 fee for registrations under $50';
    } else if (basePrice <= 100) {
      platformFee = 5;
      feeDescription = 'Flat $5 fee for registrations $50-$100';
    } else if (basePrice <= 200) {
      platformFee = basePrice * 0.05;
      feeDescription = '5% of registration fee';
    } else {
      platformFee = basePrice * 0.04;
      feeDescription = '4% of registration fee';
    }
  
    // Define Stripe fee parameters
    const STRIPE_PERCENTAGE = 2.9;
    const STRIPE_FIXED_FEE = 0.30;
    const DESTINATION_PERCENTAGE = 0.5;
    
    // Calculate Stripe processing fees
    let stripeFee;
    
    if (featureType === 'customerPays') {
      // If customer pays fees, Stripe fees are calculated on base + platform fee
      stripeFee = ((basePrice + platformFee) * (STRIPE_PERCENTAGE + DESTINATION_PERCENTAGE) / 100) + STRIPE_FIXED_FEE;
      
      return {
        basePrice,
        platformFee,
        feeDescription,
        stripeFee,
        stripeFeeDescription: `${STRIPE_PERCENTAGE}% + $${STRIPE_FIXED_FEE} + ${DESTINATION_PERCENTAGE}% destination fee`,
        total: basePrice + platformFee + stripeFee
      };
    } else {
      // If organizer pays fees, customer only sees base price
      stripeFee = (basePrice * (STRIPE_PERCENTAGE + DESTINATION_PERCENTAGE) / 100) + STRIPE_FIXED_FEE;
      
      return {
        basePrice,
        platformFee,
        feeDescription,
        stripeFee,
        stripeFeeDescription: `${STRIPE_PERCENTAGE}% + $${STRIPE_FIXED_FEE} + ${DESTINATION_PERCENTAGE}% destination fee`,
        total: basePrice
      };
    }
  };

  const handleFileUpload = async (e, field) => {
    console.log('handleFileUpload called for field:', field.field);
    if (e.target.files?.[0]) {
      const file = e.target.files[0];
      console.log('File selected:', file.name, file.type);
      
      try {
        // Store the original file for immediate display
        const tempUrl = URL.createObjectURL(file);
        setFormData(prev => ({
          ...prev,
          [`${field.field}_preview`]: tempUrl
        }));
        
        const resizedImage = await new Promise((resolve) => {
          Resizer.imageFileResizer(
            file,
            300,
            300,
            'PNG',
            80,
            0,
            (uri) => {
              resolve(uri);
            },
            'base64'
          );
        });

        const response = await fetch(resizedImage);
        const blob = await response.blob();
        
        setFormData(prev => ({
          ...prev,
          [field.field]: blob
        }));
        console.log('Image processed successfully for', field.field);
      } catch (error) {
        console.error('Error resizing image:', error);
        toast.error('Error processing image');
      }
    } else {
      console.log('No file selected');
    }
  };

  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/\D/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const handleFieldChange = (field, value) => {
    let processedValue = value;
    const error = validateField(field, value);

    if (field.isPhoneNumber) {
      // Strip non-digits for storage but show formatted version in UI
      processedValue = value.replace(/\D/g, '');
    }

    setFormData(prev => ({
      ...prev,
      [field.field]: processedValue
    }));

    setErrors(prev => ({
      ...prev,
      [field.field]: error
    }));
  };

  const handleCategoryChange = (category, value) => {
    setSelectedCategories(prev => ({
      ...prev,
      [category]: value
    }));
  
    const fieldMap = {
      division: "Division",
    };
  
    setFormData(prev => ({
      ...prev,
      [fieldMap[category]]: value
    }));
  };

  const renderConsentCheckbox = () => {
    if (props.isAdmin) return null; // Don't show consent checkbox for admin registrations
    
    return (
      <FormControl fullWidth sx={{ mt: 2 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={consentGiven}
              onChange={(e) => setConsentGiven(e.target.checked)}
              color="primary"
            />
          }
          label={
            <Typography variant="body2" color="text.secondary">
              I consent to having my registration information collected and used by the event organizers
              for tournament-related communications, results sharing, and future event notifications.
              This data will be handled according to the tournament's privacy practices.
            </Typography>
          }
        />
      </FormControl>
    );
  };

  const handleClose = () => {
    setIsSubmitting(false);
    setIsSubmitted(false);
    props.close();
  };

  const renderField = (field) => {
    if (!field.isVisible) return null;

    switch(field.type) {
      case 'Boolean':
        return (
          <FormControl fullWidth error={!!errors[field.field]}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData[field.field] || false}
                  onChange={(e) => handleFieldChange(field, e.target.checked)}
                />
              }
              label={field.field}
            />
            {errors[field.field] && (
              <FormHelperText>{errors[field.field]}</FormHelperText>
            )}
          </FormControl>
        );

      case 'Phone':
        return (
          <TextField
            fullWidth
            label={field.field}
            value={formatPhoneNumber(formData[field.field] || '')}
            onChange={(e) => handleFieldChange(field, e.target.value)}
            error={!!errors[field.field]}
            helperText={errors[field.field]}
            inputProps={{
              maxLength: 14,
              placeholder: '(XXX) XXX-XXXX'
            }}
            variant="outlined"
            margin="normal"
          />
        );

      case 'Image':
        console.log(`Rendering Image field: ${field.field}`);
        
        // Function to handle button click and trigger file input
        const handleUploadButtonClick = () => {
          console.log('Upload button clicked for', field.field);
          // Access the ref for this specific field
          const inputRef = fileInputRefs.current[field.field];
          if (inputRef) {
            console.log('Triggering click on file input');
            inputRef.click();
          } else {
            console.error('File input ref not found for', field.field);
          }
        };
        
        // Function to handle image deletion
        const handleImageDelete = () => {
          console.log('Deleting image for', field.field);
          // Clean up any preview URLs
          if (formData[`${field.field}_preview`]) {
            URL.revokeObjectURL(formData[`${field.field}_preview`]);
          }
          
          // Clear the input value
          if (fileInputRefs.current[field.field]) {
            fileInputRefs.current[field.field].value = "";
          }
          
          // Update state
          setFormData(prev => ({
            ...prev,
            [field.field]: null,
            [`${field.field}_preview`]: null
          }));
        };
        
        return (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              {field.field}{field.isRequired ? ' *' : ' (Optional)'}
            </Typography>
            
            {/* Hidden file input */}
            <input
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={(e) => handleFileUpload(e, field)}
              ref={el => fileInputRefs.current[field.field] = el}
            />
            
            {/* Custom button that triggers the hidden input */}
            <Button
              variant="outlined"
              startIcon={<CloudUploadIcon />}
              onClick={handleUploadButtonClick}
              sx={{ my: 1 }}
            >
              Upload {field.field}
            </Button>
            
            {/* Preview image if available */}
            {(formData[field.field] || formData[`${field.field}_preview`]) && (
              <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
                <Box
                  sx={{
                    width: 100,
                    height: 100,
                    border: '1px solid #ddd',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden',
                    borderRadius: '4px',
                    mr: 2
                  }}
                >
                  <img
                    src={
                      formData[`${field.field}_preview`] || 
                      (formData[field.field] instanceof Blob ? 
                        URL.createObjectURL(formData[field.field]) : 
                        formData[field.field])
                    }
                    alt={field.field}
                    style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                    onError={(e) => {
                      console.error('Image failed to load:', e);
                      e.target.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
                    }}
                  />
                </Box>
                <IconButton onClick={handleImageDelete} color="primary">
                  <DeleteIcon />
                </IconButton>
              </Box>
            )}
            
            {errors[field.field] && (
              <FormHelperText error>{errors[field.field]}</FormHelperText>
            )}
          </Box>
        );

      default:
        return (
          <TextField
            fullWidth
            label={field.field}
            value={formData[field.field] || ''}
            onChange={(e) => handleFieldChange(field, e.target.value)}
            error={!!errors[field.field]}
            helperText={errors[field.field]}
            type={
              field.type === 'Email' ? 'email' : 
              field.type === 'Number' ? 'number' : 
              'text'
            }
            required={field.isRequired}
            variant="outlined"
            margin="normal"
          />
        );
    }
  };

  const getAllFields = () => {
    if (!props.eventSettings || !props.eventSettings.registration) {
      return { nonImageFields: [], imageFields: [], checkedInField: null };
    }
  
    const defaultFields = props.eventSettings.registration.defaultTeamRegistrationFields || [];
    const userDefinedFields = props.eventSettings.registration.userDefinedTeamRegistrationFields || [];
    
    const allFields = [...defaultFields, ...userDefinedFields];
    
    // Separate image fields 
    const nonImageFields = allFields.filter(field => 
      field && field.isVisible && field.type !== 'Image' && field.field !== "Checked-In?"
    );
    
    // Ensure Team photo is added if team photo uploads are allowed
    const imageFields = [
      ...(props.eventSettings.registration.allowTeamPhoto === "true" 
        ? [{ field: "Team photo", type: "Image", isVisible: true, isRequired: false }] 
        : []),
      ...allFields.filter(field => 
        field && field.isVisible && field.type === 'Image'
      )
    ];
    
    // Find Checked-In field if admin
    const checkedInField = props.isAdmin 
      ? allFields.find(field => field.field === "Checked-In?")
      : null;
    
    return { nonImageFields, imageFields, checkedInField };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Check if registration is closed (skip for admin)
    const currentStatus = getRegistrationStatus();
    if (currentStatus === "closed" && !props.isAdmin) {
      toast.error("Registration for this event has closed.");
      return;
    }
    
    if (!props.isAdmin && !consentGiven) {
      toast.error('Please accept the consent agreement to continue');
      return;
    }
    
    try {
      setIsSubmitting(true);
      const formDataToSend = new FormData();
      
      // Add route params
      formDataToSend.append("username", username);
      formDataToSend.append("eventId", eventId);
  
      // Process form data for JSON serialization
      const processedData = {};
      Object.keys(formData).forEach(key => {
        // Skip preview URLs
        if (key.endsWith('_preview')) return;
        processedData[key] = formData[key];
      });
      
      // Add team data
      const teamDataToSend = {
        ...processedData,
        // Add all category values (only if their arrays exist in categories)
        "Division": categories.divisions.length > 0 ? selectedCategories.division || "Default" : undefined,
        "Checked-In?": props.isAdmin 
          ? (formData["Checked-In?"] || false) 
          : false, // Default to false for public registration
      };
      formDataToSend.append("teamData", JSON.stringify(teamDataToSend));
  
      // Add team photo separately if exists
      if (formData["Team photo"]) {
        formDataToSend.append("teamPhoto", formData["Team photo"]);
      }
  
      // Determine registration fee based on current status and selected division
      let basePrice = 0;
  
      // Find selected division data
      const selectedDivisionName = selectedCategories.division;
      let selectedDivisionData = null;
      
      if (props.eventSettings?.registration?.includeDivisions && 
          props.eventSettings?.registration?.divisions) {
        selectedDivisionData = props.eventSettings.registration.divisions.find(
          div => div.name === selectedDivisionName
        );
      }
  
      // Check if event settings and registration exist
      if (props.eventSettings && props.eventSettings.registration) {
        const includeEarlyBird = props.eventSettings.registration.includeEarlyBird === true || 
                              props.eventSettings.registration.includeEarlyBird === "true";
        const earlyBirdDate = props.eventSettings.registration?.earlyBirdDate 
          ? parseInt(props.eventSettings.registration.earlyBirdDate) 
          : 0;
        
        // Use the effective registration status to determine if early bird applies
        const isEarlyBird = currentStatus === "earlyBird";
        
        // Get the appropriate price based on division and early bird status
        if (selectedDivisionData) {
          const priceString = isEarlyBird && selectedDivisionData.earlyBirdPrice
            ? selectedDivisionData.earlyBirdPrice
            : selectedDivisionData.registrationPrice;
          
          if (priceString) {
            // Convert string to number, removing any non-numeric characters except decimal point
            const cleanedPrice = priceString.toString().replace(/[^\d.]/g, '');
            basePrice = parseFloat(cleanedPrice);
          }
        } else {
          // Fall back to general pricing if no division or division pricing not found
          const priceString = isEarlyBird 
            ? props.eventSettings.registration.earlyBirdPrice
            : props.eventSettings.registration.registrationPrice;
          
          if (priceString) {
            const cleanedPrice = priceString.toString().replace(/[^\d.]/g, '');
            basePrice = parseFloat(cleanedPrice);
          }
        }
        
        // Set early bird flag for the form data
        formDataToSend.append("isEarlyBird", isEarlyBird.toString());
      }
      
      // Log the calculated price for debugging
      console.log("Calculated registration fee:", {
        basePrice,
        selectedDivision: selectedDivisionName,
        divisionData: selectedDivisionData,
        status: currentStatus
      });
      
      // If admin registration, we can proceed even if price is invalid
      if (isNaN(basePrice) && !props.isAdmin) {
        console.error('Invalid registration price:', basePrice, 'Using props:', props.eventSettings?.registration);
        toast.error('Invalid registration price. Please contact event organizers.');
        setIsSubmitting(false);
        return;
      }
      
      // If price is NaN, set it to 0 for admin registrations (they don't pay anyway)
      if (isNaN(basePrice)) {
        basePrice = 0;
      }
  
      // For admin registrations, we can continue regardless of price
      formDataToSend.append("registrationFee", basePrice.toString());
  
      const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
        ? process.env.REACT_APP_SERVER_URL_PRODUCTION
        : process.env.REACT_APP_SERVER_URL_STAGING;
  
      const endpoint = props.isAdmin 
        ? 'admin_create_event_teams'
        : 'create_team_registration_session';
    
      console.log('Sending request to:', `${apiUrl}/api/${endpoint}`);
      console.log('Request details:', {
        username,
        eventId,
        basePrice,
        isEarlyBird: formDataToSend.get("isEarlyBird"),
        teamData: teamDataToSend
      });
  
      const response = await fetch(`${apiUrl}/api/${endpoint}`, {
        method: 'POST',
        body: formDataToSend,
      });
  
      if (response.ok) {
        if (!props.isAdmin) {
          const { url } = await response.json();
          window.location = url; // Redirect to Stripe Checkout for regular registrations
        } else {
          const result = await response.json();
          toast.success(`Team registered successfully. Team ID: ${result.teamId}`);
          setIsSubmitted(true);
          delayRefresh();
        }
      } else {
        const errorData = await response.json();
        console.error('Server error response:', errorData);
        toast.error(errorData.error || 'Failed to create registration');
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error('Registration error:', error);
      toast.error(error.message || 'Registration failed');
      setIsSubmitting(false);
    } 
  };

  const delayRefresh = () => {
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const { nonImageFields, imageFields, checkedInField } = getAllFields();

  return (
    <Dialog 
      open={props.status} 
      onClose={handleClose}
      maxWidth="md" 
      fullWidth
    >
      <DialogTitle style={{ backgroundColor: '#0281C5', color: 'white', padding: '16px 24px' }}>
        Team Registration
        <IconButton 
          onClick={handleClose}
          sx={{ position: 'absolute', right: 8, top: 8, color: 'white' }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {showRevenueShareMessage ? (
          <Box sx={{ p: 4, textAlign: 'center' }}>
            <Typography variant="h6" color="error" gutterBottom>
              Manual Team Creation Disabled
            </Typography>
            <Typography variant="body1" paragraph>
              You've configured this event for revenue sharing with online team registration.
              Teams must register through the public registration page.
            </Typography>
            <Typography variant="body2" sx={{ mt: 2, color: 'text.secondary' }}>
              Note: You can still edit and delete existing teams.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClose}
              sx={{ mt: 3 }}
            >
              Close
            </Button>
          </Box>
        ) : (
          <form onSubmit={handleSubmit}>
            <Stack spacing={3} sx={{ mt: 3 }}>

              {/* Render all non-image fields */}
              {nonImageFields.map(field => (
                <Box key={field.field}>
                  {renderField(field)}
                </Box>
              ))}

              {/* Team Categorization Section */}
              {categories.divisions.length > 0 && (
                <Box sx={{ mt: 2, mb: 2, p: 2, border: '1px solid #e0e0e0', borderRadius: 1, bgcolor: '#f9f9f9' }}>
                  <Typography variant="subtitle1" gutterBottom fontWeight="medium">
                    Division Settings
                  </Typography>
                  
                  {/* Divisions Dropdown */}
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="division-select-label" shrink>Division *</InputLabel>
                    <Select
                      labelId="division-select-label"
                      id="division-select"
                      value={selectedCategories.division}
                      onChange={(e) => handleCategoryChange('division', e.target.value)}
                      required
                    >
                      {categories.divisions.map((division) => (
                        <MenuItem key={division.name} value={division.name}>
                          {division.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              )}

              {/* Render team photo and other image fields */}
              {imageFields.length > 0 && (
                <Box sx={{ mt: 1 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Images
                  </Typography>
                  {imageFields.map(field => (
                    <Box key={field.field}>
                      {renderField(field)}
                    </Box>
                  ))}
                </Box>
              )}

              {/* Render Checked-In field for admin only, just before fee breakdown */}
              {props.isAdmin && checkedInField && (
                <Box key="Checked-In?">
                  {renderField(checkedInField)}
                </Box>
              )}

              {/* Fee Section */}
              {props.isAdmin ? (
                <Paper sx={{ p: 3, bgcolor: 'background.paper', borderRadius: 2 }}>
                  <Typography variant="h6" gutterBottom>
                    Registration Fee Information
                  </Typography>
                  <Box sx={{ 
                    p: 2, 
                    mb: 2, 
                    bgcolor: '#e8f4fd', 
                    border: '1px solid #0281C5', 
                    borderRadius: 1,
                    borderLeft: '4px solid #0281C5'
                  }}>
                    <Typography variant="subtitle1" fontWeight="bold" color="#0281C5">
                      Admin Registration - No Fees Applied
                    </Typography>
                    <Typography variant="body2">
                      As an admin, you are adding this team directly to the tournament. 
                      <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                        No registration fees will be collected
                      </span> for admin-created teams. No fees will be charged either.
                    </Typography>
                  </Box>
                  {(() => {
                    try {
                      const now = new Date().getTime();
                      const includeEarlyBird = props.eventSettings.registration?.includeEarlyBird === true || 
                          props.eventSettings.registration?.includeEarlyBird === "true";
                      const earlyBirdDate = props.eventSettings.registration?.earlyBirdDate 
                        ? parseInt(props.eventSettings.registration.earlyBirdDate) 
                        : 0;
                      
                      // Get the selected division data
                      const selectedDivisionName = selectedCategories.division;
                      const selectedDivisionData = props.eventSettings.registration.divisions?.find(
                        div => div.name === selectedDivisionName
                      );
                      
                      // Use the effective registration status to determine pricing
                      const isEarlyBird = getRegistrationStatus() === "earlyBird";
                      
                      // Get price based on division
                      let basePrice = 0;
                      if (selectedDivisionData) {
                        basePrice = isEarlyBird && selectedDivisionData.earlyBirdPrice
                          ? parseFloat(selectedDivisionData.earlyBirdPrice)
                          : parseFloat(selectedDivisionData.registrationPrice);
                      } else {
                        basePrice = isEarlyBird 
                          ? parseFloat(props.eventSettings.registration.earlyBirdPrice)
                          : parseFloat(props.eventSettings.registration.registrationPrice);
                      }
                      
                      // Get registration payment feature type for fee calculation
                      const registrationFeatureType = props.eventSettings.features?.onlineTeamRegistration || 'customerPays';
                      
                      // Calculate all fees including Stripe fee
                      const { platformFee, feeDescription, stripeFee, stripeFeeDescription, total } = calculateFees(basePrice, registrationFeatureType);
                      
                      return (
                        <Box sx={{ mt: 2 }}>
                          <Typography variant="body2" fontWeight="medium" sx={{ color: '#666', fontStyle: 'italic' }}>
                            {isEarlyBird ? 'Early Bird' : 'Standard'} fee information (for reference only - not applied for admin registrations):
                          </Typography>
                          <Box sx={{ mt: 1, display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="body2">Registration Fee:</Typography>
                            <Typography variant="body2">${basePrice.toFixed(2)}</Typography>
                          </Box>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="body2">Platform Fee:</Typography>
                            <Typography variant="body2">${platformFee.toFixed(2)} ({feeDescription})</Typography>
                          </Box>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="body2">Stripe Processing Fee:</Typography>
                            <Typography variant="body2">${stripeFee.toFixed(2)} ({stripeFeeDescription})</Typography>
                          </Box>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                            <Typography variant="body2" fontWeight="medium">Total:</Typography>
                            <Typography variant="body2" fontWeight="medium">${total.toFixed(2)}</Typography>
                          </Box>
                        </Box>
                      );
                    } catch (error) {
                      console.error('Error calculating reference fees:', error);
                      return null;
                    }
                  })()}
                </Paper>
              ) : (
                <Paper sx={{ p: 3, bgcolor: 'background.paper', borderRadius: 2 }}>
                  <Typography variant="h6" gutterBottom>
                    Registration Fee Breakdown
                  </Typography>
                  {(() => {
                    try {
                      const includeEarlyBird = props.eventSettings.registration?.includeEarlyBird === true || 
                          props.eventSettings.registration?.includeEarlyBird === "true";
                      const earlyBirdDate = props.eventSettings.registration?.earlyBirdDate 
                        ? parseInt(props.eventSettings.registration.earlyBirdDate) 
                        : 0;
                      
                      // Get the selected division data
                      const selectedDivisionName = selectedCategories.division;
                      const selectedDivisionData = props.eventSettings.registration.divisions?.find(
                        div => div.name === selectedDivisionName
                      );
                      
                      // Get registration payment feature type
                      const registrationFeatureType = props.eventSettings.features?.onlineTeamRegistration || 'customerPays';
                      const customerPaysProcessingFee = registrationFeatureType === 'customerPays';

                      // Use the effective registration status to determine pricing
                      const isEarlyBird = getRegistrationStatus() === "earlyBird";
                      
                      // Get price based on division
                      let basePrice = 0;
                      if (selectedDivisionData) {
                        basePrice = isEarlyBird && selectedDivisionData.earlyBirdPrice
                          ? parseFloat(selectedDivisionData.earlyBirdPrice)
                          : parseFloat(selectedDivisionData.registrationPrice);
                      } else {
                        basePrice = isEarlyBird 
                          ? parseFloat(props.eventSettings.registration.earlyBirdPrice)
                          : parseFloat(props.eventSettings.registration.registrationPrice);
                      }

                      const { platformFee, feeDescription, stripeFee, total } = calculateFees(basePrice, registrationFeatureType);

                      return (
                        <Stack spacing={1}>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography>
                              {isEarlyBird ? 'Early Bird Registration' : 'Regular Registration'}
                            </Typography>
                            <Typography>${basePrice.toFixed(2)}</Typography>
                          </Box>
                          
                          {customerPaysProcessingFee && (
                            <>
                              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography>Custom Tournament Solutions Fee</Typography>
                                <Stack alignItems="flex-end">
                                  <Typography>${platformFee.toFixed(2)}</Typography>
                                  <Typography variant="caption" color="text.secondary">
                                    ({feeDescription})
                                  </Typography>
                                </Stack>
                              </Box>
                              
                              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography>Stripe Processing Fee</Typography>
                                <Typography>${stripeFee.toFixed(2)}</Typography>
                              </Box>
                            </>
                          )}
                          
                          <Divider sx={{ my: 1 }} />
                          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="subtitle1" fontWeight="bold">Total</Typography>
                            <Typography variant="subtitle1" fontWeight="bold">
                              ${total.toFixed(2)}
                            </Typography>
                          </Box>
                        </Stack>
                      );
                    } catch (error) {
                      console.error('Error calculating registration fees:', error);
                      return (
                        <Typography color="error">
                          Unable to calculate registration fees. Please contact support.
                        </Typography>
                      );
                    }
                  })()}
                </Paper>
              )}

              <Typography variant="caption" color="text.secondary">
                * Required field
              </Typography>

              {renderConsentCheckbox()}

              {/* Registration closed warning (only show for non-admin users) */}
              {!props.isAdmin && getRegistrationStatus() === "closed" && (
                <Paper sx={{ p: 3, bgcolor: '#ffebee', borderRadius: 2, mb: 2 }}>
                  <Typography variant="h6" color="error" gutterBottom>
                    Registration Period Has Ended
                  </Typography>
                  <Typography variant="body1">
                    The registration period for this event has closed. Please contact the event organizers for assistance.
                  </Typography>
                </Paper>
              )}

              <Button
                type="submit"
                variant="contained"
                size="large"
                fullWidth
                disabled={
                  (!props.isAdmin && !consentGiven) || 
                  isSubmitting || 
                  (!props.isAdmin && getRegistrationStatus() === "closed")
                }
                startIcon={isSubmitting ? <CircularProgress size={24} color="inherit" /> : null}
                sx={{
                  backgroundColor: '#0281C5',
                  color: 'white',
                  borderRadius: '30px',
                  py: 1.5,
                  fontWeight: 'bold',
                  '&:hover': {
                    backgroundColor: '#026999',
                  },
                  mt: 2,
                  mb: 2
                }}
              >
                {isSubmitting ? "Submitting..." : 
                !props.isAdmin && getRegistrationStatus() === "closed" ? "Registration Closed" :
                props.isAdmin ? "Register Team" : "Continue to Payment"}
              </Button>
              
              {isSubmitted && (
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="subtitle1" color="primary" fontWeight="bold">
                    Team registered successfully!
                  </Typography>
                </Box>
              )}
            </Stack>
          </form>
        )}
      </DialogContent>

    </Dialog>
  );
}

export default AddTeamModal;

