import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { 
  InputLabel, 
  Button, 
  Dialog, 
  DialogContent, 
  DialogTitle, 
  IconButton, 
  Stack, 
  TextField, 
  FormControlLabel, 
  Checkbox, 
  Typography,
  Box,
  CircularProgress
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EditPotModal = (props) => {
  const { username, eventId } = useParams();
  const { eventSettings, editInfo, status, open, close } = props;
  
  // States
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedPots, setSelectedPots] = useState([]);
  const [totalFee, setTotalFee] = useState(0);
  
  // Load existing pot data when editInfo changes
  useEffect(() => {
    if (editInfo && status) {
      setLoading(true);
      initializeFormData();
    }
  }, [editInfo, status]);
  
  // Calculate total fee whenever selected pots change
  useEffect(() => {
    calculateTotalFee();
  }, [selectedPots]);
  
  const initializeFormData = () => {
    try {
      if (editInfo && editInfo.selectedPots) {
        console.log('Initializing form data with:', editInfo.selectedPots);
        
        // Transform the selectedPots data from the database format to component format
        // Handle both old format (with category) and new format (with categoryTitle)
        const formattedPots = editInfo.selectedPots.map(pot => {
          if (pot.categoryTitle) {
            // If already using the correct format
            return {
              categoryTitle: pot.categoryTitle,
              values: pot.values || []
            };
          } else if (pot.category) {
            // If using old format, convert to new format
            return {
              categoryTitle: pot.category,
              values: pot.values || []
            };
          } else {
            // If no category information is found, log error and skip
            console.error('Invalid pot entry format:', pot);
            return null;
          }
        }).filter(Boolean); // Filter out any null entries
        
        console.log('Formatted pots for component:', formattedPots);
        setSelectedPots(formattedPots);
        setLoading(false);
      } else {
        console.error('Edit info is missing selectedPots data');
        setLoading(false);
      }
    } catch (error) {
      console.error('Error initializing form data:', error);
      setLoading(false);
    }
  };
  
  const handlePotCategorySelection = (categoryTitle, isSelected) => {
    // Get the pot list from either potList or list property
    const potList = eventSettings.pots?.potList || [];
    const potConfig = potList.find(pot => pot.categoryTitle === categoryTitle);
    
    if (!potConfig) return;
    
    const updatedSelection = [...selectedPots];
    const categoryIndex = updatedSelection.findIndex(item => item.categoryTitle === categoryTitle);
    
    if (isSelected) {
      // If selecting all values for this category
      if (categoryIndex >= 0) {
        // Update existing category
        updatedSelection[categoryIndex] = {
          categoryTitle,
          values: [...potConfig.values]
        };
      } else {
        // Add new category with all values
        updatedSelection.push({
          categoryTitle,
          values: [...potConfig.values]
        });
      }
    } else {
      // If deselecting all values for this category
      if (categoryIndex >= 0) {
        // Remove the category entirely
        updatedSelection.splice(categoryIndex, 1);
      }
    }
    
    setSelectedPots(updatedSelection);
  };
  
  const handlePotValueSelection = (categoryTitle, value, isSelected) => {
    const updatedSelection = [...selectedPots];
    const categoryIndex = updatedSelection.findIndex(item => item.categoryTitle === categoryTitle);
    
    if (isSelected) {
      if (categoryIndex >= 0) {
        // Add value to existing category
        if (!updatedSelection[categoryIndex].values.includes(value)) {
          updatedSelection[categoryIndex].values.push(value);
        }
      } else {
        // Create new category with this value
        updatedSelection.push({
          categoryTitle,
          values: [value]
        });
      }
    } else {
      if (categoryIndex >= 0) {
        // Remove value from category
        updatedSelection[categoryIndex].values = updatedSelection[categoryIndex].values.filter(v => v !== value);
        
        // If no values left, remove the category
        if (updatedSelection[categoryIndex].values.length === 0) {
          updatedSelection.splice(categoryIndex, 1);
        }
      }
    }
    
    setSelectedPots(updatedSelection);
  };
  
  const calculateTotalFee = () => {
    let total = 0;
    
    selectedPots.forEach(pot => {
      pot.values.forEach(value => {
        total += parseFloat(value || 0);
      });
    });
    
    // Ensure total is a number and properly rounded
    total = Math.round(total * 100) / 100;
    setTotalFee(total);
  };
  
  // Update the submission to use categoryTitle
  const formatPotsDataForSubmission = () => {
    return selectedPots.map(pot => ({
      categoryTitle: pot.categoryTitle,
      values: pot.values
    }));
  };

  const isValueSelected = (categoryTitle, value) => {
    const categorySelection = selectedPots.find(pot => pot.categoryTitle === categoryTitle);
    return categorySelection ? categorySelection.values.includes(value) : false;
  };
  
  const areCategoryValuesAllSelected = (categoryTitle) => {
    // Get the pot list from either potList or list property
    const potList = eventSettings.pots?.potList || [];
    const potConfig = potList.find(pot => pot.categoryTitle === categoryTitle);
    if (!potConfig) return false;
    
    const categorySelection = selectedPots.find(pot => pot.categoryTitle === categoryTitle);
    if (!categorySelection) return false;
    
    return potConfig.values.every(value => categorySelection.values.includes(value));
  };
  
  const isSomeCategoryValuesSelected = (categoryTitle) => {
    const categorySelection = selectedPots.find(pot => pot.categoryTitle === categoryTitle);
    return categorySelection && categorySelection.values.length > 0;
  };
  
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);
  };
  
  const validateForm = () => {
    if (selectedPots.length === 0) {
      toast.warning("Please select at least one pot");
      return false;
    }
    
    return true;
  };
  
  const handleSubmit = async () => {
    if (!validateForm()) return;
    
    setIsSubmitting(true);
    
    try {
      const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
        ? process.env.REACT_APP_SERVER_URL_PRODUCTION
        : process.env.REACT_APP_SERVER_URL_STAGING;
      
      // Format the pots data for submission
      const potsData = formatPotsDataForSubmission();
      
      const response = await fetch(`${apiUrl}/api/admin_edit_event_pots`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          username,
          eventId,
          teamId: editInfo.teamId,
          pots: potsData,
          totalFee
        })
      });
      
      const data = await response.json();
      
      if (response.ok) {
        toast.success('Pot entry updated successfully! Page will refresh...');
        setIsSubmitted(true);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        throw new Error(data.error || 'Failed to update pot entry');
      }
    } catch (error) {
      console.error('Error updating pot entry:', error);
      toast.error('Failed to update pot entry. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };
  
  const handleClose = () => {
    setSelectedPots([]);
    setTotalFee(0);
    setIsSubmitted(false);
    setIsSubmitting(false);
    close();
  };
  
  return (
    <Dialog open={status} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Edit Pot Entry for {editInfo?.teamName || "Team"}
        <IconButton onClick={handleClose} style={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      
      <DialogContent>
        {loading ? (
          <Box display="flex" justifyContent="center" my={4}>
            <CircularProgress />
          </Box>
        ) : (
          <Stack spacing={3} sx={{ mt: 2 }}>
            {/* Pot Categories */}
            {(() => {
              // Determine which property to use for pot list
              const potList = eventSettings.pots?.potList || [];
              
              if (potList && potList.length > 0) {
                return (
                  <Box>
                    <Typography variant="h6" gutterBottom>
                      Select Pot Entries
                    </Typography>
                    
                    {potList.map((potCategory, categoryIndex) => (
                      <Box key={categoryIndex} sx={{ mb: 2, ml: 1 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={areCategoryValuesAllSelected(potCategory.categoryTitle)}
                              indeterminate={
                                isSomeCategoryValuesSelected(potCategory.categoryTitle) && 
                                !areCategoryValuesAllSelected(potCategory.categoryTitle)
                              }
                              onChange={(e) => 
                                handlePotCategorySelection(potCategory.categoryTitle, e.target.checked)
                              }
                            />
                          }
                          label={<Typography fontWeight="bold">{potCategory.categoryTitle}</Typography>}
                        />
                        
                        <Box sx={{ ml: 4 }}>
                          {potCategory.values.map((value, valueIndex) => (
                            <FormControlLabel
                              key={valueIndex}
                              control={
                                <Checkbox
                                  checked={isValueSelected(potCategory.categoryTitle, value)}
                                  onChange={(e) => 
                                    handlePotValueSelection(potCategory.categoryTitle, value, e.target.checked)
                                  }
                                />
                              }
                              label={`${formatCurrency(value)}`}
                            />
                          ))}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                );
              } else {
                return (
                  <Typography color="error">
                    No pot categories are configured for this event.
                  </Typography>
                );
              }
            })()}
            
            {/* Fee Summary */}
            {selectedPots.length > 0 && (
              <Box sx={{ mt: 2, p: 2, bgcolor: '#f5f5f5', borderRadius: 1 }}>
                <Typography variant="h6" gutterBottom>
                  Total Fee: {formatCurrency(totalFee)}
                </Typography>
                
                {selectedPots.map((pot, index) => (
                  <Box key={index} sx={{ ml: 2 }}>
                    <Typography variant="body1">
                      {pot.categoryTitle}: {pot.values.map(value => formatCurrency(value)).join(', ')}
                    </Typography>
                  </Box>
                ))}
                
                {/* Admin Payment Disclaimer */}
                <Box 
                  sx={{ 
                    mt: 2, 
                    p: 2, 
                    bgcolor: '#e8f4fd', 
                    border: '1px solid #0281C5', 
                    borderRadius: 1,
                    borderLeft: '4px solid #0281C5' 
                  }}
                >
                  <Typography variant="subtitle2" fontWeight="bold" color="#0281C5">
                    Administrator Notice
                  </Typography>
                  <Typography variant="body2">
                    As an administrator, you are directly modifying pot entries. 
                    <strong> No payment will be processed</strong> for these changes. 
                    If additional fees should be collected, please inform the team directly.
                  </Typography>
                </Box>
              </Box>
            )}
            
            {/* Submit Button */}
            {!isSubmitted ? (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={isSubmitting || selectedPots.length === 0}
                onClick={handleSubmit}
                startIcon={isSubmitting ? <CircularProgress size={24} color="inherit" /> : null}
              >
                {isSubmitting ? "Updating..." : "Update Pot Entry"}
              </Button>
            ) : (
              <Typography variant="h6" align="center" color="primary">
                Updated Successfully!
              </Typography>
            )}
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default EditPotModal;

