import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import AnimatedPage from './AnimatedPage';
import Footer from '../components/footer/Footer';
import './RegisterPage.css';

function PotErrorPage() {
  const { username, eventId } = useParams();
  const [searchParams] = useSearchParams();
  const [eventSettings, setEventSettings] = useState(null);
  const [loadingEventSettings, setLoadingEventSettings] = useState(true);
  
  // Get team name from URL params
  const teamName = searchParams.get('team');
  
  useEffect(() => {
    const fetchEventSettings = async () => {
      const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
        ? process.env.REACT_APP_SERVER_URL_PRODUCTION
        : process.env.REACT_APP_SERVER_URL_STAGING;
      
      try {
        const response = await fetch(`${apiUrl}/api/load_event_profile`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username, eventId }),
        });
        const data = await response.json();
        if (data.success) {
          setEventSettings(data.settings);
        } else {
          console.error('Error fetching event settings:', data.message);
          toast.error('Failed to load event settings');
        }
      } catch (error) {
        console.error('Error fetching event settings:', error);
        toast.error('Failed to load event settings');
      } finally {
        setLoadingEventSettings(false);
      }
    };
    
    fetchEventSettings();
  }, [username, eventId]);
  
  if (loadingEventSettings) {
    return <div>Loading...</div>;
  }
  
  return (
    <AnimatedPage>
      <main>
        {/* Banner */}
        <section 
          className="section-banner"
          style={{
            backgroundColor: eventSettings.basicInfo.backgroundColor,
            color: eventSettings.basicInfo.headlineTextColor,
          }}
        >
          <h1>Pot Registration Error</h1>
        </section>
        
        {/* Error Message Section */}
        <section className="section-register">
          <br/>
          <br/>
          
          <h2>
            An error occurred during the pot registration process.
          </h2>
          <br/>
          <br/>
          <h4>Your account has not been charged.</h4>
          <br/>
          <h4>
            {teamName 
              ? `Please return to the Pots page and try registering team "${teamName}" again.` 
              : 'Please return to the Pots page and try again.'}
          </h4>
          <br/>
          <h4>If the problem persists, please contact support@customtournamentsolutions.com for assistance.</h4>
          
          <br/>
          <br/>
          
          <Link 
            to={`/${username}/${eventId}/pots`}
            style={{
              display: 'inline-block',
              padding: '10px 20px',
              backgroundColor: eventSettings.basicInfo.backgroundColor || '#0281C5',
              color: eventSettings.headlineTextColor || 'white',
              textDecoration: 'none',
              borderRadius: '5px',
              fontWeight: 'bold'
            }}
          >
            Return to Pots Page
          </Link>
        </section>
        
        <Footer />
      </main>
    </AnimatedPage>
  );
}

export default PotErrorPage;

