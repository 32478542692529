import React, { useState, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import { 
  Box, 
  TextField, 
  Button, 
  Typography, 
  RadioGroup, 
  Radio, 
  Checkbox,
  FormControlLabel, 
  Divider,
  Paper,
  FormControl,
  FormHelperText,
  Stack,
  InputAdornment
} from '@mui/material';
import { toast } from 'react-toastify';
import { CloudUpload as CloudUploadIcon, Delete as DeleteIcon } from '@mui/icons-material';
import Resizer from "react-image-file-resizer";

function AddSponsorModal({ status, close, open, eventSettings, isAdmin, username, eventId }) {
  const [sponsorName, setSponsorName] = useState('');
  const [sponsorEmail, setSponsorEmail] = useState('');
  const [sponsorWebsite, setSponsorWebsite] = useState('');
  const [sponsorDescription, setSponsorDescription] = useState('');
  const [selectedTier, setSelectedTier] = useState('');
  const [customAmount, setCustomAmount] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [sponsorLogo, setSponsorLogo] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);
  const [consentGiven, setConsentGiven] = useState(false);
  const fileInputRef = useRef(null);

  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(price);
  };

  // New calculation function to match the team registration pattern
  const calculateFees = (baseAmount, featureType = 'customerPays') => {
    // Calculate platform fee based on sponsorship amount
    let platformFee;
    let feeDescription;
  
    if (baseAmount < 50) {
      platformFee = 3;
      feeDescription = 'Flat $3 fee for sponsorships under $50';
    } else if (baseAmount <= 100) {
      platformFee = 5;
      feeDescription = 'Flat $5 fee for sponsorships $50-$100';
    } else if (baseAmount <= 200) {
      platformFee = baseAmount * 0.05;
      feeDescription = '5% of sponsorship fee';
    } else {
      platformFee = baseAmount * 0.04;
      feeDescription = '4% of sponsorship fee';
    }
  
    // Define Stripe fee parameters
    const STRIPE_PERCENTAGE = 2.9;
    const STRIPE_FIXED_FEE = 0.30;
    const DESTINATION_PERCENTAGE = 0.5;
    
    // Calculate Stripe processing fees
    let stripeFee;
    
    if (featureType === 'customerPays') {
      // If customer pays fees, Stripe fees are calculated on base + platform fee
      stripeFee = ((baseAmount + platformFee) * (STRIPE_PERCENTAGE + DESTINATION_PERCENTAGE) / 100) + STRIPE_FIXED_FEE;
      
      return {
        baseAmount,
        platformFee,
        feeDescription,
        stripeFee,
        stripeFeeDescription: `${STRIPE_PERCENTAGE}% + $${STRIPE_FIXED_FEE} + ${DESTINATION_PERCENTAGE}% destination fee`,
        total: baseAmount + platformFee + stripeFee
      };
    } else {
      // If organizer pays fees, customer only sees base price
      stripeFee = (baseAmount * (STRIPE_PERCENTAGE + DESTINATION_PERCENTAGE) / 100) + STRIPE_FIXED_FEE;
      
      return {
        baseAmount,
        platformFee,
        feeDescription,
        stripeFee,
        stripeFeeDescription: `${STRIPE_PERCENTAGE}% + $${STRIPE_FIXED_FEE} + ${DESTINATION_PERCENTAGE}% destination fee`,
        total: baseAmount
      };
    }
  };

  const handleLogoUpload = async (e) => {
    if (e.target.files?.[0]) {
      const file = e.target.files[0];
      
      try {
        // Create temporary URL for preview
        const tempUrl = URL.createObjectURL(file);
        setLogoPreview(tempUrl);
        
        // Resize image for upload
        const resizedImage = await new Promise((resolve) => {
          Resizer.imageFileResizer(
            file,
            500, // max width
            500, // max height
            'PNG',
            85, // quality
            0,
            (uri) => {
              resolve(uri);
            },
            'base64'
          );
        });

        const response = await fetch(resizedImage);
        const blob = await response.blob();
        
        setSponsorLogo(blob);
        toast.success('Logo processed successfully');
      } catch (error) {
        console.error('Error resizing logo:', error);
        toast.error('Error processing logo. Please try again with a different image.');
        // Clear the file input
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      }
    }
  };

  const handleRemoveLogo = () => {
    setSponsorLogo(null);
    if (logoPreview) {
      URL.revokeObjectURL(logoPreview);
      setLogoPreview(null);
    }
    // Clear the file input
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const validateForm = () => {
    if (!sponsorName.trim()) {
      toast.error('Please enter your organization/company name');
      return false;
    }

    if (!sponsorEmail.trim()) {
      toast.error('Please enter a contact email');
      return false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(sponsorEmail)) {
      toast.error('Please enter a valid email address');
      return false;
    }

    if (sponsorWebsite && sponsorWebsite.trim()) {

      console.log('validating sponsorWebsite')
      // First, remove any leading/trailing whitespace
      const trimmedWebsite = sponsorWebsite.trim();
    
      // Allow entering with or without protocol
      let websiteToCheck = trimmedWebsite;
    
      // If it starts with "www.", prepend "https://"
      if (trimmedWebsite.startsWith('www.')) {
        websiteToCheck = 'https://' + trimmedWebsite;
      }
    
      // If no http:// or https://, prepend "https://"
      if (
        !trimmedWebsite.startsWith('http://') &&
        !trimmedWebsite.startsWith('https://') &&
        !trimmedWebsite.startsWith('www.')
      ) {
        websiteToCheck = 'https://' + trimmedWebsite;
      }

      console.log('Checking URL:', websiteToCheck);
      
      try {
        // Using URL constructor for robust validation
        new URL(websiteToCheck);
      } catch (e) {
        toast.error('Please enter a valid website URL or leave it blank');
        return false;
      }
    }    

    if (!selectedTier && (!eventSettings.sponsors.allowCustomAmount || !customAmount)) {
      toast.error('Please select a sponsorship tier');
      return false;
    }

    if (selectedTier === 'custom' && (!customAmount || isNaN(customAmount) || parseFloat(customAmount) <= 0)) {
      toast.error('Please enter a valid custom amount greater than zero');
      return false;
    }

    if (!isAdmin && !consentGiven) {
      toast.error('Please accept the consent agreement to continue');
      return false;
    }

    return true;
  };

  const getSponsorshipAmount = () => {
    if (selectedTier === 'custom') {
      return parseFloat(customAmount);
    }
    
    const tier = eventSettings.sponsors.tiers.find(t => t.name === selectedTier);
    return tier ? parseFloat(tier.amount) : 0;
  };

  const handleModalClose = () => {
    // Clean up
    setIsSubmitting(false);
    setIsSubmitted(false);
    setSponsorName('');
    setSponsorEmail('');
    setSponsorWebsite('');
    setSponsorDescription('');
    setSelectedTier('');
    setCustomAmount('');
    setSponsorLogo(null);
    if (logoPreview) {
      URL.revokeObjectURL(logoPreview);
      setLogoPreview(null);
    }
    
    close(); // Call the provided close function
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);

    try {
      const formData = new FormData();

      // Add basic information
      formData.append('username', username);
      formData.append('eventId', eventId);
      
      // Create sponsor data object
      const sponsorData = {
        name: sponsorName,
        email: sponsorEmail,
        website: sponsorWebsite,
        description: sponsorDescription,
        tier: selectedTier === 'custom' ? 'Custom' : selectedTier,
        amount: getSponsorshipAmount(),
        registrationTimestamp: new Date().toISOString()
      };

      console.log("sponsorData being sent:", JSON.stringify(sponsorData));
      
      const sponsorDataString = JSON.stringify(sponsorData);
      formData.append('sponsorData', sponsorDataString);
      
      // Add logo if provided
      if (sponsorLogo) {
        formData.append('sponsorLogo', sponsorLogo);
      }
      
      const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
        ? process.env.REACT_APP_SERVER_URL_PRODUCTION
        : process.env.REACT_APP_SERVER_URL_STAGING;
      
      const endpoint = isAdmin 
        ? 'admin_create_event_sponsor'
        : 'create_sponsor_registration_session';
      
      console.log(`Sending request to ${apiUrl}/api/${endpoint}`);
      
      const response = await fetch(`${apiUrl}/api/${endpoint}`, {
        method: 'POST',
        body: formData
      });
      
      if (response.ok) {
        if (isAdmin) {
          const result = await response.json();
          toast.success(`Sponsor added successfully. Sponsor ID: ${result.sponsorId}`);
          setIsSubmitted(true);
          // Delay refresh to show success message
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          const { url } = await response.json();
          window.location = url; // Redirect to Stripe Checkout
        }
      } else {
        const errorData = await response.json();
        toast.error(errorData.error || 'Failed to process sponsor registration');
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error('Sponsor registration error:', error);
      toast.error(error.message || 'Registration failed');
      setIsSubmitting(false);
    }
  };

  const renderConsentCheckbox = () => {
    if (isAdmin) return null; // Don't show consent checkbox for admin registrations
    
    return (
      <FormControl fullWidth sx={{ mt: 2 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={consentGiven}
              onChange={(e) => setConsentGiven(e.target.checked)}
              color="primary"
            />
          }
          label={
            <Typography variant="body2" color="text.secondary">
              I consent to having my sponsorship information collected and used by the event organizers
              for tournament-related communications, sponsor recognition, and future event notifications.
              This data will be handled according to the tournament's privacy practices.
            </Typography>
          }
        />
      </FormControl>
    );
  };

  // Get sponsorship payment feature type for fee calculation
  const sponsorshipFeatureType = eventSettings?.features?.onlineSponsorRegistration || 'customerPays';

  return (
    <Dialog
      open={status}
      onClose={handleModalClose}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle style={{ backgroundColor: '#0281C5', color: 'white', padding: '16px 24px' }}>
        {isAdmin ? 'Add Sponsor' : 'Become a Sponsor'}
        <IconButton
          aria-label="close"
          onClick={handleModalClose}
          sx={{ position: 'absolute', right: 8, top: 8, color: 'white' }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit} style={{ padding: '20px 0' }}>
          <Stack spacing={3}>
            {/* Organization/Company Name */}
            <TextField
              label="Organization/Company Name"
              value={sponsorName}
              onChange={(e) => setSponsorName(e.target.value)}
              placeholder="Your company name"
              required
              fullWidth
              variant="outlined"
            />

            {/* Contact Email */}
            <TextField
              label="Contact Email"
              type="email"
              value={sponsorEmail}
              onChange={(e) => setSponsorEmail(e.target.value)}
              placeholder="contact@company.com"
              required
              fullWidth
              variant="outlined"
            />

            {/* Website URL */}
            <TextField
              label="Website URL"
              // type="url"
              value={sponsorWebsite}
              onChange={(e) => setSponsorWebsite(e.target.value)}
              placeholder="https://yourcompany.com"
              fullWidth
              variant="outlined"
              helperText="Optional: Include your website to allow visitors to learn more about your organization"
            />

            {/* Description */}
            <TextField
              label="Description"
              value={sponsorDescription}
              onChange={(e) => setSponsorDescription(e.target.value)}
              placeholder="Brief description of your organization (optional)"
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              helperText="Optional: Provide a short description about your organization to display alongside your logo"
            />

            {/* Logo Upload */}
            {eventSettings.sponsors.allowLogoUpload && (
              <Box>
                <Typography variant="subtitle1" gutterBottom>
                  Organization Logo
                </Typography>
                
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={handleLogoUpload}
                  ref={fileInputRef}
                />
                
                <Box display="flex" alignItems="center" gap={2}>
                  <Button
                    variant="outlined"
                    startIcon={<CloudUploadIcon />}
                    onClick={() => fileInputRef.current?.click()}
                  >
                    Upload Logo
                  </Button>
                  
                  <Typography variant="body2" color="text.secondary">
                    Optional: Upload your organization's logo to be displayed on the sponsor page
                  </Typography>
                </Box>
                
                {/* Logo Preview */}
                {logoPreview && (
                  <Box mt={2} display="flex" alignItems="center">
                    <Box
                      sx={{
                        width: 150,
                        height: 150,
                        border: '1px solid #ddd',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        overflow: 'hidden',
                        mr: 2
                      }}
                    >
                      <img
                        src={logoPreview}
                        alt="Logo Preview"
                        style={{ 
                          maxWidth: '100%', 
                          maxHeight: '100%', 
                          objectFit: 'contain' 
                        }}
                      />
                    </Box>
                    <IconButton onClick={handleRemoveLogo}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                )}
                
                <FormHelperText>
                  For best results, upload a square logo with transparent background (PNG format recommended)
                </FormHelperText>
              </Box>
            )}

            {/* Sponsorship Tier Selection */}
            <Box>
              <Typography variant="subtitle1" gutterBottom>
                Sponsorship Tier <span style={{ color: 'red' }}>*</span>
              </Typography>
              
              <RadioGroup 
                value={selectedTier}
                onChange={(e) => {
                  setSelectedTier(e.target.value);
                  // Reset custom amount if not selecting custom tier
                  if (e.target.value !== 'custom') {
                    setCustomAmount('');
                  }
                }}
              >
                <Box 
                  display="grid" 
                  gridTemplateColumns="repeat(auto-fill, minmax(250px, 1fr))" 
                  gap={2}
                >
                  {eventSettings?.sponsors?.tiers?.map((tier, index) => (
                    <Paper 
                      key={index} 
                      sx={{ 
                        p: 2, 
                        border: selectedTier === tier.name ? '2px solid #0281C5' : '1px solid #ddd',
                        borderRadius: 2,
                        bgcolor: selectedTier === tier.name ? 'rgba(2, 129, 197, 0.05)' : 'transparent',
                        cursor: 'pointer',
                        transition: 'all 0.2s',
                        '&:hover': {
                          borderColor: '#0281C5',
                          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)'
                        }
                      }}
                      onClick={() => setSelectedTier(tier.name)}
                    >
                      <FormControlLabel 
                        value={tier.name}
                        control={<Radio />} 
                        label={
                          <Box>
                            <Typography variant="h6" sx={{ mb: 1 }}>
                              {tier.name}
                            </Typography>
                            <Typography variant="h6" color="primary" sx={{ mb: 1 }}>
                              {formatPrice(tier.amount)}
                            </Typography>
                            {tier.description && (
                              <Typography variant="body2" color="text.secondary">
                                {tier.description}
                              </Typography>
                            )}
                          </Box>
                        }
                        sx={{ width: '100%', m: 0 }}
                      />
                    </Paper>
                  ))}
                  
                  {/* Custom Amount Option */}
                  {eventSettings.sponsors.allowCustomAmount && (
                    <Paper 
                      sx={{ 
                        p: 2, 
                        border: selectedTier === 'custom' ? '2px solid #0281C5' : '1px solid #ddd',
                        borderRadius: 2,
                        bgcolor: selectedTier === 'custom' ? 'rgba(2, 129, 197, 0.05)' : 'transparent',
                        cursor: 'pointer',
                        transition: 'all 0.2s',
                        '&:hover': {
                          borderColor: '#0281C5',
                          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)'
                        }
                      }}
                      onClick={() => setSelectedTier('custom')}
                    >
                      <FormControlLabel 
                        value="custom"
                        control={<Radio />} 
                        label={
                          <Box>
                            <Typography variant="h6" sx={{ mb: 1 }}>
                              Custom Amount
                            </Typography>
                            <TextField
                              placeholder="Enter amount"
                              value={customAmount}
                              onChange={(e) => {
                                // Ensure only numbers and decimal points
                                const value = e.target.value.replace(/[^0-9.]/g, '');
                                setCustomAmount(value);
                                // Automatically select custom when entering an amount
                                setSelectedTier('custom');
                              }}
                              onClick={(e) => {
                                // Prevent radio selection from changing when clicking the textfield
                                e.stopPropagation();
                              }}
                              InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              }}
                              variant="outlined"
                              size="small"
                              fullWidth
                              disabled={selectedTier !== 'custom'}
                            />
                            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                              Specify your own sponsorship amount
                            </Typography>
                          </Box>
                        }
                        sx={{ width: '100%', m: 0 }}
                      />
                    </Paper>
                  )}
                </Box>
              </RadioGroup>
            </Box>

            {/* Fee Breakdown */}
            {isAdmin ? (
              <Paper sx={{ p: 3, bgcolor: 'background.paper', borderRadius: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Sponsorship Fee Information
                </Typography>
                <Box sx={{ 
                  p: 2, 
                  mb: 2, 
                  bgcolor: '#e8f4fd', 
                  border: '1px solid #0281C5', 
                  borderRadius: 1,
                  borderLeft: '4px solid #0281C5'
                }}>
                  <Typography variant="subtitle1" fontWeight="bold" color="#0281C5">
                    Admin Registration - No Fees Applied
                  </Typography>
                  <Typography variant="body2">
                    As an admin, you are adding this sponsor directly to the tournament. 
                    <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                      No sponsorship fees will be charged
                    </span> for admin-created sponsors.
                  </Typography>
                </Box>
                {(() => {
                  try {
                    const sponsorshipAmount = getSponsorshipAmount();
                    const { platformFee, feeDescription, stripeFee, stripeFeeDescription, total } = calculateFees(sponsorshipAmount, sponsorshipFeatureType);
                    
                    return (
                      <Box sx={{ mt: 2 }}>
                        <Typography variant="body2" fontWeight="medium" sx={{ color: '#666', fontStyle: 'italic' }}>
                          Fee information (for reference only - not applied for admin registrations):
                        </Typography>
                        <Box sx={{ mt: 1, display: 'flex', justifyContent: 'space-between' }}>
                          <Typography variant="body2">Sponsorship Amount:</Typography>
                          <Typography variant="body2">${sponsorshipAmount.toFixed(2)}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography variant="body2">Platform Fee:</Typography>
                          <Typography variant="body2">${platformFee.toFixed(2)} ({feeDescription})</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography variant="body2">Stripe Processing Fee:</Typography>
                          <Typography variant="body2">${stripeFee.toFixed(2)} ({stripeFeeDescription})</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                          <Typography variant="body2" fontWeight="medium">Total:</Typography>
                          <Typography variant="body2" fontWeight="medium">${total.toFixed(2)}</Typography>
                        </Box>
                      </Box>
                    );
                  } catch (error) {
                    console.error('Error calculating reference fees:', error);
                    return null;
                  }
                })()}
              </Paper>
            ) : (
              <Paper sx={{ p: 3, bgcolor: 'background.paper', borderRadius: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Sponsorship Fee Breakdown
                </Typography>
                {(() => {
                  try {
                    const sponsorshipAmount = getSponsorshipAmount();
                    const customerPaysProcessingFee = sponsorshipFeatureType === 'customerPays';
                    const { platformFee, feeDescription, stripeFee, total } = calculateFees(sponsorshipAmount, sponsorshipFeatureType);

                    return (
                      <Stack spacing={1}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography>
                            {selectedTier === 'custom' ? 'Custom Sponsorship' : selectedTier}
                          </Typography>
                          <Typography>${sponsorshipAmount.toFixed(2)}</Typography>
                        </Box>
                        
                        {customerPaysProcessingFee && (
                          <>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                              <Typography>Custom Tournament Solutions Fee</Typography>
                              <Stack alignItems="flex-end">
                                <Typography>${platformFee.toFixed(2)}</Typography>
                                <Typography variant="caption" color="text.secondary">
                                  ({feeDescription})
                                </Typography>
                              </Stack>
                            </Box>
                            
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                              <Typography>Stripe Processing Fee</Typography>
                              <Typography>${stripeFee.toFixed(2)}</Typography>
                            </Box>
                          </>
                        )}
                        
                        <Divider sx={{ my: 1 }} />
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography variant="subtitle1" fontWeight="bold">Total</Typography>
                          <Typography variant="subtitle1" fontWeight="bold">
                            ${total.toFixed(2)}
                          </Typography>
                        </Box>
                      </Stack>
                    );
                  } catch (error) {
                    console.error('Error calculating sponsorship fees:', error);
                    return (
                      <Typography color="error">
                        Unable to calculate sponsorship fees. Please contact support.
                      </Typography>
                    );
                  }
                })()}
              </Paper>
            )}

            {renderConsentCheckbox()}

            <Button
              type="submit"
              variant="contained"
              size="large"
              fullWidth
              disabled={isSubmitting || (!isAdmin && !consentGiven)}
              startIcon={isSubmitting ? <CircularProgress size={24} color="inherit" /> : null}
              sx={{
                backgroundColor: '#0281C5',
                color: 'white',
                borderRadius: '30px',
                py: 1.5,
                fontWeight: 'bold',
                '&:hover': {
                  backgroundColor: '#026999',
                },
              }}
            >
              {isSubmitting ? 'Processing...' : isAdmin ? 'Add Sponsor' : 'Continue to Payment'}
            </Button>
            
            {isSubmitted && (
              <Box sx={{ textAlign: 'center', mt: 2 }}>
                <Typography variant="subtitle1" color="primary" fontWeight="bold">
                  Sponsor added successfully!
                </Typography>
              </Box>
            )}
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default AddSponsorModal;

