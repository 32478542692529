import React, { useState, useEffect } from 'react';
import { useParams, NavLink, Link } from 'react-router-dom';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { Menu, X, ChevronDown } from 'lucide-react';
import DocumentViewer from '../components/viewers/DocumentViewer';
import './EventNavigation.css';

function EventNavigation() {
  const { username, eventId } = useParams();
  const [eventSettings, setEventSettings] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [infoItems, setInfoItems] = useState([]);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const fetchEventSettings = async () => {
      const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
        ? process.env.REACT_APP_SERVER_URL_PRODUCTION
        : process.env.REACT_APP_SERVER_URL_STAGING;

      try {
        const response = await fetch(`${apiUrl}/api/load_event_profile`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username, eventId }),
        });

        const data = await response.json();
        if (data.success) {
          setEventSettings(data.settings);
          processInfoItems(data.settings);
        }
      } catch (error) {
        console.error('Error fetching event settings:', error);
      }
    };

    fetchEventSettings();
  }, [username, eventId]);

  const useScreenWidth = () => {
    const [width, setWidth] = useState(window.innerWidth);
  
    useEffect(() => {
      const handleResize = () => setWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return width;
  };

  const screenWidth = useScreenWidth();

  const processInfoItems = (settings) => {
    const items = [];
    
    // Rules
    if (settings.basicInfo.rulesType !== 'none') {
      items.push({
        label: 'Rules',
        type: settings.basicInfo.rulesType,
        content: settings.basicInfo.rulesType === 'link' ? settings.basicInfo.rulesLink :
                settings.basicInfo.rulesType === 'file' ? settings.basicInfo.rulesFileUrl :
                settings.basicInfo.rulesContent,
      });
    }
  
    // Schedule
    if (settings.basicInfo.scheduleType !== 'none') {
      items.push({
        label: 'Schedule',
        type: settings.basicInfo.scheduleType,
        content: settings.basicInfo.scheduleType === 'link' ? settings.basicInfo.scheduleLink :
                settings.basicInfo.scheduleType === 'file' ? settings.basicInfo.scheduleFileUrl :
                settings.basicInfo.scheduleContent,
      });
    }
  
    // Website
    if (settings.basicInfo.websiteType === 'link' && settings.basicInfo.websiteLink) {
      items.push({
        label: 'Tournament Site',
        type: 'link',
        content: settings.basicInfo.websiteLink,
      });
    }
  
    setInfoItems(items);
  };

  const handleInfoClick = (item) => {
    if (item.type === 'link') {
      window.open(item.content.startsWith('http') ? item.content : `https://${item.content}`, '_blank');
    } else if (item.type === 'file') {
      setModalContent({
        type: 'file',
        content: item.content,
        label: item.label,
      });
      setIsModalOpen(true);
    } else if (item.type === 'text') {
      setModalContent({
        type: 'text',
        content: item.content,
        label: item.label,
      });
      setIsModalOpen(true);
    }
    setIsMenuOpen(false);
  };

  const closeMenu = () => setIsMenuOpen(false);

  // Check if sponsor feature is enabled AND visible
  const isSponsorFeatureEnabled = () => {
    return (eventSettings?.features?.onlineSponsorRegistration === "customerPays" || 
          eventSettings?.features?.onlineSponsorRegistration === "iPay") &&
          (eventSettings?.pageVisibility?.sponsors !== false); // Check visibility flag
  };

  // Check if registration is enabled AND visible
  const isRegistrationEnabled = () => {
    return (eventSettings?.features?.onlineTeamRegistration === "customerPays" || 
          eventSettings?.features?.onlineTeamRegistration === "iPay") &&
          (eventSettings?.pageVisibility?.registration !== false); // Check visibility flag
  };

  // Check if pots feature is enabled AND visible
  const isPotsFeatureEnabled = () => {
    return (eventSettings?.features?.fishPots === "customerPays" || 
          eventSettings?.features?.fishPots === "iPay") &&
          (eventSettings?.pageVisibility?.pots !== false); // Check visibility flag
  };

  // Check if leaderboard is visible
  const isLeaderboardVisible = () => {
    return eventSettings?.pageVisibility?.leaderboard !== false; // Check visibility flag
  };

  // Get nav container styles based on scroll position and event settings
  const getNavStyles = () => {
    const baseStyles = {
      backgroundColor: isScrolled ? 'white' : (eventSettings?.basicInfo?.backgroundColor || 'transparent'),
      borderBottom: isScrolled ? '1px solid #eee' : 'none',
    };
    
    // Add a semi-transparent background when not scrolled to improve visibility
    if (!isScrolled) {
      baseStyles.backgroundImage = 'linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0))';
    }
    
    return baseStyles;
  };

  // Get link styles based on scroll position and event settings
  const getLinkStyles = () => {
    if (isScrolled) {
      return { color: 'black' };
    }
    return {
      color: eventSettings?.basicInfo?.headlineTextColor || 'white',
      textShadow: '1px 1px 2px rgba(0,0,0,0.2)', // Add text shadow for better visibility
    };
  };

  if (!eventSettings) return null;

  return (
    <>
      <nav 
        className={`nav-container ${isScrolled ? 'nav-scrolled' : 'nav-transparent'}`}
        style={getNavStyles()}
      >
        <div className="nav-content">
          {/* Logo - Keep for spacing but make invisible if no logo */}
          <div className="nav-logo" style={{ visibility: eventSettings?.basicInfo?.logoUrl ? 'visible' : 'hidden' }}>
            <Link to={`/${username}/${eventId}/home`}>
              <img 
                src={eventSettings.basicInfo.logoUrl || 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'} 
                alt={eventSettings.basicInfo.title || ''}
                className="h-12 w-auto object-contain"
              />
            </Link>
          </div>

          {/* Desktop Menu */}
          <div className="nav-menu">
            <NavLink 
              to={`/${username}/${eventId}/home`} 
              className="nav-link"
              style={getLinkStyles()}
            >
              Home
            </NavLink>

            {infoItems.length > 0 && (
              <div className="nav-dropdown">
                <button 
                  className="dropdown-button"
                  style={getLinkStyles()}
                >
                  <span>Info</span>
                  <ChevronDown size={16} />
                </button>
                <div className="dropdown-content">
                  {infoItems.map((item, index) => (
                    <button
                      key={index}
                      onClick={() => handleInfoClick(item)}
                      className="dropdown-item"
                    >
                      {item.label}
                    </button>
                  ))}
                </div>
              </div>
            )}

            <div className="nav-dropdown">
              <button 
                className="dropdown-button"
                style={getLinkStyles()}
              >
                <span>Tournament</span>
                <ChevronDown size={16} />
              </button>
              <div className="dropdown-content">
                <NavLink to={`/${username}/${eventId}/newsfeed`} className="dropdown-item">
                  Newsfeed
                </NavLink>
                
                {/* Only show Leaderboard if visible */}
                {isLeaderboardVisible() && (
                  <NavLink to={`/${username}/${eventId}/leaderboard`} className="dropdown-item">
                    Leaderboard
                  </NavLink>
                )}
                
                {/* The rest of your menu items using the updated functions */}
                {isRegistrationEnabled() && (
                  <NavLink to={`/${username}/${eventId}/register`} className="dropdown-item">
                    Register
                  </NavLink>
                )}

                {isSponsorFeatureEnabled() && (
                  <NavLink to={`/${username}/${eventId}/sponsors`} className="dropdown-item">
                    Sponsors
                  </NavLink>
                )}

                {isPotsFeatureEnabled() && (
                  <NavLink to={`/${username}/${eventId}/pots`} className="dropdown-item">
                    Pots
                  </NavLink>
                )}
              
              </div>
            </div>

            <div className="nav-dropdown">
              <button 
                className="dropdown-button"
                style={getLinkStyles()}
              >
                <span>Admin</span>
                <ChevronDown size={16} />
              </button>
              <div className="dropdown-content">
                <NavLink to={`/${username}/${eventId}/admin`} className="dropdown-item">
                  Settings
                </NavLink>
                <NavLink to={`/${username}/dashboard`} className="dropdown-item">
                  Dashboard
                </NavLink>
                <NavLink to={`/${username}/${eventId}/support`} className="dropdown-item">
                  Support
                </NavLink>
              </div>
            </div>
          </div>

          {/* Mobile Menu Button */}
          {screenWidth < 768 && (
            <button
              className="mobile-menu-button"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              style={getLinkStyles()}
            >
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          )}

          {/* Mobile Menu */}
          <div className={`mobile-menu ${isMenuOpen ? 'active' : ''}`}>
            <div className="mobile-menu-section">
              <NavLink 
                to={`/${username}/${eventId}/home`} 
                className="mobile-menu-link" 
                onClick={closeMenu}
              >
                Home
              </NavLink>
            </div>
            {infoItems.map((item, index) => (
              <div key={index} className="mobile-menu-section">
                <button 
                  className="mobile-menu-link" 
                  onClick={() => handleInfoClick(item)}
                >
                  {item.label}
                </button>
              </div>
            ))}
            <div className="mobile-menu-section">
              <NavLink 
                to={`/${username}/${eventId}/newsfeed`} 
                className="mobile-menu-link" 
                onClick={closeMenu}
              >
                Newsfeed
              </NavLink>
            </div>
            <div className="mobile-menu-section">
              <NavLink 
                to={`/${username}/${eventId}/leaderboard`} 
                className="mobile-menu-link" 
                onClick={closeMenu}
              >
                Leaderboard
              </NavLink>
            </div>
            {isRegistrationEnabled() && (
              <div className="mobile-menu-section">
                <NavLink 
                  to={`/${username}/${eventId}/register`} 
                  className="mobile-menu-link" 
                  onClick={closeMenu}
                >
                  Register
                </NavLink>
              </div>
            )}
            {isSponsorFeatureEnabled() && (
              <div className="mobile-menu-section">
                <NavLink 
                  to={`/${username}/${eventId}/sponsors`} 
                  className="mobile-menu-link" 
                  onClick={closeMenu}
                >
                  Sponsors
                </NavLink>
              </div>
            )}
            {isPotsFeatureEnabled() && (
              <div className="mobile-menu-section">
                <NavLink 
                  to={`/${username}/${eventId}/pots`} 
                  className="mobile-menu-link" 
                  onClick={closeMenu}
                >
                  Pots
                </NavLink>
              </div>
            )}
            <div className="mobile-menu-section">
              <NavLink 
                to={`/${username}/${eventId}/admin`} 
                className="mobile-menu-link" 
                onClick={closeMenu}
              >
                Settings
              </NavLink>
            </div>
            <div className="mobile-menu-section">
              <NavLink 
                to={`/${username}/dashboard`} 
                className="mobile-menu-link" 
                onClick={closeMenu}
              >
                Dashboard
              </NavLink>
            </div>
            <div className="mobile-menu-section">
              <NavLink 
                to={`/${username}/${eventId}/support`} 
                className="mobile-menu-link" 
                onClick={closeMenu}
              >
                Support
              </NavLink>
            </div>
          </div>
        </div>
      </nav>

      {/* Content Modal */}
      {modalContent?.type === 'file' ? (
        <DocumentViewer
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          documentUrl={modalContent.content}
          title={modalContent.label}
        />
      ) : (
        <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)} maxWidth="md" fullWidth>
          <DialogContent>
            <IconButton onClick={() => setIsModalOpen(false)} className="absolute right-2 top-2">
              <CloseIcon />
            </IconButton>
            <div className="mt-6">
              <div className="whitespace-pre-wrap">{modalContent?.content}</div>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

export default EventNavigation;

