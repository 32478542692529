import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import AnimatedPage from './AnimatedPage';
import BarChartIcon from '@mui/icons-material/BarChart';

const StatisticsPage = () => {
  return (
    <AnimatedPage>
      <Box sx={{ 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        p: 4,
        backgroundColor: '#ffffff', // Matching background
      }}>
        <Paper
          elevation={0}
          sx={{
            p: 6,
            textAlign: 'center',
            backgroundColor: '#ffffff', // Solid white
            borderRadius: '16px',
            border: '1px solid rgba(44, 62, 80, 0.1)',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
            maxWidth: '600px'
          }}
        >
          <BarChartIcon sx={{ 
            fontSize: 80, 
            mb: 2,
            color: '#2C3E50',
          }} />

          <Typography 
            variant="h4" 
            sx={{ 
              mb: 2,
              fontWeight: 600,
              background: 'linear-gradient(45deg, #2C3E50 30%, #3A506B 90%)',
              backgroundClip: 'text',
              WebkitBackgroundClip: 'text',
              color: 'transparent',
            }}
          >
            Coming Soon
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
            We're working on bringing you powerful statistics and analytics tools.
            Stay tuned for updates!
          </Typography>
          <Box sx={{
            p: 2,
            bgcolor: 'rgba(44, 62, 80, 0.05)',
            borderRadius: '8px',
            border: '1px dashed rgba(44, 62, 80, 0.2)'
          }}>
            <Typography variant="body2" color="text.secondary">
              Featured statistics will include tournament participation rates,
              engagement metrics, and performance analytics.
            </Typography>
          </Box>
        </Paper>
      </Box>
    </AnimatedPage>
  );
};

export default StatisticsPage;

