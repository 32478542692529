import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import AnimatedPage from './AnimatedPage';
import Footer from '../components/footer/Footer';
import AddSponsorModal from '../components/modals/AddSponsorModal';
import CircularProgress from '@mui/material/CircularProgress';
import './SponsorPage.css';

function SponsorPage() {
  const { username, eventId } = useParams();
  const [eventSettings, setEventSettings] = useState(null);
  const [sponsors, setSponsors] = useState([]);  
  const [loadingEventSettings, setLoadingEventSettings] = useState(true);
  const [loadingSponsors, setLoadingSponsors] = useState(true);
  const [isAddSponsorModalOpen, setIsAddSponsorModalOpen] = useState(false);

  useEffect(() => {
    const fetchEventSettings = async () => {
      const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
        ? process.env.REACT_APP_SERVER_URL_PRODUCTION
        : process.env.REACT_APP_SERVER_URL_STAGING;

      try {
        const response = await fetch(`${apiUrl}/api/load_event_profile`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username, eventId }),
        });
        const data = await response.json();
        if (data.success) {
          setEventSettings(data.settings);
          console.log('Sponsor page fetched the following event settings: ', data.settings);
        } else {
          console.error('Error fetching event settings:', data.message);
          toast.error('Failed to load event settings');
        }
      } catch (error) {
        console.error('Error fetching event settings:', error);
        toast.error('Failed to load event settings');
      } finally {
        setLoadingEventSettings(false);
      }
    };

  const fetchSponsors = async () => {
    const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
      ? process.env.REACT_APP_SERVER_URL_PRODUCTION
      : process.env.REACT_APP_SERVER_URL_STAGING;

    console.log(`Fetching sponsors from ${apiUrl}/api/get_event_sponsors with username: ${username}, eventId: ${eventId}`);

    try {
      // Use the public endpoint for sponsor data
      const response = await fetch(`${apiUrl}/api/get_event_sponsors`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, eventId }),
      });
      
      console.log('Response status:', response.status);
      
      if (!response.ok) {
        throw new Error(`Server responded with status: ${response.status}`);
      }
      
      const data = await response.json();
      console.log('Fetched sponsors data:', data);
      
      if (data.success) {
        // Make sure sponsors is handled as an array
        const sponsorsArray = Array.isArray(data.sponsors) ? data.sponsors : [];
        console.log('Processed sponsors array:', sponsorsArray);
        setSponsors(sponsorsArray);
      } else {
        console.error('Error in sponsors response:', data.message);
        setSponsors([]);
      }
    } catch (error) {
      console.error('Error fetching sponsors:', error);
      toast.error('Failed to load sponsors');
      setSponsors([]);
    } finally {
      setLoadingSponsors(false);
    }
  };

    fetchEventSettings();
    fetchSponsors();
  }, [username, eventId]);

  // Show loading state
  if (loadingEventSettings || loadingSponsors) {
    return (
      <AnimatedPage>
        <div style={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '100vh' 
        }}>
          <CircularProgress />
        </div>
      </AnimatedPage>
    );
  }

  // Group sponsors by tier
  const groupedSponsors = sponsors.reduce((acc, sponsor) => {
    const tier = sponsor.tier || 'Other';
    if (!acc[tier]) {
      acc[tier] = [];
    }
    acc[tier].push(sponsor);
    return acc;
  }, {});

  // Define tier order and display properties
  const tierConfig = {
    'Platinum': { order: 1, className: 'sponsor-platinum' },
    'Gold': { order: 2, className: 'sponsor-gold' },
    'Silver': { order: 3, className: 'sponsor-silver' },
    'Bronze': { order: 4, className: 'sponsor-bronze' },
    'Custom': { order: 5, className: 'sponsor-custom' },
    'Other': { order: 6, className: 'sponsor-other' }
  };

  // Sort tiers by predefined order
  const sortedTiers = Object.keys(groupedSponsors).sort((a, b) => {
    return (tierConfig[a]?.order || 999) - (tierConfig[b]?.order || 999);
  });

  const handleOpenModal = () => {
    if (!eventSettings) {
      toast.error('Event settings are not loaded. Please try again.');
      return;
    }
    setIsAddSponsorModalOpen(true);
  };
  
  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(price);
  };

  return (
    <AnimatedPage>
      <main>
        {/* Banner */}
        <section 
          className="section-banner"
          style={{ 
            backgroundColor: eventSettings?.basicInfo?.backgroundColor || '#288DAF',
            color: eventSettings?.basicInfo?.headlineTextColor || '#ffffff' 
          }}
        >
          <h1>Sponsors</h1>
        </section>

        {/* Sponsors Display Section */}
        <section className="section-sponsors">
          <h1 className="section-title">Our Event Sponsors</h1>
          
          {sortedTiers.length > 0 ? (
            sortedTiers.map(tier => (
              <div key={tier} className={`sponsor-tier ${tierConfig[tier]?.className || ''}`}>
                <h2 className="tier-title">{tier} Sponsors</h2>
                <div className="sponsors-grid">
                  {groupedSponsors[tier].map((sponsor, index) => (
                    <div key={sponsor.sponsorId || index} className="sponsor-card">
                      {sponsor.logoUrl ? (
                        <div className="sponsor-logo-container">
                          <img 
                            src={sponsor.logoUrl} 
                            alt={`${sponsor.name} logo`} 
                            className="sponsor-logo"
                            onError={(e) => {
                              console.error('Logo failed to load:', sponsor.logoUrl);
                              e.target.onerror = null;
                              e.target.src = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Crect width='100' height='100' fill='%23f0f0f0'/%3E%3Ctext x='50' y='50' font-family='Arial' font-size='14' text-anchor='middle' dominant-baseline='middle' fill='%23999'%3ELogo%3C/text%3E%3C/svg%3E";
                            }}
                          />
                        </div>
                      ) : (
                        <div className="sponsor-no-logo">
                          <h3>{sponsor.name}</h3>
                        </div>
                      )}
                      <div className="sponsor-details">
                        <h3>{sponsor.name}</h3>
                        {sponsor.website && (
                          <a 
                            href={sponsor.website.startsWith('http') || sponsor.website.startsWith('www.') 
                              ? (sponsor.website.startsWith('www.') ? `https://${sponsor.website}` : sponsor.website) 
                              : `https://${sponsor.website}`} 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            className="sponsor-website"
                          >
                            Visit Website
                          </a>
                        )}
                        {sponsor.description && (
                          <p className="sponsor-description">{sponsor.description}</p>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <div className="no-sponsors">
              <p>No sponsors have registered for this event yet. Be the first!</p>
            </div>
          )}
        </section>

        {/* Become a Sponsor Section */}
        <section className="section-become-sponsor">
          <h1 className="section-title">Become a Sponsor</h1>
          <p className="sponsor-info">
            Support this event and gain visibility for your brand. 
            Sponsorship packages are available at different levels to fit your marketing goals and budget.
          </p>
          
          <div className="sponsor-tiers-info">
            {eventSettings?.sponsors?.tiers?.map((tier, index) => (
              <div key={index} className="sponsor-tier-info">
                <h2>{tier.name}</h2>
                <p>{tier.description || `${tier.name} sponsorship tier - ${formatPrice(tier.amount)}`}</p>
              </div>
            ))}
            {eventSettings?.sponsors?.allowCustomAmount && (
              <div className="sponsor-tier-info custom-tier">
                <h2>Custom Amount</h2>
                <p>Support this event with a custom sponsorship amount that works for your organization.</p>
              </div>
            )}
          </div>

          <button 
            className="sponsor-button"
            onClick={handleOpenModal}
            style={{
              backgroundColor: '#0281C5',
              color: '#ffffff',
            }}
          >
            Sponsor Event
          </button>
        </section>

        <Footer />

        {/* Sponsor Registration Modal */}
        {eventSettings && (
          <AddSponsorModal
            status={isAddSponsorModalOpen}
            close={() => setIsAddSponsorModalOpen(false)}
            open={() => setIsAddSponsorModalOpen(true)}
            eventSettings={eventSettings}
            isAdmin={false}
            username={username}
            eventId={eventId}
          />
        )}
      </main>
    </AnimatedPage>
  );
}

export default SponsorPage;

