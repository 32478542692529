import React, { useEffect, useState } from 'react';
import { Box, useMediaQuery, Paper, Typography, Divider} from '@mui/material';
import { toast } from 'react-toastify';
import AnimatedPage from './AnimatedPage';
import EventCard from '../components/dashboard/EventCard';
import CreateEventCard from '../components/dashboard/CreateEventCard';
import PlaceholderCard from '../components/dashboard/PlaceholderCard';
import DraftEventCard from '../components/dashboard/DraftEventCard';
import CreateEventStepper from '../components/modals/CreateEventStepper'; 
import './DashboardPage.css';
import { initialFormState, FEATURE_OPTIONS } from '../config/config';

const DashboardPage = () => {
  console.log('In DashboardPage...')
  const currentUser = JSON.parse(window.localStorage.getItem('user'));
  const username = window.localStorage.getItem('username');
  const [activeEvents, setActiveEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);
  const [isCreateEventStepperOpen, setIsCreateEventStepperOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width: 600px)'); // Detect screen size
  const cardWidth = isMobile ? '100%' : '30%';
  const cardHeight = '350px';
  const [draftEvents, setDraftEvents] = useState([]);
  const [initialFormData, setInitialFormData] = useState(null);

  useEffect(() => {
    // Define apiUrl based on environment
    const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
      ? process.env.REACT_APP_SERVER_URL_PRODUCTION
      : process.env.REACT_APP_SERVER_URL_STAGING;
  
    if (!currentUser || !apiUrl) return; // Ensure currentUser and apiUrl are defined
  
    const fetchUserData = async () => {
      try {
        // Get user profile data using POST and sending the userId in the request body
        const profileResponse = await fetch(`${apiUrl}/api/get_user_profile_data`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ userId: currentUser.uid }),
        });
        
        const profileData = await profileResponse.json();
        if (!profileData.success || !profileData.userData || !profileData.userData.username) {
          toast.error('Failed to fetch user profile data');
          console.error('Invalid profile data received:', profileData);
          return;
        }
        
        console.log('profileData', profileData);
        const username = profileData.userData.username;
  
        // Fetch event settings data using the profile's username in a POST request
        const settingsResponse = await fetch(`${apiUrl}/api/get_event_settings_data`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username }), // Use the validated username
        });
        
        const settingsData = await settingsResponse.json();
        if (!settingsData.success) {
          toast.error('Failed to fetch event settings data');
          return;
        }
        
        console.log('settingsData', settingsData);
  
        // Fetch draft events
        const draftsResponse = await fetch(`${apiUrl}/api/get_draft_events`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username }),
        });
        
        const draftsData = await draftsResponse.json();
        if (draftsData.success) {
          console.log('Drafts data:', draftsData);
          setDraftEvents(draftsData.drafts || []);
        } else {
          console.error('Failed to fetch draft events', draftsData);
        }
  
        // Separate events into active and past categories based on the settings data
        const now = Date.now();
        const active = [];
        const past = [];
  
        settingsData.data.forEach((eventObj) => {
          const { eventId, settings } = eventObj;
          settings["eventId"] = eventId;
          
          const eventEndDateEpoch = Number(settings["eventEndDateTimeGMT"]);
          if (eventEndDateEpoch > now) {
            active.push(settings);
          } else {
            past.push(settings);
          }
        });
  
        setActiveEvents(active);
        setPastEvents(past);
      } catch (error) {
        toast.error('An error occurred while fetching data');
        console.error('Error in fetchUserData:', error);
      }
    };
  
    fetchUserData();
  }, []); // Re-run the effect when currentUser changes

  // HELPERS
  const handleDraftDelete = (draftId) => {
    setDraftEvents(prevDrafts => 
      prevDrafts.filter(draft => draft.draftId !== draftId)
    );
    toast.success('Draft deleted successfully');
  };

  const handleDraftContinue = (draft) => {
    setInitialFormData(draft.draftData); // Add this state variable
    setIsCreateEventStepperOpen(true);
  };

  const handleOpenCreateEventStepper = (eventToCopy = null) => {
    // If no event is provided, use initialFormState
    const preparedData = eventToCopy ? prepareCopyEventData(eventToCopy) : null;
    setInitialFormData(preparedData);
    setIsCreateEventStepperOpen(true);
  };

  const handleCloseCreateEventStepper = () => {
    setIsCreateEventStepperOpen(false);
    window.location.reload();
  };

  const handleEventDelete = (eventId) => {
    // Update active events
    setActiveEvents(prevEvents => 
      prevEvents.filter(event => event.eventId !== eventId)
    );
    
    // Update past events
    setPastEvents(prevEvents => 
      prevEvents.filter(event => event.eventId !== eventId)
    );
    
    toast.success('Event deleted successfully');
  };

  const addPlaceholderCards = (eventList) => {
    const remainder = eventList.length % 3;
    const placeholderCount = remainder === 0 ? 0 : 3 - remainder;
    return Array(placeholderCount).fill(<PlaceholderCard cardWidth={cardWidth} cardHeight={cardHeight} />);
  };

  const prepareCopyEventData = (event) => {
    // Ensure we have a valid event object
    if (!event) {
      console.warn('No event provided for copying, using initial form state');
      return initialFormState;
    }
  
    return {
      ...initialFormState, // Start with the initial form state
      
      // Business and Package Information
      packageType: event.packageType || initialFormState.packageType,
      businessName: event.businessName || '',
      taxId: event.taxId || '',
      
      // Explicitly set Stripe connection to null
      stripeAccountId: null,
      stripeAccountStatus: null,
      stripeConnectedAt: null,
  
      basicInfo: {
        ...initialFormState.basicInfo,
        // Safely access title, using fallback values
        title: `Copy of ${event.eventTitle || event.title || event.basicInfo?.title || 'Untitled Event'}`,
        subheadline: (event.homepageSubheadline || event.basicInfo?.subheadline || initialFormState.basicInfo.subheadline),
        backgroundColor: (event.backgroundColor || event.basicInfo?.backgroundColor || initialFormState.basicInfo.backgroundColor),
        headlineTextColor: (event.headlineTextColor || event.basicInfo?.headlineTextColor || initialFormState.basicInfo.headlineTextColor),
        showCountdown: event.showCountdown ?? event.basicInfo?.showCountdown ?? initialFormState.basicInfo.showCountdown,
        
        // Copy content settings with multiple fallback paths
        rulesType: event.rulesType || event.basicInfo?.rulesType || 'none',
        rulesContent: event.rulesContent || event.basicInfo?.rulesContent || '',
        rulesLink: event.rulesLink || event.basicInfo?.rulesLink || '',
        rulesFileUrl: event.rulesFileUrl || event.basicInfo?.rulesFileUrl || null,
        
        scheduleType: event.scheduleType || event.basicInfo?.scheduleType || 'none',
        scheduleContent: event.scheduleContent || event.basicInfo?.scheduleContent || '',
        scheduleLink: event.scheduleLink || event.basicInfo?.scheduleLink || '',
        scheduleFileUrl: event.scheduleFileUrl || event.basicInfo?.scheduleFileUrl || null,
        
        websiteType: event.websiteType || event.basicInfo?.websiteType || 'none',
        websiteLink: event.websiteLink || event.basicInfo?.websiteLink || '',
      },
      
      features: event.features || initialFormState.features,
      
      registration: {
        ...initialFormState.registration,
        allowTeamPhoto: event.registration?.allowTeamPhoto ?? initialFormState.registration.allowTeamPhoto,
        registrationMessage: event.registration?.registrationMessage || initialFormState.registration.registrationMessage,
        defaultTeamRegistrationFields: event.registration?.defaultTeamRegistrationFields || initialFormState.registration.defaultTeamRegistrationFields,
        userDefinedTeamRegistrationFields: event.registration?.userDefinedTeamRegistrationFields || [],
        includeDivisions: event.registration?.includeDivisions ?? initialFormState.registration.includeDivisions,
        divisions: (event.registration?.divisions || initialFormState.registration.divisions).map(div => ({
          ...div,
          registrationPrice: '', // Reset prices
          earlyBirdPrice: ''
        }))
      },
      
      sponsors: event.sponsors || initialFormState.sponsors,
      
      species: {
        ...initialFormState.species,
        units: event.species?.units || initialFormState.species.units,
        allowCatchPhotoUpload: event.species?.allowCatchPhotoUpload ?? initialFormState.species.allowCatchPhotoUpload,
        speciesList: event.species?.speciesList || initialFormState.species.speciesList
      },
      
      categories: event.categories || initialFormState.categories,
      
      pots: {
        ...initialFormState.pots,
        tournamentPotCut: event.pots?.tournamentPotCut || initialFormState.pots.tournamentPotCut,
        potList: event.pots?.potList || initialFormState.pots.potList
      },
      
      pageVisibility: event.pageVisibility || initialFormState.pageVisibility
    };
  };

  return (
    <AnimatedPage>
      <section className="dashboard-content" style={{ 
        backgroundColor: '#ffffff', // Light gray-blue background
        minHeight: '100vh',
        width: "100%"
      }}>
        <div className="dashboard-main-content" style={{ paddingTop: '24px' }}>
          <Box sx={{ width: '90%', typography: 'body1' }}>

            {/* Draft Events Section */}
            {draftEvents.length > 0 && (
              <Paper 
                elevation={0} 
                sx={{ 
                  mb: 4, 
                  p: 3, 
                  backgroundColor: '#ffffff',
                  borderRadius: '16px',
                  border: '1px solid rgba(255, 152, 0, 0.3)', // Orange border
                  boxShadow: '0 8px 24px rgba(0, 0, 0, 0.08)',
                }}
              >
                <Box sx={{ mb: 3 }}>
                  <Typography 
                    variant="h5" 
                    sx={{ 
                      fontWeight: 600,
                      background: 'linear-gradient(45deg, #FF9800 30%, #FFC107 90%)', // Orange gradient
                      backgroundClip: 'text',
                      WebkitBackgroundClip: 'text',
                      color: 'transparent',
                      textAlign: 'center',
                      mb: 1
                    }}
                  >
                    Draft Events
                  </Typography>
                  <Divider sx={{ 
                    width: '80px', 
                    margin: '0 auto', 
                    borderColor: '#FF9800', // Orange divider
                    borderWidth: 2
                  }} />
                </Box>
                
                <Box display="flex" flexWrap="wrap" justifyContent="space-between" gap={3}>
                  {draftEvents.map((draft) => (
                    <DraftEventCard 
                      key={draft.draftId} 
                      draft={draft} 
                      cardWidth={cardWidth} 
                      cardHeight={cardHeight} 
                      username={username}
                      onDraftDelete={handleDraftDelete}
                      onDraftContinue={handleDraftContinue}
                    />
                  ))}
                  {!isMobile && addPlaceholderCards([...draftEvents])}
                </Box>
              </Paper>
            )}

            {/* Active Events Section */}
            <Paper 
              elevation={0} 
              sx={{ 
                mb: 4, 
                p: 3, 
                backgroundColor: '#ffffff',
                borderRadius: '16px',
                border: '1px solid rgba(44, 62, 80, 0.15)', // Slightly darker border
                boxShadow: '0 8px 24px rgba(0, 0, 0, 0.08)', // More pronounced shadow
              }}
            >
              <Box sx={{ mb: 3 }}>
                <Typography 
                  variant="h5" 
                  sx={{ 
                    fontWeight: 600,
                    background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
                    backgroundClip: 'text',
                    WebkitBackgroundClip: 'text',
                    color: 'transparent',
                    textAlign: 'center',
                    mb: 1
                  }}
                >
                  Active Events
                </Typography>
                <Divider sx={{ 
                  width: '80px', 
                  margin: '0 auto', 
                  borderColor: '#2196F3',
                  borderWidth: 2
                }} />
              </Box>
              
              <Box display="flex" flexWrap="wrap" justifyContent="space-between" gap={3}>
                {activeEvents.map((event, index) => (
                  <EventCard 
                    key={event.eventId} 
                    event={event} 
                    cardWidth={cardWidth} 
                    cardHeight={cardHeight} 
                    username={username}
                    onEventDelete={handleEventDelete}
                  />
                ))}
                <CreateEventCard 
                  cardWidth={cardWidth} 
                  cardHeight={cardHeight} 
                  open={handleOpenCreateEventStepper} 
                  close={handleCloseCreateEventStepper}
                  pastEvents={activeEvents.concat(pastEvents)} // Combine active and past events
                />
                {!isMobile && addPlaceholderCards([...activeEvents, {}])}
              </Box>
            </Paper>

            {/* Past Events Section */}
            {pastEvents.length > 0 && (
              <Paper 
                elevation={0} 
                sx={{ 
                  p: 3, 
                  backgroundColor: '#ffffff',
                  borderRadius: '16px',
                  border: '1px solid rgba(44, 62, 80, 0.15)',
                  boxShadow: '0 8px 24px rgba(0, 0, 0, 0.08)',
                }}
              >
                <Box sx={{ mb: 3 }}>
                  <Typography 
                    variant="h5" 
                    sx={{ 
                      fontWeight: 600,
                      background: 'linear-gradient(45deg, #757575 30%, #9E9E9E 90%)',
                      backgroundClip: 'text',
                      WebkitBackgroundClip: 'text',
                      color: 'transparent',
                      textAlign: 'center',
                      mb: 1
                    }}
                  >
                    Past Events
                  </Typography>
                  <Divider sx={{ 
                    width: '80px', 
                    margin: '0 auto', 
                    borderColor: '#9E9E9E',
                    borderWidth: 2
                  }} />
                </Box>

                <Box display="flex" flexWrap="wrap" justifyContent="space-between" gap={3}>
                  {pastEvents.map((event, index) => (
                    <EventCard 
                      key={event.eventId} 
                      event={event} 
                      cardWidth={cardWidth} 
                      cardHeight={cardHeight} 
                      username={username}
                      onEventDelete={handleEventDelete}
                    />
                  ))}
                  {!isMobile && addPlaceholderCards(pastEvents)}
                </Box>
              </Paper>
            )}

          </Box>
        </div>

        <CreateEventStepper 
          isOpen={isCreateEventStepperOpen} 
          onClose={handleCloseCreateEventStepper}
          initialData={initialFormData} // Add this prop
        />

      </section>
    </AnimatedPage>
  );
};

export default DashboardPage;

