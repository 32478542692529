import FeatureStep from "./steps/FeatureStep.js"
import BasicInfoStep from "./steps/BasicInfoStep.js"
import RegisterTeamsStep from "./steps/RegisterTeamsStep.js"
import RegisterSponsorsStep from "./steps/RegisterSponsorsStep.js"
import SpeciesStep from "./steps/SpeciesStep.js"
import CategoriesStep from "./steps/CategoriesStep.js"
import PotsStep from "./steps/PotsStep.js"
import CheckoutStep from "./steps/CheckoutStep.js"
import React, { useState, useEffect } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
  Fade,
  CircularProgress,
  useTheme,
  useMediaQuery,
  Drawer,
  AppBar,
  Toolbar
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import MenuIcon from '@mui/icons-material/Menu';
import { toast } from 'react-toastify';
import { teamsConfig, initialFormState } from '../../config/config';

const CreateEventStepper = ({ isOpen, onClose, initialData = null }) => {
 
  const FEATURE_OPTIONS = {
    NOT_NEEDED: 'notNeeded',
    CUSTOMER_PAYS: 'customerPays',
    I_PAY: 'iPay'
  };

  const [activeStep, setActiveStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState({});
  const [stepValidation, setStepValidation] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [visibleSteps, setVisibleSteps] = useState([]);

  const [formData, setFormData] = useState(initialData || initialFormState);

  const [previousFeatures, setPreviousFeatures] = useState({
    onlineTeamRegistration: formData.features.onlineTeamRegistration,
    onlineSponsorRegistration: formData.features.onlineSponsorRegistration,
    fishPots: formData.features.fishPots
  });  

  useEffect(() => {
    if (isOpen && initialData) {
      // If opening with initial data, use it
      setFormData(initialData);
      setActiveStep(initialData.activeStep || 0);
      setCompletedSteps(initialData.completedSteps || {});
    }
  }, [isOpen, initialData]);

  useEffect(() => {
    // Only initialize draft state if we're opening a new form (not editing)
    if (isOpen && !formData.draftId) {
      // Add creation timestamp
      setFormData(prev => ({
        ...prev,
        createdAt: new Date().valueOf()
      }));
    }
  }, [isOpen]);

  useEffect(() => {
    // Make a copy of the current form data
    let updatedFormData = { ...formData };
    let updateRequired = false;
    
    // Check for feature disabling - reset data when features are turned off
    if (formData.features.onlineTeamRegistration === FEATURE_OPTIONS.NOT_NEEDED && 
        previousFeatures.onlineTeamRegistration !== FEATURE_OPTIONS.NOT_NEEDED) {
      // Reset team registration data (keep current implementation)
      updatedFormData.registration = {
        // ... your existing reset logic
      };
      updateRequired = true;
      
      // Reset validation as you're doing
    }
    
    // Keep the same pattern for other features
    if (formData.features.onlineSponsorRegistration === FEATURE_OPTIONS.NOT_NEEDED && 
        previousFeatures.onlineSponsorRegistration !== FEATURE_OPTIONS.NOT_NEEDED) {
      // Reset sponsors data
      updatedFormData.sponsors = {
        ...initialFormState.sponsors
      };
      updateRequired = true;
      
      // Reset validation as you're doing
    }
    
    // Same for fish pots
    if (formData.features.fishPots === FEATURE_OPTIONS.NOT_NEEDED && 
        previousFeatures.fishPots !== FEATURE_OPTIONS.NOT_NEEDED) {
      // Reset pots data
      updatedFormData.pots = {
        ...initialFormState.pots
      };
      updateRequired = true;
      
      // Reset validation as you're doing
    }
    
    // Update the form data if needed
    if (updateRequired) {
      setFormData(updatedFormData);
    }
    
    // Remember the current feature states for the next comparison
    setPreviousFeatures({
      onlineTeamRegistration: formData.features.onlineTeamRegistration,
      onlineSponsorRegistration: formData.features.onlineSponsorRegistration,
      fishPots: formData.features.fishPots
    });
    
    // ALSO update visible steps here - moved from second useEffect
    const allStepsData = defineAllSteps();
    const updatedStepsStatus = allStepsData.map(step => {
      // Special case for Register Teams - never disable it, just pass feature status
      if (step.label === 'Register Teams') {
        return {
          ...step,
          disabled: false, // Never disable the entire step
          featureEnabled: formData.features.onlineTeamRegistration !== FEATURE_OPTIONS.NOT_NEEDED
        };
      }
      // For other feature-dependent steps, disable based on feature status
      else if (step.requiredFeature) {
        return {
          ...step,
          disabled: formData.features?.[step.requiredFeature] === FEATURE_OPTIONS.NOT_NEEDED
        };
      }
      return { ...step, disabled: false };
    });
    
    setVisibleSteps(updatedStepsStatus);
  }, [formData.features]); // Only run when features change

  useEffect(() => {
    if (!visibleSteps.length) return;
  
  // If current step is now disabled, go back to the last enabled step
  const currentStepDisabled = visibleSteps[activeStep]?.disabled;
  if (currentStepDisabled && activeStep > 0) {
    // Find the highest enabled step index that is less than activeStep
    for (let i = activeStep - 1; i >= 0; i--) {
      if (!visibleSteps[i].disabled) {
        setActiveStep(i);
        break;
      }
    }
  }
  
  // If a step was re-enabled, make sure we're setting up the UI correctly
  for (let i = 0; i < visibleSteps.length; i++) {
    const step = visibleSteps[i];
    if (step.requiredFeature && 
        formData.features[step.requiredFeature] !== FEATURE_OPTIONS.NOT_NEEDED) {
      
      // Step is enabled - make sure it's properly set up in the UI
      const wasDisabled = i < activeStep && !completedSteps[i];
      
      if (wasDisabled) {
        // If previously disabled, ensure completion status is cleared
        setCompletedSteps(prev => {
          const newCompleted = {...prev};
          delete newCompleted[i];
          return newCompleted;
        });
        
        setStepValidation(prev => {
          const newValidation = {...prev};
          delete newValidation[i];
          return newValidation;
        });
      }
    }
  }
  }, [visibleSteps, activeStep]);

  const saveDraftToServer = async () => {
    if (activeStep === 0) return; // Don't save if user hasn't started
    
    try {
      setIsLoading(true);
      
      // Get API URL
      const apiUrl = process.env.REACT_APP_NODE_ENV === "staging"
        ? process.env.REACT_APP_SERVER_URL_STAGING
        : process.env.REACT_APP_SERVER_URL_PRODUCTION;
      
      // Get username from localStorage
      const username = localStorage.getItem('username');
      if (!username) {
        console.error('No username found in localStorage');
        return;
      }
      
      // Create draft data object with current state
      const draftData = {
        ...formData,
        activeStep,
        completedSteps,
        lastUpdated: new Date().valueOf()
      };
      
      const response = await fetch(`${apiUrl}/api/save_draft_event`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          username,
          draftData
        })
      });
      
      const data = await response.json();
      
      if (data.success) {
        // Update draftId in formData if it was a new draft
        if (data.draftId && !formData.draftId) {
          setFormData(prev => ({
            ...prev,
            draftId: data.draftId
          }));
        }
        console.log('Draft saved successfully:', data.draftId);
      } else {
        console.error('Error saving draft:', data.error);
      }
    } catch (error) {
      console.error('Error saving draft to server:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFeatureOptionChange = (featureKey, newValue) => {
    setFormData(prev => ({
      ...prev,
      features: {
        ...prev.features,
        [featureKey]: newValue
      }
    }));
  };

  const validateFeatureStep = (data) => {
    // Check if any features are enabled, requiring Stripe connection
    const isStripeRequired = Object.entries(data.features || {}).some(([key, value]) => {
      return key !== 'basicEvent' && value !== FEATURE_OPTIONS.NOT_NEEDED;
    });
    
    // If any features are enabled, require Stripe connection
    if (isStripeRequired) {
      const hasValidStripeConnection = 
        data.stripeAccountId && 
        data.stripeAccountStatus === 'active' &&
        data.stripeConnectedAt;
  
      if (!hasValidStripeConnection) {
        console.log('Validation failed: Features require Stripe connection');
        return false;
      }
    }
  
    // All validations passed
    console.log('Features step validation passed');
    return true;
  };

  const validateBasicInfoStep = (data) => {
    const basicInfo = data.basicInfo;
    
    // Check required fields
    if (!basicInfo.title?.trim()) {
      console.log('Validation failed: Missing title');
      return false;
    }
  
    if (!basicInfo.subheadline?.trim()) {
      console.log('Validation failed: Missing subheadline');
      return false;
    }
  
    // Check color values
    if (!basicInfo.backgroundColor || !basicInfo.headlineTextColor) {
      console.log('Validation failed: Missing colors');
      return false;
    }
  
    // Check dates
    if (!basicInfo.startDate || !basicInfo.endDate) {
      console.log('Validation failed: Missing dates');
      return false;
    }
  
    // Check end date is not before start date - updated to check full date/time
    if (basicInfo.endDate.isBefore(basicInfo.startDate)) {
      console.log('Validation failed: End date/time before start date/time');
      return false;
    }
  
    console.log('Basic info validation passed');
    return true;
  };

  const validateRegistrationTeamsStep = (data) => {
    // Skip validation of registration fees and dates if teams registration is not enabled
    if (data.features?.onlineTeamRegistration === FEATURE_OPTIONS.NOT_NEEDED) {
      // When feature is disabled, the step is always valid as long as there are form fields defined
      if (data.registration && 
          data.registration.defaultTeamRegistrationFields && 
          data.registration.defaultTeamRegistrationFields.length > 0) {
        console.log('Registration feature disabled but form fields are configured');
        return true;
      }
      console.log('Validation failed: No default team registration fields defined');
      return false;
    }
    
    const registration = data.registration;
  
    // Always validate divisions - now required
    if (!registration.divisions || registration.divisions.length === 0) {
      console.log('Validation failed: At least one division is required');
      return false;
    }
  
    // Validate normal registration details when feature is enabled
    if (!registration.registrationDate) {
      console.log('Validation failed: Missing registration date');
      return false;
    }
  
    // Check division prices when online registration is enabled
    for (const division of registration.divisions) {
      if (!division.registrationPrice || parseFloat(division.registrationPrice) <= 0) {
        console.log(`Validation failed: Missing or invalid registration price for division: ${division.name}`);
        return false;
      }
      
      if (registration.includeEarlyBird && (!division.earlyBirdPrice || parseFloat(division.earlyBirdPrice) <= 0)) {
        console.log(`Validation failed: Missing or invalid early bird price for division: ${division.name}`);
        return false;
      }
    }
  
    // If early bird is enabled, validate its details
    if (registration.includeEarlyBird) {
      if (!registration.earlyBirdDate) {
        console.log('Validation failed: Missing early bird date');
        return false;
      }
  
      // Early bird date must be before registration date
      if (registration.earlyBirdDate.isAfter(registration.registrationDate)) {
        console.log('Validation failed: Early bird date must be before registration date');
        return false;
      }
    }
  
    // Validate that default field configurations exist
    if (!registration.defaultTeamRegistrationFields || 
        registration.defaultTeamRegistrationFields.length === 0) {
      console.log('Validation failed: No default team registration fields defined');
      return false;
    }
  
    console.log('Register teams step validation passed');
    return true;
  };
  
  const validateRegistrationSponsorsStep = (data) => {
    // Skip validation if sponsor registration is not enabled
    if (data.features?.onlineSponsorRegistration === FEATURE_OPTIONS.NOT_NEEDED) {
      // Always valid when disabled - we'll still save the configuration
      return true;
    }
    
    // Allow proceeding if custom amount is enabled, even without tiers
    if (data.sponsors?.allowCustomAmount) {
      console.log('Sponsor step validation passed: Custom amount option is enabled');
      return true;
    }
    
    // If custom amount is not enabled, require at least one tier
    if (!data.sponsors || !data.sponsors.tiers || data.sponsors.tiers.length === 0) {
      console.log('Validation failed: No sponsor tiers defined and custom amount not enabled');
      return false;
    }
    
    // Validate tier amounts
    const invalidTier = data.sponsors.tiers.find(tier => 
      !tier.amount || parseFloat(tier.amount) <= 0
    );
    
    if (invalidTier) {
      console.log(`Validation failed: Tier "${invalidTier.name}" has an invalid amount`);
      return false;
    }
    
    // Check for duplicate tier names with the same amount
    const tierNames = new Map();
    for (const tier of data.sponsors.tiers) {
      const key = tier.name;
      const amount = parseFloat(tier.amount);
      
      if (tierNames.has(key) && tierNames.get(key) === amount) {
        console.log(`Validation failed: Duplicate tier "${key}" with the same amount`);
        return false;
      }
      
      tierNames.set(key, amount);
    }
    
    console.log('Register sponsors step validation passed');
    return true;
  };

  const validateSpeciesStep = (formData) => {
    // Basic validation (at least one species is required)
    if (!formData.species.speciesList || formData.species.speciesList.length === 0) {
      return false;
    }
    
    // Check each species for having at least one required field and proper structure
    for (const species of formData.species.speciesList) {
      // Ensure required name
      if (!species.species) return false;
      
      // Validate scoring method
      if (species.scoringMethod === 'custom' && (!species.points || species.points <= 0)) {
        return false;
      }
      
      // Ensure at least one data collection field is required
      const hasRequiredField = 
        (species.collectTime && species.timeRequired) ||
        (species.collectWeight && species.weightRequired) ||
        (species.collectLength && species.lengthRequired) ||
        (species.collectGirth && species.girthRequired) ||
        (species.includePhoto && species.photoRequired);
        
      if (!hasRequiredField) return false;
      
      // Ensure all expected properties exist with appropriate defaults
      // This ensures a consistent data structure for all species
      species.scoringMethod = species.scoringMethod || 'custom';
      species.rounding = species.rounding || 'down';
      species.points = species.scoringMethod === 'custom' ? (species.points || 0) : 0;
      
      // Ensure all data collection fields have boolean values
      species.collectTime = !!species.collectTime;
      species.timeRequired = !!species.timeRequired;
      species.collectWeight = !!species.collectWeight;
      species.weightRequired = !!species.weightRequired;
      species.collectLength = !!species.collectLength;
      species.lengthRequired = !!species.lengthRequired;
      species.collectGirth = !!species.collectGirth;
      species.girthRequired = !!species.girthRequired;
      species.includePhoto = !!species.includePhoto;
      species.photoRequired = !!species.photoRequired;
    }
    
    return true;
  };  

  const validateCategoriesStep = (data) => {
    // Get any draft categories from the categories step
    const hasDraftCategory = data.categories.draftCategory;
    
    if (hasDraftCategory) {
      console.log('Validation failed: Please save or cancel draft category');
      return false;
    }
  
    // Ensure there are categories
    if (!data.categories.categoryList || data.categories.categoryList.length === 0) {
      console.log('Validation failed: No categories defined');
      return false;
    }
  
    console.log('Categories step validation passed');
    return true;
  };

  const validatePotsStep = (data) => {
    // If Fish Pots feature is not needed, validation passes
    if (data.features?.fishPots === FEATURE_OPTIONS.NOT_NEEDED) {
      return true;
    }
  
    // Check for draft pots
    if (data.pots.draftPot) {
      console.log('Validation failed: Draft pot not saved');
      return false;
    }
  
    // Validate pots list 
    const pots = data.pots.potList || [];
    
    // Check that at least one pot exists
    if (pots.length === 0) {
      console.log('Validation failed: At least one pot is required');
      return false;
    }
  
    // Check if tournament cut is sufficient
    const tournamentCut = data.pots.tournamentPotCut;
    
    // Helper function to calculate minimum required tournament cut with safety buffer
    const calculateMinimumCut = (potValue, featureOption) => {
      // Calculate platform fee based on pot value
      let platformFee;
      if (potValue < 50) {
        platformFee = 3;
      } else if (potValue <= 100) {
        platformFee = 5;
      } else if (potValue <= 200) {
        platformFee = potValue * 0.05; // 5%
      } else {
        platformFee = potValue * 0.04; // 4%
      }
      
      // Calculate approximate Stripe fee with buffer (15%)
      const STRIPE_PERCENTAGE = 2.9 * 1.15; // Add 15% buffer
      const STRIPE_FIXED_FEE = 0.30 * 1.15; // Add 15% buffer
      
      // Only add platform fee to total if organizer pays it
      const transactionTotal = data.features.fishPots === FEATURE_OPTIONS.I_PAY ? 
        potValue + platformFee : 
        potValue;
      
      const stripeFee = (transactionTotal * STRIPE_PERCENTAGE / 100) + STRIPE_FIXED_FEE;
      
      // If organizer pays platform fee, it needs to come from tournament cut
      const totalFees = data.features.fishPots === FEATURE_OPTIONS.I_PAY ?
        stripeFee + platformFee :
        stripeFee;
        
      // Calculate minimum tournament cut needed with a larger buffer
      const minimumCutAmount = totalFees + 2; // Add $2 buffer to ensure organizer makes something
      
      // Calculate as percentage of pot value
      const minimumCutPercentage = (minimumCutAmount / potValue) * 100;
      
      return {
        minimumCutPercentage,
        minimumCutAmount,
        platformFee,
        stripeFee,
        totalFees
      };
    };
  
    // Check each pot value
    for (const pot of pots) {
      for (const potValue of pot.values) {
        const { minimumCutPercentage, totalFees } = calculateMinimumCut(potValue, data.features.fishPots);
        
        if (tournamentCut < minimumCutPercentage) {
          console.log(`Validation failed: Tournament cut of ${tournamentCut}% may be insufficient for $${potValue} pot. Fees plus buffer total approximately $${totalFees.toFixed(2)}.`);
          return false;
        }
      }
    }
  
    // Validate each pot's values
    const isValid = pots.every(pot => {
      // All values must be > 0
      if (!pot.values.every(value => value > 0)) {
        console.log('Validation failed: All pot values must be greater than 0');
        return false;
      }
  
      // No duplicate values within a pot
      const uniqueValues = new Set(pot.values);
      if (uniqueValues.size !== pot.values.length) {
        console.log('Validation failed: Duplicate values found within a pot');
        return false;
      }
  
      return true;
    });
  
    return isValid;
  };
  
  const validateCheckoutStep = (data) => {
      // Run all previous step validations
      const allStepsValid = visibleSteps.slice(0, -1).every((step, index) => {
        return step.validation(data);
      });
    
      if (!allStepsValid) {
        console.log('Validation failed: Previous steps contain errors');
        return false;
      }
    
      return true;
    };

  // Define all possible steps
  const defineAllSteps = () => [
    {
      label: 'Features',
      description: 'Select your event features',
      validation: validateFeatureStep
    },
    {
      label: 'Basic Information',
      description: 'Enter your event details',
      validation: validateBasicInfoStep
    },
    {
      label: 'Register Teams',
      description: 'Setup your team registration process',
      validation: validateRegistrationTeamsStep,
      // We don't want to disable the entire step, we just mark it as not using the online feature
      featureStatus: formData.features.onlineTeamRegistration 
    },
    {
      label: 'Register Sponsors',
      description: 'Setup your sponsor registration process',
      validation: validateRegistrationSponsorsStep,
      requiredFeature: 'onlineSponsorRegistration'
    },
    {
      label: 'Species',
      description: 'Create your species',
      validation: validateSpeciesStep
    },
    {
      label: 'Scoring',
      description: 'Define how your scoring will work',
      validation: validateCategoriesStep
    },
    {
      label: 'Pots',
      description: 'Add fish pots on the side',
      validation: validatePotsStep,
      requiredFeature: 'fishPots'
    },
    {
      label: 'Checkout',
      description: 'Review your input and pay',
      validation: validateCheckoutStep
    }
  ];

  // Get visible steps for rendering - use all steps if visibleSteps is empty
  const steps = visibleSteps.length > 0 ? visibleSteps : defineAllSteps();

  const handleNext = async () => {
    setIsLoading(true);
    try {
      // Special validation for RegisterTeamsStep has been removed since divisions are now always included
      // and are validated properly in validateRegistrationTeamsStep
  
      // Reinstated proper validation
      const isValid = steps[activeStep].validation(formData);
      
      if (isValid) {
        setCompletedSteps(prev => ({
          ...prev,
          [activeStep]: true
        }));
        setStepValidation(prev => ({
          ...prev,
          [activeStep]: true
        }));
        
        await new Promise(resolve => setTimeout(resolve, 500));
        
        // Find the next non-disabled step
        let nextStep = activeStep + 1;
        while (nextStep < visibleSteps.length && visibleSteps[nextStep].disabled) {
          nextStep++;
        }
        
        // Check if we found a valid next step
        if (nextStep < visibleSteps.length) {
          setActiveStep(nextStep);
        } else {
          // If no valid steps left, go to the last step (checkout)
          setActiveStep(visibleSteps.length - 1);
        }
      } else {
        setStepValidation(prev => ({
          ...prev,
          [activeStep]: false
        }));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    setActiveStep(prev => prev - 1);
  };

  const handleStepClick = (index) => {
    const step = visibleSteps[index];
    
    // If it's a Sponsors or Pots step, allow navigation even when "disabled"
    const isFeatureStep = step.label === 'Register Sponsors' || step.label === 'Pots';
    
    // Allow navigation to feature steps (Sponsors, Pots) even when disabled
    if (step.disabled && !isFeatureStep) return;
    
    const canAdvance = index < activeStep || (
      steps[activeStep].validation(formData) && 
      Array.from({ length: index }, (_, i) => i).every(i => completedSteps[i])
    );
  
    if (canAdvance || isFeatureStep) {
      setActiveStep(index);
    }
  };

  const StepIcon = ({ completed, active, error }) => {
    if (completed) return <CheckCircleIcon color="success" />;
    if (error) return <ErrorOutlineIcon color="error" />;
    if (active) return <RadioButtonUncheckedIcon color="primary" />;
    return <RadioButtonUncheckedIcon color="disabled" />;
  };

  const StepsNavigation = () => (
    <Stepper 
      activeStep={activeStep} 
      orientation="vertical"
      nonLinear
    >
      {visibleSteps.map((step, index) => {
        // Determine if this is a feature step that should never appear disabled visually
        const isFeatureStep = step.label === 'Register Teams' || 
                            step.label === 'Register Sponsors' || 
                            step.label === 'Pots';
        
        // Only show as disabled visually if it's not a feature step
        const showAsDisabled = step.disabled && !isFeatureStep;
        
        return (
          <Step 
            key={step.label}
            completed={completedSteps[index]}
          >
            <StepLabel
              StepIconComponent={(props) => (
                <StepIcon 
                  {...props}
                  completed={completedSteps[index]}
                  active={activeStep === index}
                  error={stepValidation[index] === false}
                />
              )}
              onClick={() => showAsDisabled ? null : handleStepClick(index)}
              sx={{ 
                cursor: showAsDisabled ? 'default' : 'pointer',
                opacity: showAsDisabled ? 0.5 : 1,
                '&:hover': {
                  '& .MuiStepLabel-label': {
                    color: showAsDisabled ? 'inherit' : 'primary.main'
                  }
                }
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  color: activeStep === index ? 'primary.main' : 'text.secondary',
                  fontWeight: activeStep === index ? 600 : 400
                }}
              >
                {step.label}
                {showAsDisabled && (
                  <Typography 
                    component="span" 
                    sx={{ 
                      ml: 1, 
                      fontSize: '0.75rem', 
                      color: 'text.disabled',
                      fontStyle: 'italic'
                    }}
                  >
                    (disabled)
                  </Typography>
                )}
              </Typography>
              <Typography
                variant="caption"
                sx={{ 
                  color: 'text.secondary', 
                  display: 'block',
                  mt: 0.5
                }}
              >
                {step.description}
              </Typography>
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );

  const getCurrentStepContent = () => {
    if (!visibleSteps.length) {
      return null; // Or a loading indicator
    }
  
    const currentStep = visibleSteps[activeStep];
    const stepComponentMap = {
      'Features': {
        id: 'features',
        component: FeatureStep
      },
      'Basic Information': {
        id: 'basicInfo',
        component: BasicInfoStep
      },
      'Register Teams': {
        id: 'registration',
        component: RegisterTeamsStep
      },
      'Register Sponsors': {
        id: 'sponsors',
        component: RegisterSponsorsStep
      },
      'Species': {
        id: 'species',
        component: SpeciesStep
      },
      'Scoring': {
        id: 'categories',
        component: CategoriesStep
      },
      'Pots': {
        id: 'pots',
        component: PotsStep
      },
      'Checkout': {
        id: 'checkout',
        component: CheckoutStep
      }
    };
  
    const stepInfo = stepComponentMap[currentStep.label];
    const StepComponent = stepInfo.component;
    const currentStepId = stepInfo.id;
  
    const handleStepUpdate = (newData) => {
      if (currentStepId === 'features') {
        // Special handling for feature step since its structure is different
        setFormData(prev => ({
          ...prev,
          ...newData // Features step updates top-level properties directly
        }));
      } else if (currentStepId === 'checkout') {
        // Checkout doesn't need to update any specific section
        return;
      } else {
        // Normal handling for other steps
        setFormData(prev => ({
          ...prev,
          [currentStepId]: {
            ...prev[currentStepId],
            ...newData
          }
        }));
      }
    };

    // Always show the step component regardless of disabled state
    // For feature-related steps (sponsors and pots), pass the feature status
    const defaultProps = {
      data: currentStepId === 'features' ? formData : formData[currentStepId],
      onUpdate: handleStepUpdate,
      isValid: stepValidation[activeStep],
      setStepValidation: setStepValidation,
    };

    // Add special props for certain steps
    const specialProps = {};
    
    if (currentStepId === 'registration') {
      specialProps.featureOption = formData.features.onlineTeamRegistration;
      specialProps.featureEnabled = formData.features.onlineTeamRegistration !== FEATURE_OPTIONS.NOT_NEEDED;
      specialProps.setActiveStep = setActiveStep;
      specialProps.setFeatureOption = handleFeatureOptionChange; 
    } else if (currentStepId === 'sponsors') {
      specialProps.featureOption = formData.features.onlineSponsorRegistration;
      specialProps.featureEnabled = formData.features.onlineSponsorRegistration !== FEATURE_OPTIONS.NOT_NEEDED;
      specialProps.setActiveStep = setActiveStep;
      specialProps.setFeatureOption = handleFeatureOptionChange; 
    } else if (currentStepId === 'species') {
      specialProps.featureOption = formData.features.basicEvent;
      specialProps.setActiveStep = setActiveStep;
    } else if (currentStepId === 'categories') {
      specialProps.basicInfo = formData.basicInfo;
      specialProps.registration = formData.registration;
      specialProps.speciesList = formData.species.speciesList;
      specialProps.setActiveStep = setActiveStep;
    } else if (currentStepId === 'pots') {
      specialProps.categories = formData.categories.categoryList;
      specialProps.featureOption = formData.features.fishPots;
      specialProps.featureEnabled = formData.features.fishPots !== FEATURE_OPTIONS.NOT_NEEDED;
      specialProps.setFeatureOption = handleFeatureOptionChange; 
      specialProps.setActiveStep = setActiveStep;
    } else if (currentStepId === 'checkout') {
      specialProps.formData = formData;
      specialProps.onCheckout = handleCheckout;
    }

    return <StepComponent {...defaultProps} {...specialProps} />;
  };

  const handleCloseAttempt = (event, reason) => {
    // If called directly (like from IconButton), reason will be undefined
    // If called from Dialog, reason will be 'backdropClick', 'escapeKeyDown', etc.
    if (!reason || (reason !== 'backdropClick' && reason !== 'escapeKeyDown')) {
      handleClose();
    }
  };

  const handleClose = () => {
    console.log('In handleClose in CreateEventStepper!');
    
    // Only save as draft if we have made progress (not on initial state)
    // and haven't completed checkout
    if (activeStep > 0 && activeStep < steps.length - 1) {
      // Save current form state as draft to server
      saveDraftToServer();
    }
    
    // Reset form data to initial state
    setFormData(initialFormState);
    
    // Reset all step-related states
    setActiveStep(0);
    setCompletedSteps({});
    setStepValidation({});
    
    // Reset loading state
    setIsLoading(false);
    
    // Finally, close the dialog
    onClose();
  };

  const saveDraftEvent = () => {
    try {
      // Create a draft object that includes current form data and progress
      const draft = {
        formData,
        activeStep,
        completedSteps,
        lastUpdated: new Date().toISOString(),
        id: formData.draftId || `draft_${Date.now()}`
      };
      
      // Get existing drafts from localStorage
      const existingDraftsJSON = localStorage.getItem('eventDrafts');
      const existingDrafts = existingDraftsJSON ? JSON.parse(existingDraftsJSON) : [];
      
      // Add or update the current draft
      const draftIndex = existingDrafts.findIndex(d => d.id === draft.id);
      
      if (draftIndex >= 0) {
        // Update existing draft
        existingDrafts[draftIndex] = draft;
      } else {
        // Add new draft
        existingDrafts.push(draft);
      }
      
      // Save updated drafts back to localStorage
      localStorage.setItem('eventDrafts', JSON.stringify(existingDrafts));
      
      console.log('Draft saved successfully:', draft.id);
    } catch (error) {
      console.error('Error saving draft:', error);
    }
  };

  const handleCheckout = async () => {
    try {
      setIsLoading(true);
  
      // Create FormData object
      const formDataToSend = new FormData();
 
      // Helper for nested fields - keep this as is
      const normalizeFields = (fields) => {
        return fields.map(field => ({
          field: field.field,
          headerName: field.headerName || field.field,
          type: field.type,
          isRequired: field.isRequired ?? field.required,
          isVisible: field.isVisible ?? true,
          isEditable: field.isEditable ?? true,
          
          // Additional type-specific flags
          isBoolean: field.isBoolean || field.type === 'Boolean',
          isEmail: field.isEmail || field.type === 'Email',
          isPhoneNumber: field.isPhoneNumber || field.type === 'Phone',
          
          // Consistent UI properties
          flex: field.flex || 2,
          width: field.width || 200,
          headerClassName: field.headerClassName || 'super-app-theme--header',
          headerAlign: field.headerAlign || 'center',
          align: field.align || 'center'
        }));
      };

      // Help for speciesList
      const speciesList = formData.species.speciesList.map(species => ({
        species: species.species,
        scoringMethod: species.scoringMethod || 'custom',
        rounding: species.rounding || 'down',
        points: species.scoringMethod === 'custom' ? (species.points || 0) : 0,
        
        // Include all data collection fields, defaulting to false if missing
        collectTime: !!species.collectTime,
        timeRequired: !!species.timeRequired,
        collectWeight: !!species.collectWeight,
        weightRequired: !!species.weightRequired,
        collectLength: !!species.collectLength,
        lengthRequired: !!species.lengthRequired,
        collectGirth: !!species.collectGirth,
        girthRequired: !!species.girthRequired,
        includePhoto: !!species.includePhoto,
        photoRequired: !!species.photoRequired
      }));
  
      // STEP 1: Feature Information
      formDataToSend.append("features", JSON.stringify(formData.features));
      formDataToSend.append("packageType", formData.packageType);
      formDataToSend.append("businessName", formData.businessName || "");
      formDataToSend.append("taxId", formData.taxId || "");
      formDataToSend.append("stripeAccountId", formData.stripeAccountId || "");
      formDataToSend.append("stripeAccountStatus", formData.stripeAccountStatus || "");
      formDataToSend.append("stripeConnectedAt", formData.stripeConnectedAt || "");
  
      // STEP 2: Basic Information
      formDataToSend.append("title", formData.basicInfo.title);
      formDataToSend.append("startDate", formData.basicInfo.startDate?.valueOf());
      formDataToSend.append("endDate", formData.basicInfo.endDate?.valueOf());
      formDataToSend.append("subheadline", formData.basicInfo.subheadline || "");
      formDataToSend.append("backgroundColor", formData.basicInfo.backgroundColor);
      formDataToSend.append("headlineTextColor", formData.basicInfo.headlineTextColor);
      formDataToSend.append("showCountdown", formData.basicInfo.showCountdown);
  
      // Rules, Schedule, and Website handling
      formDataToSend.append("rulesType", formData.basicInfo.rulesType || "none");
      formDataToSend.append("rulesContent", formData.basicInfo.rulesContent || "");
      formDataToSend.append("rulesLink", formData.basicInfo.rulesLink || "");
      
      formDataToSend.append("scheduleType", formData.basicInfo.scheduleType || "none");
      formDataToSend.append("scheduleContent", formData.basicInfo.scheduleContent || "");
      formDataToSend.append("scheduleLink", formData.basicInfo.scheduleLink || "");
      
      formDataToSend.append("websiteType", formData.basicInfo.websiteType || "none");
      formDataToSend.append("websiteLink", formData.basicInfo.websiteLink || "");
  
      // STEP 3: Team Registration (only if feature is enabled)
      if (formData.features?.onlineTeamRegistration !== FEATURE_OPTIONS.NOT_NEEDED) {
        formDataToSend.append("includeEarlyBird", formData.registration.includeEarlyBird);
        formDataToSend.append("earlyBirdDate", formData.registration.earlyBirdDate?.valueOf() || "");
        formDataToSend.append("earlyBirdPrice", formData.registration.earlyBirdPrice || "");
        formDataToSend.append("registrationDate", formData.registration.registrationDate?.valueOf() || "");
        formDataToSend.append("registrationPrice", formData.registration.registrationPrice || "");
      }

      // Always include these registration fields regardless of feature status
      formDataToSend.append("allowTeamPhoto", formData.registration.allowTeamPhoto);
      formDataToSend.append("registrationMessage", formData.registration.registrationMessage || "");
      formDataToSend.append("defaultTeamRegistrationFields", 
        JSON.stringify(normalizeFields(formData.registration.defaultTeamRegistrationFields))
      );
      formDataToSend.append("userDefinedTeamRegistrationFields", 
        JSON.stringify(normalizeFields(formData.registration.userDefinedTeamRegistrationFields))
      );
      formDataToSend.append("includeDivisions", formData.registration.includeDivisions);
      formDataToSend.append("divisions", JSON.stringify(formData.registration.divisions || []));
        
      // STEP 4: Sponsor Registration (only if feature is enabled)
      if (formData.features?.onlineSponsorRegistration !== FEATURE_OPTIONS.NOT_NEEDED) {
        formDataToSend.append("sponsorTiers", JSON.stringify(formData.sponsors.tiers || []));
        formDataToSend.append("allowLogoUpload", formData.sponsors.allowLogoUpload);
        formDataToSend.append("allowCustomAmount", formData.sponsors.allowCustomAmount);
      }
  
      // STEP 5: Species
      formDataToSend.append("units", formData.species.units);
      formDataToSend.append("allowCatchPhotoUpload", formData.species.allowCatchPhotoUpload);
      formDataToSend.append("speciesList", JSON.stringify(speciesList || []));
  
      // STEP 6: Categories
      formDataToSend.append("categoryList", JSON.stringify(formData.categories.categoryList || []));
  
      // STEP 7: Pots (only if feature is enabled)
      if (formData.features?.fishPots !== FEATURE_OPTIONS.NOT_NEEDED) {
        formDataToSend.append("tournamentPotCut", formData.pots.tournamentPotCut);
        formDataToSend.append("potList", JSON.stringify(formData.pots.potList || []));
      }
  
      // Handle file uploads with consistent naming
      if (formData.basicInfo.logo) {
        formDataToSend.append("logo", formData.basicInfo.logo, formData.basicInfo.logoName);
      }
  
      if (formData.basicInfo.rulesType === 'file') {
        formDataToSend.append("rulesFile", formData.basicInfo.rulesFile, formData.basicInfo.rulesFileName);
      }
  
      if (formData.basicInfo.scheduleType === 'file') {
        formDataToSend.append("scheduleFile", formData.basicInfo.scheduleFile, formData.basicInfo.scheduleFileName);
      }
  
      // Add username
      // Get username from localStorage
      const username = localStorage.getItem('username');
      if (!username) {
        throw new Error('No username found in localStorage');
      }
      formDataToSend.append("username", username);

      formDataToSend.append("pageVisibility", JSON.stringify(formData.pageVisibility || {
        leaderboard: true,
        registration: true,
        sponsors: true,
        pots: true
      }));
  
      // Determine API URL based on environment
      const apiUrl = process.env.REACT_APP_NODE_ENV === "staging"
        ? process.env.REACT_APP_SERVER_URL_STAGING
        : process.env.REACT_APP_SERVER_URL_PRODUCTION;
  
      // Make the API call
      const response = await fetch(`${apiUrl}/api/create_event_checkout_session`, {
        method: "POST",
        body: formDataToSend,
      });
  
      if (response.ok) {
        const { url } = await response.json();
        window.location = url;
      } else {
        const { message } = await response.json();
        toast.error(`Error: ${message || "Unknown error"}`);
      }

      // Delete draft if needed
      if (formData.draftId && response.ok) {
        try {
          const apiUrl = process.env.REACT_APP_NODE_ENV === "staging"
            ? process.env.REACT_APP_SERVER_URL_STAGING
            : process.env.REACT_APP_SERVER_URL_PRODUCTION;
          
          const username = localStorage.getItem('username');
          
          // Delete the draft since we've completed checkout
          await fetch(`${apiUrl}/api/delete_draft_event`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ 
              username,
              draftId: formData.draftId
            })
          });
          
          console.log('Draft deleted after successful checkout');
        } catch (deleteError) {
          console.error('Error deleting draft after checkout:', deleteError);
        }
      }

    } catch (error) {
      console.error("Error during checkout:", error);
      toast.error("An error occurred during checkout. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };
  
  return (
    <Dialog 
      open={isOpen} 
      onClose={handleCloseAttempt}
      maxWidth="lg"
      fullWidth
      disableEscapeKeyDown
      disableAutoFocus
      disableRestoreFocus
      disableEnforceFocus
      sx={{
        '& .MuiDialog-paper': {
          margin: 2
        }
      }}
    >
      <IconButton
        onClick={handleCloseAttempt}
        sx={{ 
          position: 'absolute', 
          right: 8, 
          top: 8, 
          zIndex: 1 
        }}
      >
        <CloseIcon />
      </IconButton>
  
      <DialogContent sx={{ 
        p: 3,
        mt: 0 
      }}>
        {/* Title and Disclaimer */}
        <Box sx={{ 
          textAlign: 'center', 
          mb: 4,
          borderBottom: '1px solid #eee',
          pb: 3
        }}>
          <Typography variant="h4" sx={{ fontWeight: 600, mb: 1 }}>
            Create Your Event
          </Typography>
          <Typography 
            variant="subtitle1" 
            sx={{ 
              color: 'primary.main',
              fontSize: '1.0rem',
              fontStyle: 'italic'
            }}
          >
            Need help? Contact us at{' '}
            <Box 
              component="span" 
              sx={{ 
                color: 'primary.main',
                fontWeight: 500
              }}
            >
              support@customtournamentsolutions.com
            </Box>
          </Typography>
        </Box>
  
        <Box sx={{ 
          display: 'flex', 
          gap: 4,
          minHeight: '60vh',
          flexDirection: 'row'
        }}>
          {/* Left sidebar with steps */}
          <Box sx={{ 
            width: '280px', 
            borderRight: '1px solid #eee', 
            pt: 4,
            flexShrink: 0
          }}>
            <StepsNavigation />
          </Box>
  
          {/* Main content area */}
          <Box sx={{ 
            flex: 1, 
            pt: 4, 
            px: 4,
            display: 'flex',
            flexDirection: 'column'
          }}>
            <Fade in={true} timeout={400}>
              <Box sx={{ flex: 1 }}>
                <>
                  <Typography 
                    variant="h5" 
                    sx={{ 
                      mb: 1,
                      fontWeight: 600
                    }}
                  >
                    {steps[activeStep].label}
                  </Typography>
                  <Typography 
                    variant="body1" 
                    color="text.secondary" 
                    sx={{ mb: 4 }}
                  >
                    {steps[activeStep].description}
                  </Typography>
                </>
  
                {getCurrentStepContent()}
              </Box>
            </Fade>
  
            {/* Navigation */}
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              mt: 4,
              pt: 2,
              pb: 0,
              borderTop: '1px solid #eee',
              position: 'relative',
              bottom: 0,
              bgcolor: 'background.paper',
              px: 0
            }}>
              <Button
                onClick={handleBack}
                disabled={activeStep === 0}
                sx={{ minWidth: 100 }}
              >
                Back
              </Button>
              {activeStep !== steps.length - 1 && (
                <Button
                  variant="contained"
                  onClick={handleNext}
                  disabled={isLoading}
                  sx={{ 
                    minWidth: 100,
                    position: 'relative'
                  }}
                  data-continue-button
                >
                  {isLoading ? (
                    <CircularProgress 
                      size={24} 
                      sx={{ 
                        color: 'white',
                        position: 'absolute'
                      }} 
                    />
                  ) : 'Continue'}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CreateEventStepper;

