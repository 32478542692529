import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { auth } from '../firebase';
import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import dayjs from 'dayjs';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CircularProgress from '@mui/material/CircularProgress';

import AnimatedPage from './AnimatedPage';
import CrudTable from '../components/tables/CrudTable';
import Footer from '../components/footer/Footer';
import { fetchAndGenerateRegistrationReport } from '../generators/registrationReports';
import { fetchAndGenerateCatchesByTeamReport, fetchAndGenerateCatchesBySpeciesReport } from '../generators/catchesReports';
import { generateLeaderboardReport } from '../generators/leaderboardReports';
import { generatePotsReport } from '../generators/potReports';
import EventSettingsTab from './EventSettingsTab';

import { teamsConfig, catchesConfig, announcementsConfig } from "../config/config";
import "./HomePage.css";
import EventAuthenticate from '../components/authenticate/EventAuthenticate';

function AdminPage() {   

  // STATE - AUTH
  const navigate = useNavigate();

  // STATE - GENERAL
  const { username, eventId } = useParams(); // Get username and eventId from the URL
  const [eventSettings, setEventSettings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tabLoading, setTabLoading] = useState(true); // Tab-specific loading state
  const currentUser = JSON.parse(window.localStorage.getItem('user'));    // login status
  const theme = useTheme();    // device size
  const matches = useMediaQuery(theme.breakpoints.up("md"));  
  const isMobile = !matches; // Detect if the user is on mobile
  const today = new Date();
  const desktopScroll = null;
  const mobileScroll = 'scroll';
  const [style, setStyle] = useState();
  const [initialState, setInitialState] = useState();
  const [pageSizeOptions, setPageSizeOptions] = useState();
  const [tabName, setTabName] = useState(window.localStorage.getItem('selectedTab') || "Catches"); 
  const tabNameList = ["Teams", "Catches", "Pots", "Announcements", "Sponsors", "Stats", "Reports", "Settings"];
  const [tableProperties, setTableProperties] = useState([])

  // STATE - TEAMS
  const [teamRows, setTeamRows] = useState([]);
  const [teamRowsHaveLoaded, setTeamRowsHaveLoaded] = useState(false);
  const [isAddTeamModalOpen, setIsAddTeamModalOpen] = useState(false);
  const [isDeleteTeamModalOpen, setIsDeleteTeamModalOpen] = useState(false);
  const [isEditTeamModalOpen, setIsEditTeamModalOpen] = useState(false);
  const [deleteTeamInfo, setDeleteTeamInfo] = useState();
  const [editTeamInfo, setEditTeamInfo] = useState();
  const openAddTeamModal = () => {setIsAddTeamModalOpen(true)};
  const closeAddTeamModal = () => {setIsAddTeamModalOpen(false)};
  const openEditTeamModal = () => {setIsEditTeamModalOpen(true)};
  const closeEditTeamModal = () => {setIsEditTeamModalOpen(false)};
  const openDeleteTeamModal = () => {setIsDeleteTeamModalOpen(true)};
  const closeDeleteTeamModal = () => {setIsDeleteTeamModalOpen(false)};

  // STATE - CATCHES
  const [catchRows, setCatchRows] = useState([]);
  const [catchRowsHaveLoaded, setCatchRowsHaveLoaded] = useState(false);
  const [isAddCatchModalOpen, setIsAddCatchModalOpen] = useState(false);
  const [isDeleteCatchModalOpen, setIsDeleteCatchModalOpen] = useState(false);
  const [isEditCatchModalOpen, setIsEditCatchModalOpen] = useState(false);
  const [deleteCatchInfo, setDeleteCatchInfo] = useState();
  const [editCatchInfo, setEditCatchInfo] = useState();
  const openAddCatchModal = () => {setIsAddCatchModalOpen(true)};
  const closeAddCatchModal = () => {setIsAddCatchModalOpen(false)};
  const openEditCatchModal = () => {setIsEditCatchModalOpen(true)};
  const closeEditCatchModal = () => {setIsEditCatchModalOpen(false)};
  const openDeleteCatchModal = () => {setIsDeleteCatchModalOpen(true)};
  const closeDeleteCatchModal = () => {setIsDeleteCatchModalOpen(false)};

  // STATE - ANNOUNCEMENTS
  const [announcementRows, setAnnouncementRows] = useState([]);
  const [announcementRowsHaveLoaded, setAnnouncementRowsHaveLoaded] = useState(false);
  const [isAddAnnouncementModalOpen, setIsAddAnnouncementModalOpen] = useState(false);
  const [isDeleteAnnouncementModalOpen, setIsDeleteAnnouncementModalOpen] = useState(false);
  const [isEditAnnouncementModalOpen, setIsEditAnnouncementModalOpen] = useState(false);
  const [deleteAnnouncementInfo, setDeleteAnnouncementInfo] = useState();
  const [editAnnouncementInfo, setEditAnnouncementInfo] = useState();
  const openAddAnnouncementModal = () => {setIsAddAnnouncementModalOpen(true)};
  const closeAddAnnouncementModal = () => {setIsAddAnnouncementModalOpen(false)};
  const openEditAnnouncementModal = () => {setIsEditAnnouncementModalOpen(true)};
  const closeEditAnnouncementModal = () => {setIsEditAnnouncementModalOpen(false)};
  const openDeleteAnnouncementModal = () => {setIsDeleteAnnouncementModalOpen(true)};
  const closeDeleteAnnouncementModal = () => {setIsDeleteAnnouncementModalOpen(false)};

  // STATE - POTS
  const [potRows, setPotRows] = useState([]);
  const [potRowsHaveLoaded, setPotRowsHaveLoaded] = useState(false);
  const [isAddPotModalOpen, setIsAddPotModalOpen] = useState(false);
  const [isDeletePotModalOpen, setIsDeletePotModalOpen] = useState(false);
  const [isEditPotModalOpen, setIsEditPotModalOpen] = useState(false);
  const [deletePotInfo, setDeletePotInfo] = useState();
  const [editPotInfo, setEditPotInfo] = useState();
  const openAddPotModal = () => {setIsAddPotModalOpen(true)};
  const closeAddPotModal = () => {setIsAddPotModalOpen(false)};
  const openEditPotModal = () => {setIsEditPotModalOpen(true)};
  const closeEditPotModal = () => {setIsEditPotModalOpen(false)};
  const openDeletePotModal = () => {setIsDeletePotModalOpen(true)};
  const closeDeletePotModal = () => {setIsDeletePotModalOpen(false)};

  // STATE - SPONSORS
  const [sponsorRows, setSponsorRows] = useState([]);
  const [sponsorRowsHaveLoaded, setSponsorRowsHaveLoaded] = useState(false);
  const [isAddSponsorModalOpen, setIsAddSponsorModalOpen] = useState(false);
  const [isDeleteSponsorModalOpen, setIsDeleteSponsorModalOpen] = useState(false);
  const [isEditSponsorModalOpen, setIsEditSponsorModalOpen] = useState(false);
  const [deleteSponsorInfo, setDeleteSponsorInfo] = useState();
  const [editSponsorInfo, setEditSponsorInfo] = useState();
  const openAddSponsorModal = () => {setIsAddSponsorModalOpen(true)};
  const closeAddSponsorModal = () => {setIsAddSponsorModalOpen(false)};
  const openEditSponsorModal = () => {setIsEditSponsorModalOpen(true)};
  const closeEditSponsorModal = () => {setIsEditSponsorModalOpen(false)};
  const openDeleteSponsorModal = () => {setIsDeleteSponsorModalOpen(true)};
  const closeDeleteSponsorModal = () => {setIsDeleteSponsorModalOpen(false)};

  // STATE - STATS
  const [statsData, setStatsData] = useState({});

  // STATE - REPORTS
  const [isRegistrationReportLoading, setIsRegistrationReportLoading] = useState(false);
  const [isCatchesSpeciesReportLoading, setIsCatchesSpeciesReportLoading] = useState(false);
  const [isCatchesTeamReportLoading, setIsCatchesTeamReportLoading] = useState(false);
  const [isLeaderboardReportLoading, setIsLeaderboardReportLoading] = useState(false);
  const [isPotsReportLoading, setIsPotsReportLoading] = useState(false);

  // INITIALIZE
  useEffect(() => {
    const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
      ? process.env.REACT_APP_SERVER_URL_PRODUCTION
      : process.env.REACT_APP_SERVER_URL_STAGING;

    // Fetch event settings
    const fetchEventSettings = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/admin_get_event_settings`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username, eventId }),
        });
        const data = await response.json();
        if (response.ok && data.success) {
          console.log('Here are the eventSettings loaded for the AdminPage:', data.settings)
          setEventSettings(data.settings); // Store settings in state
        } else {
          console.error('Error fetching event settings 1:', data.message);
        }
      } catch (error) {
        console.error('Error fetching event settings: 2', error);
      } finally {
        setLoading(false); // Set loading to false after fetching settings
      }
    };
    fetchEventSettings();
  }, [username, eventId]);

  useEffect(() => {
    const selectedTab = tabName || "Catches";  // Ensure fallback to the default tab
    
    // Always fetch Stats data alongside any selected tab
    fetchTabData(selectedTab); // Load config and fetch data for the selected tab
    
    // If the selected tab isn't Stats, also fetch Stats data in the background
    if (selectedTab !== "Stats") {
      fetchTabData("Stats");
    }
  }, [tabName]);  // Refetch when the tab changes

  const fetchTabData = async (tab) => {
    // Only set loading state if fetching data for the active tab
    if (tab === tabName) {
      setTabLoading(true);
    }
    
    const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
      ? process.env.REACT_APP_SERVER_URL_PRODUCTION
      : process.env.REACT_APP_SERVER_URL_STAGING;
  
    let tempRows = [];
    try {
      if (tab === "Teams") {
        const response = await fetch(`${apiUrl}/api/admin_get_event_teams`, {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({ username, eventId }) 
        });
        const teamsData = await response.json();
        tempRows = Object.values(teamsData); // Transform teams data into rows format if necessary
        console.log('Here are the teams rows that were loaded:', tempRows)
        setTeamRows(tempRows);
        setTeamRowsHaveLoaded(true);
      } else if (tab === "Catches") {
        const response = await fetch(`${apiUrl}/api/admin_get_event_catches`, {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({ username, eventId }) 
        });
        const responseData = await response.json();
        const catchesData = responseData.catches ? responseData.catches : responseData;
        tempRows = Object.entries(catchesData).map(([id, data]) => ({
          ...data,
          catchId: data.catchId || id
        }));
        tempRows = Object.values(catchesData); // Transform catches data into rows format if necessary
        console.log('Here are the catch rows that were loaded:', tempRows)
        setCatchRows(tempRows);
        setCatchRowsHaveLoaded(true);
      } else if (tab === "Announcements") {
        const response = await fetch(`${apiUrl}/api/admin_get_event_announcements`, {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({ username, eventId }) 
        });
        const announcementsData = await response.json();
        tempRows = Object.values(announcementsData); // Transform announcements data into rows format if necessary
        setAnnouncementRows(tempRows);
        setAnnouncementRowsHaveLoaded(true);
      } else if (tab === "Pots") {
        const response = await fetch(`${apiUrl}/api/admin_get_event_pots`, {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({ username, eventId }) 
        });
        const potsData = await response.json();
        tempRows = Object.values(potsData);
        setPotRows(tempRows);
        setPotRowsHaveLoaded(true);
      } else if (tab === "Sponsors") {
        const response = await fetch(`${apiUrl}/api/admin_get_event_sponsors`, {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({ username, eventId }) 
        });
        const sponsorsData = await response.json();
        tempRows = Object.values(sponsorsData);
        setSponsorRows(tempRows);
        setSponsorRowsHaveLoaded(true);
      } else if (tab === "Stats") {
        const response = await fetch(`${apiUrl}/api/admin_get_event_stats`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username, eventId })
        });
        const statsData = await response.json();
        if (response.ok) {
          console.log('Stats data:', statsData);
          setStatsData(statsData);  // Store the stats in state
        } else {
          console.error('Error fetching stats:', statsData.error);
        }
      }
    } catch (error) {
      console.error(`Error fetching ${tab} data:`, error);
    } finally {
      if (tab === tabName) {
        setTabLoading(false);
      }
    }
  };

  // HELPERS
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);
  };

  const handleTabChange = (event, newTab) => {
    setTabName(newTab);
    window.localStorage.setItem('selectedTab', newTab); // Save the selected tab to local storage
    fetchTabData(newTab);
  };

  const delayRefresh = () => {
    setTimeout(() => {
      console.log('Delaying page refresh...');
      window.location.reload();
    }, 2000);
  }

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  // AUTHENTICATION
  // First check if this is a master admin or regular user
  const handleLogout = (e) => {
    const eventAuth = JSON.parse(window.localStorage.getItem(`eventAuth_${username}_${eventId}`));
    
    // Clear event auth from localStorage
    window.localStorage.removeItem(`eventAuth_${username}_${eventId}`);

    // If not master admin, also sign out of Firebase
    if (!eventAuth?.isMasterAdmin) {
      signOut(auth)
        .then(() => {
          window.localStorage.setItem('user', null);
          window.localStorage.setItem('username', null);
          toast.success('Logout successful! Redirecting...', { autoClose: 2000 });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((error) => {
          toast.error("Error during logout. Please contact support.");
        });
    } else {
      // For master admin, just show success message and reload
      toast.success('Logout successful! Redirecting...', { autoClose: 2000 });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };

  const checkEventAuth = () => {
    try {
      const eventAuth = JSON.parse(window.localStorage.getItem(`eventAuth_${username}_${eventId}`));
      if (!eventAuth) return false;
      
      const isValid = 
        eventAuth.username === username &&
        eventAuth.eventId === eventId &&
        eventAuth.timestamp > (Date.now() - 24*60*60*1000);
        
      if (!isValid) {
        window.localStorage.removeItem(`eventAuth_${username}_${eventId}`);
      }
      return isValid;
    } catch (error) {
      console.error('Error checking event auth:', error);
      return false;
    }
  };

  // REPORT GENERATORS
  const handleGenerateRegistrationReport = async () => {
    setIsRegistrationReportLoading(true); // Set the loading state
    try {
      console.log('In handleGenerateRegistrationReport...');
      await fetchAndGenerateRegistrationReport(
        username,
        eventId,
        eventSettings, // Pass the entire eventSettings instead of teamsConfig
        eventSettings.eventTitle
      );
    } catch (error) {
      console.error('Error generating registration report:', error);
      toast.error('Failed to generate registration report');
    } finally {
      setIsRegistrationReportLoading(false); // Reset the loading state after completion
    }
  };

  const handleGenerateCatchesReportSpecies = async () => {
    setIsCatchesSpeciesReportLoading(true);
    try {
      console.log('In handleGenerateCatchesReportSpecies...');
      await fetchAndGenerateCatchesBySpeciesReport(
        username,
        eventId,
        eventSettings.eventTitle
      );
    } catch (error) {
      console.error('Error generating catches report (Species):', error);
    } finally {
      setIsCatchesSpeciesReportLoading(false);
    }
  };  

  const handleGenerateCatchesReportTeams = async () => {
    setIsCatchesTeamReportLoading(true);
    try {
      console.log('In handleGenerateCatchesReportTeams...');
      await fetchAndGenerateCatchesByTeamReport(
        username,
        eventId,
        eventSettings.eventTitle
      );
    } catch (error) {
      console.error('Error generating catches report (Teams):', error);
    } finally {
      setIsCatchesTeamReportLoading(false);
    }
  };

  const handleGenerateLeaderboardReport = async () => {
    setIsLeaderboardReportLoading(true);
    try {
      console.log('In handleGenerateLeaderboardReport...');
      await generateLeaderboardReport(
        username,
        eventId,
        eventSettings.eventTitle
      );
    } catch (error) {
      console.error('Error generating leaderboard report:', error);
    } finally {
      setIsLeaderboardReportLoading(false);
    }
  };

  const handleGeneratePotsReport = async () => {
    setIsPotsReportLoading(true);
    try {
      console.log('In handleGeneratePotsReport...');
      await generatePotsReport(
        username,
        eventId,
        eventSettings.eventTitle
      );
    } catch (error) {
      console.error('Error generating pots report:', error);
    } finally {
      setIsPotsReportLoading(false);
    }
  };

  if (loading || tabLoading || !eventSettings) {
    return <div>Loading...</div>;
  };

  return (
    <AnimatedPage>
      <main>
        <section style={{ backgroundColor: eventSettings?.backgroundColor }} className="section-banner">
          <h1 style={{ color: eventSettings?.headlineTextColor }}>Settings</h1>
        </section>

        <section className="section-contact">
          {checkEventAuth() ? (
            <Box sx={{ width: '90%', typography: 'body1' }}>
              <p style={{color: "black"}}>
                {`You are currently logged in as: ${JSON.parse(window.localStorage.getItem(`eventAuth_${username}_${eventId}`))?.email || 'Unknown User'}`}
              </p>
              <br/>
              <Button 
                onClick={handleLogout} 
                color="primary" 
                variant="contained" 
                sx={{ width: '200px' }}
              >
                Logout
              </Button>
        
              <br/>
              <br/>

              <br/>
              <TabContext value={tabName}>

                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList variant="scrollable" onChange={handleTabChange} aria-label="lab API tabs example">
                    {tabNameList
                      .filter(tab => {
                        // Always show these tabs
                        if (["Teams", "Catches", "Stats", "Reports", "Settings"].includes(tab)) {
                          return true;
                        }
                        
                        // Only show Sponsors tab if sponsor feature is enabled
                        if (tab === "Sponsors") {
                          return eventSettings?.features?.onlineSponsorRegistration !== 'notNeeded';
                        }
                        
                        // Only show Pots tab if pots feature is enabled
                        if (tab === "Pots") {
                          return eventSettings?.features?.fishPots !== 'notNeeded';
                        }
                        
                        // By default, show the tab
                        return true;
                      })
                      .map((tab) => (
                        <Tab key={tab} label={tab} value={tab} />
                      ))}
                  </TabList>
                </Box>

                {tabNameList.map((tab) => {
                  if (tab === "Stats") {
                    return (
                      <TabPanel key="Stats" value="Stats">
                        {tabLoading || !statsData || !statsData.catchesBySpecies ? (
                          <CircularProgress />
                        ) : (
                          <div>
                            <h3>Teams</h3>
                            <p>Total Registered: {statsData.totalTeams} ({statsData.checkedInTeams} checked-in)</p>

                            {/* Team Categories Section */}
                            <Box sx={{ ml: 3, mb: 3 }}>

                              {/* Only show Division section if includeDivisions is true */}
                              {eventSettings.registration?.includeDivisions === true && statsData.teamsByDivision && (
                                <div style={{ marginBottom: '15px' }}>
                                  <p><strong>Teams by Division:</strong></p>
                                  {Object.keys(statsData.teamsByDivision).length > 0 ? (
                                    Object.entries(statsData.teamsByDivision).map(([division, count]) => (
                                      <p key={division} style={{ marginLeft: '20px' }}>
                                        {division || 'Unassigned'}: {count}
                                      </p>
                                    ))
                                  ) : (
                                    <p style={{ marginLeft: '20px' }}>No division data available</p>
                                  )}
                                </div>
                              )}

                            </Box>

                            <h3>Catches</h3>
                            {Object.keys(statsData.catchesBySpecies).length > 0 ? (
                              <>
                                {Object.entries(statsData.catchesBySpecies).map(([species, count]) => (
                                  <p key={species}>{species}: {count}</p>
                                ))}
                                <p><strong>Total Caught:</strong> {statsData.totalCatches}</p>
                              </>
                            ) : (
                              <p>No catches by species data available.</p>
                            )}
                            <br/>

                            {/* Only show Pots section if feature is enabled */}
                            {eventSettings?.features?.fishPots !== 'notNeeded' && (
                              <>
                                <h3>Pots</h3>
                                <p>Teams Participating: {statsData.totalTeamsInPots}</p>
                                <p>Gross Fees Collected: {formatCurrency(statsData.totalGrossPotValue)}</p>
                                <br/>
                              </>
                            )}

                            {/* Only show Sponsors section if feature is enabled */}
                            {eventSettings?.features?.onlineSponsorRegistration !== 'notNeeded' && (
                              <>
                                <h3>Sponsors</h3>
                                {statsData.totalSponsors > 0 ? (
                                  <>
                                    <p>Total Sponsors: {statsData.totalSponsors}</p>
                                    
                                    {/* Show sponsors by tier if available */}
                                    {statsData.sponsorsByTier && Object.keys(statsData.sponsorsByTier).length > 0 && (
                                      <>
                                        <p><strong>Breakdown by Tier:</strong></p>
                                        {Object.entries(statsData.sponsorsByTier).map(([tier, count]) => (
                                          <p key={tier} style={{ marginLeft: '20px' }}>{tier}: {count}</p>
                                        ))}
                                      </>
                                    )}
                                    
                                    {/* Show total sponsorship amount */}
                                    {statsData.totalSponsorshipAmount > 0 && (
                                      <p><strong>Total Sponsorship Value:</strong> {formatCurrency(statsData.totalSponsorshipAmount)}</p>
                                    )}
                                  </>
                                ) : (
                                  <p>No sponsors registered for this event.</p>
                                )}
                                <br/>
                              </>
                            )}
                          </div>
                        )}
                      </TabPanel>
                    );
                  } else if (tab === "Reports") {
                    return (
                      <TabPanel key="Reports" value="Reports">
                        <div>
                          <div>
                            <Button
                              onClick={() => handleGenerateRegistrationReport()}
                              color="primary"
                              variant="contained"
                              disabled={isRegistrationReportLoading}
                            >
                              {isRegistrationReportLoading ? "Processing..." : "Download Registration Form"}
                            </Button>
                            <br /><br />
                          </div>

                          <div>
                            <Button
                              onClick={() => handleGenerateCatchesReportSpecies()}
                              color="primary"
                              variant="contained"
                              disabled={isCatchesSpeciesReportLoading || !statsData || statsData.totalCatches === 0}
                            >
                              {isCatchesSpeciesReportLoading ? 'Processing...' : 'Download Catch Log (Species)'}
                            </Button>
                            <br /><br />
                            <Button
                              onClick={() => handleGenerateCatchesReportTeams()}
                              color="primary"
                              variant="contained"
                              disabled={isCatchesTeamReportLoading || !statsData || statsData.totalCatches === 0}
                            >
                              {isCatchesTeamReportLoading ? 'Processing...' : 'Download Catch Log (Teams)'}
                            </Button>
                            <br /><br />
                          </div>

                          <div>
                            <Button
                              onClick={() => handleGenerateLeaderboardReport()}
                              color="primary"
                              variant="contained"
                              disabled={isLeaderboardReportLoading || !statsData || statsData.totalCatches === 0}
                            >
                              {isLeaderboardReportLoading ? 'Processing...' : 'Download Leaderboard'}
                            </Button>
                            <br /><br />
                          </div>

                          {/* Only show Pots report button if feature is enabled */}
                          {eventSettings?.features?.fishPots !== 'notNeeded' && (
                            <div>
                              <Button
                                onClick={() => handleGeneratePotsReport()}
                                color="primary"
                                variant="contained"
                                disabled={
                                  isPotsReportLoading || 
                                  !statsData || 
                                  statsData.totalCatches === 0 ||
                                  (!eventSettings?.pots?.potList && !eventSettings?.pots?.list)
                                }
                              >
                                {isPotsReportLoading ? 'Processing...' : 'Download Pot Winners'}
                              </Button>
                              <br />
                              <br />
                            </div>
                          )}
                          
                        </div>
                      </TabPanel>
                    );
                  } else if (tab === "Settings") {
                    return (
                      <TabPanel key="Settings" value="Settings">
                        <EventSettingsTab 
                          eventSettings={eventSettings}
                          username={username}
                          eventId={eventId}
                        />
                      </TabPanel>
                    );
                  } else if (tab === "Teams") {
                    return (
                      <TabPanel key={tab} value={tab}>
                        {!teamRowsHaveLoaded ? (
                          <CircularProgress />
                        ) : (
                          <div style={style}> 
                            <CrudTable
                              eventSettings={eventSettings}
                              today={today}
                              startDate={eventSettings.eventStartDateTimeGMT}
                              endDate={eventSettings.eventEndDateTimeGMT}
                              tableType={tab}
                              buttonLabel={`Add ${tab}`}
                              tableProperties={tableProperties}
                              style={style}
                              rows={teamRows || []}
                              scroll={matches ? desktopScroll : mobileScroll}
                              initialState={initialState}
                              pageSizeOptions={pageSizeOptions}
                              checkboxSelection={true}
                              addStatus={isAddTeamModalOpen}
                              openAddModal={openAddTeamModal}
                              closeAddModal={closeAddTeamModal}
                              editStatus={isEditTeamModalOpen}
                              editInfo={editTeamInfo}
                              setEditInfo={setEditTeamInfo}
                              openEditModal={openEditTeamModal}
                              closeEditModal={closeEditTeamModal}
                              deleteStatus={isDeleteTeamModalOpen}
                              deleteInfo={deleteTeamInfo}
                              setDeleteInfo={setDeleteTeamInfo}
                              openDeleteModal={openDeleteTeamModal}
                              closeDeleteModal={closeDeleteTeamModal}
                            />
                          </div>
                        )}
                      </TabPanel>
                    );
                  } else if (tab === "Catches") {
                    return (
                      <TabPanel key={tab} value={tab}>
                        {!catchRowsHaveLoaded ? (
                          <CircularProgress />
                        ) : (
                          <div style={style}> 
                            <CrudTable
                              eventSettings={eventSettings}
                              today={today}
                              startDate={eventSettings.eventStartDateTimeGMT}
                              endDate={eventSettings.eventEndDateTimeGMT}
                              tableType={tab}
                              buttonLabel={`Add ${tab}`}
                              tableProperties={tableProperties}
                              style={style}
                              rows={catchRows || []}
                              scroll={matches ? desktopScroll : mobileScroll}
                              initialState={initialState}
                              pageSizeOptions={pageSizeOptions}
                              checkboxSelection={true}
                              addStatus={isAddCatchModalOpen}
                              openAddModal={openAddCatchModal}
                              closeAddModal={closeAddCatchModal}
                              editStatus={isEditCatchModalOpen}
                              editInfo={editCatchInfo}
                              setEditInfo={setEditCatchInfo}
                              openEditModal={openEditCatchModal}
                              closeEditModal={closeEditCatchModal}
                              deleteStatus={isDeleteCatchModalOpen}
                              deleteInfo={deleteCatchInfo}
                              setDeleteInfo={setDeleteCatchInfo}
                              openDeleteModal={openDeleteCatchModal}
                              closeDeleteModal={closeDeleteCatchModal}
                            />
                          </div>
                        )}
                      </TabPanel>
                    );
                  } else if (tab === "Announcements") {
                    return (
                      <TabPanel key={tab} value={tab}>
                        {!announcementRowsHaveLoaded ? (
                          <CircularProgress />
                        ) : (
                          <div style={style}> 
                            <CrudTable
                              eventSettings={eventSettings}
                              today={today}
                              startDate={eventSettings.eventStartDateTimeGMT}
                              endDate={eventSettings.eventEndDateTimeGMT}
                              tableType={tab}
                              buttonLabel={`Add ${tab}`}
                              tableProperties={tableProperties}
                              style={style}
                              rows={announcementRows || []}
                              scroll={matches ? desktopScroll : mobileScroll}
                              initialState={initialState}
                              pageSizeOptions={pageSizeOptions}
                              checkboxSelection={true}
                              addStatus={isAddAnnouncementModalOpen}
                              openAddModal={openAddAnnouncementModal}
                              closeAddModal={closeAddAnnouncementModal}
                              editStatus={isEditAnnouncementModalOpen}
                              editInfo={editAnnouncementInfo}
                              setEditInfo={setEditAnnouncementInfo}
                              openEditModal={openEditAnnouncementModal}
                              closeEditModal={closeEditAnnouncementModal}
                              deleteStatus={isDeleteAnnouncementModalOpen}
                              deleteInfo={deleteAnnouncementInfo}
                              setDeleteInfo={setDeleteAnnouncementInfo}
                              openDeleteModal={openDeleteAnnouncementModal}
                              closeDeleteModal={closeDeleteAnnouncementModal}
                            />
                          </div>
                        )}
                      </TabPanel>
                    );
                  } else if (tab === "Pots") {
                    return (
                      <TabPanel key={tab} value={tab}>
                        {!potRowsHaveLoaded ? (
                          <CircularProgress />
                        ) : (
                          <div style={style}> 
                            <CrudTable
                              eventSettings={eventSettings}
                              today={today}
                              startDate={eventSettings.eventStartDateTimeGMT}
                              endDate={eventSettings.eventEndDateTimeGMT}
                              tableType={tab}
                              buttonLabel={`Add ${tab}`}
                              tableProperties={tableProperties}
                              style={style}
                              rows={potRows || []}
                              scroll={matches ? desktopScroll : mobileScroll}
                              initialState={initialState}
                              pageSizeOptions={pageSizeOptions}
                              checkboxSelection={true}
                              addStatus={isAddPotModalOpen}
                              openAddModal={openAddPotModal}
                              closeAddModal={closeAddPotModal}
                              editStatus={isEditPotModalOpen}
                              editInfo={editPotInfo}
                              setEditInfo={setEditPotInfo}
                              openEditModal={openEditPotModal}
                              closeEditModal={closeEditPotModal}
                              deleteStatus={isDeletePotModalOpen}
                              deleteInfo={deletePotInfo}
                              setDeleteInfo={setDeletePotInfo}
                              openDeleteModal={openDeletePotModal}
                              closeDeleteModal={closeDeletePotModal}
                            />
                          </div>
                        )}
                      </TabPanel>
                    );
                  } else if (tab === "Sponsors") {
                    return (
                      <TabPanel key={tab} value={tab}>
                        {!sponsorRowsHaveLoaded ? (
                          <CircularProgress />
                        ) : (
                          <div style={style}> 
                            <CrudTable
                              eventSettings={eventSettings}
                              today={today}
                              startDate={eventSettings.eventStartDateTimeGMT}
                              endDate={eventSettings.eventEndDateTimeGMT}
                              tableType={tab}
                              buttonLabel={`Add ${tab}`}
                              tableProperties={tableProperties}
                              style={style}
                              rows={sponsorRows || []}
                              scroll={matches ? desktopScroll : mobileScroll}
                              initialState={initialState}
                              pageSizeOptions={pageSizeOptions}
                              checkboxSelection={true}
                              addStatus={isAddSponsorModalOpen}
                              openAddModal={openAddSponsorModal}
                              closeAddModal={closeAddSponsorModal}
                              editStatus={isEditSponsorModalOpen}
                              editInfo={editSponsorInfo}
                              setEditInfo={setEditSponsorInfo}
                              openEditModal={openEditSponsorModal}
                              closeEditModal={closeEditSponsorModal}
                              deleteStatus={isDeleteSponsorModalOpen}
                              deleteInfo={deleteSponsorInfo}
                              setDeleteInfo={setDeleteSponsorInfo}
                              openDeleteModal={openDeleteSponsorModal}
                              closeDeleteModal={closeDeleteSponsorModal}
                            />
                          </div>
                        )}
                      </TabPanel>
                    );
                  }
                  
                  })}
                  
            </TabContext> 
          </Box>
          ) : (
          <EventAuthenticate />
          )}
        </section>
        
        <Footer/>
      </main>
    </AnimatedPage>
    );
                  
  }
                  
export default AdminPage;

