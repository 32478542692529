import React, { useState, useEffect, useRef } from 'react';
import { 
  Box, 
  TextField, 
  Button, 
  Dialog, 
  DialogContent, 
  DialogTitle, 
  IconButton, 
  Stack,
  Typography,
  CircularProgress,
  Paper
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Resizer from 'react-image-file-resizer';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

const EditSponsorModal = (props) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [website, setWebsite] = useState('');
  const [description, setDescription] = useState('');
  const [tier, setTier] = useState('');
  const [amount, setAmount] = useState('');
  const [logoUrl, setLogoUrl] = useState(null);
  const [newLogo, setNewLogo] = useState(null);
  const [logoDeleted, setLogoDeleted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const fileInputRef = useRef(null);

  // Format currency for display
  const formatPrice = (price) => {
    if (!price && price !== 0) return '';
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(price);
  };

  useEffect(() => {
    if (props.editInfo) {
      initializeFormData();
    }
  }, [props.editInfo]);

  const initializeFormData = () => {
    if (!props.editInfo) {
      console.error('Missing sponsor data');
      return;
    }

    setName(props.editInfo.name || '');
    setEmail(props.editInfo.email || '');
    setWebsite(props.editInfo.website || '');
    setDescription(props.editInfo.description || '');
    setTier(props.editInfo.tier || '');
    setAmount(props.editInfo.amount ? props.editInfo.amount.toString() : '');
    setLogoUrl(props.editInfo.logoUrl || null);
    setLogoDeleted(false);
    setIsLoading(false);
  };

  const handleLogoUpload = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const fileType = file.type.split("/")[1];
      
      setLogoDeleted(false);

      Resizer.imageFileResizer(
        file,
        500, // max width
        500, // max height
        fileType.toUpperCase(),
        85, // quality
        0,
        (uri) => {
          fetch(uri)
            .then((res) => res.blob())
            .then((blob) => {
              const resizedBlob = new Blob([blob], { type: file.type });
              setNewLogo(resizedBlob);
              const blobUrl = URL.createObjectURL(resizedBlob);
              setLogoUrl(blobUrl);
            })
            .catch((error) => {
              console.error("Error resizing image:", error);
              toast.error("Error resizing image.");
            });
        },
        "base64"
      );
    }
  };

  const handleRemoveLogo = () => {
    setNewLogo(null);
    if (typeof logoUrl === 'string' && logoUrl.startsWith('blob:')) {
      URL.revokeObjectURL(logoUrl);
    }
    setLogoUrl(null);
    setLogoDeleted(true);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleClose = () => {
    setName('');
    setEmail('');
    setWebsite('');
    setDescription('');
    setTier('');
    setAmount('');
    setLogoUrl(null);
    setNewLogo(null);
    setLogoDeleted(false);
    setIsSubmitting(false);
    setIsSubmitted(false);
    props.close();
  };

  const validateForm = () => {
    if (!name.trim()) {
      toast.error('Organization/Company name is required');
      return false;
    }

    if (!email.trim()) {
      toast.error('Contact email is required');
      return false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error('Please enter a valid email address');
      return false;
    }

    if (website && website.trim()) {
      try {
        // If it starts with "www." prepend "https://"
        let websiteToCheck = website.trim();
        if (websiteToCheck.startsWith('www.')) {
          websiteToCheck = 'https://' + websiteToCheck;
        }
        // If no protocol, prepend "https://"
        if (!websiteToCheck.startsWith('http://') && !websiteToCheck.startsWith('https://')) {
          websiteToCheck = 'https://' + websiteToCheck;
        }
        // Using URL constructor for robust validation
        new URL(websiteToCheck);
      } catch (e) {
        toast.error('Please enter a valid website URL or leave it blank');
        return false;
      }
    }

    return true;
  };

  const handleEdit = async () => {
    if (!validateForm()) {
      return;
    }
    
    setIsSubmitting(true);
    
    try {
      const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
        ? process.env.REACT_APP_SERVER_URL_PRODUCTION
        : process.env.REACT_APP_SERVER_URL_STAGING;

      // Create FormData for the request
      const formData = new FormData();
      formData.append('username', props.username);
      formData.append('eventId', props.eventId);

      // Prepare updated sponsor data - keeping original tier and amount
      const updatedSponsor = {
        ...props.editInfo,
        name,
        email,
        website,
        description,
        // Keep original tier and amount - don't allow changes
        tier: props.editInfo.tier,
        amount: props.editInfo.amount,
        logoUrl: logoDeleted ? null : logoUrl
      };

      // Append updated sponsor data
      formData.append('updatedSponsor', JSON.stringify(updatedSponsor));

      // Append new logo if uploaded
      if (newLogo) {
        formData.append('sponsorLogo', newLogo);
      }

      const response = await fetch(`${apiUrl}/api/admin_edit_event_sponsor`, {
        method: 'POST',
        body: formData
      });

      if (response.ok) {
        toast.success('Sponsor updated successfully!');
        setIsSubmitted(true);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        const errorData = await response.json();
        toast.error(`Error: ${errorData.error || 'Error updating sponsor'}`);
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error('Error updating sponsor:', error);
      toast.error('Error updating sponsor');
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={props.status} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle style={{ backgroundColor: '#0281C5', color: 'white', padding: '16px 24px' }}>
        Edit Sponsor Information
        <IconButton
          onClick={handleClose}
          sx={{ position: 'absolute', right: 8, top: 8, color: 'white' }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Box display="flex" justifyContent="center" my={4}>
            <CircularProgress />
          </Box>
        ) : (
          <Stack spacing={3} sx={{ mt: 3 }}>
            <Typography variant="subtitle1" fontWeight="bold">
              Sponsor ID: {props.editInfo?.sponsorId}
            </Typography>

            {/* Sponsorship Tier and Amount (Read-only) */}
            <Paper 
              elevation={0} 
              variant="outlined" 
              sx={{ p: 2, mb: 2, backgroundColor: '#f8f9fa' }}
            >
              <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                Sponsorship Details (Read-only)
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2, mb: 1 }}>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="body2" color="text.secondary">
                    Tier
                  </Typography>
                  <Typography variant="body1" fontWeight="medium">
                    {tier}
                  </Typography>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="body2" color="text.secondary">
                    Amount
                  </Typography>
                  <Typography variant="body1" fontWeight="medium">
                    {formatPrice(amount)}
                  </Typography>
                </Box>
              </Box>
              <Typography variant="caption" color="text.secondary">
                Tier and amount cannot be modified. Contact support if changes are needed.
              </Typography>
            </Paper>

            {/* Organization/Company Name */}
            <TextField
              label="Organization/Company Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              fullWidth
              variant="outlined"
            />

            {/* Contact Email */}
            <TextField
              label="Contact Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              fullWidth
              variant="outlined"
            />

            {/* Website URL */}
            <TextField
              label="Website URL"
              type="url"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
              placeholder="https://example.com"
              fullWidth
              variant="outlined"
              helperText="Optional: Include website URL to display on sponsor page"
            />

            {/* Description */}
            <TextField
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              multiline
              rows={3}
              fullWidth
              variant="outlined"
              helperText="Optional: Brief description of the sponsor"
            />

            {/* Logo Upload */}
            {props.eventSettings?.sponsors?.allowLogoUpload && (
              <Box>
                <Typography variant="subtitle1" gutterBottom>
                  Sponsor Logo
                </Typography>
                
                {/* Current Logo Preview */}
                {logoUrl && !logoDeleted && (
                  <Box display="flex" alignItems="center" mb={2}>
                    <Box
                      sx={{
                        width: 150,
                        height: 150,
                        border: '1px solid #ddd',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        overflow: 'hidden',
                        mr: 2
                      }}
                    >
                      <img
                        src={logoUrl}
                        alt="Sponsor Logo"
                        style={{ 
                          maxWidth: '100%', 
                          maxHeight: '100%', 
                          objectFit: 'contain' 
                        }}
                        onError={(e) => {
                          console.error('Logo failed to load:', e);
                          e.target.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
                        }}
                      />
                    </Box>
                    <IconButton onClick={handleRemoveLogo} color="primary">
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                )}
                
                {/* File Input and Upload Button */}
                <input
                  type="file"
                  accept="image/*"
                  id="sponsor-logo-upload"
                  style={{ display: 'none' }}
                  onChange={handleLogoUpload}
                  ref={fileInputRef}
                />
                <label htmlFor="sponsor-logo-upload">
                  <Button
                    variant="outlined"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                  >
                    {logoUrl && !logoDeleted ? "Replace Logo" : "Upload Logo"}
                  </Button>
                </label>
              </Box>
            )}

            {!isSubmitted ? (
              <Button
                variant="contained"
                onClick={handleEdit}
                disabled={isSubmitting}
                startIcon={isSubmitting ? <CircularProgress size={24} color="inherit" /> : null}
                sx={{
                  backgroundColor: '#0281C5',
                  color: 'white',
                  borderRadius: '30px',
                  border: '2px solid black',
                  py: 1.5,
                  fontWeight: 'bold',
                  '&:hover': {
                    backgroundColor: '#026999',
                  },
                  mt: 2,
                  mb: 2
                }}
              >
                {isSubmitting ? "Updating..." : "Update Sponsor"}
              </Button>
            ) : (
              <Box sx={{ textAlign: 'center', mt: 2 }}>
                <Typography variant="subtitle1" color="primary" fontWeight="bold">
                  Sponsor updated successfully!
                </Typography>
              </Box>
            )}
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default EditSponsorModal;

