import React, { useState, useEffect } from 'react';
import { InputLabel, Box, Divider, Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, TextField, Autocomplete, Grid } from "@mui/material";
import Resizer from 'react-image-file-resizer';
import CircularProgress from '@mui/material/CircularProgress';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import CloseIcon from "@mui/icons-material/Close";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useMediaQuery from '@mui/material/useMediaQuery';

const AddCatchModal = (props) => {
  const [isSubmitting, setIsSubmitting] = useState(false); 
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState({ "Team name": "" });
  const [teamNames, setTeamNames] = useState([]); 
  const [isLoadingTeamNames, setIsLoadingTeamNames] = useState(true);
  const [numCatches, setNumCatches] = useState(0);
  const [numNewCatches, setNumNewCatches] = useState(0);
  const [catchData, setCatchData] = useState([]);
  const [speciesList, setSpeciesList] = useState([]);
  const [speciesConfig, setSpeciesConfig] = useState({});
  // Track max 250 catches for Tier 1 events
  const isMobile = useMediaQuery('(max-width:650px)');
  const [catchPhotos, setCatchPhotos] = useState({});
  const [catchPhotoNames, setCatchPhotoNames] = useState({});
  const [eventStartDate, setEventStartDate] = useState(null);
  const [eventEndDate, setEventEndDate] = useState(null);

  useEffect(() => {

    console.log("Event settings:", props.eventSettings);

    if (props.startDate) {
      let startTimestamp;
      if (typeof props.startDate === 'string') {
        startTimestamp = parseInt(props.startDate, 10);
      } else if (typeof props.startDate === 'number') {
        startTimestamp = props.startDate;
      }
      if (!isNaN(startTimestamp)) {
        setEventStartDate(dayjs(startTimestamp));
        console.log('Set event start date:', dayjs(startTimestamp).format('MM/DD/YYYY hh:mm A'));
      }
    }
    
    if (props.endDate) {
      let endTimestamp;
      if (typeof props.endDate === 'string') {
        endTimestamp = parseInt(props.endDate, 10);
      } else if (typeof props.endDate === 'number') {
        endTimestamp = props.endDate;
      }
      if (!isNaN(endTimestamp)) {
        setEventEndDate(dayjs(endTimestamp));
        console.log('Set event end date:', dayjs(endTimestamp).format('MM/DD/YYYY hh:mm A'));
      }
    }

    fetchTeamNames();
    fetchCatchCount();
    loadSpeciesOptions();
  }, [props.startDate, props.endDate]); 

  const fetchTeamNames = async () => {
    try {
      const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
        ? process.env.REACT_APP_SERVER_URL_PRODUCTION
        : process.env.REACT_APP_SERVER_URL_STAGING;

      const response = await fetch(`${apiUrl}/api/admin_get_event_team_names`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: props.username, eventId: props.eventId })
      });

      if (response.ok) {
        const data = await response.json();
        setTeamNames(data.teamNames || []);
      } else {
        toast.error('Error fetching team names.');
      }
    } catch (error) {
      console.error('Error fetching team names:', error);
      toast.error('Error fetching team names.');
    } finally {
      setIsLoadingTeamNames(false); 
    }
  };

  const fetchCatchCount = async () => {
    try {
      const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
        ? process.env.REACT_APP_SERVER_URL_PRODUCTION
        : process.env.REACT_APP_SERVER_URL_STAGING;
  
      const response = await fetch(`${apiUrl}/api/admin_get_number_of_event_catches`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: props.username, eventId: props.eventId })
      });
  
      if (response.ok) {
        const data = await response.json();
        const totalCatchesFromDb = Object.values(data).reduce((sum, count) => sum + count, 0);
        setNumCatches(totalCatchesFromDb);
      } else {
        toast.error('Error fetching catch count.');
      }
    } catch (error) {
      console.error('Error fetching catch count:', error);
      toast.error('Error fetching catch count.');
    }
  };

  const isValidIncrement = (value, increment) => {
    if (!value) return false;
    const remainder = (value * 1000) % (increment * 1000);
    return Math.abs(remainder) < 0.001;
  };
  
  const getValidationConfig = (units) => {
    const [weightUnit, lengthUnit] = (units || "pounds, tenths of inches").split(", ");
    
    return {
      weight: {
        increment: 0.1,
        errorMsg: `Weight must be in increments of 0.1 ${weightUnit}`
      },
      length: {
        increment: lengthUnit === "eighths of inches" ? 0.125 :
                   lengthUnit === "tenths of inches" ? 0.1 :
                   lengthUnit === "millimeters" ? 1 : 1,
        errorMsg: lengthUnit === "eighths of inches" ? "All distance measurements must be in 1/8 inch increments" :
                  lengthUnit === "tenths of inches" ? "All distance measurements must be in 0.1 inch increments" :
                  lengthUnit === "millimeters" ? "All distance measurements must be in whole millimeters" :
                  "All distance measurements must be in whole centimeters"
      }
    };
  };

  const getUnitConfig = () => {
    // Get the units from the actual structure (directly at root level)
    const units = props.eventSettings?.units || "pounds, tenths of inches";
    const [weightUnit, lengthUnit] = units.split(", ");
    
    const config = {
      weight: {
        step: 0.1,
        unit: weightUnit === "pounds" ? "lb" : "kg",
        label: `Weight (by 1/10 ${weightUnit})`
      },
      length: {
        step: lengthUnit === "eighths of inches" ? 0.125 : 
              lengthUnit === "tenths of inches" ? 0.1 : 
              lengthUnit === "millimeters" ? 1 : 1,
        unit: lengthUnit.includes("inches") ? "in" : 
              lengthUnit === "centimeters" ? "cm" : "mm",
        label: `Length (by ${lengthUnit === "eighths of inches" ? "1/8 inch" : 
                            lengthUnit === "tenths of inches" ? "1/10 inch" :
                            lengthUnit === "centimeters" ? "1 cm" : "1 mm"})`
      }
    };
    return config;
  }

  const unitConfig = getUnitConfig();

  const loadSpeciesOptions = () => {
    console.log("Loading species options...");
    console.log("Event settings structure:", props.eventSettings);
    
    // Check for direct speciesList property (which appears to be the actual structure used)
    if (props.eventSettings?.speciesList && props.eventSettings.speciesList.length > 0) {
      console.log("Found species list at root level:", props.eventSettings.speciesList);
      
      const speciesOptions = props.eventSettings.speciesList.map(species => ({
        label: species.species,
        config: species
      }));
      
      // Create a lookup object for species configs
      const speciesMap = {};
      props.eventSettings.speciesList.forEach(species => {
        speciesMap[species.species] = species;
      });
      
      setSpeciesList(speciesOptions);
      setSpeciesConfig(speciesMap);
      console.log("Set species list with items:", speciesOptions.length);
    }
    // Fallback check for nested structure
    else if (props.eventSettings?.species?.speciesList && props.eventSettings.species.speciesList.length > 0) {
      console.log("Found species list in nested structure:", props.eventSettings.species.speciesList);
      
      const speciesOptions = props.eventSettings.species.speciesList.map(species => ({
        label: species.species,
        config: species
      }));
      
      // Create a lookup object for species configs
      const speciesMap = {};
      props.eventSettings.species.speciesList.forEach(species => {
        speciesMap[species.species] = species;
      });
      
      setSpeciesList(speciesOptions);
      setSpeciesConfig(speciesMap);
      console.log("Set species list with items:", speciesOptions.length);
    }
    else {
      console.error("No species list found in event settings");
      console.log("Available event settings keys:", Object.keys(props.eventSettings || {}));
      setSpeciesList([]);
      setSpeciesConfig({});
    }
  }

  // Calculate points based on species configuration and catch data
  const calculatePoints = (catchEntry) => {
    const species = speciesConfig[catchEntry.species];
    if (!species) return 0.0;
    
    // Get values needed for calculation
    const { scoringMethod, rounding, points } = species;
    const weight = parseFloat(catchEntry.weight) || 0;
    const length = parseFloat(catchEntry.length) || 0;
    
    let calculatedPoints = 0.0;
    
    // Calculate based on scoring method
    if (scoringMethod === "points" || scoringMethod === "custom") {
      // Fixed points
      calculatedPoints = parseFloat(points);
    } else if (scoringMethod === "weight") {
      // Points = weight value
      calculatedPoints = weight;
    } else if (scoringMethod === "length") {
      // Points = length value
      calculatedPoints = length;
    }
    
    // Apply rounding if applicable
    if (rounding === "up") {
      calculatedPoints = Math.ceil(calculatedPoints);
    } else if (rounding === "down") {
      calculatedPoints = Math.floor(calculatedPoints);
    } else if (rounding === "nearest") {
      calculatedPoints = Math.round(calculatedPoints);
    }
    // For "exact", keep the original value
    
    return parseFloat(calculatedPoints);
  };

  // Handle catch photo upload based on species configuration
  const handleCatchPhotoUpload = (index, e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const fileType = file.type.split("/")[1];
      const fileName = file.name;
  
      // Update the photo name for this catch
      setCatchPhotoNames(prev => ({
        ...prev,
        [index]: fileName
      }));
  
      Resizer.imageFileResizer(
        file,
        300,
        300,
        fileType.toUpperCase(),
        80,
        0,
        (uri) => {
          fetch(uri)
            .then((res) => res.blob())
            .then((blob) => {
              const resizedBlob = new Blob([blob], { type: file.type });
              setCatchPhotos(prev => ({
                ...prev,
                [index]: resizedBlob
              }));
            })
            .catch((error) => {
              console.error("Error resizing image:", error);
              toast.error("Error resizing image.");
            });
        },
        "base64"
      );
    }
  };
  
  // Remove photo handler
  const handleRemoveCatchPhoto = (index) => {
    setCatchPhotos(prev => {
      const newPhotos = { ...prev };
      delete newPhotos[index];
      return newPhotos;
    });
    setCatchPhotoNames(prev => {
      const newNames = { ...prev };
      delete newNames[index];
      return newNames;
    });
  
    // Clear the file input
    const photoInput = document.getElementById(`catch-photo-upload-${index}`);
    if (photoInput) photoInput.value = "";
  };
  
  const handleClose = () => {
    setFormData({ "Team name": "" });
    setNumCatches(0);
    setNumNewCatches(0);
    setCatchData([]);
    setIsSubmitting(false);
    setIsSubmitted(false);
    setCatchPhotos({});
    setCatchPhotoNames({});
    props.close();
  };

  const validateDateTime = (dateTime) => {
    // First, ensure dateTime is a valid date object or timestamp
    let catchDate;
    
    if (dateTime instanceof Date) {
      catchDate = dateTime.getTime();
    } else if (typeof dateTime === 'string') {
      catchDate = new Date(dateTime).getTime();
    } else if (typeof dateTime === 'number') {
      catchDate = dateTime;
    } else {
      // If it's none of the above, it's invalid
      return {
        isValid: false,
        errorMessage: "Invalid date format"
      };
    }
    
    // Now handle the start and end dates from props
    // These might be strings, numbers, or Date objects
    let startDate, endDate;
    
    // Handle start date
    if (typeof props.startDate === 'string') {
      // If it's a numeric string (timestamp), convert directly to number
      if (!isNaN(props.startDate)) {
        startDate = parseInt(props.startDate, 10);
      } else {
        // Otherwise parse as date string
        startDate = new Date(props.startDate).getTime();
      }
    } else if (props.startDate instanceof Date) {
      startDate = props.startDate.getTime();
    } else if (typeof props.startDate === 'number') {
      startDate = props.startDate;
    } else {
      // Default to a very old date if undefined/invalid
      startDate = 0;
    }
    
    // Handle end date
    if (typeof props.endDate === 'string') {
      // If it's a numeric string (timestamp), convert directly to number
      if (!isNaN(props.endDate)) {
        endDate = parseInt(props.endDate, 10);
      } else {
        // Otherwise parse as date string
        endDate = new Date(props.endDate).getTime();
      }
    } else if (props.endDate instanceof Date) {
      endDate = props.endDate.getTime();
    } else if (typeof props.endDate === 'number') {
      endDate = props.endDate;
    } else {
      // Default to a far future date if undefined/invalid
      endDate = 32503680000000; // Year 3000
    }
    
    // Now do the comparisons with proper error handling
    if (isNaN(catchDate)) {
      return {
        isValid: false,
        errorMessage: "Please select a valid date and time"
      };
    }
    
    if (isNaN(startDate) || isNaN(endDate)) {
      console.error("Event start or end date is invalid:", { startDate: props.startDate, endDate: props.endDate });
      return {
        isValid: true, // Allow it to pass if we can't validate properly
        errorMessage: ""
      };
    }
    
    // Check if catch date is within event period
    if (catchDate < startDate) {
      return {
        isValid: false,
        errorMessage: "Catch date cannot be before the event start date"
      };
    }
    
    if (catchDate > endDate) {
      return {
        isValid: false,
        errorMessage: "Catch date cannot be after the event end date"
      };
    }

    return {
      isValid: true,
      errorMessage: ""
    };
  };

  const validateUserInput = () => {
    let inputIsValid = true;
    const validation = getValidationConfig(props.eventSettings?.units);
  
    if (!formData['Team name']) {
      toast.error('Please select a team.');
      inputIsValid = false;
    }
  
    let counter = 1;
    catchData.forEach((entry) => {
      if (!entry.species) {
        toast.error(`Species is required for Catch #${counter}`);
        inputIsValid = false;
      }
  
      // Get species configuration for validation
      const species = speciesConfig[entry.species];
      if (!species) {
        inputIsValid = false;
        return;
      }
  
      // Validate date/time if required
      if (species.collectTime && species.timeRequired && !entry.dateTime) {
        toast.error(`Catch date/time is required for Catch #${counter}`);
        inputIsValid = false;
      }
  
      // Validate weight if required
      if (species.collectWeight && species.weightRequired) {
        if (entry.weight <= 0) {
          toast.error(`Weight must be greater than zero for Catch #${counter}`);
          inputIsValid = false;
        } else if (!isValidIncrement(entry.weight, validation.weight.increment)) {
          toast.error(`${validation.weight.errorMsg} for Catch #${counter}`);
          inputIsValid = false;
        }
      }
  
      // Validate length if required
      if (species.collectLength && species.lengthRequired) {
        if (entry.length <= 0) {
          toast.error(`Length must be greater than zero for Catch #${counter}`);
          inputIsValid = false;
        } else if (!isValidIncrement(entry.length, validation.length.increment)) {
          toast.error(`${validation.length.errorMsg} for Catch #${counter}`);
          inputIsValid = false;
        }
      }
  
      // Validate girth if required
      if (species.collectGirth && species.girthRequired) {
        if (entry.girth <= 0) {
          toast.error(`Girth must be greater than zero for Catch #${counter}`);
          inputIsValid = false;
        } else if (!isValidIncrement(entry.girth, validation.length.increment)) {
          toast.error(`${validation.length.errorMsg} for Catch #${counter}`);
          inputIsValid = false;
        }
      }
      
      // Validate photo if required
      if (species.includePhoto && species.photoRequired && !catchPhotos[counter-1]) {
        toast.error(`Photo is required for ${entry.species} catch #${counter}`);
        inputIsValid = false;
      }
  
      counter++;
    });
  
    return inputIsValid;
  };

  const handleChangeNumberOfCatches = (e) => {
    setNumNewCatches(e.target.value);
    if (e.target.value > 0) {
      const catchDataList = Array.from({ length: e.target.value }, (_, i) => ({
        id: i,
        teamName: formData['Team name'],
        species: "",
        dateTime: undefined,
        length: 0,
        girth: 0,
        weight: 0,
        points: 0.0,
        "Catch photo": null,
      }));
      setCatchData(catchDataList);
      setCatchPhotos({});
      setCatchPhotoNames({});
    } else {
      setCatchData([]);
      setCatchPhotos({});
      setCatchPhotoNames({});
    }
  };

  const handleTeamSelection = (event, value) => {
    setFormData(prevData => ({
      ...prevData,
      'Team name': value
    }));

    setCatchData(prevData =>
      prevData.map(catchEntry => ({
        ...catchEntry,
        teamName: value
      }))
    );
  };

  const handleSpeciesSelection = (index, event, value) => {
    if (!value) return;
    
    const newCatchData = [...catchData];
    const species = value.label;
    const speciesEntry = value.config;
    
    // Get default datetime
    const defaultDateTime = dayjs().toISOString();
    
    // Update the catch with selected species and initialize fields
    newCatchData[index] = {
      ...newCatchData[index],
      species: species,
      // Initialize with 0 values - they'll be filled in by the user as needed
      weight: speciesEntry.collectWeight ? 0 : undefined,
      length: speciesEntry.collectLength ? 0 : undefined,
      girth: speciesEntry.collectGirth ? 0 : undefined,
      // Set default date if time is collected
      dateTime: speciesEntry.collectTime ? defaultDateTime : undefined,
    };
    
    // Calculate and set points based on the selected species
    newCatchData[index].points = calculatePoints(newCatchData[index]);
    
    setCatchData(newCatchData);
  };
  
  const handleDateTimeSelection = (index, event) => {
    const newCatchData = [...catchData];
    newCatchData[index].dateTime = event ? event.toISOString() : null;
    setCatchData(newCatchData);
  };
  
  const handleWeightSelection = (index, event) => {
    const newCatchData = [...catchData];
    newCatchData[index].weight = parseFloat(event.target.value);
    
    // Recalculate points after weight update
    newCatchData[index].points = calculatePoints(newCatchData[index]);
    
    setCatchData(newCatchData);
  };

  const handleLengthSelection = (index, event) => {
    const newCatchData = [...catchData];
    newCatchData[index].length = parseFloat(event.target.value);
    
    // Recalculate points after length update
    newCatchData[index].points = calculatePoints(newCatchData[index]);
    
    setCatchData(newCatchData);
  };

  const handleGirthSelection = (index, event) => {
    const newCatchData = [...catchData];
    newCatchData[index].girth = parseFloat(event.target.value);
    setCatchData(newCatchData);
  };

  const handleCreateCatches = async () => {
    if (validateUserInput()) {
      setIsSubmitting(true);
  
      try {
        const formData = new FormData();
        formData.append("username", props.username);
        formData.append("eventId", props.eventId);
  
        // Append each catch's photo if it exists
        Object.entries(catchPhotos).forEach(([index, photo]) => {
          formData.append('catchPhotos', photo, catchPhotoNames[index]);
        });
  
        // Prepare catch data with species config info
        const preparedCatchData = catchData.map(catchItem => {
          const species = speciesConfig[catchItem.species];
          if (!species) return catchItem;
          
          // Add required flags based on species configuration
          return {
            ...catchItem,
            // Save scoring information
            scoringMethod: species.scoringMethod,
            rounding: species.rounding,
            // Save required flags for validation
            collectTime: species.collectTime,
            timeRequired: species.timeRequired,
            collectWeight: species.collectWeight,
            weightRequired: species.weightRequired,
            collectLength: species.collectLength,
            lengthRequired: species.lengthRequired,
            collectGirth: species.collectGirth,
            girthRequired: species.girthRequired,
            includePhoto: species.includePhoto,
            photoRequired: species.photoRequired,
            // Ensure points is always a float
            points: parseFloat(catchItem.points) || 0.0
          };
        });
        
        // Append catch data to form
        formData.append('catches', JSON.stringify(preparedCatchData));
  
        let apiUrl = process.env.REACT_APP_NODE_ENV === 'staging'
          ? process.env.REACT_APP_SERVER_URL_STAGING
          : process.env.REACT_APP_SERVER_URL_PRODUCTION;
  
        const response = await fetch(`${apiUrl}/api/admin_create_event_catches`, {
          method: 'POST',
          body: formData,
        });
  
        if (response.ok) {
          toast.success(`Successfully added ${numNewCatches} catches!`);
          setIsSubmitted(true);
          delayRefresh();
        } else {
          toast.error('Error while saving catches.');
          setIsSubmitting(false);
        }
      } catch (error) {
        console.error('Error saving catches:', error);
        toast.error('Error saving catches to the database.');
        setIsSubmitting(false);
      }
    }
  };
  
  const delayRefresh = () => {
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  if (isLoadingTeamNames) {
    return (
      <Dialog open={props.status} onClose={handleClose} fullWidth maxWidth="xl">
        <DialogContent>
          <CircularProgress size={24} />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={props.status} onClose={handleClose} fullWidth maxWidth="xl">
      <form onSubmit={(e) => e.preventDefault()}>

        <DialogTitle>
          Add Catches
          <IconButton onClick={handleClose} style={{float:'right'}}>
            <CloseIcon color="primary" />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Stack spacing={2} margin={2}>
            <Autocomplete
              id="team-autocomplete"
              options={teamNames}
              value={formData['Team name']}
              onChange={handleTeamSelection}
              renderInput={(params) => (
                <TextField {...params} label="Select team" required />
              )}
            />
            <TextField
              label="Number of catches"
              type="number"
              value={numNewCatches}
              onChange={handleChangeNumberOfCatches}
              fullWidth
              required
              inputProps={{ min: 0 }}
            />
            {catchData.length > 0 && catchData.map((catchItem, index) => (

              <div key={index}>

                <Divider />
                <br/>
                <InputLabel>Catch #{index + 1} ({catchData[index].points} points)</InputLabel>
                <br/>

                <Autocomplete
                  disablePortal
                  id={`species-select-${index}`}
                  options={speciesList}
                  getOptionLabel={(option) => option.label}
                  onChange={(event, value) => handleSpeciesSelection(index, event, value)}
                  renderInput={(params) => (
                    <TextField 
                      {...params} 
                      label="Select species" 
                      required 
                      error={!catchData[index].species}
                      helperText={!catchData[index].species ? "Species is required" : ""}
                    />
                  )}
                /> 

                {/* Render fields based on what the species requires */}
                {catchData[index].species && speciesConfig[catchData[index].species] && (
                  <>
                    {/* Date Time Input - only show when collecting time */}
                    {speciesConfig[catchData[index].species].collectTime && (
                      <div>
                        <br/>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            label={speciesConfig[catchData[index].species].timeRequired ? 
                              "Catch Date/Time (required)" : "Catch Date/Time (optional)"}
                            value={catchData[index].dateTime ? dayjs(catchData[index].dateTime) : null}
                            onChange={(date) => handleDateTimeSelection(index, date)}
                            minDateTime={eventStartDate}
                            maxDateTime={eventEndDate}
                            timeSteps={{ minutes: 1 }}
                            slotProps={{
                              textField: {
                                fullWidth: true,
                                helperText: "Select a date/time during the tournament period"
                              }
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                    )}

                    {/* Weight, Length, Girth Inputs - Desktop or tablet view */}
                    {!isMobile && (
                      <div>
                        <br/>
                        <Grid container spacing={2}>
                          {speciesConfig[catchData[index].species].collectWeight && (
                            <Grid item xs={12} sm={4}>
                              <TextField
                                label={speciesConfig[catchData[index].species].weightRequired ? 
                                  `${unitConfig.weight.label} (required)` : `${unitConfig.weight.label} (optional)`}
                                type="number"
                                value={catchData[index].weight || ''}
                                onChange={(e) => handleWeightSelection(index, e)}
                                InputProps={{ 
                                  inputProps: { 
                                    step: unitConfig.weight.step, 
                                    min: unitConfig.weight.step 
                                  } 
                                }}
                                fullWidth
                              />
                            </Grid>
                          )}

                          {speciesConfig[catchData[index].species].collectLength && (
                            <Grid item xs={12} sm={4}>
                              <TextField
                                label={speciesConfig[catchData[index].species].lengthRequired ? 
                                  `${unitConfig.length.label} (required)` : `${unitConfig.length.label} (optional)`}
                                type="number"
                                value={catchData[index].length || ''}
                                onChange={(e) => handleLengthSelection(index, e)}
                                InputProps={{ 
                                  inputProps: { 
                                    step: unitConfig.length.step, 
                                    min: unitConfig.length.step 
                                  } 
                                }}
                                fullWidth
                              />
                            </Grid>
                          )}

                          {speciesConfig[catchData[index].species].collectGirth && (
                            <Grid item xs={12} sm={4}>
                              <TextField
                                label={speciesConfig[catchData[index].species].girthRequired ? 
                                  `Girth (by ${unitConfig.length.step} ${unitConfig.length.unit}) (required)` : 
                                  `Girth (by ${unitConfig.length.step} ${unitConfig.length.unit}) (optional)`}
                                type="number"
                                value={catchData[index].girth || ''}
                                onChange={(e) => handleGirthSelection(index, e)}
                                InputProps={{ 
                                  inputProps: { 
                                    step: unitConfig.length.step, 
                                    min: unitConfig.length.step 
                                  } 
                                }}
                                fullWidth
                              />
                            </Grid>
                          )}
                        </Grid>
                      </div>
                    )}

                    {/* Weight, Length, Girth Inputs - Mobile view */}
                    {isMobile && (
                      <div>
                        {speciesConfig[catchData[index].species].collectWeight && (
                          <div>
                            <br/>
                            <TextField
                              label={speciesConfig[catchData[index].species].weightRequired ? 
                                `${unitConfig.weight.label} (required)` : `${unitConfig.weight.label} (optional)`}
                              type="number"
                              value={catchData[index].weight || ''}
                              onChange={(e) => handleWeightSelection(index, e)}
                              InputProps={{ inputProps: { step: unitConfig.weight.step, min: unitConfig.weight.step } }}
                              fullWidth
                            />
                          </div>
                        )}

                        {speciesConfig[catchData[index].species].collectLength && (
                          <div>
                            <br/>
                            <TextField
                              label={speciesConfig[catchData[index].species].lengthRequired ? 
                                `${unitConfig.length.label} (required)` : `${unitConfig.length.label} (optional)`}
                              type="number"
                              value={catchData[index].length || ''}
                              onChange={(e) => handleLengthSelection(index, e)}
                              InputProps={{ inputProps: { step: unitConfig.length.step, min: unitConfig.length.step } }}
                              fullWidth
                            />
                          </div>
                        )}

                        {speciesConfig[catchData[index].species].collectGirth && (
                          <div>
                            <br/>
                            <TextField
                              label={speciesConfig[catchData[index].species].girthRequired ? 
                                `Girth (by ${unitConfig.length.step} ${unitConfig.length.unit}) (required)` : 
                                `Girth (by ${unitConfig.length.step} ${unitConfig.length.unit}) (optional)`}
                              type="number"
                              value={catchData[index].girth || ''}
                              onChange={(e) => handleGirthSelection(index, e)}
                              InputProps={{ inputProps: { step: unitConfig.length.step, min: unitConfig.length.step } }}
                              fullWidth
                            />
                          </div>
                        )}
                      </div>
                    )}      

                    {/* Photo input - Only show when species config includes photo */}
                    {speciesConfig[catchData[index].species].includePhoto && (
                      <Box marginTop={2}>
                        <p style={{ color: 'gray', marginBottom: '8px' }}>
                          Upload catch photo
                          {speciesConfig[catchData[index].species].photoRequired ? ' (required)' : ' (optional)'}:
                        </p>
                        <input
                          type="file"
                          onChange={(e) => handleCatchPhotoUpload(index, e)}
                          id={`catch-photo-upload-${index}`}
                          accept="image/*"
                          required={speciesConfig[catchData[index].species].photoRequired}
                        />
                        {catchPhotos[index] && (
                          <Box display="flex" alignItems="center" marginTop={2}>
                            <img
                              src={URL.createObjectURL(catchPhotos[index])}
                              alt="Catch Preview"
                              style={{
                                width: "100px",
                                height: "100px",
                                objectFit: "cover",
                                marginRight: "10px",
                              }}
                            />
                            <IconButton size="small" onClick={() => handleRemoveCatchPhoto(index)}>
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </Box>
                        )}
                      </Box>
                    )}
                  </>
                )}     
              </div>
            ))}
            <Button
              disabled={isSubmitting || numNewCatches <= 0}
              color="primary"
              variant="contained"
              onClick={handleCreateCatches}
              startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </Button>
            {isSubmitted && <h3>Submitted!</h3>}
          </Stack>
        </DialogContent>
      </form>
    </Dialog>
  );

};

export default AddCatchModal;

