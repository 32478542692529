import React, { useState, useEffect } from 'react';
import { 
  Button, 
  Dialog, 
  DialogContent, 
  DialogTitle, 
  IconButton, 
  Stack, 
  CircularProgress,
  Typography,
  Box,
  Paper
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DeleteAnnouncementModal = (props) => {
  const [announcementInfo, setAnnouncementInfo] = useState(props.deleteInfo);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    console.log('Deleting announcement:', props.deleteInfo);
    setAnnouncementInfo(props.deleteInfo);
  }, [props.deleteInfo]);

  const handleClose = () => {
    setIsSubmitting(false);
    setIsSubmitted(false);
    props.close();
  };

  const delayRefresh = () => {
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const handleDelete = async () => {
    setIsSubmitting(true);
  
    try {
      let apiUrl = process.env.REACT_APP_NODE_ENV === "staging"
        ? process.env.REACT_APP_SERVER_URL_STAGING
        : process.env.REACT_APP_SERVER_URL_PRODUCTION;
  
      const response = await fetch(`${apiUrl}/api/admin_delete_event_announcements`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          username: props.username,
          eventId: props.eventId,
          announcementId: announcementInfo.announcementId,
        }),
      });
  
      if (response.ok) {
        toast.success('Announcement deleted successfully.');
        setIsSubmitted(true);
        delayRefresh();
      } else {
        toast.error('Error deleting announcement.');
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error('Error deleting announcement:', error);
      toast.error('Error deleting announcement.');
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog 
      open={props.status} 
      onClose={handleClose} 
      fullWidth 
      maxWidth="sm"
      PaperProps={{
        sx: {
          borderRadius: '8px',
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
        }
      }}
    >
      <DialogTitle sx={{ bgcolor: '#f8f9fa', padding: '16px 24px', borderBottom: '1px solid #e0e0e0' }}>
        <Typography variant="h6" component="div" sx={{ fontWeight: 600, color: 'error.main' }}>
          Delete Announcement
        </Typography>
        <IconButton 
          onClick={handleClose} 
          aria-label="close"
          sx={{ 
            position: 'absolute',
            right: 12,
            top: 12,
            color: 'grey.500'
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: '24px' }}>
        <Stack spacing={3}>
          <br/>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <WarningIcon color="warning" sx={{ mr: 1, fontSize: 28 }} />
            <Typography variant="body1">
              Are you sure you want to delete this announcement? This action cannot be undone.
            </Typography>
          </Box>
          
          <Paper variant="outlined" sx={{ p: 2, bgcolor: '#f8f9fa' }}>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                Subject: <Typography component="span" variant="body2">{announcementInfo?.subject}</Typography>
              </Typography>
              <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                Hyperlink: <Typography component="span" variant="body2" sx={{ wordBreak: 'break-all' }}>{announcementInfo?.hyperlink || 'None'}</Typography>
              </Typography>
              <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                Message: 
              </Typography>
              <Typography variant="body2" sx={{ pl: 1 }}>
                {announcementInfo?.subtitle}
              </Typography>
            </Stack>
          </Paper>
          
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={handleClose}
              sx={{ mr: 2 }}
              variant="outlined"
            >
              Cancel
            </Button>
            {!isSubmitted ? (
              <Button
                color="error"
                variant="contained"
                onClick={handleDelete}
                disabled={isSubmitting || isSubmitted}
                startIcon={isSubmitting ? <CircularProgress size={20} color="inherit" /> : null}
                sx={{ 
                  minWidth: '120px',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                  textTransform: 'none'
                }}
              >
                {isSubmitting ? "Deleting..." : "Delete"}
              </Button>
            ) : (
              <Typography variant="subtitle1" color="success.main" sx={{ fontWeight: 600 }}>
                Deleted!
              </Typography>
            )}
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteAnnouncementModal;

