import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './StripePage.css';

function StripeSuccessPage() {
  const location = useLocation();

  useEffect(() => {
    // Extract account details from URL if needed
    const searchParams = new URLSearchParams(location.search);
    const accountId = searchParams.get('accountId');
    
    // You might want to do something with the accountId here
    console.log('Connected Stripe Account ID:', accountId);
  }, [location]);

  return (
    <main>
      <div className="message">
        <h2>? Connection Successful!</h2>
        <p>Your Stripe account has been connected successfully.</p>
        <p>You can close this tab and return to the event creation.</p>
        <button 
          className="button" 
          onClick={() => window.close()}
        >
          Close This Tab
        </button>
      </div>
    </main>
  );
}

export default StripeSuccessPage;

