import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const DraftEventCard = ({ draft, cardWidth, cardHeight, username, onDraftDelete, onDraftContinue }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState('idle');
  const navigate = useNavigate();
  
  // Extract draft information
  const draftData = draft.draftData || {};
  const lastUpdated = new Date(draft.lastUpdated || draft.createdAt || Date.now());
  const title = draftData.basicInfo?.title || "Untitled Draft";
  const activeStep = draftData.activeStep || 0;
  
  // Format last updated date
  const formatDate = (date) => {
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };
  
  // Get progress percentage
  const getProgressPercentage = () => {
    // Assuming 8 steps total
    return Math.round((activeStep / 7) * 100);
  };
  
  const handleDeleteClick = (e) => {
    e.stopPropagation();
    setDeleteDialogOpen(true);
  };
  
  const handleConfirmDelete = async () => {
    setDeleteStatus('deleting');
    
    const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
      ? process.env.REACT_APP_SERVER_URL_PRODUCTION
      : process.env.REACT_APP_SERVER_URL_STAGING;

    try {
      const response = await fetch(`${apiUrl}/api/delete_draft_event`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          username,
          draftId: draft.draftId
        })
      });

      if (response.ok) {
        setDeleteStatus('deleted');
        setTimeout(() => {
          // Reload the page to ensure clean state
          window.location.reload();
        }, 1000);
      } else {
        setDeleteStatus('error');
        console.error('Failed to delete draft event');
      }
    } catch (error) {
      setDeleteStatus('error');
      console.error('Error deleting draft event:', error);
    }
  };
  
  const handleContinue = (e) => {
    e.stopPropagation();
    if (onDraftContinue) onDraftContinue(draft);
  };
  
  const handleCloseDialog = () => {
    if (deleteStatus !== 'deleting') {
      setDeleteDialogOpen(false);
      // Reset the delete status when closing the dialog
      setTimeout(() => setDeleteStatus('idle'), 300);
    }
  };
  
  const getDeleteButtonText = () => {
    switch (deleteStatus) {
      case 'deleting':
        return 'Deleting...';
      case 'deleted':
        return 'Deleted!';
      case 'error':
        return 'Error - Try Again';
      default:
        return 'Delete';
    }
  };
  
  return (
    <div
      style={{
        width: cardWidth,
        height: cardHeight,
        position: 'relative',
        border: '2px dashed #ff9800', // Orange dashed border to indicate draft
        borderRadius: '8px',
        padding: '16px',
        transition: 'transform 0.3s ease',
        transform: isHovered ? 'scale(1.05)' : 'scale(1)',
        backgroundColor: '#fff8e1', // Light amber background
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Overlay on hover */}
      {isHovered && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '16px',
            borderRadius: '6px',
            zIndex: 2
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleContinue}
            style={{ width: '80%' }}
          >
            Continue Setup
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleDeleteClick}
            style={{ width: '80%' }}
          >
            Delete Draft
          </Button>
        </div>
      )}

      {/* Draft content */}
      <div style={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%'
      }}>
        {/* Draft indicator */}
        <div style={{
          backgroundColor: '#ff9800',
          color: 'white',
          padding: '4px 8px',
          borderRadius: '4px',
          fontWeight: 'bold',
          marginBottom: '12px'
        }}>
          DRAFT
        </div>
        
        <h3 style={{ margin: '8px 0', color: '#333', fontSize: '18px' }}>{title}</h3>
        
        {/* Progress information */}
        <div style={{ margin: '12px 0', width: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '14px' }}>
            <span>Progress: {getProgressPercentage()}%</span>
            <span>Step {activeStep + 1}/8</span>
          </div>
          <div style={{
            height: '8px',
            backgroundColor: '#e0e0e0',
            borderRadius: '4px',
            marginTop: '4px',
            overflow: 'hidden'
          }}>
            <div style={{
              height: '100%',
              width: `${getProgressPercentage()}%`,
              backgroundColor: '#4CAF50',
              borderRadius: '4px'
            }}></div>
          </div>
        </div>
        
        <div style={{ marginTop: 'auto', fontSize: '12px', color: '#666', alignSelf: 'flex-start' }}>
          Last updated: {formatDate(lastUpdated)}
        </div>
      </div>

      {/* Delete confirmation dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Delete Draft?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this draft? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleCloseDialog} 
            disabled={deleteStatus === 'deleting'}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleConfirmDelete} 
            color={deleteStatus === 'deleted' ? 'success' : deleteStatus === 'error' ? 'error' : 'error'} 
            disabled={deleteStatus === 'deleting' || deleteStatus === 'deleted'}
            autoFocus
          >
            {getDeleteButtonText()}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DraftEventCard;

