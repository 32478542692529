import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import AnimatedPage from './AnimatedPage';
import Footer from '../components/footer/Footer';
import './RegisterPage.css';

function PotSuccessPage() {
  const { username, eventId } = useParams();
  const [searchParams] = useSearchParams();
  const [eventSettings, setEventSettings] = useState(null);
  const [loadingEventSettings, setLoadingEventSettings] = useState(true);
  
  // Get team name from URL params
  const teamName = searchParams.get('team');
  
  useEffect(() => {
    const fetchEventSettings = async () => {
      const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
        ? process.env.REACT_APP_SERVER_URL_PRODUCTION
        : process.env.REACT_APP_SERVER_URL_STAGING;
      
      try {
        const response = await fetch(`${apiUrl}/api/load_event_profile`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username, eventId }),
        });
        const data = await response.json();
        if (data.success) {
          setEventSettings(data.settings);
          console.log('eventSettings:', data.settings)
        } else {
          console.error('Error fetching event settings:', data.message);
          toast.error('Failed to load event settings');
        }
      } catch (error) {
        console.error('Error fetching event settings:', error);
        toast.error('Failed to load event settings');
      } finally {
        setLoadingEventSettings(false);
      }
    };
    
    fetchEventSettings();
  }, [username, eventId]);
  
  if (loadingEventSettings) {
    return <div>Loading...</div>;
  }
  
  return (
    <AnimatedPage>
      <main>
        {/* Banner */}
        <section 
          className="section-banner"
          style={{
            backgroundColor: eventSettings.basicInfo.backgroundColor,
            color: eventSettings.basicInfo.headlineTextColor
          }}
        >
          <h1>Pot Registration Complete</h1>
        </section>
        
        {/* Success Message Section */}
        <section className="section-register">
          <br/>
          <br/>
          
          <h2>
            {teamName ? `Your team (${teamName}) has` : 'You have'} successfully registered for pots!
          </h2>
          <br/>
          <br/>
          <h4>A confirmation email receipt will be sent to your registered team email shortly.</h4>
          
          <br/>
          <br/>
          
          <h4>
            You can view all your pot entries on the tournament's Pots page.
          </h4>
          
          <br/>
          <br/>
        </section>
        
        <Footer />
      </main>
    </AnimatedPage>
  );
}

export default PotSuccessPage;

