export const sizeConfig = {
  sidebar: {
    width: "300px"
  }
};

export const colorConfig = {
  sidebar: {
    bg: "#233044",
    color: "#eeeeee",
    hoverBg: "#1e293a",
    activeBg: "#1e253a"
  },
  topbar: {
    bg: '#ffffff',
    color: "#000"
  },
  // mainBg: colors.grey["100"],
  mainBg:'#ffffff',
  authenticateBg: 'grey',
};

export const availableFeatures = [
  { title: 'Website (works on any device)', category: 'basicEvent' },
  { title: 'Realtime leaderboard, custom scoring', category: 'basicEvent' },
  { title: 'Unlimited teams, catches, photos', category: 'basicEvent' },
  { title: 'All fees deposit directly to you (via Stripe)', category: 'revenueFeature' },  // Special category for any revenue feature
  { title: 'Online team registration', category: 'onlineTeamRegistration' },
  { title: 'Online sponsor registration', category: 'onlineSponsorRegistration' },
  { title: 'Online fish pot registration', category: 'fishPots' },
];

// Event cost for checkout step
export const eventCost = 0;  // Free for revenue-share model, would be different for standard

export const teamsConfig = [
  {
    field: "teamId",
    headerName: "Team ID",
    type: "string",
    isEditable: false,
    isRequired: true,
    isVisible: false,
    flex: 3,
    width: 300,
    isId: true,
    isCurrency: false,
    isPhoneNumber: false,
    isBoolean: false,
    isEmail: false,
    isDateTime: false,
    isImage: false,
    isAddOnCharge: false,
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "Team name",
    headerName: "Name",
    type: "string",
    isEditable: true,
    isRequired: true,
    isVisible: true,
    flex: 3,
    width: 300,
    isId: false,
    isCurrency: false,
    isPhoneNumber: false,
    isBoolean: false,
    isEmail: false,
    isDateTime: false,
    isImage: false,
    isAddOnCharge: false,
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "Team email",
    headerName: "Email",
    type: "string",
    isEditable: true,
    isRequired: true,
    isVisible: true,
    flex: 4,
    width: 400,
    isId: false,
    isPhoneNumber: false,
    isBoolean: false,
    isCurrency: false,
    isEmail: true,
    isDateTime: false,
    isImage: false,
    isAddOnCharge: false,
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "Team phone",
    headerName: "Phone",
    type: "string",
    isEditable: true,
    isRequired: true,
    isVisible: true,
    flex: 2,
    width: 200,
    isId: false,
    isPhoneNumber: true,
    isBoolean: false,
    isCurrency: false,
    isEmail: false,
    isDateTime: false,
    isImage: false,
    isAddOnCharge: false,
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "Checked-In?",
    headerName: "Checked-In?",
    type: "Boolean",
    isEditable: true,
    isRequired: true,
    isVisible: true,
    flex: 2,
    width: 200,
    isId: false,
    isCurrency: false,
    isPhoneNumber: false,
    isBoolean: true,
    isEmail: false,
    isDateTime: false,
    isImage: false,
    isAddOnCharge: false,
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "Team photo",
    headerName: "Photo",
    type: "Image",
    isEditable: true,
    isRequired: false,
    isVisible: true,
    flex: 2,
    width: 200,
    isId: false,
    isCurrency: false,
    isPhoneNumber: false,
    isBoolean: false,
    isEmail: false,
    isDateTime: false,
    isImage: true,
    isAddOnCharge: false,
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
  },
];

export const FEATURE_OPTIONS = {
  NOT_NEEDED: 'notNeeded',
  CUSTOMER_PAYS: 'customerPays',
  I_PAY: 'iPay'
};

export const initialFormState = {

  draftId: null,
  packageType: 'revenue-share',
  features: {
    basicEvent: FEATURE_OPTIONS.CUSTOMER_PAYS,
    onlineTeamRegistration: FEATURE_OPTIONS.CUSTOMER_PAYS, // Only this one is enabled by default
    onlineSponsorRegistration: FEATURE_OPTIONS.NOT_NEEDED,
    fishPots: FEATURE_OPTIONS.NOT_NEEDED
  },
  pageVisibility: {
    leaderboard: true,
    registration: true,
    sponsors: true,
    pots: true
  },
  businessName: '',
  taxId: '',
  stripeAccountId: null,
  stripeAccountStatus: null,
  stripeConnectedAt: null,
  basicInfo: {
    title: 'Default Title',
    startDate: null,
    endDate: null,
    subheadline: 'This is your default event subheadline',
    logo: null,
    logoName: null,
    backgroundColor: '#87CEEB',
    headlineTextColor: '#ffffff',
    showCountdown: true,
    // New fields for rules
    rulesType: 'none',
    rulesContent: '',
    rulesLink: '',
    rulesFile: null,
    rulesFileName: null,
    // New fields for schedule
    scheduleType: 'none',
    scheduleContent: '',
    scheduleLink: '',
    scheduleFile: null,
    scheduleFileName: null,
    // New fields for website
    websiteType: 'none',
    websiteLink: ''
  },
  registration: {
    includeEarlyBird: false,
    earlyBirdDate: null,
    earlyBirdPrice: '',
    registrationDate: null,
    registrationPrice: '',
    allowTeamPhoto: false,
    registrationMessage: 'Example disclaimer: if the weather is bad, we may need to delay or reschedule this event',
    defaultTeamRegistrationFields: teamsConfig.filter(field => field.isVisible && field.field !== "teamId"),
    userDefinedTeamRegistrationFields: [],
    // The following line should be updated to true since divisions are now always included
    includeDivisions: true,
    divisions: [{
      name: "Open (default)",
      registrationPrice: '',
      earlyBirdPrice: ''
    }],
  },
  sponsors: {
    tiers: [],
    allowLogoUpload: true,
    allowCustomAmount: false
  },
  species: {
    units: 'pounds, tenths of inches',
    allowCatchPhotoUpload: false,
    speciesList: []
  },
  categories: {
    categoryList: []
  },
  pots: {
    tournamentPotCut: 10,
    potList: []
  }
};

export const catchesConfig = [
  {
    field: "catchId",
    headerName: "Catch ID",
    type: "string",
    isEditable: false,
    isRequired: true,
    isVisible: false,
    flex: 3,
    width: 300,
    isId: true,
    isCurrency: false,
    isPhoneNumber: false,
    isBoolean: false,
    isEmail: false,
    isDateTime: false,
    isImage: false,
    isAddOnCharge: false,
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "teamId",
    headerName: "Team ID",
    type: "string",
    isEditable: false,
    isRequired: true,
    isVisible: false,
    flex: 3,
    width: 300,
    isId: true,
    isCurrency: false,
    isPhoneNumber: false,
    isBoolean: false,
    isEmail: false,
    isDateTime: false,
    isImage: false,
    isAddOnCharge: false,
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "species",
    headerName: "Species",
    type: "string",
    isEditable: false,
    isRequired: true,
    isVisible: true,
    flex: 2,
    width: 200,
    isId: false,
    isPhoneNumber: false,
    isBoolean: false,
    isCurrency: false,
    isEmail: false,
    isDateTime: false,
    isImage: false,
    isAddOnCharge: false,
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
  },
  // {
  //   field: "speciesType",
  //   headerName: "Type",
  //   type: "string",
  //   isEditable: false,
  //   isRequired: true,
  //   isVisible: true,
  //   flex: 2,
  //   width: 200,
  //   isId: false,
  //   isPhoneNumber: false,
  //   isBoolean: false,
  //   isCurrency: false,
  //   isEmail: false,
  //   isDateTime: false,
  //   isImage: false,
  //   isAddOnCharge: false,
  //   headerClassName: "super-app-theme--header",
  //   headerAlign: "center",
  //   align: "center",
  // },
  {
    field: "teamName",
    headerName: "Team",
    type: "string",
    isEditable: false,
    isRequired: true,
    isVisible: true,
    flex: 3,
    width: 300,
    isId: false,
    isCurrency: false,
    isPhoneNumber: false,
    isBoolean: false,
    isEmail: false,
    isDateTime: false,
    isImage: false,
    isAddOnCharge: false,
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "weight",
    headerName: "Weight",
    type: "number",
    isEditable: true,
    isRequired: false,
    isVisible: true,
    flex: 2,
    width: 200,
    isId: false,
    isCurrency: false,
    isPhoneNumber: false,
    isBoolean: false,
    isEmail: false,
    isDateTime: false,
    isImage: false,
    isAddOnCharge: false,
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "length",
    headerName: "Length",
    type: "number",
    isEditable: true,
    isRequired: false,
    isVisible: true,
    flex: 2,
    width: 200,
    isId: false,
    isCurrency: false,
    isPhoneNumber: false,
    isBoolean: false,
    isEmail: false,
    isDateTime: false,
    isImage: false,
    isAddOnCharge: false,
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "girth",
    headerName: "Girth",
    type: "number",
    isEditable: true,
    isRequired: false,
    isVisible: true,
    flex: 2,
    width: 200,
    isId: false,
    isCurrency: false,
    isPhoneNumber: false,
    isBoolean: false,
    isEmail: false,
    isDateTime: false,
    isImage: false,
    isAddOnCharge: false,
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "points",
    headerName: "Points",
    type: "number",
    isEditable: false,
    isRequired: false,
    isVisible: true,
    flex: 2,
    width: 200,
    isId: false,
    isCurrency: false,
    isPhoneNumber: false,
    isBoolean: false,
    isEmail: false,
    isDateTime: false,
    isImage: false,
    isAddOnCharge: false,
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "dateTime",
    headerName: "Time of Catch",
    type: "dateTime",
    isEditable: true,
    isRequired: true,
    isVisible: true,
    flex: 3,
    width: 300,
    isId: false,
    isCurrency: false,
    isPhoneNumber: false,
    isBoolean: false,
    isEmail: false,
    isDateTime: true,
    isImage: false,
    isAddOnCharge: false,
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "Catch photo",
    headerName: "Photo",
    type: "Image",
    isEditable: true,
    isRequired: false,
    isVisible: true,
    flex: 2,
    width: 200,
    isId: false,
    isCurrency: false,
    isPhoneNumber: false,
    isBoolean: false,
    isEmail: false,
    isDateTime: false,
    isImage: true,
    isAddOnCharge: false,
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
  },
];

export const potsConfig = [
  {
    field: 'teamName',
    headerName: 'Team Name',
    type: 'string',
    isEditable: false,
    isRequired: true,
    isVisible: true,
    flex: 2
  },
  {
    field: 'potQuantity',
    headerName: 'Number of Pots',
    type: 'number',
    isEditable: false,
    isRequired: true,
    isVisible: true,
    flex: 1,
    // valueFormatter: (params) => params.value || 0
  },
  {
    field: 'totalFee',
    headerName: 'Total Cost',
    type: 'number',
    isEditable: false,
    isRequired: true,
    isVisible: true,
    flex: 1,
    isCurrency: true
  },
  {
    field: 'registrationTimestamp',
    headerName: 'Registration Time',
    type: 'string',
    isEditable: false,
    isRequired: true,
    isVisible: true,
    flex: 2,
    isDateTime: true
  }
];

export const announcementsConfig = [
  {
    field: "announcementId",
    headerName: "ID",
    type: "string",
    isEditable: false,
    isRequired: true,
    isVisible: false,
    flex: 1,
    width: 200,
    isId: true,
    isCurrency: false,
    isPhoneNumber: false,
    isBoolean: false,
    isEmail: false,
    isDateTime: false,
    isImage: false,
    isAddOnCharge: false,
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
  },
  {
    field: "subject",
    headerName: "Subject",
    type: "string",
    isEditable: false,
    isRequired: true,
    isVisible: true,
    flex: 1,
    width: 200,
    isId: true,
    isCurrency: false,
    isPhoneNumber: false,
    isBoolean: false,
    isEmail: false,
    isDateTime: false,
    isImage: false,
    isAddOnCharge: false,
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
  },    
  {
    field: "subtitle",
    headerName: "Message",
    type: "string",
    isEditable: false,
    isRequired: true,
    isVisible: true,
    flex: 1,
    width: 200,
    isId: true,
    isCurrency: false,
    isPhoneNumber: false,
    isBoolean: false,
    isEmail: false,
    isDateTime: false,
    isImage: false,
    isAddOnCharge: false,
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
  },    
  {
    field: "hyperlink",
    headerName: "Hyperlink",
    type: "string",
    isEditable: false,
    isRequired: true,
    isVisible: true,
    flex: 1,
    width: 200,
    isId: true,
    isCurrency: false,
    isPhoneNumber: false,
    isBoolean: false,
    isEmail: false,
    isDateTime: false,
    isImage: false,
    isAddOnCharge: false,
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
  },    
  {
    field: "timestamp",
    headerName: "Time Created",
    type: "dateTime",
    isEditable: false,
    isRequired: true,
    isVisible: true,
    flex: 1,
    width: 200,
    isId: false,
    isCurrency: false,
    isPhoneNumber: false,
    isBoolean: false,
    isEmail: false,
    isDateTime: true,
    isImage: false,
    isAddOnCharge: false,
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
  },    
];

export const catchAndReleaseDesktopColumns = [
  { field: 'place', headerName: 'Place', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false },
  { field: 'team', headerName: 'Team', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false },
  { field: 'points', headerName: 'Points', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false },
  { field: 'lastCatch', headerName: 'Time of Last Catch', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: true }
];

export const catchAndReleaseMobileColumns = [
  { field: 'place', headerName: 'Place', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false },
  { field: 'team', headerName: 'Team', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false },
  { field: 'points', headerName: 'Points', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false },
  { field: 'lastCatch', headerName: 'Last Catch', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: true }
];

export const weightOnlyDesktopColumns = [
  { field: 'place', headerName: 'Place', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'team', headerName: 'Team', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'weight', headerName: 'Points (Weight)', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
];

export const weightOnlyMobileColumns = [
  { field: 'place', headerName: 'Place', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'team', headerName: 'Team', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'weight', headerName: 'Points (Weight)', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
];

export const weightAndLengthDesktopColumns = [
  { field: 'place', headerName: 'Place', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'team', headerName: 'Team', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'weight', headerName: 'Points (Weight)', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'length', headerName: 'Length', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
];

export const weightAndLengthMobileColumns = [
  { field: 'place', headerName: 'Place', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'team', headerName: 'Team', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'weight', headerName: 'Points (Weight)', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'length', headerName: 'Length', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
];

export const weightAndLengthAndGirthDesktopColumns = [
  { field: 'place', headerName: 'Place', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'team', headerName: 'Team', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'weight', headerName: 'Points (Weight)', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'length', headerName: 'Length', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'girth', headerName: 'Girth', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
];

export const weightAndLengthAndGirthMobileColumns = [
  { field: 'place', headerName: 'Place', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center'},
  { field: 'team', headerName: 'Team', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center'},
  { field: 'weight', headerName: 'Points (Weight)', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'length', headerName: 'Length', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'girth', headerName: 'Girth', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
];

export const lengthOnlyDesktopColumns = [
  { field: 'place', headerName: 'Place', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'team', headerName: 'Team', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'length', headerName: 'Length', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
];

export const lengthOnlyMobileColumns = [
  { field: 'place', headerName: 'Place', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'team', headerName: 'Team', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'length', headerName: 'Length', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
];

export const lengthAndWeightDesktopColumns = [
  { field: 'place', headerName: 'Place', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'team', headerName: 'Team', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'length', headerName: 'Length', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'weight', headerName: 'Points (Weight)', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
];

export const lengthAndWeightMobileColumns = [
  { field: 'place', headerName: 'Place', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'team', headerName: 'Team', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'length', headerName: 'Length', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'weight', headerName: 'Points (Weight)', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
];

export const lengthAndGirthDesktopColumns = [
  { field: 'place', headerName: 'Place', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'team', headerName: 'Team', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'length', headerName: 'Length', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'girth', headerName: 'Girth', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
];

export const lengthAndGirthMobileColumns = [
  { field: 'place', headerName: 'Place', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'team', headerName: 'Team', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'length', headerName: 'Length', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
  { field: 'girth', headerName: 'Girth', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false},
];

export const customPointsDesktopColumns = [
  { field: 'place', headerName: 'Place', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false },
  { field: 'team', headerName: 'Team', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false },
  { field: 'points', headerName: 'Points', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false }
];

export const customPointsMobileColumns = [
  { field: 'place', headerName: 'Place', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false },
  { field: 'team', headerName: 'Team', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false },
  { field: 'points', headerName: 'Points', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false }
];

export const customFastestDesktopColumns = [
  { field: 'place', headerName: 'Place', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false },
  { field: 'team', headerName: 'Team', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false },
  { field: 'completionTime', headerName: 'Time of Last Catch', flex: 1, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: true }
];

export const customFastestMobileColumns = [
  { field: 'place', headerName: 'Place', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false },
  { field: 'team', headerName: 'Team', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: false },
  { field: 'completionTime', headerName: 'Time of Last Catch', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center', isDateTime: true }
];

