import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './StripePage.css';

function StripeFailurePage() {
  const location = useLocation();

  const message = new URLSearchParams(location.search).get('message') || 
    'We couldn\'t connect your Stripe account.';

  useEffect(() => {
    // Log the error message for debugging
    console.error('Stripe Connection Error:', message);
  }, [message]);

  return (
    <main>
      <div className="message">
        <h2>? Connection Failed</h2>
        <p>{message}</p>
        <p>You can close this tab and try again from the event creation page.</p>
        <button 
          className="button" 
          onClick={() => window.close()}
        >
          Close This Tab
        </button>
      </div>
    </main>
  );
}

export default StripeFailurePage;

