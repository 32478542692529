import React, { useState } from 'react';
import { 
  Grid, 
  Box, 
  Paper, 
  TextField, 
  Button, 
  Typography, 
  Link, 
  CircularProgress, 
  FormControlLabel, 
  Checkbox,
  Container,
  Divider
} from '@mui/material';
import { LockOutlined, MailOutline, PhoneOutlined, BusinessOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import ToggleSliderButton from '../buttons/ToggleSliderButton';
import AnimatedPage from '../../pages/AnimatedPage';
import { sendPasswordResetEmail, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { toast } from 'react-toastify';
import { auth } from '../../firebase';
import { colorConfig } from '../../config/config';

const Authenticate = () => {
  const navigate = useNavigate();

  // State management
  const [authenticationChoice, setAuthenticationChoice] = useState('Login');
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [organization, setOrganization] = useState("");
  const [phone, setPhone] = useState("");
  const [resetPasswordMode, setResetPasswordMode] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [consentChecked, setConsentChecked] = useState(true);

  // Helpers
  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const validatePhoneNumber = (phone) => {
    return /^\(?([0-9]{3})\)?[-.?]?([0-9]{3})[-.?]?([0-9]{4})$/.test(phone);
  };

  const convertToCamelCase = (string) => string.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
    index === 0 ? word.toLowerCase() : word.toUpperCase()
  ).replace(/\s+/g, '');

  // Authentication methods
  const handleCreateAccount = async () => {
    if (!consentChecked) {
      toast.error("Please accept the data usage terms to create an account.");
      return;
    }
    if (!organization) {
      toast.error("Please enter an organization / event name.");
      return;
    }
    if (!validateEmail(email)) {
      toast.error("Please enter a valid email.");
      return;
    }
    if (phone && !validatePhoneNumber(phone)) {
      toast.error("Please enter a valid phone number.");
      return;
    }
    if (!password || password.length < 6) {
      toast.error("Password must be at least 6 characters.");
      return;
    }
  
    setIsSubmitting(true);
  
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        const username = convertToCamelCase(organization);
        const userData = {
          userId: user.uid,
          username,
          email: user.email,
          dateProfileCreated: new Date().toISOString(),
          phone: phone || '',
          organization,
        };

        // Call the new API endpoint to create the user profile in Firestore
        fetch('/api/create_new_user_profile', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(userData)
        })
          .then(response => response.json())
          .then(data => {
            if (data.success) {
              toast.success("Account created successfully! Logging you in...", { autoClose: 2000 });
              window.localStorage.setItem('user', JSON.stringify(user));
              window.localStorage.setItem('username', username);
              setTimeout(() => navigate(`/${username}/dashboard`), 2000);
            } else {
              toast.error("Failed to create profile in Firestore: " + data.message);
            }
          })
          .catch(error => {
            toast.error("Error creating profile in Firestore: " + error.message);
          });
      })
      .catch((error) => {
        toast.error("Error creating account: " + error.message);
      });
  };

  const handleLogin = async () => {
    if (!validateEmail(email)) {
      toast.error("Please enter a valid email.");
      return;
    }
    if (!password) {
      toast.error("Please enter your password.");
      return;
    }
  
    setIsSubmitting(true);
  
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      
      const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
        ? process.env.REACT_APP_SERVER_URL_PRODUCTION
        : process.env.REACT_APP_SERVER_URL_STAGING;
  
      // Load user profile using getUserProfileData endpoint
      const profileResponse = await fetch(`${apiUrl}/api/get_user_profile_data`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ userId: user.uid })
      });
  
      const profileData = await profileResponse.json();
      
      if (profileData.success && profileData.userData && profileData.userData.username) {
        const { username } = profileData.userData;
        
        toast.success("Login successful! Taking you to your dashboard.", { autoClose: 2000 });
        
        // Store user data
        window.localStorage.setItem('user', JSON.stringify(user));
        window.localStorage.setItem('username', username);
        
        // Navigate to dashboard using the username
        setTimeout(() => navigate(`/${username}/dashboard`), 2000);
      } else {
        toast.error("Failed to load user profile: " + (profileData.message || 'Profile data not found'));
        setIsSubmitting(false);
      }
    } catch (error) {
      if (error.code === 'auth/wrong-password') {
        toast.error("Incorrect password. Please try again.");
      } else if (error.code === 'auth/user-not-found') {
        toast.error("Email not found. Please sign up first.");
      } else {
        toast.error("Error logging in: " + error.message);
      }
      setIsSubmitting(false);
    }
  };
  
  const handleResetPassword = async () => {
    if (!validateEmail(email)) {
      toast.error("Please enter a valid email.");
      return;
    }

    setIsSubmitting(true);

    try {
      await sendPasswordResetEmail(auth, email);
      toast.success("Password reset email sent! Check your inbox.", {
        onClose: () => setIsSubmitting(false),
      });
    } catch (error) {
      if (error.code === 'auth/user-not-found') {
        toast.error("No account found for this email. Please sign up first.", { onClose: () => setIsSubmitting(false) });
      } else {
        toast.error("Error sending password reset email: " + error.message, { onClose: () => setIsSubmitting(false) });
      }
    }
  };
  
  return (
    <AnimatedPage>
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: `linear-gradient(135deg, ${colorConfig.authenticateBg} 0%, #f5f5f5 100%)`,
          py: 4,
        }}
      >
        <Container maxWidth="md">
          <Grid container spacing={2} alignItems="stretch">
            {/* Left Side - Info Panel */}
            <Grid item xs={12} md={5} sx={{ display: { xs: 'none', md: 'block' } }}>
              <Paper
                elevation={8}
                sx={{
                  height: '100%',
                  p: 4,
                  background: colorConfig.sidebar.bg,
                  color: 'white',
                  borderRadius: '12px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="h4" component="h1" fontWeight="bold" gutterBottom>
                  Custom Tournament Solutions
                </Typography>
                
                <Box sx={{ my: 4 }}>
                  <Typography variant="h6" gutterBottom>
                    <u>Getting Started</u>
                  </Typography>
                  
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="body1" paragraph>
                      <strong>Step 1.</strong> Create (or login) to your free user account.
                    </Typography>
                    
                    <Typography variant="body1" paragraph>
                      <strong>Step 2.</strong> Use the "Create Event" button to build your first event.
                    </Typography>
                    
                    <Typography variant="body1" paragraph>
                      <strong>Step 3.</strong> Manage or create additional events anytime!
                    </Typography>
                  </Box>
                </Box>
                
                <Box sx={{ mt: 'auto' }}>
                  <Typography variant="body2">
                    Need help? Email us at:
                  </Typography>
                  <Typography variant="body2" fontWeight="bold">
                    support@customtournamentsolutions.com
                  </Typography>
                </Box>
              </Paper>
            </Grid>

            {/* Right Side - Authentication Form */}
            <Grid item xs={12} md={7}>
              <Paper
                elevation={8}
                sx={{
                  p: { xs: 3, sm: 4 },
                  borderRadius: '12px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {/* Mobile-only header */}
                <Box sx={{ display: { xs: 'block', md: 'none' }, mb: 3, textAlign: 'center' }}>
                  <Typography variant="h5" component="h1" fontWeight="bold" gutterBottom>
                    Custom Tournament Solutions
                  </Typography>
                </Box>

                {/* Lock Icon in Circle */}
                <Box 
                  sx={{ 
                    width: 56, 
                    height: 56, 
                    borderRadius: '50%', 
                    backgroundColor: colorConfig.sidebar.bg,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mx: 'auto',
                    mb: 2
                  }}
                >
                  <LockOutlined sx={{ color: 'white' }} />
                </Box>

                <Typography variant="h5" component="h2" textAlign="center" fontWeight="bold" gutterBottom>
                  {resetPasswordMode ? 'Reset Password' : authenticationChoice === 'Login' ? 'Welcome Back' : 'Create Account'}
                </Typography>
                
                {/* Mobile-only instructions */}
                <Box sx={{ display: { xs: 'block', md: 'none' }, mb: 3 }}>
                  <Typography variant="body2" sx={{ color: 'text.secondary', textAlign: 'center' }}>
                    {authenticationChoice === 'Login' 
                      ? 'Sign in to access your tournament dashboard' 
                      : 'Create your free account to get started'}
                  </Typography>
                </Box>

                {!resetPasswordMode && (
                  <Box sx={{ my: 2 }}>
                    <ToggleSliderButton 
                      choice={authenticationChoice} 
                      choiceList={["Login", "Create Account"]} 
                      setAlignment={setAuthenticationChoice} 
                    />
                  </Box>
                )}

                {/* Auth Forms */}
                <Box component="form" sx={{ mt: 2 }}>
                  {resetPasswordMode ? (
                    <>
                      <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary', textAlign: 'center' }}>
                        Enter your email to receive a password reset link.
                      </Typography>
                      
                      <TextField
                        variant="outlined"
                        fullWidth
                        label="Email"
                        required
                        onChange={(e) => setEmail(e.target.value)}
                        disabled={isSubmitting || isSubmitted}
                        InputProps={{
                          startAdornment: <MailOutline color="action" sx={{ mr: 1 }} />,
                        }}
                        margin="normal"
                      />
                      
                      <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        onClick={handleResetPassword}
                        disabled={isSubmitting || isSubmitted}
                        sx={{
                          mt: 3,
                          mb: 2,
                          py: 1.5,
                          backgroundColor: colorConfig.sidebar.bg,
                          '&:hover': {
                            backgroundColor: '#00639e',
                          },
                          borderRadius: '8px',
                          textTransform: 'none',
                          fontSize: '1rem',
                        }}
                      >
                        {isSubmitting ? <CircularProgress size={24} /> : "Send Reset Link"}
                      </Button>
                      
                      <Box textAlign="center">
                        <Link 
                          component="button" 
                          variant="body2" 
                          onClick={() => setResetPasswordMode(false)}
                          sx={{ color: colorConfig.sidebar.bg }}
                        >
                          Back to {authenticationChoice}
                        </Link>
                      </Box>
                    </>
                  ) : (
                    <>
                      {authenticationChoice === "Login" && (
                        <>
                          <TextField
                            variant="outlined"
                            fullWidth
                            label="Email"
                            required
                            onChange={(e) => setEmail(e.target.value)}
                            disabled={isSubmitting || isSubmitted}
                            InputProps={{
                              startAdornment: <MailOutline color="action" sx={{ mr: 1 }} />,
                            }}
                            margin="normal"
                          />
                          
                          <TextField
                            variant="outlined"
                            fullWidth
                            label="Password"
                            type="password"
                            required
                            onChange={(e) => setPassword(e.target.value)}
                            disabled={isSubmitting || isSubmitted}
                            InputProps={{
                              startAdornment: <LockOutlined color="action" sx={{ mr: 1 }} />,
                            }}
                            margin="normal"
                          />
                          
                          <Box sx={{ textAlign: 'right', mt: 1 }}>
                            <Link
                              component="button"
                              variant="body2"
                              onClick={() => setResetPasswordMode(true)}
                              sx={{ color: colorConfig.sidebar.bg }}
                            >
                              Forgot Password?
                            </Link>
                          </Box>
                          
                          <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            onClick={handleLogin}
                            disabled={isSubmitting || isSubmitted}
                            sx={{
                              mt: 3,
                              mb: 2,
                              py: 1.5,
                              backgroundColor: colorConfig.sidebar.bg,
                              '&:hover': {
                                backgroundColor: '#00639e',
                              },
                              borderRadius: '8px',
                              textTransform: 'none',
                              fontSize: '1rem',
                            }}
                          >
                            {isSubmitting ? <CircularProgress size={24} /> : "Sign In"}
                          </Button>
                        </>
                      )}

                      {authenticationChoice === "Create Account" && (
                        <>
                          <TextField
                            variant="outlined"
                            fullWidth
                            label="Organization / Event Name"
                            required
                            onChange={(e) => setOrganization(e.target.value)}
                            disabled={isSubmitting || isSubmitted}
                            InputProps={{
                              startAdornment: <BusinessOutlined color="action" sx={{ mr: 1 }} />,
                            }}
                            margin="normal"
                          />
                          
                          <TextField
                            variant="outlined"
                            fullWidth
                            label="Phone (Optional)"
                            onChange={(e) => setPhone(e.target.value)}
                            disabled={isSubmitting || isSubmitted}
                            InputProps={{
                              startAdornment: <PhoneOutlined color="action" sx={{ mr: 1 }} />,
                            }}
                            margin="normal"
                          />
                          
                          <TextField
                            variant="outlined"
                            fullWidth
                            label="Email"
                            required
                            onChange={(e) => setEmail(e.target.value)}
                            disabled={isSubmitting || isSubmitted}
                            InputProps={{
                              startAdornment: <MailOutline color="action" sx={{ mr: 1 }} />,
                            }}
                            margin="normal"
                          />
                          
                          <TextField
                            variant="outlined"
                            fullWidth
                            label="Password (min. 6 chars)"
                            type="password"
                            required
                            onChange={(e) => setPassword(e.target.value)}
                            disabled={isSubmitting || isSubmitted}
                            InputProps={{
                              startAdornment: <LockOutlined color="action" sx={{ mr: 1 }} />,
                            }}
                            margin="normal"
                          />
                          
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={consentChecked}
                                onChange={(e) => setConsentChecked(e.target.checked)}
                                color="primary"
                                disabled={isSubmitting || isSubmitted}
                              />
                            }
                            label={
                              <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: '0.8rem' }}>
                                By creating an account I consent to the collection and use of my tournament data 
                                and contact information for service improvements and promotional purposes.
                              </Typography>
                            }
                            sx={{ mt: 1, alignItems: 'flex-start' }}
                          />
                          
                          <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            onClick={handleCreateAccount}
                            disabled={isSubmitting || isSubmitted || !consentChecked}
                            sx={{
                              mt: 3,
                              mb: 2,
                              py: 1.5,
                              backgroundColor: colorConfig.sidebar.bg,
                              '&:hover': {
                                backgroundColor: '#00639e',
                              },
                              opacity: (isSubmitting || isSubmitted || !consentChecked) ? 0.7 : 1,
                              borderRadius: '8px',
                              textTransform: 'none',
                              fontSize: '1rem',
                            }}
                          >
                            {isSubmitting ? <CircularProgress size={24} /> : "Create Account"}
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </Box>
                
                {/* Mobile-only instructions */}
                <Box sx={{ display: { xs: 'block', md: 'none' }, mt: 3 }}>
                  <Divider sx={{ mb: 3 }} />
                  <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 1 }}>
                    <u>Getting Started</u>
                  </Typography>
                  
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    <strong>Step 1.</strong> Create (or login) to your free user account.
                  </Typography>
                  
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    <strong>Step 2.</strong> Use the "Create Event" button to build your first event.
                  </Typography>
                  
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    <strong>Step 3.</strong> Manage or create additional events anytime!
                  </Typography>
                  
                  <Typography variant="body2" sx={{ mt: 2, textAlign: 'center' }}>
                    Need help? Email us at:
                    <br />
                    <strong>support@customtournamentsolutions.com</strong>
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </AnimatedPage>
  );
}

export default Authenticate;

