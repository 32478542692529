import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './StripePage.css';

function StripeRefreshPage() {
  const location = useLocation();
  const navigate = useNavigate();

  const message = new URLSearchParams(location.search).get('message') || 
    'Your Stripe connection needs to be refreshed.';

  useEffect(() => {
    // Log the refresh message for debugging
    console.warn('Stripe Connection Refresh:', message);

    // Optional: Auto-close after a few seconds
    const timer = setTimeout(() => {
      window.close();
    }, 5000);

    return () => clearTimeout(timer);
  }, [message]);

  return (
    <main>
      <div className="message">
        <h2>?? Connection Refresh Needed</h2>
        <p>{message}</p>
        <p>Please return to the event creation page and reconnect your Stripe account.</p>
        <button 
          className="button" 
          onClick={() => window.close()}
        >
          Close This Tab
        </button>
      </div>
    </main>
  );
}

export default StripeRefreshPage;

