import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle, 
  CircularProgress,
  Typography,
  Box
} from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DeletePotModal = (props) => {
  const { username, eventId } = useParams();
  const { deleteInfo, status, open, close, eventSettings } = props;
  
  const [isDeleting, setIsDeleting] = useState(false);
  
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);
  };
  
  const handleDelete = async () => {
    if (!deleteInfo || !deleteInfo.teamId) {
      toast.error('Missing team information');
      return;
    }
    
    setIsDeleting(true);
    
    try {
      const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
        ? process.env.REACT_APP_SERVER_URL_PRODUCTION
        : process.env.REACT_APP_SERVER_URL_STAGING;
      
      const response = await fetch(`${apiUrl}/api/admin_delete_event_pots`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          username,
          eventId,
          teamId: deleteInfo.teamId
        })
      });
      
      const data = await response.json();
      
      if (response.ok) {
        toast.success('Pot entry deleted successfully! Page will refresh...');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        throw new Error(data.error || 'Failed to delete pot entry');
      }
    } catch (error) {
      console.error('Error deleting pot entry:', error);
      toast.error('Failed to delete pot entry. Please try again.');
    } finally {
      setIsDeleting(false);
      handleClose();
    }
  };
  
  const handleClose = () => {
    close();
  };
  
  // Calculate the total fees from the selected pots
  const calculateTotalFees = () => {
    if (!deleteInfo || !deleteInfo.selectedPots) return 0;
    
    let total = 0;
    deleteInfo.selectedPots.forEach(pot => {
      pot.values.forEach(value => {
        total += parseFloat(value || 0);
      });
    });
    
    // Ensure total is a number and properly rounded
    return Math.round(total * 100) / 100;
  };

  return (
    <Dialog
      open={status}
      onClose={handleClose}
      aria-labelledby="delete-pot-dialog-title"
      aria-describedby="delete-pot-dialog-description"
    >
      <DialogTitle id="delete-pot-dialog-title">
        Confirm Deletion
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-pot-dialog-description">
          Are you sure you want to delete the pot entry for team <strong>{deleteInfo?.teamName}</strong>?
          This action cannot be undone and fees (if any were paid) will not be refunded.
        </DialogContentText>
        
        {deleteInfo && deleteInfo.selectedPots && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Current pot entries:
            </Typography>
            
            {deleteInfo.selectedPots.map((pot, index) => (
              <Box key={index} sx={{ ml: 2, mb: 1 }}>
                <Typography variant="body2">
                  <strong>{pot.categoryTitle}:</strong> {pot.values.map(value => formatCurrency(value)).join(', ')}
                </Typography>
              </Box>
            ))}
            
            <Typography variant="subtitle2" sx={{ mt: 1 }}>
              Total: {formatCurrency(calculateTotalFees())}
            </Typography>
          </Box>
        )}

      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button 
          onClick={handleDelete} 
          color="error" 
          variant="contained"
          disabled={isDeleting}
          startIcon={isDeleting ? <CircularProgress size={20} color="inherit" /> : null}
        >
          {isDeleting ? "Deleting..." : "Delete"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeletePotModal;