import React, { useState } from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  TextField, 
  Button, 
  Divider,
  Alert,
  CircularProgress
} from '@mui/material';
import AnimatedPage from './AnimatedPage';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SendIcon from '@mui/icons-material/Send';
import { toast } from 'react-toastify';

const SupportPage = () => {
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [replyEmail, setReplyEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  
  // Get user details from localStorage
  const user = JSON.parse(window.localStorage.getItem('user'));
  const username = window.localStorage.getItem('username');
  const userId = user?.uid || '';
  
  // Get profile data if available from localStorage
  const [profileData, setProfileData] = useState(null);
  
  React.useEffect(() => {
    const fetchProfileData = async () => {
      if (!userId) return;
      
      try {
        const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
          ? process.env.REACT_APP_SERVER_URL_PRODUCTION
          : process.env.REACT_APP_SERVER_URL_STAGING;

        const response = await fetch(`${apiUrl}/api/get_user_profile_data`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ userId }),
        });

        if (response.ok) {
          const data = await response.json();
          if (data.success && data.userData) {
            setProfileData(data.userData);
            // Initialize the email field with the user's email if available
            if (data.userData.email) {
              setReplyEmail(data.userData.email);
            }
          }
        }
      } catch (err) {
        console.error("Error fetching profile data:", err);
      }
    };

    fetchProfileData();
  }, [userId]);
  
  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!subject.trim() || !message.trim()) {
      toast.error("Please fill in all required fields");
      return;
    }
    
    if (!replyEmail.trim() || !validateEmail(replyEmail)) {
      toast.error("Please enter a valid email address");
      return;
    }
    
    setLoading(true);
    
    try {
      const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
        ? process.env.REACT_APP_SERVER_URL_PRODUCTION
        : process.env.REACT_APP_SERVER_URL_STAGING;
        
      const organization = profileData?.organization || 'Not provided';
      
      const supportMessage = {
        userId,
        username,
        organization,
        replyEmail, // Using the required reply email
        subject,
        message,
        type: 'user', // Indicates this is from user profile
        timestamp: new Date().toISOString()
      };
      
      const response = await fetch(`${apiUrl}/api/submit_support_message`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(supportMessage),
        credentials: 'include' // Include credentials if you're using cookies for authentication
      });
      
      const data = await response.json();
      
      if (data.success) {
        setSuccess(true);
        setSubject('');
        setMessage('');
        toast.success("Your message has been sent successfully!");
      } else {
        toast.error("Failed to send message: " + (data.message || 'Unknown error'));
      }
    } catch (err) {
      console.error("Error sending support message:", err);
      toast.error("Failed to send message. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  
  const handleReset = () => {
    setSuccess(false);
    setSubject('');
    setMessage('');
  };
  
  return (
    <AnimatedPage>
      <Box sx={{ 
        maxWidth: '800px', 
        mx: 'auto', 
        pt: 2, 
        px: 4,
        minHeight: '100vh',
        backgroundColor: '#ffffff',
      }}>
        <Paper 
          elevation={0}
          sx={{ 
            p: 4,
            backgroundColor: '#ffffff',
            borderRadius: '16px',
            border: '1px solid rgba(44, 62, 80, 0.1)',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
          }}
        >
          <Box sx={{ mb: 4, textAlign: 'center' }}>
            <HelpOutlineIcon 
              sx={{ 
                fontSize: 60, 
                mb: 2,
                color: '#2C3E50',
              }} 
            />
            <Typography 
              variant="h5" 
              sx={{ 
                fontWeight: 600,
                background: 'linear-gradient(45deg, #2C3E50 30%, #3A506B 90%)',
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                color: 'transparent',
                mb: 1
              }}
            >
              Need Help?
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
              We're here to help! Submit your question or issue and our support team will get back to you as soon as possible.
            </Typography>
          </Box>
          
          {success ? (
            <Box sx={{ mb: 4, textAlign: 'center' }}>
              <Alert severity="success" sx={{ mb: 2 }}>
                Thank you for contacting us! We've received your message and will respond to your email shortly.
              </Alert>
              <Button 
                variant="outlined" 
                color="primary" 
                onClick={handleReset}
                sx={{ mt: 2 }}
              >
                Send Another Message
              </Button>
            </Box>
          ) : (
            <form onSubmit={handleSubmit}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                <Box>
                  <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 500 }}>
                    Your Information
                  </Typography>
                  <Box 
                    sx={{ 
                      p: 2, 
                      bgcolor: 'rgba(44, 62, 80, 0.05)', 
                      borderRadius: '8px',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1
                    }}
                  >
                    <Typography variant="body2">
                      <strong>Username:</strong> {username || 'Not available'}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Organization:</strong> {profileData?.organization || 'Not available'}
                    </Typography>
                  </Box>
                </Box>
                
                <TextField
                  label="Email Address for Reply"
                  variant="outlined"
                  type="email"
                  fullWidth
                  value={replyEmail}
                  onChange={(e) => setReplyEmail(e.target.value)}
                  required
                  placeholder="Enter an email where we can reply to you"
                  error={replyEmail !== '' && !validateEmail(replyEmail)}
                  helperText={replyEmail !== '' && !validateEmail(replyEmail) ? "Please enter a valid email address" : ""}
                  sx={{ mt: 2 }}
                />
                
                <TextField
                  label="Subject"
                  variant="outlined"
                  fullWidth
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  required
                />
                
                <TextField
                  label="Message"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={6}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                  placeholder="Please describe your issue or question in detail..."
                />
                
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  disabled={loading}
                  endIcon={loading ? <CircularProgress size={20} /> : <SendIcon />}
                  sx={{
                    mt: 2,
                    background: 'linear-gradient(45deg, #2C3E50 30%, #3A506B 90%)',
                    color: '#fff',
                    '&:hover': {
                      background: 'linear-gradient(45deg, #1a2530 30%, #2a3b4b 90%)',
                    }
                  }}
                >
                  {loading ? 'Sending...' : 'Send Message'}
                </Button>
                
                <Typography variant="caption" sx={{ textAlign: 'center', color: 'text.secondary', mt: 1 }}>
                  * Required fields
                </Typography>
                
                <Typography variant="caption" sx={{ textAlign: 'center', color: 'text.secondary' }}>
                  We typically respond within 24-48 business hours.
                </Typography>
              </Box>
            </form>
          )}
        </Paper>
      </Box>
    </AnimatedPage>
  );
};

export default SupportPage;

