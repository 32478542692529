import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import AnimatedPage from './AnimatedPage';
import Footer from '../components/footer/Footer';
import AddTeamModal from '../components/modals/AddTeamModal';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import './RegisterPage.css';

function RegisterPage() {
  const { username, eventId } = useParams();
  const [eventSettings, setEventSettings] = useState(null);
  const [loadingEventSettings, setLoadingEventSettings] = useState(true);
  const [isAddTeamModalOpen, setIsAddTeamModalOpen] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date().getTime());
  const [registrationStatus, setRegistrationStatus] = useState("loading"); // "open", "closed", "earlyBird"
  
  // Add state for selected division
  const [selectedDivision, setSelectedDivision] = useState(0);

  useEffect(() => {
    const fetchEventSettings = async () => {
      const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
        ? process.env.REACT_APP_SERVER_URL_PRODUCTION
        : process.env.REACT_APP_SERVER_URL_STAGING;

      try {
        const response = await fetch(`${apiUrl}/api/load_event_profile`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username, eventId }),
        });
        const data = await response.json();
        if (data.success) {
          setEventSettings(data.settings);
          console.log('Register page fetched the following event settings: ', data.settings);
        } else {
          console.error('Error fetching event settings:', data.message);
          toast.error('Failed to load event settings');
        }
      } catch (error) {
        console.error('Error fetching event settings:', error);
        toast.error('Failed to load event settings');
      } finally {
        setLoadingEventSettings(false);
      }
    };

    fetchEventSettings();
  }, [username, eventId]);

  // Function to evaluate registration status based on current time
  const updateRegistrationStatus = () => {
    if (!eventSettings || !eventSettings.registration) {
      setRegistrationStatus("closed");
      return;
    }
    
    const now = new Date().getTime();
    
    // Check if registration is closed (after registration end date)
    const registrationDate = eventSettings.registration?.registrationDate
      ? parseInt(eventSettings.registration.registrationDate)
      : 0;
      
    if (registrationDate && now > registrationDate) {
      setRegistrationStatus("closed");
      return;
    }
    
    // Check if we're in early bird period
    const includeEarlyBird = eventSettings.registration.includeEarlyBird === true || 
                          eventSettings.registration.includeEarlyBird === "true";
    const earlyBirdDate = eventSettings.registration?.earlyBirdDate
      ? parseInt(eventSettings.registration.earlyBirdDate)
      : 0;
    
    if (includeEarlyBird && now < earlyBirdDate) {
      setRegistrationStatus("earlyBird");
    } else {
      setRegistrationStatus("open");
    }
    
    console.log("Registration status updated:", {
      now: new Date(now).toLocaleString(),
      earlyBirdDeadline: earlyBirdDate ? new Date(earlyBirdDate).toLocaleString() : 'Not set',
      registrationDeadline: registrationDate ? new Date(registrationDate).toLocaleString() : 'Not set',
      status: registrationStatus
    });
  };

  // Effect for real-time timer and status updates
  useEffect(() => {
    if (eventSettings) {
      // Update current time immediately
      updateRegistrationStatus();
      
      // Set up interval to update time every minute (60000ms)
      const interval = setInterval(() => {
        const now = new Date().getTime();
        setCurrentTime(now);
        updateRegistrationStatus();
      }, 60000);
      
      // Clean up interval on component unmount
      return () => clearInterval(interval);
    }
  }, [eventSettings]); // Re-initialize when event settings change

  // Handler for division tab change
  const handleDivisionChange = (event, newValue) => {
    setSelectedDivision(newValue);
  };
  
  // Function to format division name by removing " (default)" text  
  const formatDivisionName = (name) => {
    if (!name) return '';
    return name.replace(/\s*\(default\)\s*/g, '');
  };

  // Check if revenue sharing is enabled for team registration
  const isRegistrationEnabled = () => {
    return (
      eventSettings.packageType === 'revenue-share' && 
      (eventSettings.features?.onlineTeamRegistration === 'customerPays' || 
       eventSettings.features?.onlineTeamRegistration === 'iPay')
    );
  };

  // Show loading state
  if (loadingEventSettings || !eventSettings) {
    return (
      <AnimatedPage>
        <div style={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '100vh' 
        }}>
          <CircularProgress />
        </div>
      </AnimatedPage>
    );
  }

  // Detect if using divisions for pricing
  const hasDivisions = eventSettings.registration?.includeDivisions && 
                     eventSettings.registration?.divisions && 
                     eventSettings.registration?.divisions.length > 0;
  
  // Get current division data
  const currentDivisionData = hasDivisions 
    ? eventSettings.registration.divisions[selectedDivision] 
    : { 
        registrationPrice: eventSettings.registration.registrationPrice,
        earlyBirdPrice: eventSettings.registration.earlyBirdPrice
      };

  const handleOpenModal = () => {
    // Check if standard package (not revenue-share) is used
    if (eventSettings.packageType !== 'revenue-share' && 
        eventSettings.features?.onlineTeamRegistration === 'notNeeded') {
      toast.info('Team registration for standard package events is handled by the event organizer. Please contact them directly.');
      return;
    }
    
    // Check registration status before opening modal
    if (registrationStatus === "closed") {
      toast.error('Registration for this event has closed.');
      return;
    }
    
    // Additional check before opening modal
    if (!eventSettings) {
      toast.error('Event settings are not loaded. Please try again.');
      return;
    }
    
    setIsAddTeamModalOpen(true);
  };

  const formatDate = (epochTime) => {
    if (!epochTime) return 'Date not set';
    
    const options = { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
      timeZoneName: 'short'  // Adds time zone abbreviation (e.g., EST, PST)
    };
    return new Date(Number(epochTime)).toLocaleDateString('en-US', options);
  };

  const formatPrice = (price) => {
    if (!price) return '$0.00';
    
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(price);
  };

  // Extract primary and accent colors from event settings for consistent styling
  const primaryColor = eventSettings.basicInfo?.backgroundColor || '#333333';
  const accentColor = '#0281C5'; // Default button color

  return (
    <AnimatedPage>
      <main>
        {/* Banner */}
        <section 
          className="section-banner"
          style={{ 
            backgroundColor: primaryColor,
            color: eventSettings.basicInfo?.headlineTextColor || '#ffffff'
          }}
        >
          <h1>Register</h1>
        </section>

        {/* Registration Section */}
        <section className="section-register">
          <br/>
          <br/>
          <h1 className="register-heading">Registration Date & Fee Information</h1>
          <br/>
          <br/>

          {/* Add Division Tabs if divisions are present */}
          {hasDivisions && (
            <Box sx={{ width: '100%', mb: 4 }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs 
                  value={selectedDivision} 
                  onChange={handleDivisionChange} 
                  aria-label="division tabs"
                  centered
                  variant={eventSettings.registration.divisions.length > 3 ? "scrollable" : "standard"}
                  scrollButtons="auto"
                >
                  {eventSettings.registration.divisions.map((division, index) => (
                    <Tab 
                      key={index} 
                      label={formatDivisionName(division.name)} 
                      sx={{ 
                        fontWeight: 'medium',
                        fontSize: '1rem'
                      }} 
                    />
                  ))}
                </Tabs>
              </Box>
              <Box sx={{ p: 2, textAlign: 'center' }}>
                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', color: '#0281C5' }}>
                  {formatDivisionName(eventSettings.registration.divisions[selectedDivision].name)} Division
                </Typography>
              </Box>
            </Box>
          )}

          {/* Show early bird info if enabled */}
          {(eventSettings.registration.includeEarlyBird === true || 
            eventSettings.registration.includeEarlyBird === "true") && (
            <div style={{
              padding: '20px 25px', 
              borderRadius: '10px',
              border: registrationStatus === "earlyBird" ? '1px solid #4caf50' : '1px solid #e0e0e0',
              backgroundColor: registrationStatus === "earlyBird" ? 'rgba(76, 175, 80, 0.08)' : 'white',
              marginBottom: '25px',
              position: 'relative',
              boxShadow: registrationStatus === "earlyBird" ? '0 4px 12px rgba(76, 175, 80, 0.15)' : 'none',
              transition: 'all 0.3s ease'
            }}>
              {registrationStatus === "earlyBird" && 
                <div style={{
                  position: 'absolute',
                  top: '12px',
                  right: '12px',
                  backgroundColor: '#4caf50',
                  color: 'white',
                  padding: '4px 10px',
                  borderRadius: '20px',
                  fontSize: '0.75em',
                  fontWeight: 'bold',
                  letterSpacing: '0.5px',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.15)'
                }}>
                  CURRENT RATE
                </div>
              }
              <h2 className="register-subheading" style={{ 
                color: registrationStatus === "earlyBird" ? '#2e7d32' : '#333333',
                fontWeight: registrationStatus === "earlyBird" ? '600' : 'normal',
                marginTop: '5px',
                marginBottom: '15px'
              }}>
                Early Bird Registration
              </h2>
              <h3 className="register-price" style={{ 
                fontSize: registrationStatus === "earlyBird" ? '1.5em' : '1.3em',
                color: registrationStatus === "earlyBird" ? '#2e7d32' : '#0281C5',
                fontWeight: registrationStatus === "earlyBird" ? '600' : 'normal',
                marginBottom: '12px'
              }}>
                {/* Use division-specific pricing if available */}
                {formatPrice(hasDivisions ? 
                  currentDivisionData.earlyBirdPrice : 
                  eventSettings.registration.earlyBirdPrice)} per team
              </h3>
              <h4 className="register-date" style={{
                color: '#666666',
                marginTop: '0'
              }}>
                Available until {formatDate(eventSettings.registration.earlyBirdDate)}
              </h4>
            </div>
          )}

          {/* Regular registration */}
          <div style={{
            padding: '20px 25px', 
            borderRadius: '10px',
            border: registrationStatus === "open" ? '1px solid #4caf50' : '1px solid #e0e0e0',
            backgroundColor: registrationStatus === "open" ? 'rgba(76, 175, 80, 0.08)' : 'white',
            marginBottom: '25px',
            position: 'relative',
            boxShadow: registrationStatus === "open" ? '0 4px 12px rgba(76, 175, 80, 0.15)' : 'none',
            transition: 'all 0.3s ease'
          }}>
            {registrationStatus === "open" && 
              <div style={{
                position: 'absolute',
                top: '12px',
                right: '12px',
                backgroundColor: '#4caf50',
                color: 'white',
                padding: '4px 10px',
                borderRadius: '20px',
                fontSize: '0.75em',
                fontWeight: 'bold',
                letterSpacing: '0.5px',
                boxShadow: '0 2px 4px rgba(0,0,0,0.15)'
              }}>
                CURRENT RATE
              </div>
            }
            <h2 className="register-subheading" style={{ 
              color: registrationStatus === "open" ? '#2e7d32' : '#333333',
              fontWeight: registrationStatus === "open" ? '600' : 'normal',
              marginTop: '5px',
              marginBottom: '15px'
            }}>
              Regular Registration
            </h2>
            <h3 className="register-price" style={{ 
              fontSize: registrationStatus === "open" ? '1.5em' : '1.3em',
              color: registrationStatus === "open" ? '#2e7d32' : '#0281C5',
              fontWeight: registrationStatus === "open" ? '600' : 'normal',
              marginBottom: '12px'
            }}>
              {/* Use division-specific pricing if available */}
              {formatPrice(hasDivisions ? 
                currentDivisionData.registrationPrice : 
                eventSettings.registration.registrationPrice)} per team
            </h3>
            <h4 className="register-date" style={{
              color: '#666666',
              marginTop: '0'
            }}>
              Available until {formatDate(eventSettings.registration.registrationDate)}
            </h4>
          </div>

          <br/>
          <br/>

          {registrationStatus === "closed" ? (
            <div style={{
              backgroundColor: '#ffebee',
              padding: '20px',
              borderRadius: '8px',
              marginBottom: '20px',
              textAlign: 'center'
            }}>
              <h2 style={{ color: '#d32f2f', marginBottom: '10px' }}>Registration Closed</h2>
              <p>The registration period for this event has ended. Please contact the event organizers for assistance.</p>
            </div>
          ) : eventSettings.packageType !== 'revenue-share' && eventSettings.features?.onlineTeamRegistration === 'notNeeded' ? (
            <div style={{
              backgroundColor: '#f0f7ff',
              padding: '20px',
              borderRadius: '8px',
              marginBottom: '20px',
              textAlign: 'center',
              border: '1px solid #90caf9'
            }}>
              <h2 style={{ color: '#0d47a1', marginBottom: '10px' }}>Contact Event Organizer</h2>
              <p>This event uses manual registration. Please contact the event organizer directly to register your team.</p>
            </div>
          ) : (
            <button 
              className="register-button"
              onClick={handleOpenModal}
              style={{
                backgroundColor: accentColor,
                color: '#ffffff',
                borderColor: '#333333',
                borderWidth: '2px'
              }}
              disabled={eventSettings.packageType !== 'revenue-share' && eventSettings.features?.onlineTeamRegistration === 'notNeeded'}
            >
              Register Now!
            </button>
          )}
          <br/>
          <br/>

          {/* Registration Message */}
          {eventSettings.registration?.registrationMessage && (
            <>
              <h1 className="register-heading">Reminders & Disclaimers</h1>
              {eventSettings.packageType === 'revenue-share' && (
                <h4 className="register-note">All registrations will have a processing fee applied at checkout.</h4>
              )}
              <br/>
              <h4 className="register-note">{eventSettings.registration.registrationMessage}</h4>
            </>
          )}

          <br/>
          <br/>
          
        </section>

        <Footer />

        {/* Registration Modal */}
        <AddTeamModal
          status={isAddTeamModalOpen}
          close={() => setIsAddTeamModalOpen(false)}
          open={() => setIsAddTeamModalOpen(true)}
          eventSettings={eventSettings}
          selectedDivision={selectedDivision} // Pass selected division
          isAdmin={false}
          username={username}
          eventId={eventId}
          registrationStatus={registrationStatus}
          currentTime={currentTime}
        />
      </main>
    </AnimatedPage>
  );
}

export default RegisterPage;

