import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Paper,
  Typography,
  Button, 
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AnimatedPage from './AnimatedPage';
import ProfileField from '../components/dashboard/ProfileField';
import { toast } from 'react-toastify';

const ProfilePage = () => {
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editField, setEditField] = useState(null);
  const [editValue, setEditValue] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [validationError, setValidationError] = useState('');

  // Your existing validation functions
  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const validatePhone = (phone) => {
    return /^\(?([0-9]{3})\)?[-.?]?([0-9]{3})[-.?]?([0-9]{4})$/.test(phone);
  };

  const validateField = (field, value) => {
    switch (field) {
      case 'email':
        if (!validateEmail(value)) return 'Please enter a valid email address';
        break;
      case 'phone':
        if (value && !validatePhone(value)) return 'Please enter a valid phone number';
        break;
      case 'organization':
        if (!value.trim()) return 'Organization name cannot be empty';
        break;
      default:
        return '';
    }
    return '';
  };

  useEffect(() => {
    const fetchProfileData = async () => {
      const user = JSON.parse(window.localStorage.getItem('user'));
      if (!user || !user.uid) {
        setError("User not found in local storage.");
        setLoading(false);
        return;
      }

      try {
        const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
          ? process.env.REACT_APP_SERVER_URL_PRODUCTION
          : process.env.REACT_APP_SERVER_URL_STAGING;

        const response = await fetch(`${apiUrl}/api/get_user_profile_data`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ userId: user.uid }),
        });

        if (response.ok) {
          const data = await response.json();
          if (data.success && data.userData) {
            setProfileData(data.userData);
          } else {
            setError("Failed to fetch profile data: " + (data.message || 'Unknown error'));
          }
        } else {
          setError("Failed to fetch profile data.");
        }
      } catch (err) {
        console.error("Error fetching profile data:", err);
        setError("Failed to fetch profile data.");
      } finally {
        setLoading(false);
      }
    };

    fetchProfileData();
  }, []);

  const handleEdit = (field, value) => {
    setEditField(field);
    setEditValue(value || '');
    setValidationError('');
  };

  const handleCancel = () => {
    setEditField(null);
    setEditValue('');
    setValidationError('');
  };

  const handleSave = async () => {
    console.log('Saving field:', editField, 'with value:', editValue);
    
    const error = validateField(editField, editValue);
    if (error) {
      setValidationError(error);
      return;
    }
  
    try {
      const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
        ? process.env.REACT_APP_SERVER_URL_PRODUCTION
        : process.env.REACT_APP_SERVER_URL_STAGING;
  
      // If updating organization, also update username
      const updates = {
        userId: profileData.userId,
        field: editField,
        value: editValue
      };
  
      let newUsername = null;
      if (editField === 'organization') {
        const convertToCamelCase = (string) => string.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
          index === 0 ? word.toLowerCase() : word.toUpperCase()
        ).replace(/\s+/g, '');
        
        newUsername = convertToCamelCase(editValue);
        updates.username = newUsername;
      }
  
      console.log('Sending update request:', updates);
  
      const response = await fetch(`${apiUrl}/api/update_user_profile`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache'
        },
        body: JSON.stringify(updates),
      });
  
      const data = await response.json();
      console.log('Received response:', data);
  
      if (data.success) {
        const updatedData = {
          ...profileData,
          [editField]: editValue
        };
  
        if (editField === 'organization') {
          updatedData.username = newUsername;
          window.localStorage.setItem('username', newUsername);
          // Update URL to reflect new username
          navigate(`/${newUsername}/profile`);
        }
  
        setProfileData(updatedData);
        toast.success(`Field updated successfully`);
        setEditField(null);
        setEditValue('');
        setValidationError('');
  
        // Refetch profile data to ensure we have the latest state
        const refreshResponse = await fetch(`${apiUrl}/api/get_user_profile_data`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ userId: profileData.userId }),
        });
  
        if (refreshResponse.ok) {
          const refreshData = await refreshResponse.json();
          if (refreshData.success && refreshData.userData) {
            setProfileData(refreshData.userData);
          }
        }
      } else {
        toast.error("Failed to update profile: " + (data.message || 'Unknown error'));
      }
    } catch (err) {
      console.error("Error updating profile:", err);
      toast.error("Failed to update profile");
    }
  };

  const handleDelete = async () => {
    try {
      const apiUrl = process.env.REACT_APP_NODE_ENV === 'production'
        ? process.env.REACT_APP_SERVER_URL_PRODUCTION
        : process.env.REACT_APP_SERVER_URL_STAGING;

      const response = await fetch(`${apiUrl}/api/delete_user_profile`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId: profileData.userId }),
      });

      const data = await response.json();
      if (data.success) {
        toast.success("Profile deleted successfully");
        window.localStorage.clear();
        navigate('/');
      } else {
        toast.error("Failed to delete profile: " + data.message);
      }
    } catch (err) {
      console.error("Error deleting profile:", err);
      toast.error("Failed to delete profile");
    }
    setDeleteDialogOpen(false);
  };

  if (loading) return (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center',
      height: 'calc(100vh - 200px)'
    }}>
      <Typography variant="h6" color="text.secondary">
        Loading profile...
      </Typography>
    </Box>
  );

  if (error) return (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center',
      height: 'calc(100vh - 200px)'
    }}>
      <Typography variant="h6" color="error">
        {error}
      </Typography>
    </Box>
  );

  return (
    <AnimatedPage>
      <Box sx={{ 
        maxWidth: '800px', 
        mx: 'auto', 
        pt: 2, 
        px: 4,
        minHeight: '100vh',
        backgroundColor: '#ffffff', // Matching background
      }}>
        <Paper 
          elevation={0}
          sx={{ 
            p: 4,
            backgroundColor: '#ffffff', // Solid white
            borderRadius: '16px',
            border: '1px solid rgba(44, 62, 80, 0.1)',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
          }}
        >
          {/* Account Creation Info */}
          <Box sx={{ mb: 4 }}>
            <Typography 
              variant="h6" 
              sx={{ 
                fontWeight: 600,
                background: 'linear-gradient(45deg, #2C3E50 30%, #3A506B 90%)',
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                color: 'transparent',
                mb: 1
              }}
            >
              Account Created
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Box sx={{ textAlign: 'center', py: 2 }}>
              <Typography variant="body1">
                {new Date(profileData.dateProfileCreated).toLocaleDateString()}
              </Typography>
            </Box>
          </Box>

          {/* Organization Section */}
          <Box sx={{ mb: 4 }}>
            <Typography 
              variant="h6" 
              sx={{ 
                fontWeight: 600,
                background: 'linear-gradient(45deg, #2C3E50 30%, #3A506B 90%)',
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                color: 'transparent',
                mb: 1
              }}
            >
              Organization Details
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Box sx={{ '& > div': { py: 2 } }}>
              <ProfileField
                label="Organization"
                value={profileData.organization || 'Not provided'}
                field="organization"
                editField={editField}
                editValue={editValue}
                validationError={validationError}
                onEdit={handleEdit}
                onSave={handleSave}
                onCancel={handleCancel}
                onValueChange={setEditValue}
              />
              <ProfileField
                label="Username"
                value={profileData.username}
                field="username"
                editField={editField}
                editValue={editValue}
                validationError={validationError}
                onEdit={handleEdit}
                onSave={handleSave}
                onCancel={handleCancel}
                onValueChange={setEditValue}
              />
            </Box>
          </Box>

          {/* Contact Section */}
          <Box sx={{ mb: 4 }}>
            <Typography 
              variant="h6" 
              sx={{ 
                fontWeight: 600,
                background: 'linear-gradient(45deg, #2C3E50 30%, #3A506B 90%)',
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                color: 'transparent',
                mb: 1
              }}
            >
              Contact Information
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Box sx={{ '& > div': { py: 2 } }}>
              <ProfileField
                label="Login Email"
                value={profileData.email}
                field="email"
                editField={editField}
                editValue={editValue}
                validationError={validationError}
                onEdit={handleEdit}
                onSave={handleSave}
                onCancel={handleCancel}
                onValueChange={setEditValue}
              />
              <ProfileField
                label="Phone"
                value={profileData.phone || 'Not provided'}
                field="phone"
                editField={editField}
                editValue={editValue}
                validationError={validationError}
                onEdit={handleEdit}
                onSave={handleSave}
                onCancel={handleCancel}
                onValueChange={setEditValue}
              />
            </Box>
          </Box>

          {/* Danger Zone */}
          <Paper 
            elevation={0}
            sx={{ 
              p: 3, 
              bgcolor: 'rgba(211, 47, 47, 0.05)', 
              borderRadius: '8px',
              border: '1px solid rgba(211, 47, 47, 0.2)'
            }}
          >
            <Typography variant="h6" color="error" sx={{ mb: 1 }}>
              Danger Zone
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Once you delete your profile, there is no going back. Please be certain.
            </Typography>
            <Button
              variant="outlined"
              color="error"
              onClick={() => setDeleteDialogOpen(true)}
              sx={{ 
                borderColor: 'rgba(211, 47, 47, 0.5)',
                '&:hover': {
                  borderColor: 'rgba(211, 47, 47, 0.8)',
                  bgcolor: 'rgba(211, 47, 47, 0.08)'
                }
              }}
            >
              Delete User Profile
            </Button>
          </Paper>

          {/* Delete Confirmation Dialog */}
          <Dialog
            open={deleteDialogOpen}
            onClose={() => setDeleteDialogOpen(false)}
          >
            <DialogTitle>Delete User Profile?</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure? All user profile and individual event data will be permanently deleted and setup fees will not be refunded. This action cannot be undone. If you are having technical issues and would like some assistance, 
                please contact support@customtournamentsolutions.com.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
              <Button onClick={handleDelete} color="error" autoFocus>
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </Box>
    </AnimatedPage>
  );
};

export default ProfilePage;

